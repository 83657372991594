import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';
import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
import SideManu from '../sidebar_international';

const Eligibility = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Pre Arrival Information" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div class="container text-justify">
                                                    <br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Pre Arrival Information</b> <br />
                                                    <b className='name' style={{ textAlign: "justify" }}> Students should make sure about the following points before leaving their home country:</b><br/><br/>
                                                    <p   style={{ textAlign: "justify" }}>
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Aditya Educational Institution is just 2 hours drive away from Vishakhapatnam International Airport, and you need to book your ticket to Vishakhapatnam International Airport after taking a Valid Student Visa from the Indian Embassy / High Commission</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Take all the required vaccinations like Yellow Fever, Typhoid Vaccine, Hepatitis Vaccine, etc. to evade medical complications during your stay in India </span><br/>
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Make sure you report to Aditya Educational Institution -International Relations Division for the Airport pickup at least two weeks before arrival and provide the complete details of the flight.</span><br/>
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Make sure that you bring the Admission Letter issued by the Institute along with all the Certificates/Degrees and Transcripts (in original) and 15 passport size photographs </span><br/>
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />	You can carry all your money in the form of Travellers Cheques, which can be easily redeemed at the airport as well as many foreign exchange offices and banks or you can use International Debit / Credit Cards. </span><br/>
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> During summers the temperatures range between 30 to 45 degree Celsius and 18 to 25 degree Celsius during winters. The winter season starts around November so the warm material (blankets, quilts, woolens, etc.) must be brought accordingly. </span><br/>
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Even though everything is available in India, yet try to get all that you would require during the initial few days. All the essentials can be availed from the Institute tuck shops as well as markets available around a distance of 1 km from the institute hostel campus </span><br/>
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> The electric standard in India works on 240 V. So, ensure that you have an adapter for your mobile or laptop accordingly. Also, the electrical sockets are 2 or 3 pins so ensure that you carry a converter in case required. </span><br/>
                                        
                                                        
                                                    </p>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            </div>
            <FooterTwo />
        </>
    );
}

export default Eligibility;