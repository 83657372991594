import React from 'react';

import SectionTitle from '../../components/SectionTitle'

import SingleService from '../../components/Service/SingleService';

import campus1 from '../../assets/images/campus/CULTURAL EVENTS.jpg'
import campus2 from '../../assets/images/campus/SPORTS EVENTS.jpg'
import campus3 from '../../assets/images/campus/INCLUSIVE ECOSYSTEM.jpg'
import campus4 from '../../assets/images/campus/campus-life-4.jpg'
import tempcampus4 from '../../assets/images/campus/SPIRITUAL HARMONY.jpg'

const Service = () => {

    return (
        <div className="react_popular_topics pt---100 pb---70">
            <div className="container">
                <SectionTitle Title="Campus Life" />
                <div className="row">
                    <div className="col-lg-3 wow animate__fadeInUp" data-wow-duration="0.3s">
                        <SingleService
                            itemImg={campus1}
                            title="Abhinaya Club"
                        />
                    </div>
                    <div className="col-lg-3 wow animate__fadeInUp" data-wow-duration="0.5s">
                        <SingleService
                            itemImg={campus2}
                            title="Sports in Action"
                        />
                    </div>
                    <div className="col-lg-3 wow animate__fadeInUp" data-wow-duration="0.7s">
                        <SingleService
                            itemImg={campus3}
                            title="Inclusive Ecosystem"
                        />
                    </div>
                    <div className="col-lg-3 wow animate__fadeInUp" data-wow-duration="0.9s">
                        <SingleService
                            itemImg={tempcampus4}
                            title="Spiritual Harmony"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;