import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import '../../../components/Styles/pragram.css';
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_opportunities';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                  <StudyBreadcrumb pageTitle="CAREER DEVELOPMENT CENTRE" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                <div class="container">
                                                    <center><b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}> CAREER DEVELOPMENT CENTRE </b> <br /></center>
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}> Overview :</b> <br />
                                                     <p className='name'>
                                                     Welcome to the Career Development Centre, your gateway to a successful future. At our centre, we are committed to empowering you with the tools and opportunities needed to excel in your chosen career path. Whether you are seeking Placements, Training programs, Higher education guidance, Internships or support for Entrepreneurial ventures, we are here to assist you at every step. Our dedicated team provides personalized services to ensure you achieve your professional aspirations and thrive in a dynamic job market. Explore our resources and discover how we can help you navigate your career journey with confidence and success.
                                                    </p>
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}> Objectives of CDC :</b> <br />
                                                     <span className='name'><img src={point} style={{height:"25px"}}/> To Facilitate Career Planning and Exploration</span><br/>
                                                     <span className='name'><img src={point} style={{height:"25px"}}/> To Increase Job Placement and Internship Opportunities</span><br/>
                                                     <span className='name'><img src={point} style={{height:"25px"}}/> To Support Higher Education and Lifelong Learning </span><br/>
                                                     <span className='name'><img src={point} style={{height:"25px"}}/> To Promote Entrepreneurship and Innovation </span><br/>
                                                     <span className='name'><img src={point} style={{height:"25px"}}/> To Promote Global Career Opportunities </span><br/>
                                             </div>
                                               <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;