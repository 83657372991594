import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_mech';
import Banner from './banner';
// import MECHPopUp from "../../../../../components/Popup/Mechanical/MechanicalPopUp";






const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    {/* <MECHPopUp /> */}

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  B-Tech </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>B. Tech Mechanical Engineering at Aditya University offers a comprehensive curriculum designed to equip students with the knowledge and skills necessary to excel in the field. Throughout the program, students delve into core subjects such as thermodynamics, fluid mechanics, materials science, manufacturing processes, automobile engineering, and machine design, gaining a solid foundation in the principles that underpin modern engineering practices. With state-of-the-art facilities and laboratories, students have ample opportunities for hands-on experience, applying theoretical concepts to real-world challenges. Our esteemed faculty, comprised of industry experts and seasoned academics, provides mentorship and guidance, nurturing the next generation of Mechanical Engineers poised to make impactful contributions to industries ranging from automotive to aerospace.</p>

                                                        <p className='name'>The Department also places a strong emphasis on holistic development, encouraging the development of critical thinking, collaboration, and creativity in addition to technical expertise. Students get vital real-world experience and exposure to cutting-edge technology through industry partnerships, internships, and research opportunities, setting them up for success in a global marketplace that is changing quickly.  Additionally, the university provides students with the flexibility to pursue minor degree programs in complementary fields. Through these minors, students can customize their education to align with their interests and career objectives, equipping them with a comprehensive skill set that holds significant value in today's competitive job market. With a focus on interdisciplinary collaboration and a commitment to excellence, our Mechanical Engineering program at Aditya University empowers students to become leaders and innovators in shaping the future of engineering. </p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Mechanical Engineering <br />
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Mechanical Engineering with minor Specialization in
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Automation & Robotics</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Thermal Engineering</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Automotive Engineering</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Design and Manufacturing</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Advanced Specialization on Electric Vehicles (Mechanical)  <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Partners - L&T) </b></span> <br />
                                                        </p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Mechanical Engineering with 
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Civil  Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Electrical and Electronics Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Electronics and Communication Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Computer Science and Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Data Science<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Artificial Intelligence and Machine Learning<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Petroleum Technology<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Mining Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Agricultural Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Entrepreneurship Development and Incubation<br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;