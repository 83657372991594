import React from 'react';
import { useState } from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_aiml';
import Banner from './banner';
// import AIMLPopUp from "../../../../../components/Popup/AIML/AIMLPopUp";






const CEview = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    {/* <AIMLPopUp /> */}
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  B.Tech </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>B.Tech - AI&ML is a part of Industrial revolution, is committed to advancing the frontiers of AI&ML through rigorous research, high-quality education, and strong industry partnerships. By fostering a collaborative and innovative environment, the department aims to make significant contributions to technology and society, shaping the future of intelligent systems. We initiate with the foundational courses in Core Computer Science, Introduction to AI&ML and Programming in C. Later advances with Algorithms related to basic and complex Data Structures and AI, Java & Advanced Java with Spring Boot Framework with REST-API, Python with Libraries and Frameworks, BDA Frameworks. We also concentrate on specialized AI&ML subjects and algorithms in DWM, ML, DL, RL, SC, NLP, CV, AR/VR..</p>

                                                        <p className='name'>We aim at Industry collaboration and conduct Workshops, Hackathons and Seminars. We sign MoU’s with leading technology companies and startups to collaborate and bridge the gap between academic research and real-world applications. Through MoU’s we aim for Internships and Co-ops to provide students with practical experience through industry placements, enhancing their learning and career prospects. Conduct regular events featuring leading experts in AI&ML to discuss the latest advancements and research findings. We make students to participate in AI&ML hackathons to encourage innovation and problem-solving among students to transform them as professionals.</p>
                                                        <p className='name'>

                                                            <img src={point} style={{ height: "25px" }} />  B.Tech in Artificial Intelligence & Machine Learning <br />
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech in Artificial Intelligence & Machine Learning along with Minor Degree in
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>

                                                            <span className='name'><img src={point1} style={{ height: "25px" }} /> Cognitive Analytics</span><br />
                                                            <span className='name'><img src={point1} style={{ height: "25px" }} /> Data Science</span><br />
                                                            <span className='name'><img src={point1} style={{ height: "25px" }} /> Mobile and Web Application Development</span><br />
                                                            <span className='name'><img src={point1} style={{ height: "25px" }} /> Cyber Security <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'><img src={point1} style={{ height: "25px" }} /> Agile Methodology with DevOps Programming <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'><img src={point1} style={{ height: "25px" }} /> Cloud Infrastructure <b style={{ color: "#328afc", fontSize: "17px" }}> (Certification Programs - CISCO, Red hat, Oracle, AWS, Google & Microsoft) </b> </span><br />
                                                            <span className='name'><img src={point1} style={{ height: "25px" }} /> Rapid Application Development 
                                                            <b style={{ color: "#328afc", fontSize: "17px" }}>(Certification Programs - PEGA, Oracle, Microsoft & Salesforce)</b></span><br />
                                                            <span className='name'><img src={point1} style={{ height: "25px" }} /> Entrepreneurship Development & Incubation</span><br />
                                                            {/* <span className='name'> <img src={point1} style={{ height: "25px" }} /> Cloud Infrastructure <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Partners - CISCO, Red hat, Oracle, AWS, Google & Microsoft) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Rapid Application Development <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Partners - PEGA, Oracle, Microsoft & Salesforce) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Agile Methodology with DevOps Programming <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Data Engineering <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Business Analytics <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Cyber Security <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Infrastructure Technology <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Technology for Digital Resilience <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Smart ICT Infrastructure (Electronics) <b style={{ color: "#328afc", fontSize: "17px" }}>  (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Cognitive Analytics</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Networks & Security</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Mobile and Web Application Development</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Automation & Robotics</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Entrepreneurship Development & Incubation</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Smart ICT Infrastructure (Electrical)</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Social Science</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Bio Medical Engineering</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Legal Studies</span><br /> */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;