import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import MenuItems from './MenuItems';
import img1 from './Ribbon.png';



import normalLogo from '../../assets/images/logos/AU-logo.jpg';
import stickyLogo from '../../assets/images/logos/AU-logo.jpg';
// import NAAC from '../../assets/images/logos/NAAC.png';
// import NIRF from '../../assets/images/logos/NIRF.png';

import Scrolling from './scrolling';




import DropdownExample from './DropdownExample';
// import Shortlisted  from '../../assets/images/programsoffered/Ph.D_2024.pdf';
// import Veda from '../../assets/images/programsoffered/veda.jpeg';
import PBI from '../../assets/images/programsoffered/PBI.jpg';
import FDP from '../../assets/images/programsoffered/FDP.pdf';





const handleMouseOver = (event) => {
	event.target.stop();
};

const handleMouseOut = (event) => {
	event.target.start();
};


const Header = (props) => {
	const navigate = useNavigate();
	const styles = {
		center: {
			margin: '0 auto'
		},
		awesome: {
			fontFamily: 'futura',
			// fontStyle: 'italic',
			width: '100%',
			margin: '-6px auto',
			textAlign: 'center',
			color: '#313131',
			fontSize: '15px',
			fontWeight: 'bold',
			WebkitAnimation: 'colorchange 20s infinite alternate',
			marginTop: '0px',
		}

	};
	const styles1 = {
		center: {
			margin: '0 auto'
		},
		awesome1: {
			fontFamily: 'futura',
			// fontStyle: 'italic',
			width: '100%',
			margin: '-6px auto',
			textAlign: 'center',
			color: '#cddc39',
			fontSize: '15px',
			fontWeight: 'bold',
			WebkitAnimation: 'colorchange1 20s infinite alternate',
			marginTop: '0px',
		}

	};


	const keyframes = `
		@-webkit-keyframes colorchange {
		  0% { color:  #ffffff; }
		  10% { color: #eeff41; }
		  20% { color: #00e5ff; }
		  30% { color: #ffffff; }
		  40% { color: #eeff41 ; }
		  50% { color: #00e5ff; }
		  60% { color: #ffffff; }
		  70% { color: #eeff41 ; }
		  80% { color: #00e5ff; }
		  90% { color: #ffffff; }
		  100% { color: #eeff41; }
		}
	   @-webkit-keyframes colorchange1 {
		  0% { color:  #ce93d8; }
		  10% { color: #4fc3f7; }
		  20% { color: #69f0ae; }
		  30% { color: #ce93d8; }
		  40% { color: #4fc3f7 ; }
		  50% { color: #69f0ae; }
		  60% { color: #ce93d8; }
		  70% { color: #4fc3f7 ; }
		  80% { color: #69f0ae; }
		  90% { color: #ce93d8; }
		  100% { color: #4fc3f7; }
		}
	  `;
	const { topbarEnable, menuCategoryEnable, headerClass, parentMenu, headerNormalLogo, headerStickyLogo } = props;

	const [menuOpen, setMenuOpen] = useState(false)
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		// Sticky is displayed after scrolling for 100 pixels
		const toggleVisibility = () => {
			if (window.pageYOffset > 100) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);

	const sty = {
		fontSize: '16px',
		lineHeight: '10px',
		fontWeight: '600',
		borderRadius: '6px',
		border: '2px solid rgba(0, 48, 110, 0.1)',
		padding: '14px 35px',
		color: 'white !important',
		display: 'inline-block',
		height: '43px',
		marginTop: '25px',
		background: '#04306c',
		verticalAlign: 'middle',
	};
	const [currentMarquee, setCurrentMarquee] = useState(0);
	const handleMarqueeClick1 = () => {
		localStorage.setItem('event_status', 1);
		navigate('/academy_events');
	};
	// const handleMarqueeClick2 = () => {
	// 	window.open('assets/images/programsoffered/List of shortlisted candidates for Interview with schedule -Ph.D Admission-2024.zip'); // Update with your PDF file URL
	// };
	
	useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMarquee((prevMarquee) => (prevMarquee + 1) % 3);
    }, 10000); 
    return () => clearInterval(interval);
  }, []);

	return (
		<>

			<header id="react-header" className={headerClass ? headerClass : 'react-header react-header-three'}>
				<div className={isVisible ? 'header-area react-sticky' : 'header-area'}>
					{
						topbarEnable ?
							<div className="topbar-area style1">
								<div className="container-fixed">
									<div className="row">
										<div className="col-lg-8" style={{ marginTop: "5px" }}>
											<div className="topbar-contact">
												<ul>
													<li></li>

													<li>
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
														<a href="tel:(+91)9989776661"> (+91)9989 776661</a>
													</li>
													<li>
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
														<a href="mailto:info@adityauniversity.in">info@adityauniversity.in</a>
													</li>


													{/* <li>
														<div>
															<style>
																{keyframes}
															</style>
															< a href='/phd_programs'><div style={styles.awesome} className="center">
																PhD Admission for AY: 2024-25
															</div></a>
														</div>
													</li>

													<li>
														<div style={styles1.awesome1} className="center">
															EAPCET Counselling Code: <b>ADTPPU</b>
														</div>

													</li> */}

												</ul>
											</div>

										</div>
										<div className="col-md-2">
											<div className='applybuttion_now' style={{ width: "90%", margin: "10px auto", padding: "5px", height: "10px", borderRadius: "15px" }}>
												<p style={{ lineHeight: "20px" }}>
													<a href="https://apply.adityauniversity.in" target="_blank" style={{ color: "#9a0000" }}>
														<b className="applybuttion text page__text content" data-text="Apply Now !">Apply Now !</b>
													</a>
												</p>
											</div>
										</div>
										<div className="col-md-2">
											<DropdownExample />
										</div>
										<div className="marquee-container" >
									        	{/* <div className="marquee">
													<Link to={PBI} target="blank"> Online Certification Course on "Data Visualization with Power BI & Tableau", 23/09/2024 - 04/10/2024 by Aditya Learning Academy. </Link>
												</div> */}
											
											{/* {currentMarquee === 0 && (
												<div className="marquee" onClick={handleMarqueeClick1}>
													 Online Certification Course on "Data Visualization with Power BI & Tableau", 23/09/2024 - 04/10/2024 by Aditya Learning Academy.
												</div>
											)} */}
											
											{currentMarquee === 0 && (
												<div className="marquee">
													<Link to={FDP} target="blank"> ATAL FDP on “AI & ML Empowerment in Energy Storage and Advanced Robotics” from 9th to 14th December 2024, Organized by Department of Mechanical Engineering.. </Link>
												</div>
											 )}
											 {currentMarquee === 1 && (
												<div className="marquee"> 
												   <Link to={'https://adityauniversity.in/static/media/event1.d4eb0ced0232592ba4a2.png'} target="blank"> 
												   An Online Lecture Series on "Emerging Trends of Research in Electrical Engineering" from 6th to 16 th November 2024, Organized by Aditya Learning Academy(ALA).</Link>
												</div>
                                            )} 
											 {currentMarquee === 2 && (
												<div className="marquee"> 
												   <Link to={'https://adityauniversity.in/static/media/event.4acddd40e759ff792242.png'} target="blank"> 
												   An Online Lecture Series on "Research Scope in Emerging Areas of Civil Engineering"  from 20/11/2024 to 30/11/2024, Organized by Aditya Learning Academy(ALA).</Link>
												</div>
                                            )}
									   </div>

									</div>
								</div>
							</div> : ''
					}
					<div className="menu-part">
						<div className="container">
							<div className="react-main-menu">
								<nav>
									<div className="menu-toggle">
										<div className="logo">

											{
												isVisible ?
													<Link to="/" className="logo-text">
														<img src={headerStickyLogo ? headerStickyLogo : stickyLogo} alt="" style={{ height: "80px" }}  />
														
													</Link> :
													<Link to="/" className="logo-text">
														<img src={headerNormalLogo ? headerNormalLogo : normalLogo} alt="" style={{ height: "80px" ,marginLeft:'-9px'}} className='universitylogo' />
														
													</Link>
											}
											<Scrolling/>      
										</div>

										<button type="button" id="menu-btn" className={menuOpen ? "mobile-menu-btn open" : "mobile-menu-btn"} onClick={() => { setMenuOpen(!menuOpen) }}>
											<span className="icon-bar"></span>
											<span className="icon-bar"></span>
											<span className="icon-bar"></span>
										</button>
									</div>
									<div className={menuOpen ? "react-inner-menus menu-open" : "react-inner-menus"}>


										{/* {
											menuCategoryEnable ?
											<div className="cate-part">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
												<ul className="react-category-menu">
													<li><Link to="#">Categories <i className="arrow_carrot-down"></i></Link> 
														<ul>
															<li><Link to="/course">English Book</Link></li>
															<li><Link to="/course">Math Book</Link></li>
															<li><Link to="/course">Story Book</Link></li>
														</ul>
													</li>
												</ul>
											</div> : ''
										} */}
										
										<ul id="backmenu" className="react-menus react-sub-shadow">
									     	
											<MenuItems
												parentMenu={parentMenu}
											/>
										</ul>
										{/* <div className="searchbar-part"> 
											<form className="search-form">
												<input type="text" className="form-input" placeholder="Search Course" />
												<Link to="/course" className="form-button">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
												</Link>
											</form>
										</div>  */}

										{/* <div className="searchbar-part" style={sty}>
											<ul className="wow animate__fadeInUp" data-wow-duration="0.9s">
												<li><Link to="/admissions" className="more-about"> National </Link></li>
                                            </ul>
											
										</div> */}
										<div>
											<div className='admission_2024 admission_2024_1' id="navbar111" style={{ backgroundImage: `url(${img1})`, width: "152px", height: "142px" }}>
												<b className="">Admissions</b>
												<div style={{ width: "90%", marginLeft: "5px", margin: "10px auto", padding: "5px", height: "30px", borderRadius: "15px" }}>
													<p style={{ lineHeight: "20px" }}>
														<a href="/admissionprocess" style={{ color: "#9a0000" }}>
															<b className="button1">National</b>
														</a>
													</p>
													<div>
														<div style={{ width: "110%", marginLeft: "-2px", margin: "10px auto", padding: "5px", height: "30px", borderRadius: "15px" }}	>
															<p style={{ lineHeight: "20px" }}>
																<a href="https://www.aditya.ac.in/international/" target="_blank" style={{ color: "#9a0000" }}>
																	<b className="button2">International</b>
																</a>
															</p>
														</div>
													</div>

												</div>
											</div>
										</div>
									</div>
								</nav>
							</div>
						</div>
					</div>
				</div >
			</header >
		</>
	);
}

export default Header;