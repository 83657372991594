import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';
import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
import SideManu from '../sidebar_international';
import India from './images/india.png';





import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const Eligibility = (props) => {
    let tab1 = "For International Students (Except Bangladesh, Bhutan, Nepal & Sri Lanka)",
        tab2 = "From Bangladesh, Bhutan, Nepal  & Sri Lanka "
    const tabStyle = 'nav nav-tabs';


    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div className="back__course__page_grid react-courses__single-page pb---16 pt---110">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Payment Procedure" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div class="container text-justify">
                                                    <br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Payment Procedure</b> <br />
                                                    <Tabs>
                                                        <div className="course-single-tab">
                                                            <TabList className={tabStyle}>
                                                                <Tab><button>{tab1}</button></Tab>
                                                                <Tab><button>{tab2}</button></Tab>

                                                            </TabList>

                                                            <div>
                                                                <TabPanel>
                                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>For International Students (Except Bangladesh, Bhutan, Nepal & Sri Lanka)</b> <br />
                                                                    <p style={{ textAlign: "justify" }}>
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Mode of payment</span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /><b> Option I</b> </span><br />

                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Through Bank Transfer SWIFT/RTGS/NEFT/WIRE</span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Click here for Bank account details</span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Currency in which payment can be made: - USD (United States Dollar)</span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Minimum Provisional Registration fee to be paid</span><br />
                                                                        <p class="name" style={{ padding: '14px' }}>
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> For Tuition Fee:- <b> USD 500</b> </span><br />
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> For Residential Facility:-<b> USD 500 </b></span>
                                                                        </p>
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Important Information : </span><br />
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> The amount paid for the Provisional Registration (Tuition Fee) will be adjusted towards Admission Processing Fee and the first semester tuition fee. The Provisional Registration Fee (Residential Facility) will be adjusted towards the first year of residential facility charges</span><br />
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> In case of any processing fee or other charges are levied by the bank/agency during transferring the fee etc. that will be borne by the applicant/students. The Institute will update the amount it has received in its account and difference of the amount received by the institute will be required to be deposited additionally by the applicant/student.</span><br />
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> It is the responsibility of student to ensure that bank mentions all the remittance details including student’s name, registration no./Offer Letter no., currency and country during transfer the money to Institute Bank account. In the absence of these details money may not be credited in students Institute fee account and may result in loss of attendance, fines and extra cost for which student will only be responsible. Also, student must ensure to submit the payment transfer proof to Institute’s accounts department on time.</span><br />
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> Students admitted through associates/ agencies in their countries should collect the transfer proof or Agency fee receipt and send us through email global@aditya.ac.in</span><br />
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> Any excess amount transferred in the Institute account will only be adjusted in the subsequent term/year fee.</span><br />
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> The fee and other components mentioned in the Offer Letter/ Acceptance Letter may not be error free thus for the most updated information please visit the Institute website or please contact the admission office of the Institute at <b>global@aditya.ac.in .</b></span><br />
                                                                    </p>
                                                                
                                                                <br />
                                                            </TabPanel>
                                                            <TabPanel>
                                                                <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}> From Bangladesh, Bhutan, Nepal  & Sri Lanka</b> <br />
                                                                <p style={{ textAlign: "justify" }}>
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Mode of payment</span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /><b> Option I</b> </span><br />

                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Through Bank Transfer SWIFT/RTGS/NEFT/WIRE</span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Click here for Bank account details</span><br /><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> <b> Option II</b></span><br />

                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Through Bank Demand Draft or Pay Order</span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Currency in which payment can be made: – INR (Indian Rupee)</span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Minimum Provisional Registration fee to be paid</span><br />
                                                                        <p class="name" style={{ padding: '14px' }}>
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> For Tuition Fee:- <b>INR 25000</b> </span><br />
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> For Residential Facility:- <b>INR 25000</b></span>
                                                                        </p>
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Important Information : </span><br />
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> The amount paid for the Provisional Registration (Tuition Fee) will be adjusted towards Admission Processing Fee and the first semester tuition fee. The Provisional Registration Fee (Residential Facility) will be adjusted towards the first year of residential facility charges.</span><br />
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} />In case of any processing fee or other charges are levied by the bank/agency during transferring the fee etc. that will be borne by the applicant/students. The institute will update the amount it has received in its account and difference of the amount received by the Institute will be required to be deposited additionally by the applicant/student.</span><br />
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> It is the responsibility of student to ensure that bank mentions all the remittance details including student’s name, registration no./Offer Letter no., currency and country during transfer the money to Institute Bank account. In the absence of these details money may not be credited in students Institute fee account and may result in loss of attendance, fines and extra cost for which student will only be responsible. Also, student must ensure to submit the payment transfer proof to Institute’s accounts department on time.</span><br />
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> SIt is the responsibility of students to update the information of transferred fee under the tab “Fee updation” in the online admission portal for international applicants. Students admitted through associates/ agencies in their countries should collect the transfer proof or Agency fee receipt and send us through email  <b>global@aditya.ac.in</b></span><br />
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> Any excess amount transferred in the Institute account will only be adjusted in the subsequent term/year fee.</span><br />
                                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> The fee and other components mentioned in the Offer Letter/ Acceptance Letter may not be error free thus for the most updated information please visit the Institute website or please contact the admission office of the Institute at <b>global@aditya.ac.in .</b></span><br />
                                                                    </p>
                                                                <br />

                                                            </TabPanel>

                                                        </div>
                                                </div>
                                            </Tabs>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop />
        </div >
            </div >
    <FooterTwo />
        </>
    );
}

export default Eligibility;