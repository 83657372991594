import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_pt';
import Banner from './banner';





const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    {/* <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Ph.D Program </b> </center><br /> */}
                                                    <div class="about__content">
                                                        <p className='name'>Our PhD program in Petroleum Technology is designed to equip students with the advanced knowledge and skills necessary to address the complex challenges facing the global petroleum industry. We strive to make a positive contribution to the petroleum industry and society. Our dedicated faculty members engage in impactful studies, often funded by national agencies both governmental and corporate. The outcomes of our research find their way into leading international journals and conferences, contributing to the global knowledge pool. Our state-of-the-art laboratories and research facilities receive regular updates, ensuring a vibrant environment for innovation. Additionally, our faculty actively participates in sponsored research and consultancy projects. We remain committed to advancing knowledge, fostering collaboration, and driving positive change in the petroleum industry. By combining academic excellence with practical relevance, the Department of Petroleum Technology is shaping the future of the petroleum industry. Join our Ph.D. program and pioneer the future of petroleum engineering and elevate your career in energy innovation. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;