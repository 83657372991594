import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/collaborations';
import ScrollToTop from '../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_collaborations';
import image1 from './institute_images/1.png';
import image2 from './institute_images/2.png';
import image3 from './institute_images/3.png';
import image4 from './institute_images/4.png';
import image5 from './institute_images/5.png';
import image6 from './institute_images/6.png';
import image7 from './institute_images/7.jpg';
import image8 from './institute_images/8.png';
import image9 from './institute_images/9.png';
import image10 from './institute_images/10.jpeg';










const overview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Collaborations" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <br />
                                                    <div class="container row" style={{ border: '1px solid #dfe2e7', boxShadow: '0 1.5px 3px 0 #00000026', padding: '11px', marginLeft: '0px', background: 'white' }}>
                                                        <div class="col-md-6 ranking_line" >
                                                            <center>
                                                                <img src={image1} />
                                                            </center>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <center>
                                                                <img src={image2} />
                                                            </center>
                                                        </div>
                                                    </div>
                                                    <br/><br/>

                                                    <div class="container row" style={{ border: '1px solid #dfe2e7', boxShadow: '0 1.5px 3px 0 #00000026', padding: '11px', marginLeft: '0px', background: 'white' }}>
                                                        <div class="col-md-6 ranking_line" >
                                                            <center>
                                                                <img src={image3} />
                                                            </center>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <center>
                                                                <img src={image4} />
                                                            </center>
                                                        </div>
                                                    </div>
                                                    <br/><br/>

                                                    <div class="container row" style={{ border: '1px solid #dfe2e7', boxShadow: '0 1.5px 3px 0 #00000026', padding: '11px', marginLeft: '0px', background: 'white' }}>
                                                        <div class="col-md-6 ranking_line" >
                                                            <center>
                                                                <br/>
                                                                <img src={image5} />
                                                            </center>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <center>
                                                                <img src={image9} />
                                                            </center>
                                                        </div>
                                                    </div>
                                                    <br/><br/>
                                                    <div class="container row" style={{ border: '1px solid #dfe2e7', boxShadow: '0 1.5px 3px 0 #00000026', padding: '11px', marginLeft: '0px', background: 'white' }}>
                                                        <div class="col-md-6 ranking_line" >
                                                            <center>
                                                                <img src={image7} />
                                                            </center>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <center>
                                                                <br/><br/>
                                                                <img src={image8} />
                                                            </center>
                                                        </div>
                                                    </div>
                                                    <br/><br/>
                                                    <div class="container row" style={{ border: '1px solid #dfe2e7', boxShadow: '0 1.5px 3px 0 #00000026', padding: '11px', marginLeft: '0px', background: 'white' }}>
                                                        <div class="col-md-6 ranking_line" >
                                                            <center>
                                                                <img src={image6} />
                                                            </center>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <center>
                                                                <br/><br/>
                                                                <img src={image10} />
                                                            </center>
                                                        </div>
                                                    </div>
                                                    <br/><br/>

                                                    {/* <div class="container row" style={{ border: '1px solid #dfe2e7', boxShadow: '0 1.5px 3px 0 #00000026', padding: '11px', marginLeft: '0px', background: 'white' }}>
                                                           <center><div class="col-md-6" >
                                                                    <img src={image6} />
                                                                  </div>
                                                                  <div class="col-md-6" >
                                                                    <img src={image10} />
                                                                  </div>
                                                           </center>
                                                    </div> */}
                                                    <br/><br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default overview;