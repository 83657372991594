import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_academics';



const overview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                   <StudyBreadcrumb pageTitle="Academics Overview" />
                   <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                <div class="container">
                                                    <center><b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}><img src={point} style={{height:"25px"}}/>  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>At Aditya University, we offer a dynamic educational experience.     Our curriculum is meticulously aligned with the NEP 2020 framework, ensuring that we meet contemporary educational standards and expectations. We prioritize personalized learning through our innovative Choice-Based Credit System, which allows students to tailor their academic experience according to their interests and career goals.</p>
                                                        <p className='name'>Our collaborations with esteemed foreign universities offer joint degree certification programs and semester abroad programs, fostering a global perspective. Students can pursue Honors and Minor Degrees, enhancing their academic credentials and broadening their knowledge base</p>
                                                        <p className='name'>In addition to traditional courses, we provide a range of skill-oriented courses, value-added courses, and certification programs, to equip students with the practical skills necessary for real-world challenges. </p>
                                                        <p className='name'>We are dedicated to empower students to chart their own academic journey, encouraging them to excel in their chosen disciplines while developing a versatile skill set that will serve them well in their future careers.</p>
                                                        <br/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            <FooterTwo />

        </>
    );
}

export default overview;