import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/aditya_learning_academy';
import ScrollToTop from '../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_learning_academy';



const overview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Aditya Learning Academy" pageName="Comprehensive Support Services" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}>Aditya Learning Academy (Centre for Teaching & Learning), Aditya University </b> </center><br />
                                                    <div class="about__content">
                                                        <b style={{ color: "#D2093C", fontSize: "17px", textAlign: "center" }}> Comprehensive Support Services </b>
                                                        <br />
                                                        <p className='name' style={{ padding: "14px" }}>
                                                            <div class="degree-list-container">
                                                                <ul class="degree-list">
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Online Course Design and Development: Providing expertise in creating online courses, including content development, digital pedagogy, and effective use of multimedia tools to enhance learner engagement and achievement.</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Outcome-Based Education (OBE) Manual Creation: Developing and distributing manuals and guidelines on outcome-based education to ensure that all courses align with specific competencies and outcomes that students are expected to achieve.</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Collaborative Online Courses with Foreign Universities: Facilitating partnerships with universities abroad to co-develop and co-deliver courses, enhancing global perspectives and diverse educational offerings.</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Industry Collaboration: Working with industry partners to design courses that meet current market needs, integrating real-world applications and expert guest lectures from the field.</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Corporate Training Programs: Offering tailor-made training sessions for industry professionals and other colleges, conducted by university faculty, focusing on specialized topics relevant to ongoing professional development and industry trends.</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Pedagogical Training: Offering workshops, seminars, and courses on modern teaching methods, including active learning, flipped classrooms, and online teaching strategies.</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Curriculum Development Support: Assisting faculty in designing and revising courses and curricula to improve alignment with learning outcomes, incorporating new subject matter and interdisciplinary approaches.</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Technology Integration: Helping faculty integrate technology into their teaching, including learning management systems (LMS), multimedia tools, and virtual reality (VR) environments to enrich the learning experience.</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Assessment and Evaluation: Providing resources and expertise on effective assessment strategies and tools to measure student learning and course effectiveness.</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>	Research on Teaching and Learning: Encouraging and supporting scholarly research on pedagogy, including the scholarship of teaching and learning (SoTL), to foster evidence-based teaching practices.</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>	Mentoring and Coaching: Establishing a mentoring program where experienced faculty members guide newer instructors, offering advice on teaching, career development, and work-life balance.</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>	Instructional Design Assistance: Offering support for course design and redesign, helping faculty create engaging and effective learning experiences for students.</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>	Teaching Resources and Materials: Maintaining a library of teaching resources, such as articles, textbooks, case studies, and online modules that faculty can access to enhance their teaching.</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>	Faculty Development Workshops and Retreats: Organizing regular events focused on professional development, such as teaching retreats and workshops on topics like classroom management, student engagement, and inclusive teaching</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point} /></span><span>	Peer Observation and Feedback: Facilitating a structured program for peer observation where faculty can attend each other’s classes and provide constructive feedback on teaching methods and classroom engagement</span></li>
                                                                </ul>
                                                            </div>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default overview;