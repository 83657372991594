import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';



const Sidebar = () => {
    const [submenuVisible, setSubmenuVisible] = useState(false);
    const [activeSubmenu, setActiveSubmenu] = useState('');
    const location = useLocation();

    const toggleSubmenu = () => {
        setSubmenuVisible(!submenuVisible);
    };

    const handleSubmenuClick = (path) => {
        setActiveSubmenu(path);
    };

    return (
        <div className="col-md-3 col-sm-3 col-0">
             
            <div className="side-navbar">
                <nav className="navbar">
                    <ul className="navbar-nav">
                        <li className="navbar-title text-center">
                            <a className="navbar-brand text-light1" href="#" style={{ padding: '12px 121px 12px 131px' }}>Explore</a>
                        </li>
                        <li className={`nav-item ${location.pathname === '/Civil' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/Civil">Overview</Link>
                        </li>
                        <li
                            className={`nav-item ${['/Civil_UG_program', '/Civil_PG_program', '/Civil_PHD_program'].includes(location.pathname)  ? 'active' : ''}`}
                            onMouseEnter={toggleSubmenu}
                            onMouseLeave={toggleSubmenu}
                            onClick={toggleSubmenu}
                        >
                            <Link className={`nav-link ${['/Civil_UG_program', '/Civil_PG_program', '/Civil_PHD_program'].includes(location.pathname) || submenuVisible ? 'active' : ''}`} to="#">Programs Offered</Link>
                            {submenuVisible && (
                                <ul className="submenu">
                                    <li
                                        className={`nav-item ${location.pathname === '/Civil_UG_program' || activeSubmenu === '/Civil_UG_program' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/Civil_UG_program')}
                                    >
                                        <Link className="nav-link" to="/Civil_UG_program"> B.Tech </Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/Civil_PG_program' || activeSubmenu === '/Civil_PG_program' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/Civil_PG_program')}
                                    >
                                        <Link className="nav-link" to="/Civil_PG_program"> M.Tech</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/Civil_PHD_program' || activeSubmenu === '/Civil_PHD_program' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/Civil_PHD_program')}
                                    >
                                        <Link className="nav-link" to="/Civil_PHD_program"> Ph.D Program</Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li className={`nav-item ${location.pathname === '/Civil_faculty' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/Civil_faculty">Faculty</Link>
                        </li>
                        {/* <li className={`nav-item ${location.pathname === '/Civil_events' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/Civil_events">Events</Link>
                        </li>
                        <li className={`nav-item ${location.pathname === '/Civil_placements' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/Civil_placements">Placements</Link>
                        </li> */}
                        <br />
                    </ul>
                </nav>
                <div className="campus_sec1">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <Link to="/school_of_engineering" className="more-about">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left">
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                    <polyline points="12 19 5 12 12 5"></polyline>
                                </svg> Home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
