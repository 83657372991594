import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../components/Breadcrumb/international';
import ScrollToTop from '../../components/ScrollTop';
import { Link } from 'react-router-dom';
import "../../components/Styles/pragram.css"
import point from '../../assets/images/icons/image.png';
import point1 from '../../assets/images/icons/image1.png';
import SideManu from './sidebar_international';

const Eligibility = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Courses Offered" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div class="container">
                                                    <br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Diploma Programs Offered</b> <br/>
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Diploma in Civil Engineering</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Diploma in Computer Science and Engineering</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Diploma in Communication & Computer Networking</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Diploma in Electronics and Communication Engineering</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Diploma in Electrical and Electronics Engineering</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Diploma in Mechanical Engineering</span><br />
                                                    <br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>B.Tech Programs Offered </b> <br/>
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Agricultural Engineering</span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Civil Engineering</span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Civil Engineering - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with L & T </b></span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Computer Science and Engineering</span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Computer Science and Engineering - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft </b> </span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. – Artificial Intelligence & Machine Learning</span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. – Artificial Intelligence & Machine Learning - <b style={{ color: "#328afc", fontSize: "16px" }}>In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft  </b> </span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. – (Computer Science and Engineering) - Data Science</span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. – (Computer Science and Engineering) - Data Science - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft  </b> </span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Electronics and Communication Engineering</span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Electronics and Communication Engineering - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft  </b> </span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Electronics and Electrical Engineering</span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Electronics and Electrical Engineering - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft  </b> </span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Information Technology</span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Information Technology - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft  </b> </span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Mechanical Engineering</span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Mechanical Engineering - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft  </b> </span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Mining Engineering</span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Mining Engineering - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with L & T and Altair </b> </span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Petroleum Technology</span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Petroleum Technology - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with L & T, CISCO, Red Hat, Oracle, AWS, Google & Microsoft </b></span> <br />

                                                    <br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>PG Programs Offered </b>
                                                    <br/>
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> M.Tech in Structural Engineering </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> M.Tech in Power Electronics and Drives </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> M.Tech in Thermal Engineering </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> M.Tech in VLSI Design </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> M.Tech in Computer Science & Engineering </span><br />
                                                    <br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Ph.D. Programs Offered </b>
                                                    <br/>
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Ph.D. in Engineering disciplines </span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Ph.D. in Management Studies </span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Ph.D. in Mathematics </span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Ph.D. in English </span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Ph.D. in Physics </span> <br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Ph.D. in Chemistry </span> <br />

                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            </div>
            <FooterTwo />
        </>
    );
}

export default Eligibility;