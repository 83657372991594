import React, { useEffect } from 'react'; // Added useEffect import
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';
import "../../../../../components/Styles/pragram.css";
import point from '../../../../../assets/images/icons/image.png';
import SideManu from './sidebar_mca';
import Banner from './banner';

const CEview = () => {
    useEffect(() => {
        // Function to disable right-click
        const handleContextMenu = (event) => {
            event.preventDefault(); // Prevent the default context menu from appearing
        };

        // Attach the event listener
        window.addEventListener('contextmenu', handleContextMenu);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []); // Empty dependency array ensures this runs only on mount and unmount

    return (
        <>
            <Header parentMenu='page' menuCategoryEnable='enable' />
            <div className="react-wrapper">
                <div className="react-wrapper-inner">
                    <Banner />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div className="research">
                                    <div className="container">
                                        <div className="row m-0">
                                            <SideManu />
                                            <div className="col-md-1 col-sm-1 col-2"></div>
                                            <div className="col-md-8 col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div className="container">
                                                    <div className="about__content">
                                                        <p className='name'>
                                                            Aditya University is proud to offer a prestigious and advanced Ph.D. program tailored for MCA graduates seeking to delve into pioneering research and academic excellence. This program is designed to empower graduates with comprehensive research skills, specialized knowledge, and innovation capabilities in the dynamic field of Computer Applications and related
                                                        </p>
                                                        <p className='name'>
                                                            <b>Who Should Apply?</b> <br />
                                                            This program is ideal for MCA graduates who aspire to elevate their expertise through rigorous research and contribute to the field with scholarly work. With a strong focus on advanced computing concepts, digital transformation, AI, data science, and emerging technologies, our Ph.D. program is perfect for candidates aiming for careers in academia, research institutions, or leadership roles in tech industries.
                                                        </p>
                                                        <p className='name'>
                                                            <b>Program Highlights :</b>
                                                            <br />
                                                            <div class="degree-list-container">
                                                                <ul class="degree-list">
                                                                    <li>
                                                                        <span class="icon"><img className='pointimage' src={point} /></span><span><b>Expert Faculty Mentorship:</b>  Engage with a faculty team that brings rich industry and academic experience, ensuring guidance at every stage of your research journey.</span>
                                                                    </li>
                                                                    <li>
                                                                        <span class="icon"><img className='pointimage' src={point} /></span><span><b>State-of-the-Art Facilities:</b>  Gain access to cutting-edge research labs, computing resources, and libraries that support innovative and impactful research</span>
                                                                    </li>
                                                                    <li>
                                                                        <span class="icon"><img className='pointimage' src={point} /></span><span><b>Interdisciplinary Research Opportunities:</b>  Collaborate across various fields within and beyond computer science, fostering holistic and pioneering research outcomes</span>
                                                                    </li>
                                                                    <li>
                                                                        <span class="icon"><img className='pointimage' src={point} /></span><span><b>Flexible Learning Options:</b> Customize your research focus and timelines with options for full-time or part-time study to align with your personal and professional goals.</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            Embark on a transformative journey with Aditya University's Ph.D. program and contribute to the advancement of knowledge, technology, and society.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />
        </>
    );
}

export default CEview;
