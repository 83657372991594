import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Apply_now from './buttonapply_now';
import Enquire_now from './enquire_now';


const Sidebar = () => {
    const [visibleSubmenu, setVisibleSubmenu] = useState(null);
    const location = useLocation();

    const toggleSubmenu = (submenu) => {
        setVisibleSubmenu(visibleSubmenu === submenu ? null : submenu);
    };

    const handleSubmenuClick = (path) => {
        setVisibleSubmenu(null); // Close the submenu after clicking an item
    };

    return (
        
        <div className="col-md-3 col-sm-3 col-0">
            <Apply_now />
            <Enquire_now />

            <div className="side-navbar">
                <nav className="navbar">
                    <ul className="navbar-nav">
                        <li className="navbar-title text-center">
                            <a className="navbar-brand text-light1" href="#" style={{ padding: '12px 121px 12px 131px' }}>Explore</a>
                        </li>
                        <li className={`nav-item ${location.pathname === '/international' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/international">Overview</Link>
                        </li>
                        <li className={`nav-item ${location.pathname === '/international/Courses_Offered' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/international/Courses_Offered">Courses Offered </Link>
                        </li>
                        
                        <li
                            className={`nav-item ${['/international/Admission_Process', '/international/Fee_Particulars', '/international/scholarships', '/international/admission_rules', '/international/payment_procedure', '/international/information_booklet'].includes(location.pathname) || visibleSubmenu === 'admissions' ? 'active' : ''}`}
                            onMouseEnter={() => toggleSubmenu('admissions')}
                            onMouseLeave={() => toggleSubmenu(null)}
                            onClick={() => toggleSubmenu('admissions')}
                        >
                            <Link className="nav-link" to="#">Admissions</Link>
                            {visibleSubmenu === 'admissions' && (
                                <ul className="submenu">
                                    <li
                                        className={`nav-item ${location.pathname === '/international/Admission_Process' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/international/Admission_Process')}
                                    >
                                        <Link className="nav-link" to="/international/Admission_Process"> Admission Process</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/international/Fee_Particulars' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/international/Fee_Particulars')}
                                    >
                                        <Link className="nav-link" to="/international/Fee_Particulars"> Fee Particulars</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/international/scholarships' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/international/scholarships')}
                                    >
                                        <Link className="nav-link" to="/international/scholarships"> Scholarships</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/international/admission_rules' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/international/admission_rules')}
                                    >
                                        <Link className="nav-link" to="/international/admission_rules"> Admission Rules</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/international/payment_procedure' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/international/payment_procedure')}
                                    >
                                        <Link className="nav-link" to="/international/payment_procedure"> Payment Procedure</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/international/information_booklet' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/international/information_booklet')}
                                    >
                                        <Link className="nav-link" to="/international/information_booklet"> Information Booklet</Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                        {/* <li className={`nav-item ${location.pathname === '/international/apply_now' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/international/apply_now">Apply Now! </Link>
                        </li> */}
                        <li
                            className={`nav-item ${['/international/visa_assistance', '/international/pre_arrival_information', '/international/post_arrival_information', '/international/guidelines'].includes(location.pathname) || visibleSubmenu === 'Information_to_Students' ? 'active' : ''}`}
                            onMouseEnter={() => toggleSubmenu('Information_to_Students')}
                            onMouseLeave={() => toggleSubmenu(null)}
                            onClick={() => toggleSubmenu('Information_to_Students')}
                        >
                            <Link className="nav-link" to="#">Information to Students</Link>
                            {visibleSubmenu === 'Information_to_Students' && (
                                <ul className="submenu">
                                    <li
                                        className={`nav-item ${location.pathname === '/international/visa_assistance' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/international/visa_assistance')}
                                    >
                                        <Link className="nav-link" to="/international/visa_assistance"> Visa Assistance</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/international/pre_arrival_information' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/international/pre_arrival_information')}
                                    >
                                        <Link className="nav-link" to="/international/pre_arrival_information"> Pre Arrival Information</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/international/post_arrival_information' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/international/post_arrival_information')}
                                    >
                                        <Link className="nav-link" to="/international/post_arrival_information">Post Arrival Information</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/international/guidelines' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/international/guidelines')}
                                    >
                                        <Link className="nav-link" to="/international/guidelines"> Guidelines for Visa & Medical Insurance</Link>
                                    </li>
                                    
                                </ul>
                            )}
                        </li>
                       
                        <li className={`nav-item ${location.pathname === '/international/contact' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/international/contact">Contact</Link>
                        </li>
                        <br />
                    </ul>
                </nav>
            </div>
        </div>
        
    );
}

export default Sidebar;
