import React from 'react';
import Slider from 'react-slick';
import '../../../components/Styles/pragram.css';
import SectionTitle from '../../../components/SectionTitle';



const DiplomaPrograms = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <>
            <div className="container-fluid" style={{ backgroundColor: "#f8f9fa" }}>
                <div className="react-wrapper-inner">
                    <div className="high_quality-section pt---30 pb---35">
                        <div className="scrolling-container">
                            <Slider {...settings}>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/ocfXp3nY8RU" allowFullScreen title="Video 1" ></iframe>
                                </div>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/O9B8MEUW7cM" allowFullScreen title="Video 2"></iframe>
                                </div>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/2Cd13B4d9rk" allowFullScreen title="Video 3"></iframe>
                                </div>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/NbmJCjQo7-Y" allowFullScreen title="Video 4"></iframe>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DiplomaPrograms;
