import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_aiml';
import Banner from './banner';



const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Overview </b> </center><br />
                                                    <div class="about__content">

                                                        <p className='name'>Join the Computer Science Engineering - Artificial Intelligence and Machine Learning (AI and ML) program for an exciting journey into the future of technology. Our innovative curriculum, guided by experienced faculty and supported by advanced facilities, blends theoretical foundations with hands-on experience. From understanding deep learning techniques to developing intelligent systems, our program prepares students to excel in the rapidly advancing field of AI and ML.
                                                        </p>
                                                        <p className='name'>With a strong emphasis on the latest breakthroughs in AI and ML, including autonomous systems, natural language processing, and cognitive computing, students gain cutting-edge skills. Collaborations with industry leaders and research institutions ensure that students are exposed to the newest trends and technological advancements. Unlock your potential to drive the future of intelligent technology with us.
                                                        </p>
                                                        <br />
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  VISION : </b> <br />

                                                        <p className='name'>
                                                            To become a recognized centre of excellence in the field of AIML that fulfil the needs of industry and society.
                                                        </p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> MISSION:</b><br />
                                                        <div class="degree-list-container">
                                                            <ul class="degree-list name">
                                                                <li> M1:<span>&nbsp;Impart the knowledge through state-of-the-art concepts, tools and techniques in Artificial Intelligence.</span></li>
                                                                <li>M2:<span>&nbsp;Promote technical competence by collaborating with Industries to provide solutions for future challenges.</span></li>
                                                                <li>M3:<span>&nbsp;Inculcate ethics, environmental and societal consciousness for social responsibility.</span></li>
                                                                <li>M4:<span>&nbsp;Build leadership and life-long learning skills to ensure the holistic development of stakeholder.</span></li>
                                                            </ul>
                                                        </div>
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> PROGRAM EDUCATIONAL OBJECTIVES (PEOs)</b><br />
                                                        <div class="degree-list-container">
                                                            <ul class="degree-list name">
                                                                <li> PEO1: <span>&nbsp; Apply core concepts in mathematics and computer science with Artificial Intelligence principles to solve complex computing problems and produce optimized solutions.</span></li>
                                                                <li> PEO2: <span>&nbsp; Pursue higher education and research activities through innovative ideas and latest technology-driven projects in the domain of Artificial Intelligence.</span></li>
                                                                <li> PEO3: <span>&nbsp; Work in a collaborative environment and also lead the team by understanding the ethical, societal and financial impact of their work.</span></li>
                                                               
                                                            </ul>
                                                        </div>
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> PROGRAM SPECIFIC OUTCOMES (PSOs)</b><br />
                                                        <div class="degree-list-container">
                                                            <ul class="degree-list name">
                                                                <li> PSO1: <span>&nbsp;&nbsp;&nbsp;Apply the core concepts of Computational, Artificial Intelligence, Data Science, Security and Optimization Algorithms to produce efficient solutions.</span></li>
                                                                <li> PSO2: <span>&nbsp;Apply technical and research skills in AIML to become a successful Graduate /Entrepreneur/Machine Intelligence Expert through innovative tools and techniques by providing solutions in the areas of engineering, industry and society.</span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;