import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_eee';
import Banner from './banner';




const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                <Banner/>
                <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>The Department of Electrical and Electronics Engineering at Aditya is one of the major departments of the Institute, dedicated to producing highly competent engineers. The department focuses on equipping students with advanced professional knowledge, entrepreneurial thinking, professional and ethical attitudes, and critical problem-solving and analytical skills through an effective teaching-learning process, research, and industrial collaboration.</p>
                                                        <p className='name'>The faculty of the department is a rich blend of academic and industrial experience, constantly engaging in research on cutting-edge technologies and regularly publishing their findings in top international journals. The academic quality of the department is reflected in the laurels won by the students and the distinguished positions held by alumni in industry and academia.</p>
                                                        <p className='name'>To enhance the knowledge of both faculty and students, the department organizes various workshops, industry-institute interactions, continuous improvement programs, seminars, and research activities. These events often feature eminent personalities from industry and academic institutions. Students also benefit from internship programs in prominent power plants and industries like Reliance Industries Limited, SAIL, HPCL, GMR, GVK, VTPS, Vizag Steel Plant, ONGC, APGPCL, and APEPDCL.</p>
                                                        <p className='name'>The department spares no expense in equipping its labs with the latest equipment, such as the Three Phase AC Integrated Machine, DC Integrated Machine, and Wireless Transmission of Electric Power using Tesla Coil. Additionally, technical software like MATLAB and P-Spice are available for students to use.</p>
                                                        <p className='name'>Furthermore, the IEEE Student Chapter and IEEE Student Branch provide dynamic platforms for students to engage, learn, and contribute to the field of Electrical Engineering. Through these initiatives, students stay updated with the latest advancements and trends in the industry, ensuring they are well-prepared for their professional careers.</p>


                                                        <br />
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  VISION : </b> <br />

                                                        <p className='name'>To excel in electrical education, research and technology in tune with societal needs.</p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> MISSION:</b><br />
                                                        <p className='name'>
                                                            M1:Impart quality education and entrepreneur skills.<br />
                                                            M2: Provide cutting edge technologies for research and sustainability in collaboration with industry.<br />
                                                            M3: Nurture Professional ethics and lifelong learning in tune with societal needs.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;