import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/aditya_learning_academy';
import ScrollToTop from '../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_learning_academy';



const overview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Aditya Learning Academy" pageName="Objectives"/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Aditya Learning Academy (Centre for Teaching & Learning), Aditya University </b> </center><br />
                                                    <div class="about__content">
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> <img src={point} style={{ height: "25px" }} />   Objectives </b>
                                                        <br />
                                                        <p className='name' style={{ padding: "14px" }}>
                                                            <img src={point1} style={{ height: "25px" }} /> Enhance Teaching and Learning<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Support Curriculum Development<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Foster Collaborative Partnerships <br />
                                                            <img src={point1} style={{ height: "25px" }} /> Expand Digital Education  <br />
                                                            <img src={point1} style={{ height: "25px" }} /> Encourage Research and Scholarship  <br />
                                                            <img src={point1} style={{ height: "25px" }} /> Deliver Targeted Professional Development  <br />
                                                        </p>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default overview;