import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_mech';
import Banner from './banner';





const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Ph.D Program </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>The Doctor of Philosophy (Ph.D.) program in the Department of Mechanical Engineering at Aditya University offers a cutting-edge research platform focused on diverse areas, including Solar Energy, Biodiesel, Heat Transfer, Hybrid Nanofluid, CFD, Nanotechnology, Additive Manufacturing, Advanced Micro Machining, Biomedical Applications, and Heat Exchangers. Under the guidance of esteemed faculty members and utilizing state-of-the-art facilities, students engage in rigorous scholarly exploration and experimentation. From harnessing solar power to developing sustainable fuel alternatives, and from pioneering advancements in nano-engineering to revolutionizing biomedical technologies, the program empowers students to push the boundaries of innovation. Through interdisciplinary collaboration and a commitment to excellence, graduates emerge as leaders poised to address global challenges and drive impactful change in their respective fields, shaping a brighter and more sustainable future for generations to come.</p>
                                                   </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;