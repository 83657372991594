import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/programsoffered';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import email from '../../../assets/images/icons/email.png';
import contact from '../../../assets/images/icons/contact.png';
import SideManu from './sidebar_programs';



const Corporate = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">

                    <StudyBreadcrumb pageTitle="Corporate PG Diploma Programs" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                           <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div class="container">
                                                    <br />
                                                    <div className="col-md-12 row">
                                                        <div className="col-md-3 ">
                                                            <img src='../uploads/programs_offered/1.png'></img>
                                                        </div>
                                                        <div className="col-md-6">
                                                             <img src='../uploads/programs_offered/web.png' style={{padding:"2px"}}></img>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <img style={{ background: "white" }} src='../uploads/programs_offered/4.png'></img>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div class="about__content">

                                                        <p className='name'>The EdutechNest, a joint venture between Munjal Group and Indian Centre for Research and Manufacturing Excellence Pvt. Ltd.
                                                            (ICME) providing Exclusive Industry Based “One Year Post Graduate Diploma Courses” for Industry Professionals & Engineering
                                                            Students Approved by Aditya University Accredited by NBA and NAAC with A++ with Job Assistance to our students.
                                                            <br />After ICME
                                                            “Industry assessment of skill Gaps” and non availability of three key areas professionals,EduTechNest has started below PG
                                                            courses to assist Students and Professionals with required set of skills.</p>
                                                        <div className="campus_sec1">
                                                            <div className="col-md-12">
                                                                <div className="text-center">
                                                                    <Link to="../uploads/programs_offered/Operation Excellence Brochure.pdf" className="more-about" target="blank" style={{ width: "100%" }}> One Year Online Post Graduate Diploma in Operational Excellence.  </Link>
                                                                </div>
                                                            </div>
                                                        </div><br></br>
                                                        <div className="campus_sec1">
                                                            <div className="col-lg-12">
                                                                <div className="text-center">
                                                                    <Link to="../uploads/programs_offered/Preventive and Predictive Maintenance.pdf" className="more-about" target="blank" style={{ width: "100%" }}>One Year Online Post Graduate Diploma in Preventive and Predictive Maintenance.  </Link>
                                                                </div>
                                                            </div>
                                                        </div><br></br>
                                                        <div className="campus_sec1">
                                                            <div className="col-lg-12">
                                                                <div className="text-center">
                                                                    <Link to="../uploads/programs_offered/quality_management.pdf" className="more-about" target="blank" style={{ width: "100%" }}> One Year Online Post Graduate Diploma in Quality Management Systems and Problem Solving</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />

                                                        <p className='name'>EdutechNest focuses on empowering individuals through comprehensive PG Diploma Courses.
                                                            Leveraging Munjal Group expertise in sustainable technology and Indian Centre for Research
                                                            and Manufacturing Excellence Pvt. Ltd. (ICME) proficiency in Lean Consulting, Technical
                                                            Education Universities and Manufacturing Industry. The company aims to bridge skill gaps
                                                            and foster career growth with a commitment to innovation and sustainability, they striveto  to shape
                                                            a workforce capable of meeting evolving industry demands. </p>


                                                        <p className='name'>EdutechNest with Aditya University are committed to nurturing a skilled workforce that not only meets current
                                                            industry demands but also anticipates and adapts to future challenges. By combining Munjal Group commitment
                                                            to sustainability with Indian Centre for Research and Manufacturing Excellence Pvt. Ltd. (ICME) industrial
                                                            excellence, we strive to create a lasting impact on individuals and industries alike.
                                                            <br />
                                                            For further inquiries and to embark on your journey towards excellence, reach out to us via.
                                                            <br />
                                                            <p className='name'><img src={email} style={{ height: "25px" }} /> <b style={{ color: "#D2093C" }} >:</b> <span >Head@edutechnest.com</span> <br /> <b style={{ color: "#D2093C" }}><img src={contact} style={{ height: "25px" }} /> :</b> +91 62805 24259. </p>
                                                            You can also explore our offerings and learn more about our initiatives by visiting following website : <br/><Link
                                                                to="https://www.edutechnest.com"
                                                                className="name"
                                                                target="_blank"
                                                                style={{ color: "blue", textDecoration: "none", fontSize: "20" }}
                                                                onMouseEnter={(e) => (e.target.style.color = "#D2093C")}
                                                                onMouseLeave={(e) => (e.target.style.color = "#00306e")}
                                                            >
                                                                https://www.edutechnest.com
                                                            </Link></p>

                                                        <p className='name'> Begin your transformative educational experience today by scanning the QR Code below.</p>
                                                        <img style={{ width: "150px", marginBottom: "30px" }} src='../uploads/programs_offered/Picture5.png'></img>

                                                        {/* <div className="campus_sec1 row">
                                                            <div className="col-lg-4">
                                                                <div className="text-center">
                                                                    <Link to="/fees&scholarship" className="more-about" target="blank" style={{width:"100%"}}> Fees <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="text-center">
                                                                    <Link to="/fees&scholarship" className="more-about" target="blank" style={{width:"100%"}}> Scholarships   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="text-center">
                                                                    <Link to="https://apply.adityauniversity.in/" className="more-about" target="blank" style={{width:"100%"}}> Apply Now !  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                                                </div>
                                                            </div>

                                                        </div> */}
                                                    </div>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default Corporate;