import React, { useState } from 'react';
import Header from '../../../../components/Header';
import FooterTwo from '../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../components/Breadcrumb/programsoffered';
import ScrollToTop from '../../../../components/ScrollTop';

import "../../../../components/Styles/pragram.css";
// import SideManu from './sidebar_eng';
import Modal from 'react-modal';  // Add a modal library for pop-up PDFs

// import EEEimage from './images/eee.png';
import Cvimage from './images/cv.png';
import Linkimage from './images/link.gif';

import English from './images/phd_courese/english.pdf';
import Physics from './images/phd_courese/physics.pdf';
import Maths from './images/phd_courese/maths.pdf';
import Chemistry from './images/phd_courese/chemistry.pdf';
import EEEimage from './images/pg_curriculum/curriculum.png';



import CL1 from './images/phd_courese/ce.pdf';
import CL2 from './images/phd_courese/eee.pdf';
import CL3 from './images/phd_courese/mech.pdf';
import CL4 from './images/phd_courese/ece.pdf';
import CL5 from './images/phd_courese/cse.pdf';
import CL6 from './images/phd_courese/pt.pdf';
import CL7 from './images/phd_courese/minig.pdf';
import CL8 from './images/phd_courese/age.pdf';











// Sample program data array
const programData = [
    { 
        id: 0, 
        title: 'Engineering', 
        image: Cvimage, 
        filecount:'10',   
        curriculum_name: ['Civil Engineering','Electrical and Electronics Engineering','Mechanical Engineering','Electronics and Communication Engineering','Computer Science and Engineering','Petroleum Technology','Mining Engineering','Agricultural Engineering'], 
        curriculum: [CL1,CL2,CL3,CL4,CL5,CL6,CL7,CL8] 
    },
    { 
        id: 1, 
        title: 'Mathematics',
        filecount:'2',
        image: EEEimage,
        curriculum_name: ['Ph.D. in Mathematics'], 
        curriculum: [Maths] 
        
    },
    { 
        id: 2, 
        title: 'Physics',
        filecount:'2',

        image: EEEimage,
        curriculum_name: ['Ph.D. in Physics'], 
        curriculum: [Physics] 
        
    },
    { 
        id: 3, 
        title: 'Chemistry',
        image: EEEimage,
        filecount:'2',
        curriculum_name: ['Ph.D. in Chemistry'], 
        curriculum: [Chemistry] 
        
    },
    { 
        id: 4, 
        title: 'English',
        image: EEEimage,
        filecount:'2',
        curriculum_name: ['Ph.D. in English'], 
        curriculum: [English] 
        
    },

   
   
];

const Engoverview = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const openModal = (pdf) => {
        setSelectedPdf(pdf);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedPdf(null);
    };

    return (
        <>
            <Header parentMenu='page' menuCategoryEnable='enable' />
            <div className="react-wrapper">
                <div className="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Pre-Ph.D. Courses" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div className="research">
                                    <div className="container">
                                        <div className="row m-0">
                                            {/* <SideManu /> */}
                                            <div className="col-md-1 col-sm-1 col-2"></div>
                                            <div className="col-md-11 col-sm-11 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div className="container">
                                                    <div className="about__content">
                                                        <div>
                                                        {programData.map(({ id, title, image, pdf, curriculum, curriculum_name ,filecount}) => (
                                                                <React.Fragment key={id}>
                                                                    <button className={`accordion ${activeIndex === id ? 'active' : ''}`} onClick={() => toggleAccordion(id)}>
                                                                        <img src={image} style={{ height: '40px' }} alt={title} />
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;{title}
                                                                        <span className="symbol">{activeIndex === id ? '▲' : '▼'}</span>
                                                                    </button>
                                                                    <div className="panel" style={{ display: activeIndex === id ? 'block' : 'none', marginBottom: '14px' }}>
                                                                        
                                                                        <br />
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ textAlign: 'center' }}>Program</th>
                                                                                    <th style={{ textAlign: 'center' }}>2024-25</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td rowspan={filecount}>Courses</td>
                                                                                    <td></td>
                                                                                </tr>

                                                                                
                                                                                {curriculum && curriculum_name && curriculum.map((curr, index) => (
                                                                                    <tr key={index}>
                                                                                        
                                                                                        <td>
                                                                                            <span onClick={() => openModal(curr)} style={{ color: "#000", cursor: 'pointer' }}>
                                                                                            {curriculum_name[index]}
                                                                                            </span>
                                                                                            <img src={Linkimage} style={{ height: '25px' }} alt="link icon" />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                        <br />
                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

            {/* Modal for PDF Display */}
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Curriculum PDF" className="Modal">
                <br/>
                <button onClick={closeModal} className="close-button" style={{
                        float: 'right',
                        background: '#1f3860',
                        color: 'white',
                        height: '39px',
                        width: '79px',
                        padding: '3px'
                }}><b>Close</b></button>
                {selectedPdf && (
                   <center>
                    <br/><iframe src={`${selectedPdf}#toolbar=0`} title="Curriculum PDF" width="60%" height="700px" /></center>

                )}
            </Modal>
            
        </>
    );
};

export default Engoverview;
