import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import PaymentMain from '../payment/PaymentMain';
import "./styles.css";
const backendUrl = "https://adityauniversity.in:4001/api/"


const paymentEligibility = () => {
  return (
    <>
      <Header
        parentMenu='page'
        menuCategoryEnable='enable'
      />
      <div class="react-wrapper">
        <div className="high_quality-section pt---110 pb---120">
            <div className="container">
              <div style={{ background: '#f2f4f9', backdropFilter: 'blur(10px)', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }} className="container col-md-6 mt-5 mb-5 ">
                <h5 style={{ textAlign: 'center' }} className="mt-5 mb-4"><b>Application fee 1000/-</b></h5>
                <PaymentMain /> 
              </div>
            </div>
          </div>
        </div>
      <FooterTwo />
    </>
  );
}

export default paymentEligibility;