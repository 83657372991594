import React , { useRef, useEffect}  from 'react';
import Fees from './fees';
// import Scholarships from './scholarships';

const FeesScholarship = () => {
    const tabStyle = 'nav nav-tabs';

  return (
        <div className="high_quality-section ">
            <div className="container">
                 <Fees />
                 {/* <Scholarships /> */}
           </div >
        </div >
   );
}


export default FeesScholarship;