import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_mech';
import Banner from './banner';





const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  M-Tech </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>The Department of Mechanical Engineering is well-known for its all-encompassing approach to energy systems and provides a postgraduate program (PG) in Thermal Engineering. Students study topics including solar energy, biodiesel, heat exchangers, and refrigeration under the direction of professors from prominent universities including IITs and NITs. Students obtain vital insights and abilities to tackle urgent energy concerns through practical research and assistance from seasoned experts.  </p>

                                                        <p className='name'>Graduates are well-positioned to make important contributions to the discipline because they have access to cutting-edge facilities and chances for multidisciplinary collaboration. Equipped with an in-depth comprehension of both theoretical concepts and real-world applications, they are well-positioned to spearhead innovation and pave the path for a more sustainable future in the field of thermal engineering and beyond.</p>

                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;