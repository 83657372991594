import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/aditya_learning_academy';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_learning_academy';
import EventfileAI from '../../../assets/images/aditya_learning_academy/mchine_learning.pdf';
import image1 from './Images/left.gif';
import Event_image_1 from './Images/event1.png';
import Event_image from './Images/event.png';






import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const Eligibility = (props) => {
    let tab1 = "Completed Events",
        tab2 = "Ongoing Event",
        tab3 = "Upcoming Event"
    const tabStyle = 'nav nav-tabs';


    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        const status = JSON.parse(localStorage.getItem('event_status'));
        if (status === 1) {
            setSelectedIndex(2); // Tab3
        } else {
            setSelectedIndex(0); // Tab1
        }
    }, [status]);
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div className="back__course__page_grid react-courses__single-page pb---16 ">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Aditya Learning Academy" pageName="Events" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div class="container text-justify">
                                                    <br />

                                                    <Tabs selectedIndex={selectedIndex} onSelect={index => setSelectedIndex(index)}>
                                                        <div className="course-single-tab">
                                                            <TabList className={tabStyle}>
                                                                <Tab className='col-md-4'><button>{tab1}</button></Tab>
                                                                <Tab className='col-md-4'><button>{tab2}</button></Tab>
                                                                <Tab className='col-md-4'><button>{tab3}</button></Tab>
                                                            </TabList>

                                                            <div>
                                                                <TabPanel>
                                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Completed Events</b> <br />
                                                                    <div class="degree-list-container">
                                                                        <ul class="degree-list">
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span> 1. A Five Day Sensitization program on “Outcome Based Education (OBE)” is scheduled from 10th to 14th June 2024, organized by IQAC in association with ALA </span></li>
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span> 2. A Five Day FDP on “Joy of Computing using Python” is schedlied from 2nd to 6th Jliy 2024, organized by the Department of AIML in association with ALA. </span></li>
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span> 3. A Five Day FDP on “Data Analytics Using Microsoft Power BI” is schedlied from 8th to 12th Jliy 2024, organized by the Department of IT in association with ALA </span></li>
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span> 4. An online Certification course on "Machine Learning and GenAI" is schedlied from 05th to 16th August 2024, organized by IEduVibhu in association with ALA. </span></li>
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span> 5. A Five Day FDP on “Innovative Teaching Methodologies” is schedlied from 22nd to 26th July 2024, organized by IQAC in association with ALA</span> </li>
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span> 6. Online Certification Course on "Data Visualization with Power BI & Tableau", 23/09/2024 - 04/10/2024 by Aditya Learning Academy.</span> </li>
                                                                        </ul>
                                                                    </div>
                                                                </TabPanel>
                                                                <TabPanel>
                                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Ongoing Event </b> <br />
                                                                    <div class="degree-list-container">
                                                                        <ul class="degree-list">
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span>
                                                                                <Link to={Event_image_1} target="blank"><span className='name namehover' style={{ color: "#000" }}> 1.An Online Lecture Series on "Emerging Trends of Research in Electrical Engineering" from 6/11/2024 to 16/11/2024, Organized by Aditya Learning Academy(ALA). </span>
                                                                                </Link> </span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>

                                                                </TabPanel>
                                                                <TabPanel>
                                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Upcoming Event </b> <br />
                                                                    <div class="degree-list-container">
                                                                        <ul class="degree-list">
                                                                            {/* <li><span class="icon"><img className='pointimage' src={point} /></span><span>
                                                                                <Link to={Event_image_1} target="blank"><span className='name namehover' style={{ color: "#000" }}> 1.An Online Lecture Series on "Emerging Trends of Research in Electrical Engineering" from 6/11/2024 to 16/11/2024, Organized by Aditya Learning Academy(ALA). </span>
                                                                                </Link> </span>
                                                                            </li> */}
                                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span>
                                                                                <Link to={Event_image} target="blank"><span className='name namehover' style={{ color: "#000" }}>1.An Online Lecture Series on "Research Scope in Emerging Areas of Civil Engineering"  from 20/11/2024 to 30/11/2024, Organized by Aditya Learning Academy(ALA). </span>
                                                                                </Link> </span>
                                                                            </li>
                                                                        </ul>

                                                                    </div>

                                                                </TabPanel>

                                                            </div>
                                                        </div>
                                                    </Tabs>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            </div>
            <FooterTwo />
        </>
    );
}

export default Eligibility;