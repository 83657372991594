import React from 'react';
import "../Styles/menu.css";
import DropdownExample from './DropdownExample';



const AdmissionBtn = () => {
    const styles = {
		center: {
		  margin: '0 auto'
		},
		awesome: {
		//   fontFamily: 'futura',
		//   fontStyle: 'italic',
		  width: '100%',
		  margin: '-6px auto',
		  textAlign: 'center',
		  color: '#313131',
		  fontSize: '18px',
		  fontWeight: 'bold',
		  WebkitAnimation: 'colorchange 20s infinite alternate'
		}
	  };
	  
	  const keyframes = `
		@-webkit-keyframes colorchange {
		  0% { color:  #ffffff; }
		  10% { color: #eeff41; }
		  20% { color: #00e5ff; }
		  30% { color: #ffffff; }
		  40% { color: #eeff41 ; }
		  50% { color: #00e5ff; }
		  60% { color: #ffffff; }
		  70% { color: #eeff41 ; }
		  80% { color: #00e5ff; }
		  90% { color: #ffffff; }
		  100% { color: #eeff41; }
		}
	  `;
    return (
        <>
            <div class="row admissionBtnmobie">
                <div class="col-lg-12">
				<DropdownExample />
                    <ul class="button-list">
                        <li><a href="https://apply.adityauniversity.in/" target="_blank"><b className="buttionnamesapply">Apply Now !</b></a></li>
                    </ul>

                    <ul class="button-list">
                        <li><b style={{ color: 'white' }}>Admissions : </b></li>
                        <li><a href="/admissions" target="_blank"><b class="buttionnames">National</b></a></li>
                        <li><a href="https://www.aditya.ac.in/international/" target="_blank"><b class="buttionnames">InterNational</b></a></li>
                    </ul>
                    {/* <ul>
                        <li>
                            <div style={styles.awesome} className="center">
                                EAPCET Counselling Code: <b>ADTPPU</b>
                            </div>
                        </li>
                    </ul> */}

                </div>
            </div>
        </>
    );
}

export default AdmissionBtn;