import React from 'react';

const Apply = () => {
    const handleApplyClick = () => {
        window.location.href = 'https://adityauniversity.in/international/apply_now';
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="applybuttion_internation3" style={{ display: 'block' }}>
                        <button type="button" className="applybuttion_internation_3" onClick={handleApplyClick}>
                            Apply Now!
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Apply;
