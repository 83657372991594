import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import Sustainability_1 from './images/sustainability_1.png';




const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                    <StudyBreadcrumb pageTitle="Sustainability" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}> A Floor Drain Trap with an Automatic Waste Cleaning System and Method Thereof:</b> <br />
                                                    <b className='name'>Theme:</b>
                                                     <p className='name'>
                                                     An innovative work on wearable device and method for treatment of plantar fascia and more, particularly to a wearable device and method for performing reflexology to treat plantar fasciitis.
                                                    </p>
                                                    <b className='name'>Background:</b>
                                                     <p className='name'>
                                                     Reflexology is a technique involving massage of strategic or reflex points on the feet, ears, and/or hands. Various organs or muscles of the human body are connected by nerves to certain reflex points or zones on the feet. The feet have reflex points of all organs, glands and parts of the body. Massage applied to these reflex points or zones may promote circulation of blood flow to the organs. Inadequate blood flow in the feet may result in causing diseases. Various types of wearable massaging devices have been proposed for stimulating soles of the user’s feet in order to promote blood circulation and treat the plantar fascia. Conventional wearable massaging devices with a function of stimulating and reflexing the reflective area of the soles laid on the bottom of the shoe are well known. In particular, the insoles of such shoes act on predetermined points of the foot sole by means of suitable projections. Few existing patent references attempted to address the problems caused due to usage of computing devices within an organization are explained as follows.
                                                    </p>
                                                    <b className='name'>Our Invention:</b>
                                                     <p className='name'>
                                                     A floor drain trap integrated with an automatic waste cleaning system is disclosed.  The floor drain trap automatically cleans clogged waste at an outlet, thereby preventing accumulation of waste at the floor drain trap. The automatic waste cleaning system comprises a lever, a casing, a cylindrical cam, a roller, a ball bearing having a slot, a float buoyancy with a weight, and a submergible body. The cylindrical cam alternatively releases and contacts with the lever  at the first end of the cylindrical cam  due to slope of the first end upon rotation of the cylindrical cam, thereby creating a reciprocating motion of the  lever  through the slot  to automatically push the clogged waste at the outlet  of the floor drain trap  without user intervention.
                                                    </p>
                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Commercial Kitchens and Restaurants</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Hospitals and Healthcare Facilities</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Public Bathrooms and Restrooms</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Swimming Pools and Water Parks</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Food Processing Plants</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Supermarkets and Grocery Stores</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Commercial Laundries</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Residential Building</span><br />
                                                    </p>
                                                    <b className='name'>IP Status</b>
                                                     <p className='name'>
                                                     The invention is the subject of a Granted Patent No. 495855 IP India<br></br>
                                                     Application No.  202241067271                                                       
                                                    </p>
                                                    <b className='name'>Inventors</b>
                                                     <p className='name'>
                                                     Dr. Papolu Satish                                                       
                                                    </p>
                                                    <center>
                                                        <img src={Sustainability_1} /><br></br>
                                                        <b className='name'>A Floor Drain Trap with an Automatic Waste Cleaning System</b>
                                                    </center>
                                                    <br/>
                                                    <b className='name'>Contact us</b>
                                                     <p className='name'>
                                                     If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at :<b>dean_rc@adityauniversity.in</b>                                                       
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;