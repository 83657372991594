import { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import sliderImg1 from '../../assets/images/whyaditya_slider/1_new.jpg';
// import sliderImg1 from '../../assets/images/whyaditya_slider/1.jpg';

import sliderImg2 from '../../assets/images/whyaditya_slider/2.jpg';
import sliderImg3 from '../../assets/images/whyaditya_slider/3.jpg';
import sliderImg4 from '../../assets/images/whyaditya_slider/4.jpg';
import sliderImg5 from '../../assets/images/whyaditya_slider/5.jpg';


const HomeSlider = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [videoId, setVideoId] = useState('');

    // Function to open modal with specific video ID
    const openModalWithVideo = (id) => {
        setVideoId(id);
        setIsOpen(true);
    };

    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
        autoplay:true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                }
            }
        ]
    };

    return (
        <>
            <div className="react-slider-part">
                <ModalVideo channel='youtube' isOpen={isOpen} videoId={videoId} onClose={() => setIsOpen(false)} />

                <div className="home-sliders home2">
                    <Slider {...sliderSettings}>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg1} alt="Slider Image 1" />
                                <img className="mobile"  src={sliderImg1} alt="Slider Image 1" />
                            </div>
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg2} alt="Slider Image 2" />
                                <img className="mobile"  src={sliderImg2} alt="Slider Image 2" />
                            </div>
                            {/* <div className="container">
                                <div className="event__video-btn--play wow animate__fadeInUp" data-wow-duration="1.4s">
                                    <Link to="#" className="event__video-btn--play-btn custom-popup" onClick={() => openModalWithVideo('wuPzQP-aSS0')}>
                                        <i className="arrow_triangle-right"></i>
                                    </Link>
                                </div>
                            </div> */}
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg3} alt="Slider Image 3" />
                                <img className="mobile"  src={sliderImg3} alt="Slider Image 3" />
                            </div>
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg4} alt="Slider Image 4" />
                                <img className="mobile"  src={sliderImg4} alt="Slider Image 4" />
                            </div>
                            {/* <div className="container">
                               <div className="event__video-btn--play wow animate__fadeInUp" data-wow-duration="1.4s">
                                    <Link to="#" className="event__video-btn--play-btn custom-popup" onClick={() => openModalWithVideo('O4NiGWDbM0I')}>
                                        <i className="arrow_triangle-right"></i>
                                    </Link>
                                </div>
                            </div> */}
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg5} alt="Slider Image 5" />
                                <img className="mobile"  src={sliderImg5} alt="Slider Image 5" />
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default HomeSlider;
