import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../components/Breadcrumb/international';
import ScrollToTop from '../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../components/Styles/pragram.css"
import point from '../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_international';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                {/* <Enquire /> */}

                    <StudyBreadcrumb pageTitle="Contact" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                <div class="container">
                                                  <center>  <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}> Contact :</b> <br /></center>
                                                
                                                    <span className='name'><img src={point} style={{height:"25px"}}/> <b>	Email:</b>
                                                     global@adityauniversity.in
                                                     </span> <br />
                                                     <span className='name'><img src={point} style={{height:"25px"}}/><b>Call:</b> +91 9676115608 </span><br/>
                                                     <span className='name'><img src={point} style={{height:"25px"}}/> <b>Whatsapp:</b> +91 9676115608  </span><br/>
                                                     <span className='name'><img src={point} style={{height:"25px"}}/> <b>International Office:</b> For help and guidance regarding admission at ADITYA we have our representatives in a various countries</span><br/>
                                                     <span className='name'><img src={point} style={{height:"25px"}}/> To get the details of the representative in your country, kindly contact the International Office on +91-9676115608 or write to us at global@adityauniversity.in </span><br/>
                                                     <span className='name'><img src={point} style={{height:"25px"}}/> Counselors who would like to become a representative of Aditya in their home countries, to help and direct our international students, call/ WhatsApp +91 9676115608 or   write to us at global@adityauniversity.in </span><br/>
                                                     
                                                     
                                                   
                                                    {/* <p className='name'>
                                                    Welcome to Aditya University, where diversity thrives and unity reigns. Affectionately known as Vasudaika Kutumbam, or the “Global Family”, our university proudly welcomes students from 20 countries around the world. At Aditya, we believe that diversity is our strength, and we celebrate the rich tapestry of cultures, languages, and perspectives that our global       community brings to campus. Aditya University stands out as a truly global educational institution, boasting a diverse student body hailing from countries such as Afghanistan, Bangladesh, Bhutan, Cote D’ Ivoire, DR Congo, Ghana, Guinea, Kuwait, Lesotho, Mozambique, Mali, Nepal, Nigeria, Rwanda, Sudan, South Sudan, Swaziland, Tanzania, Uganda, Zambia, and Zimbabwe. The campus provides tailored infrastructure for international students, fostering a serene environment conducive to both academic and personal growth.
                                                    </p> */}

                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;