import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import '../../../components/Styles/pragram.css';
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
import top_recruiters from './images/top_recruiters.jpg'



import SideManu from './sidebar_opportunities';
import PlacementSlider from './placement_slider';




const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                <PlacementSlider />

                    {/* <StudyBreadcrumb pageTitle="Placement Policy" /> */}

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> Top Recruiters </b> <br /></center>
                                                    <img src={top_recruiters} />
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;