import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import wearable6 from './images/wearable6.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                    <StudyBreadcrumb pageTitle="Healthcare" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                            <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>Wearable Device and Method for Treatment of Plantar Fasi</b> <br />
                                                    <b className='name'>Theme:</b>
                                                     <p className='name'>
                                                     An innovative approach to treat plantar fasia using a wearable device.
                                                    </p>
                                                    <b className='name'>Background:</b>
                                                     <p className='name'>
                                                     Reflexology is a technique involving massage of strategic or reflex points on the feet, ears, and/or hands. Various organs or muscles of the human body are connected by nerves to certain reflex points or zones on the feet. The feet have reflex points of all organs, glands and parts of the body. Massage applied to these reflex points or zones may promote circulation of blood flow to the organs. Inadequate blood flow in the feet may result in causing diseases
                                                    </p>
                                                     <p className='name'>
                                                     Various types of wearable massaging devices have been proposed for stimulating soles of the user’s feet in order to promote blood circulation and treat the plantar fascia. Conventional wearable massaging devices with a function of stimulating and reflexing the reflective area of the soles laid on the bottom of the shoe are well known. In particular, the insoles of such shoes act on predetermined points of the foot sole by means of suitable projections
                                                    </p>
                                                    <b className='name'>Our Invention</b>
                                                    <p className='name'>
                                                    This disclosure relates to a wearable device and method for performing reflexology to treat plantar fasciitis. The wearable device comprises a controller, several stimulation devices, and a sensor unit. The stimulation devices will have communication with the controller and is configured to apply pressure on a sole of a user's foot for stimulating reflex points or zones, thereby treating the plantar fasciitis. The controller may determine at least one therapy or treatment process for treating the plantar fasciitis
                                                    </p>
                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Individuals </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Hospitals </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Physiotherapy Centres</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Gymnasiums</span><br />
                                                     </p>
                                                    <b className='name'>IP Status </b>
                                                    <p className='name'>
                                                    The invention is the subject of a Granted Patent No. 429108 IP India  
                                                    </p>
                                                    <p className='name'>
                                                    Application No. 202241043771
                                                    </p>
                                                    <b className='name'>Inventors </b>
                                                    <p className='name'>
                                                    J Pavan  
                                                    </p>
                                                    <p className='name'>
                                                    V Satyanarayana 
                                                    </p>
                                                    <center>
                                                        <img src={wearable6} /><br></br>
                                                    </center>
                                                    <br/>
                                                    <b className='name'>Contact us</b>
                                                     <p className='name'>
                                                     If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at:<b>dean_rc@adityauniversity.in</b>                                                   
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;