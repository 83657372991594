import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_cse';
import coming_soon from '../../../../../assets/images/icons/coming_soon.png';

import Banner from './banner';





const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> Ph.D Program </b> </center><br />
                                                    <div class="about__content">
                                                        <div id="overview" class="container">
                                                            <p className='name'>
                                                                The Ph.D programme in Computer Science and Engineering imparts the knowledge, skills and attitude to do world class research in the area of computer science. The Ph.D. programme in Computer Science & Engineering is centred on inculcating the students with attitude, skills, and knowledge to conduct world-class computer science & engineering research. Doctorate students are encouraged to publish their work in top-notch international journals and conferences. Students from various parts of the nation pursue a Ph.D. programme at the Department of Computer Engineering & Applications. <br /><br />
                                                                Our state-of-the art laboratories are equipped with laboratories Supported with high end configuration systems supporting all trendy and research-oriented courses like Artificial Intelligence, Machine Learning, Deep Learning, Data science, security etc.,
                                                            </p>
                                                            <p className='name'>The faculty members of Computer Science and Engineering department have been actively engaged in research in the following areas.
                                                            </p>
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Software Engineering</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Artificial Intelligence</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Machine Learning and Deep learning</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Natural Language Processing</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Image Processing</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Data Science</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Internet of Things</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Big Data Analytics</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Network Security</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Bio Informatics</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Cyber Security and Blockchain</span><br />
                                                        </div>
                                                        <br/>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;