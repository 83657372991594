import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import { Dropdown, Container, Row, Col } from 'react-bootstrap';
import "../Styles/menu.css"
import Refund_Policy from "./files/Refund_Policy.pdf"




const MenuItems = (props) => {
    const { parentMenu } = props;
    const location = useLocation();
    const postURL = location.pathname.split('/');
    const pathLength = Number(postURL.length);

    const [home, setHome] = useState(false);
    const [students, setStudents] = useState(false);
    const [research, setResearch] = useState(false);
    const [community, setCommunity] = useState(false);
    const [blog, setBlog] = useState(false);
    const [megaMenu, setMegaMenu] = useState(false);

    const openMobileMenu = (menu) => {
        setHome(false);
        setStudents(false);
        setResearch(false);
        setCommunity(false);
        setBlog(false);
        setMegaMenu(false);

        if (menu === 'home') setHome(true);
        else if (menu === 'students') setStudents(true);
        else if (menu === 'research') setResearch(true);
        else if (menu === 'community') setCommunity(true);
        else if (menu === 'blog') setBlog(true);
        else if (menu === 'megaMenu') setMegaMenu(true);
    };

    return (
        <>

            <li className={parentMenu === 'home' ? 'has-sub menu-active' : 'has-sub'}>

                <Link to="#" className={home ? 'hash menu-active' : 'hash'} onClick={() => setHome(!home)}>
                    Study<span className='arrow'></span>
                </Link>

                <ul className={home ? 'sub-menu sub-menu-open mega-sub-menu studymenu' : 'sub-menu mega-sub-menu studymenu'}>
                    <div className='row' >
                        <div className='col-lg-2'>
                            <li>
                                <ul>
                                    {/* <li className="text-primary">
                                       whyaditya
                                    </li> */}
                                    <li>
                                        <Link to="/whyaditya"> WhyAditya</Link>
                                    </li>
                               </ul>
                            </li>
                        </div>
                        <div className='col-lg-3'>
                            <li>
                                <ul>
                                    <li className="text-primary">
                                     Programs Offered
                                    </li>
                                    <li>
                                        <Link to="/diploma_programs">Diploma Programs</Link>
                                    </li>
                                    <li>
                                        <Link to="/programsoffered_ug">UG Programs</Link>
                                    </li>
                                    <li>
                                        <Link to="/programsoffered_pg">PG Programs</Link>
                                    </li>
                                    <li>
                                        <Link to="/phd_programs">Ph.D Programs</Link>
                                    </li>
                                    <li>
                                        <Link to="/corporate_programs">Corporate PG Diploma Programs</Link>
                                    </li>
                                </ul>
                            </li>
                        </div>
                        <div className='col-lg-2'>
                            <li>
                                <ul>
                                    {/* <li className="text-primary">
                                    Admission Process
                                    </li> */}
                                    <li>
                                        <Link to="/admissionprocess"> Admission Process</Link>
                                    </li>
                               </ul>
                            </li>
                        </div>
                        <div className='col-lg-2'>
                            <li>
                                <ul>
                                    {/* <li className="text-primary">
                                    Hostel Facilities
                                    </li> */}
                                    <li>
                                        <Link to="/hostelfacilities"> Hostel Facilities</Link>
                                    </li>
                               </ul>
                            </li>
                        </div>
                        <div className='col-lg-3'>
                            <li>
                                <ul>
                                    {/* <li className="text-primary">
                                    Fees & Scholarship
                                    </li> */}
                                    <li>
                                        <Link to="/fees"> Fees</Link>
                                    </li>
                                    <li>
                                        <Link to="/scholarship"> Scholarship</Link>
                                    </li>
                                    <li>
                                        <Link target='New' to={Refund_Policy}>  Refund Policy</Link>
                                    </li>
                               </ul>
                            </li>
                        </div>
                        

                    </div>
                </ul>


                {/* <ul style={{ width: "250px" }} className={home ? 'sub-menu sub-menu-open' : 'sub-menu'}>

                    <div className='row' >
                        <div className='col-lg-12'>
                            <li className='menu-item'>
                                <li className={location.pathname === "/home" ? "menu-active" : ""}>
                                    <Link to="/whyaditya">Why Aditya</Link>
                                </li>
                                <li className={location.pathname === "/home" ? "menu-active" : ""}>
                                    <Link to="/programsoffered">Programs offered</Link>
                                </li>

                                <li className={location.pathname === "/home" ? "menu-active" : ""}>
                                    <Link to="/eligibilitycriteria">Eligibility Criteria</Link>
                                </li>
                                <li className={location.pathname === "/home" ? "menu-active" : ""}>
                                    <Link to="/admissionprocess">Admission Process</Link>
                                </li>
                                <li className={location.pathname === "/home" ? "menu-active" : ""}>
                                    <Link to="/hostelfacilities">Hostel Facilities</Link>
                                </li>
                                <li className={location.pathname === "/home" ? "menu-active" : ""}>
                                    <Link to="/fees&scholarship">Fees & Scholarship </Link>
                                </li>
                            </li>
                        </div>
                    </div>
                </ul> */}
            </li>
            <li className={parentMenu === 'students' ? 'has-sub menu-active' : 'has-sub'}>
                <Link to="#" className={students ? 'hash menu-active' : 'hash'} onClick={() => setStudents(!students)}>
                    Students<span className='arrow'></span>
                </Link>
                <ul className={students ? 'sub-menu sub-menu-open mega-sub-menu studentsmenu' : 'sub-menu mega-sub-menu studentsmenu'}>
                    <div className='row' >
                        <div className='col-lg-6'>
                            <li>
                                <ul>
                                    <li className="text-primary">
                                       Academics
                                    </li>
                                    <li>
                                        <Link to="/academics"> Academics Overview</Link>
                                    </li>
                                    <li>
                                        <Link to="/school_of_engineering">School of Engineering</Link>
                                    </li>
                                    <li>
                                        <Link to="/school_of_business">School of Business</Link>
                                    </li>
                                    <li>
                                        <Link to="/examination_centre">Examination centre</Link>
                                    </li>
                                    <li>
                                        <Link to="/holidays">List of Holidays</Link>
                                    </li>
                                   
                                </ul>
                            </li>
                        </div>
                        <div className='col-lg-6'>
                            <li>
                                <ul>
                                    <li className="text-primary">
                                      Opportunities
                                    </li>
                                    <li>
                                        <Link to="/Opportunities">Overview</Link>
                                    </li>
                                    <li>
                                        <Link to="/student_testimonials">Placements</Link>
                                    </li>
                                    <li>
                                        <Link to="/Trainings">Trainings</Link>
                                    </li>
                                    <li>
                                        <Link to="/Higher_education">Higher Education</Link>
                                    </li>
                                    <li>
                                        <Link to="/Internships">Internships</Link>
                                    </li>
                                    <li>
                                        <Link to="/Entrepreneurship_incubation">Entrepreneurship & Incubation</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact_us">Contact us</Link>
                                    </li>
                                   
                                </ul>
                            </li>
                        </div>
                        

                    </div>
                </ul>
                {/* <ul style={{ width: "250px" }} className={students ? "sub-menu sub-menu-open" : "sub-menu"}>
                    <div className='row' >
                        <div className='col-lg-12'>
                            <li className='menu-item'>
                                <li className={location.pathname === "/students" ? "menu-active" : ""}>
                                    <Link to="/academics">Academics</Link>
                                </li>
                                <li className={location.pathname === "/students" ? "menu-active" : ""}>
                                    <Link to="/Opportunities">Opportunities</Link>
                                </li>
                                <li className={location.pathname === "/students" ? "menu-active" : ""}>
                                    <Link to="#">Examinations</Link>
                                </li>
                                <li className={location.pathname === "/students" ? "menu-active" : ""}>
                                    <Link to="#">Support</Link>
                                </li>
                                <li className={location.pathname === "/students" ? "menu-active" : ""}>
                                    <Link to="#">Campus Life</Link>
                                </li>
                            </li>
                        </div>
                    </div>
                </ul> */}
            </li>
            <li className={parentMenu === 'research' ? 'has-sub menu-active' : 'has-sub'}>
                <Link to="#" className={research ? 'hash menu-active' : 'hash'} onClick={() => setResearch(!research)}>
                    Research
                    <span className='arrow'></span>
                </Link>
                <ul className={research ? 'sub-menu sub-menu-open mega-sub-menu researchmenu' : 'sub-menu mega-sub-menu researchmenu'}>
                    <div className='row' >
                        <div className='col-lg-6'>
                            <li>
                                <ul>
                                    {/* <li className="text-primary">
                                       Academics
                                    </li> */}
                                    <li>
                                        <Link to="/OurResearch"> Our Research</Link>
                                    </li>
                                </ul>
                            </li>
                        </div>
                        <div className='col-lg-6'>
                            <li>
                                <ul>
                                    <li className="text-primary">
                                    Patents & Commercialisation
                                    </li>
                                    <li>
                                        <Link to="/Patents_Commercialisation">Overview</Link>
                                    </li>
                                    <li>
                                        <Link to="/virtual_reality">Advanced Engineering Applications</Link>
                                    </li>
                                    <li>
                                        <Link to="/retrieval_system">Automobile Engineering</Link>
                                    </li>
                                    
                                    <li>
                                        <Link to="/wearable_Device">Healthcare</Link>
                                    </li>
                                    <li>
                                        <Link to="/Automatic_waste">Sustainability</Link>
                                    </li>
                                    <li>
                                        <Link to="/cooking_system">Food Engineering</Link>
                                    </li>
                                    <li>
                                        <Link to="/Toilet_commode">Technology for Disabled and Elderly</Link>
                                    </li>
                                   
                                </ul>
                            </li>
                        </div>
                        

                    </div>
                </ul>
                {/* <ul style={{ width: "250px" }} className={research ? "sub-menu sub-menu-open" : "sub-menu"}>
                    <div className='row' >
                        <div className='col-lg-12'>
                            <li className='menu-item'>
                                <li className={location.pathname === "/research" ? "menu-active" : ""}>
                                    <Link to="/OurResearch"> Our Research</Link>
                                </li>
                                <li className={location.pathname === "/research" ? "menu-active" : ""}>
                                    <Link to="/Patents_Commercialisation">Patents & Commercialisation</Link>
                                </li>
                                <li className={location.pathname === "/research" ? "menu-active" : ""}>
                                    <Link to="#">Research Endeavours & Collaboration</Link>
                                </li>
                            </li>
                        </div>
                    </div>
                </ul> */}
            </li>

            <li className={parentMenu === 'community' ? 'has-sub menu-active' : 'has-sub'}>
                <Link to="#" className={community ? 'hash menu-active' : 'hash'} onClick={() => setCommunity(!community)}>
                    Outreach
                    <span className='arrow'></span>
                </Link>
                <ul className={community ? 'sub-menu sub-menu-open mega-sub-menu' : 'sub-menu mega-sub-menu'}>
                    <div className='row' >
                        <div className='col-lg-4'>
                            <li>
                                <ul>
                                    <li className="text-primary">
                                      Collaborations
                                    </li>
                                    <li>
                                        <Link to="/collaborations">Institute Collaborations</Link>
                                    </li>
                                    <li>
                                        <Link to="/industry"> Industry Collaborations</Link>
                                    </li>
                               </ul>
                            </li>
                        </div>
                       <div className='col-lg-2'>
                            <li>
                                <ul>
                                    {/* <li className="text-primary">
                                    Admission Process
                                    </li> */}
                                    <li>
                                        <Link to="/alumni"> Alumni</Link>
                                    </li>
                               </ul>
                            </li>
                        </div>
                        <div className='col-lg-2'>
                            <li>
                                <ul>
                                    {/* <li className="text-primary">
                                    Hostel Facilities
                                    </li> */}
                                    <li>
                                        <Link to="/community_activities"> Community Activities</Link>
                                    </li>
                               </ul>
                            </li>
                        </div>
                        <div className='col-lg-4'>
                            <li>
                                <ul>
                                    <li className="text-primary">
                                       Aditya Learning Academy (ALA)
                                    </li>
                                    <li>
                                        <Link to="/aditya_learning_academy">About</Link>
                                    </li>
                                    <li>
                                        <Link to="/objectives">Objectives</Link>
                                    </li>
                                    <li>
                                        <Link to="/comprehensive_support_services">Comprehensive Support Services</Link>
                                    </li>
                                    <li>
                                        <Link to="/academy_team">Team</Link>
                                    </li>
                                    <li>
                                        <Link to="/academy_events">Events</Link>
                                    </li>
                               </ul>
                            </li>
                        </div>
                        

                    </div>
                </ul>
                {/* <ul style={{ width: "300px" }} className={community ? "sub-menu sub-menu-open" : "sub-menu"}>
                    <div className='row' >
                        <div className='col-lg-12'>
                            <li className='menu-item'>
                                <li className={location.pathname === "/community" ? "menu-active" : ""}>
                                    <Link to="/collaborations">Collaborations</Link>
                                </li>
                                <li className={location.pathname === "/community" ? "menu-active" : ""}>
                                    <Link to="/alumni">Alumni</Link>
                                </li>
                                <li className={location.pathname === "/community" ? "menu-active" : ""}>
                                    <Link to="/community_activities">Community Activities</Link>
                                </li>
                                <li className={location.pathname === "/community" ? "menu-active" : ""}>
                                    <Link to="/aditya_learning_academy">Aditya Learning Academy</Link>
                                </li>
                            </li>
                        </div>
                    </div>
                </ul> */}
            </li>
            <li className={megaMenu ? 'has-sub menu-active' : 'has-sub'}>
                <Link to="#" className={megaMenu ? 'hash menu-active' : 'hash'} onClick={() => setMegaMenu(!megaMenu)}>
                    About
                    <span className="arrow"></span>
                </Link>
                <ul className={megaMenu ? 'sub-menu sub-menu-open mega-sub-menu' : 'sub-menu mega-sub-menu'}>
                    <div className='row' >
                        <div className='col-lg-4'>
                            <li>
                                {/* <h3>Test 1</h3> */}
                                <ul>
                                    <li className="text-primary">
                                        Aditya University
                                    </li>
                                    <li>
                                        <Link to="/overview">Overview</Link>
                                    </li>
                                    <li>
                                        <Link to="/visionmission">Vision and Mission</Link>
                                    </li>
                                    <li>
                                        <Link to="/corevalues">Core Values</Link>
                                    </li>
                                    <li>
                                        <Link to="/Chancellor">Chancellor's Message</Link>
                                    </li>
                                    <li>
                                        <Link to="/Pro_Chancellor">Pro-Chancellor's Message</Link>
                                    </li>
                                    <li>
                                        <Link to="/Deputy_Pro_Chancellor">Deputy Pro-Chancellor's Message</Link>
                                    </li>
                                    <li>
                                        <Link to="/Vice_Chancellor">Vice Chancellor's Message</Link>
                                    </li>
                                </ul>
                            </li>
                        </div>
                        <div className='col-lg-4'>
                            <li>
                                <ul>
                                    <li className="text-primary">
                                        Governance
                                    </li>
                                    <li>
                                        <Link to="/governing_body_members">Governing Body</Link>
                                    </li>
                                    <li>
                                        <Link to="/board_of_management">Board of Management</Link>
                                    </li>
                                    <li>
                                        <Link to="/academic_councils_members">Academic Council</Link>
                                    </li>
                                    <li>
                                        <Link to="/finance_committee">Finance Committee</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="#">Organogram</Link>
                                    </li> */}
                                </ul>
                            </li>
                        </div>
                        <div className='col-lg-4'>
                            <li>
                                <ul>
                                    <li className="text-primary">
                                        Credentials
                                    </li>
                                    <li>
                                        <Link to="/accreditations">Accreditations , Recognitions & Rankings</Link>
                                    </li>
                                    <li>
                                        <Link to="/iqac">IQAC</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="#">Rankings</Link>
                                    </li> */}
                                </ul>
                            </li>
                        </div>

                    </div>
                </ul>
            </li>
            
                
           

        </>
    );
};

export default MenuItems;
