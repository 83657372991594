import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';

import Food_management_4 from './images/food_management_4.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                    <StudyBreadcrumb pageTitle="Food Engineering" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>Multi-Chamber Mop Bucket for Cleaning and Squeezing Mop Head and Method Thereof</b> <br />
                                                    <b className='name'>Theme:</b>
                                                     <p className='name'>
                                                     An innovative work on mop buckets, and in specific relates to a mop bucket assembly with plurality of chambers and plurality of squeezers to clean and squeeze the mop head efficiently every time while mopping the floor.</p>
                                                    <b className='name'>Background:</b>
                                                     <p className='name'>
                                                     Cleaning tasks such as mopping or sponging surfaces often involve the use of a receptacle such as a bucket for holding water and cleaning solutions. Mop is a tool used for cleaning floors. It consists of a bundle of strings or yarn, or a piece of cloth, sponge, or some other absorbent material that is fixed to a pole that acts as a handle. Homemade mops were made from old pieces of cloth and the so-called “mop nail” – a long nail with a wide flat head that holds the pieces and then nailed into a handle. <br></br><br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Further, a sponge mop that used a lever and flat strip of metal to press against the wet mop and squeeze it dry. This automatic mop eliminated the need to bend over and wring the mop repeatedly by hand. Typically for mopping the floor a user will submerge a mop or sponge in a bucket filled with cleaning solution, remove the excess solution by squeezing or wringing the mop or sponge, and apply the mop or sponge to the surface to be cleaned. Repetitive mopping motions are applied to the surface until an additional solution is needed or the mop or sponge is saturated with dirt and debris. For repeating the process, the sponge or mop is re-submerged into the bucket.
                                                    </p>
                                                    <b className='name'>Our Invention:</b>
                                                     <p className='name'>
                                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The present disclosure proposes a mop bucket assembly with a plurality of chambers and a plurality of squeezers to clean and squeeze the mop head efficiently after mopping the floor. The multi-chamber mop bucket comprises an additive chamber a primary squeezing chamber a reservoir chamber and a secondary squeezing chamber. The multi- chamber mop bucket facilities effective cleaning and squeezing of the mop head. The multi-chamber mop bucket is equipped with a handle and a plurality of wheels to enable easy movement of the mop bucket while mopping the floor. The proposed multi-chambered mop bucket facilitates the use of a fresh and clean mop every time while mopping the floor to avoid the spread of additional dirt and  microorganisms
                                                    </p>
                                                    
                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Residential Cleaning</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Industrial Facilities</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Food Service Industrys</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Retail Stores</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Educational Institutions</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Healthcare Facilities</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Commercial Cleaning Services</span><br />
                                                    
                                                    </p>

                                                    <b className='name'>IP Status</b>
                                                     <p className='name'>
                                                     The invention is the subject of a Granted Patent No. 523615 IP India<br></br>
                                                     Application No. 202241050493                                                   
                                                    </p>

                                                    <b className='name'>Inventors</b>
                                                     <p className='name'>
                                                     Dr. M. Raja Babu   
                                                     </p>
                                                    <center>
                                                        <div className='md-6'>
                                                        <img src={Food_management_4} />
                                                        </div>
                                                        <b className='name'>Model diagram of Multi-Chamber Mop Bucket for Cleaning</b>
                                                    </center>
                                                    <br/>

                                                    <b className='name'>Contact us</b>
                                                     <p className='name'>
                                                     If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at :<b>dean_rc@adityauniversity.in</b>                                                       
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;