import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import retrieval from './images/retrieval.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                    <StudyBreadcrumb pageTitle="Automobile Engineering" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                            <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>Development of Clutch Based Mechanical Oriented Side Stand Retrieval System Mechanism for 2 Wheelers and Method Thereof</b> <br />
                                                    <b className='name'>Theme:</b>
                                                     <p className='name'>
                                                     An innovative approach to develop a Clutch Based Mechanical Oriented Side Stand Retrieval System Mechanism for 2 Wheelers.
                                                    </p>
                                                    <b className='name'>Background:</b>
                                                     <p className='name'>
                                                     Motorized two wheelers are generally provided with a supporting stand on its side, attached pivotally to the wheeler. The term supporting stand refers to all the kind of arrangements such as a rod, bar on one side or arranged to be on both the sides of the two wheeler vehicle. The side stand is swung upwardly so that it does not interfere during the running of the two wheeler and is moved downwardly to rest the two wheeler. The two wheeler rests upon the supporting stand, while not in motion.
                                                    </p>
                                                    
                                                     <p className='name'>
                                                     If a two wheeler is started, with its side stand remaining upright, the side stand tends to impinge on the road surface, particularly when the two wheeler is turned to the side of the side stand, thus leading to the overturning of the motorcycle and leading to skidding of bike. This invention relates to a device for controlling the movement of the clutch, if the supporting stand is in a downward position, so as to avoid the motion of the two wheeler.
                                                    </p>
                                                    
                                                    <b className='name'>Our Invention</b>
                                                    <p className='name'>
                                                    The system of present invention restricts the movement of the clutch in case the supporting stand is in a downward direction. The three gear arrangement comprising of a top and bottom sector gear, capable of moving over a idler gear; a supporting stand attached to the bottom sector gear; a right angle lever attached to the top sector gear at one end and is closely placed to the clutch lever. When the supporting stand is in a downward position, the upper sector gear moves over the idler gear so as to move the right angle lever towards the clutch lever, thereby restricting the movement of the clutch.
                                                    </p>
                                                    

                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>
                                                   
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Two wheeler assembling companies </span><br />
                                                   
                                                    
                                                    </p>
                                                    <b className='name'>IP Status </b>
                                                    <p className='name'>
                                                    The invention is the subject of a Granted Patent No. 411065 IP India
                                                    </p>
                                                    <p className='name'>
                                                    Application No. 201841038721
                                                    </p>
                                                    <b className='name'>Inventors </b>
                                                    <p className='name'>
                                                    <span>Dr P S Ranjit</span><br/>
                                                    <span>Dr V V Kamesh</span><br/>
                                                   <span> Dr V S N Ch Dattu</span>
                                                    </p>
                                                   

                                                    <center>
                                                        <img src={retrieval} /><br></br>
                                                       
                                                    </center>
                                                    <br/>

                                                    <b className='name'>Contact us</b>
                                                     <p className='name'>
                                                     If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at  <a style={{color:"blue"}} href='dean_rd@adityauniversity.in'>dean_rc@adityauniversity.in</a>                                                      
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;