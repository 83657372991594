import React from 'react';
import Header from '../../../../components/Header';
import FooterTwo from '../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../components/ScrollTop';
import "../../../../components/Styles/pragram.css"
import point from '../../../../assets/images/icons/image.png';
import SideManu from './sidebar_management';



const Managementoverview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                   <StudyBreadcrumb pageTitle="School of Business " />
                   <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                <div class="container">
                                                    <center><b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}><img src={point} style={{height:"25px"}}/>  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>Welcome to the School of Business  at Aditya University, where we are dedicated to cultivating the business leaders of tomorrow through our comprehensive Master of Business Administration (MBA) programs. Our school is a hub of excellence, combining rigorous academic standards with practical, real-world applications to prepare students for successful careers in the dynamic world of business.</p>
                                                        <p className='name'>Our MBA programs are designed to provide a deep understanding of business fundamentals while fostering critical thinking, leadership, and strategic decision-making skills. Through a curriculum that integrates core business disciplines with contemporary management practices, we aim to develop well-rounded professionals equipped to navigate and thrive in a rapidly changing global marketplace.</p>

                                                        <p className='name'>A strong emphasis on experiential learning is provided, our students engage in case studies, internships, and live projects that provide hands-on experience and practical insights into the complexities of modern business operations. This approach ensures that our graduates are not only knowledgeable but also adept at applying their skills in real-world scenarios.</p>
                                                        <p className='name'>We are proud to offer a vibrant learning community that encourages collaboration, innovation, and professional growth. Our partnerships with leading businesses and global institutions provide students with access to a network of resources and opportunities that enhance their educational experience and career prospects.</p>
                                                        <p className='name'>At the School of Business , we are committed to empowering our students to achieve their full potential. Explore the myriad opportunities awaiting you at the School of Business at Aditya University, where your future in business leadership begins.</p>
                                                        
                                                        <br/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            <FooterTwo />

        </>
    );
}

export default Managementoverview;