import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_ce';
import Banner from './banner';





const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  M.Tech </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>The Department of Civil Engineering offers a postgraduate program (PG) in Structural Engineering (M. Tech – Structural Engineering), that provides a rich blend of academic rigor, practical application, and personal development. Nestled within a vibrant academic community, our Structural Engineering program was designed to ignite research curiosity, foster critical thinking, and equip students with the skills needed to thrive in the Structural Engineering field. </p>

                                                        <p className='name'>NABL-accredited laboratories with a scope for 50 tests offer a wide range of experiments for structural engineering applications. Well-established R&D Lab equipped with high-performance computers for numerical simulations. Experienced faculty members having structural engineering specialization and completed their Ph.D. from IITs and NITs. MOUs with reputed industries and institutions (L&T EduTech, UltraTech Cements Limited., Manihamsa Power Projects Ltd., APSSDC, Sreshta Engineers etc.)  Opportunity to get placed in various core companies with the highest package.</p>

                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;