import { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "../../components/Styles/admission.css";



import sliderImg1 from "../../assets/images/slider/admission-slider-1.jpg";
import sliderImg2 from "../../assets/images/slider/admission-slider-2.jpg";
import sliderImg3 from "../../assets/images/slider/admission-slider-3.jpg";
import sliderImg4 from "../../assets/images/slider/admission-slider-4.jpg";
// import sliderImg5 from "../../assets/images/slider/admission-slider-5.jpg";
import sliderImg6 from "../../assets/images/slider/admission-slider-6.jpg";
import sliderImg7 from "../../assets/images/slider/admission-slider-7.jpg";

import sliderImg1Mb from "../../assets/images/slider/admission-slider-1-mb.jpg";
import sliderImg2Mb from "../../assets/images/slider/admission-slider-2-mb.jpg";
// import sliderImg3Mb from "../../assets/images/slider/admission-slider-3-mb.jpg";
import sliderImg4Mb from "../../assets/images/slider/admission-slider-4-mb.jpg";
import sliderImg5Mb from "../../assets/images/slider/admission-slider-5-mb.jpg";
import sliderImg6Mb from "../../assets/images/slider/admission-slider-6-mb.jpg";
import sliderImg7Mb from "../../assets/images/slider/admission-slider-7-mb.jpg";

import sliderImg11 from "../../assets/images/slider/11.jpg";
import sliderImg12 from "../../assets/images/slider/12.jpg";

const HomeSlider = () => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        margin: 0,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                }
            }
        ]
    };

    return (
        <>
            <div className="react-slider-part">
                <ModalVideo channel='youtube' isOpen={isOpen} videoId='e5Hc2B50Z7c' onClose={() => { openModal(); }} />
                <div className="home-sliders home2">
                    <Slider {...sliderSettings}>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg1} />
                                <Link to="https://apply.adityauniversity.in/" className="admission-slider-btn">
                                        <img style={{zIndex:"999"}} className="mobile" src={sliderImg1Mb} alt="Slider Image 1" />
                                </Link>
                            </div>
                            <div className="container">
                                <div className="slider-content ">
                                    <div className="content-part">
                                        {/* <span className="slider-pretitle wow animate__fadeInUp" data-wow-duration="1s">Great Quality Cocial life</span>
                                        <h2 className="slider-title wow animate__fadeInUp" data-wow-duration="1s">
                                            Discover the world of<br />
                                            possible university.
                                        </h2> */}
                                        <div className="admission-slider-1 wow d-none d-md-block d-lg-block" data-wow-duration="1.2s">
                                            <Link to="https://apply.adityauniversity.in/" className="admission-slider-btn">Apply Now</Link>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="event__video-btn--play wow animate__fadeInUp" data-wow-duration="1.4s">
                                <Link to="#" className="event__video-btn--play-btn custom-popup" onClick={() => { openModal(); }}>
                                    <i className="arrow_triangle-right"></i>
                                    <em>Watch Video <br />Intro</em>
                                </Link>
                                </div> */}
                            </div>                        
                        </div>

                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg2} />
                                <Link to="https://apply.adityauniversity.in/" className="admission-slider-btn">
                                    <img className="mobile" src={sliderImg2Mb} alt="Slider Image 1" />
                                </Link>
                            </div>
                            <div className="container">
                                <div className="slider-content slider5-content-change">
                                    <div className="content-part">
                                        {/* <span className="slider-pretitle wow animate__fadeInUp" data-wow-duration="1s">Great Quality Cocial life</span>
                                        <h2 className="slider-title wow animate__fadeInUp" data-wow-duration="1s">
                                            Discover the world of<br />
                                            possible university.
                                        </h2> */}
                                        <div className="admission-slider-2 wow d-none d-md-block d-lg-block" data-wow-duration="1.2s">
                                            <Link to="https://apply.adityauniversity.in/" className="admission-slider-btn">Apply Now</Link>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="event__video-btn--play wow animate__fadeInUp" data-wow-duration="1.4s">
                                <Link to="#" className="event__video-btn--play-btn custom-popup" onClick={() => { openModal(); }}>
                                    <i className="arrow_triangle-right"></i>
                                    <em>Watch Video <br />Intro</em>
                                </Link>
                                </div> */}
                            </div>                        
                        </div>
                       
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg3} />
                               
                                <Link to="https://apply.adityauniversity.in/" className="admission-slider-btn">
                                    <img className="mobile" src={sliderImg5Mb} alt="Slider Image 1" />
                                </Link>
                            </div>
                            <div className="container">
                                <div className="slider-content ">
                                    <div className="content-part">
                                        {/* <span className="slider-pretitle wow animate__fadeInUp" data-wow-duration="1s">Great Quality Cocial life</span>
                                        <h2 className="slider-title wow animate__fadeInUp" data-wow-duration="1s">
                                            Discover the world of<br />
                                            possible university.
                                        </h2> */}
                                        <div className="admission-slider-3 wow d-none d-md-block d-lg-block" data-wow-duration="1.2s">
                                            <Link to="https://apply.adityauniversity.in/" className="admission-slider-btn">Apply Now</Link>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="event__video-btn--play wow animate__fadeInUp" data-wow-duration="1.4s">
                                <Link to="#" className="event__video-btn--play-btn custom-popup" onClick={() => { openModal(); }}>
                                    <i className="arrow_triangle-right"></i>
                                    <em>Watch Video <br />Intro</em>
                                </Link>
                                </div> */}
                            </div>                        
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg4} />
                                <Link to="https://apply.adityauniversity.in/" className="admission-slider-btn">
                                    <img className="mobile" src={sliderImg4Mb} alt="Slider Image 1" />
                                </Link>
                            </div>
                            <div className="container">
                                <div className="slider-content slider5-content-change">
                                    <div className="content-part">
                                        {/* <span className="slider-pretitle wow animate__fadeInUp" data-wow-duration="1s">Great Quality Cocial life</span>
                                        <h2 className="slider-title wow animate__fadeInUp" data-wow-duration="1s">
                                            Discover the world of<br />
                                            possible university.
                                        </h2> */}
                                        <div className="admission-slider-2 wow d-none d-md-block d-lg-block" data-wow-duration="1.2s">
                                            <Link to="https://apply.adityauniversity.in/" className="admission-slider-btn">Apply Now</Link>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="event__video-btn--play wow animate__fadeInUp" data-wow-duration="1.4s">
                                <Link to="#" className="event__video-btn--play-btn custom-popup" onClick={() => { openModal(); }}>
                                    <i className="arrow_triangle-right"></i>
                                    <em>Watch Video <br />Intro</em>
                                </Link>
                                </div> */}
                            </div>                        
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg6} />
                                <Link to="https://apply.adityauniversity.in/" className="admission-slider-btn">
                                    <img className="mobile" src={sliderImg6Mb} alt="Slider Image 1" />
                                </Link>
                            </div>
                            <div className="container">
                                <div className="slider-content slider5-content-change">
                                    <div className="content-part">
                                        {/* <span className="slider-pretitle wow animate__fadeInUp" data-wow-duration="1s">Great Quality Cocial life</span>
                                        <h2 className="slider-title wow animate__fadeInUp" data-wow-duration="1s">
                                            Discover the world of<br />
                                            possible university.
                                        </h2> */}
                                        <div className="admission-slider-2 wow d-none d-md-block d-lg-block" data-wow-duration="1.2s">
                                            <Link to="https://apply.adityauniversity.in/" className="admission-slider-btn">Apply Now</Link>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="event__video-btn--play wow animate__fadeInUp" data-wow-duration="1.4s">
                                <Link to="#" className="event__video-btn--play-btn custom-popup" onClick={() => { openModal(); }}>
                                    <i className="arrow_triangle-right"></i>
                                    <em>Watch Video <br />Intro</em>
                                </Link>
                                </div> */}
                            </div>                        
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg7} />
                                <Link to="https://apply.adityauniversity.in/" className="admission-slider-btn">
                                    <img className="mobile" src={sliderImg7Mb} alt="Slider Image 7" />
                                </Link>
                            </div>
                            <div className="container">
                                <div className="slider-content slider5-content-change">
                                    <div className="content-part">
                                        
                                        <div className="admission-slider-2 wow d-none d-md-block d-lg-block" data-wow-duration="1.2s">
                                            <Link to="https://apply.adityauniversity.in/" className="admission-slider-btn">Apply Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>                        
                        </div>
                        {/* <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg5} alt="Slider Image 1" />
                                  <Link to="https://apply.adityauniversity.in/" className="admission-slider-btn">
                                        <img className="mobile" src={sliderImg3Mb} alt="Slider Image 1" />                                        
                                </Link> 
                            </div>
                            <div className="container">
                                <div className="slider-content slider5-content-change">
                                    <div className="content-part">
                                        
                                        <div className="admission-slider-2 wow d-none d-md-block d-lg-block" data-wow-duration="1.2s">
                                            <Link to="https://apply.adityauniversity.in/" className="admission-slider-btn">Apply Now</Link>
                                        </div>
                                    </div>
                                </div>
                                
                            </div> 
                        </div> */}
                    </Slider>
                </div>
            </div>
        </>
    );
}

export default HomeSlider;