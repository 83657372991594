import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_pt';
import Banner from './banner';
// import PTPopUp from "../../../../../components/Popup/Petroleum/PTPopUp";





const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    {/* <PTPopUp /> */}
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  B-Tech </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>The UG Programme Petroleum Technology provides a comprehensive education that combines theoretical knowledge with practical application, preparing students to thrive in the dynamic energy sector while being environmentally conscious and ethically grounded. Our curriculum focuses on essential areas within petroleum technology such as Engineering and Petroleum Geology, Drilling Engineering, Production Engineering, Reservoir Engineering, Petroleum Economics and Safety Management. These knowledge areas prepare the students to enhance the quality of life, foster economic growth, and tackle societal challenges. By integrating technology, education, and policy, we can create a positive impact that resonates globally. The Department has strong partnerships with leading oil and gas companies to ensure the students gain internships, practical exposure and insights from industry experts. </p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Petroleum Technology <br />
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Petroleum Technology with  Minor Specialization in
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Pipeline and Petroleum Transport Engineering </span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Fire and Safety in Petroleum Industries</span> <br />
                                                        </p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Petroleum Technology with
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Civil  Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Electrical and Electronics Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Mechanical Technology<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Electronics and Communication Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Computer Science and Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Data Science<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Artificial Intelligence and Machine Learning<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Mining Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Agricultural Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Entrepreneurship Development and Incubation<br />
                                                        </p>

                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;