import React from "react";

import CoreValuesPart from './CoreValuesSection'
import Testimonial from './TestimonialSection'
import Feature from './FeatureSection'
import Instructor from './InstructorSection'

const CoreValuesMain = () => {


    return (
        <>
            <CoreValuesPart />
            
            {/* <Feature /> */}
            
            {/* <Instructor /> */}

            {/* <Testimonial /> */}
        </>
    )
}

export default CoreValuesMain;