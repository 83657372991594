import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';


const Sidebar = () => {
    const [visibleSubmenu, setVisibleSubmenu] = useState(null);
    const location = useLocation();

    const toggleSubmenu = (submenu) => {
        setVisibleSubmenu(visibleSubmenu === submenu ? null : submenu);
    };

    const handleSubmenuClick = (path) => {
        setVisibleSubmenu(null); // Close the submenu after clicking an item
    };

    return (
        
        <div className="col-md-3 col-sm-3 col-0">
            <div className="side-navbar">
                <nav className="navbar">
                    <ul className="navbar-nav">
                        <li className="navbar-title text-center">
                            <a className="navbar-brand text-light1" href="#" style={{ padding: '12px 121px 12px 131px' }}>Explore</a>
                        </li>
                        <li className={`nav-item ${location.pathname === '/Opportunities' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/Opportunities">Overview</Link>
                        </li>
                        
                        <li
                            className={`nav-item ${['/student_testimonials', '/top_recruiters', '/placement_rules'].includes(location.pathname) || visibleSubmenu === 'placements' ? 'active' : ''}`}
                            onMouseEnter={() => toggleSubmenu('placements')}
                            onMouseLeave={() => toggleSubmenu(null)}
                            onClick={() => toggleSubmenu('placements')}
                        >
                            <Link className="nav-link" to="#">Placements</Link>
                            {visibleSubmenu === 'placements' && (
                                <ul className="submenu">
                                    
                                    <li
                                        className={`nav-item ${location.pathname === '/student_testimonials' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/student_testimonials')}
                                    >
                                        <Link className="nav-link" to="/student_testimonials">Student Testimonials</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/top_recruiters' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/top_recruiters')}
                                    >
                                        <Link className="nav-link" to="/top_recruiters"> Top Recruiters</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/placement_rules' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('#')}
                                    >
                                        <Link className="nav-link" to="/placement_rules">Placement rules</Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li className={`nav-item ${location.pathname === '/Trainings' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/Trainings">Trainings </Link>
                        </li>
                       
                        <li className={`nav-item ${location.pathname === '/Higher_education' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/Higher_education">Higher Education</Link>
                        </li>
                        <li className={`nav-item ${location.pathname === '/Internships' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/Internships">Internships</Link>
                        </li>
                        <li className={`nav-item ${location.pathname === '/Entrepreneurship_incubation' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/Entrepreneurship_incubation">Entrepreneurship & Incubation</Link>
                        </li>
                        <li className={`nav-item ${location.pathname === '/contact_us' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/contact_us">Contact us </Link>
                        </li>
                        <br />
                    </ul>
                </nav>
            </div>
        </div>
        
    );
}

export default Sidebar;
