import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import '../../../components/Styles/pragram.css';
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_opportunities';
// import Entrepreneur from './images/Entrepreneur.jpg';
import EntrepreneurshipSlider from './entrepreneurship_slider';






const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                <EntrepreneurshipSlider />
                  {/* <StudyBreadcrumb pageTitle="Entrepreneurship & Incubation" /> */}
                  {/* <img src={Entrepreneur} /> */}

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                <div class="container">
                                                   <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}> Entrepreneurship & Incubation :</b> <br />
                                                     <p className='name'>
                                                     The Career Development Centre champions the spirit of innovation and entrepreneurship through dedicated support and resources. We provide aspiring entrepreneurs with comprehensive guidance on business planning, development and execution. Our incubation programs offer access to cutting-edge facilities, mentorship from industry experts and networking opportunities with potential investors and partners. By fostering a culture of creativity and resilience, we empower students to transform innovative ideas into viable business ventures. Whether you're looking to start a tech startup, social enterprise or any innovative project. our centre is equipped to support your journey from concept to successful enterprise, ensuring you have the tools and confidence needed to thrive in the entrepreneurial landscape.
                                                    </p>
                                                   </div>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;