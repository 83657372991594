import React, { useEffect, useState } from 'react';

const PolicyMain = () => {


    return (
        <div className="react-login-page react-signup-page pt---120 pb---120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="registration-right-form">
                            <form>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <h4 style={{textDecoration:"underline"}}>Terms and conditions</h4>
                                        <p>The fee paid will be considered under the specific head only. No change of head or No adjustment to other head will be entertained</p>
                                    </div>
                                    <div className='col-lg-12'>
                                        <h5 className='text-danger'>Refund and Cancellation</h5>
                                        <p>The fee Once paid will not be refunded or cancelled</p>
                                    </div>
                                    <div className='col-lg-12'>
                                        <h5 className='text-danger'>Privacy Policy</h5>
                                        <p>The credentials of the payee i.e., Account number, Card No and other details will not be saved at our end and the institution will not be responsible for fraudulent transactions , if any</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default PolicyMain;