import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_ds';
import Banner from './banner';




const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        
                                                        <p className='name'>Join the Computer Science Engineering - Data Science program for a journey of innovation and exploration. The dynamic curriculum, led by experienced faculty and supported by state-of-the-art facilities, offers a blend of theoretical knowledge and practical application. From mastering advanced data analytics to developing cutting-edge machine learning models, the department empowers students to become versatile professionals in the rapidly evolving field of data science.
                                                        </p>
                                                        <p className='name'>Our program focuses on recent advancements in data science, including artificial intelligence, big data technologies, and data visualization. Collaboration with industry leaders and professional organizations ensures that students stay current with the latest trends and technological innovations. Unleash your potential to shape the future of data-driven technology with us. 
                                                        </p>
                                                        



                                                        <br />
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  VISION : </b> <br />

                                                        <p className='name'>To become a leading department in producing Data analytic experts & researchers to accomplish the needs in industry and society.</p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> MISSION:</b><br />
                                                        <p className='name'>
                                                            M1: Impart the knowledge through states-of-the-art concepts, tools and techniques in Data Science and Analytics.<br />
                                                            M2: Promote technical competence to provide solutions to the future challenges through Industry collaboration.<br />
                                                            M3: Inculcate social responsibility through ethics, environmental and societal consciousness.<br />
                                                            M4: Build leadership and life-long learning qualities to ensure the overall development of stakeholder.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;