import React from 'react';
import point from '../../assets/images/icons/image.png';
const CoreValuesPart = () => {

    return (
        <div class=" p-relative pb---60" style={{ marginTop: "40px" }}>
            <div class="container">
                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s"><b>Core Values</b></h2>
                <div class="degree-list-container">
                    <ul class="degree-list">
                        <h6 className="wow animate__fadeInUp text-danger" data-wow-duration="0.3s"> <b>Excellence</b></h6>
                        <li><span class="icon"><img className='pointimage' src={point} /></span><span>We hold ourselves to the highest standards in all aspects of education, research, and community engagement.</span> </li>
                    </ul>
                </div>
                <div class="degree-list-container">
                    <ul class="degree-list">
                        <h6 className="wow animate__fadeInUp text-danger" data-wow-duration="0.3s"> <b>Inclusivity and Diversity</b></h6>
                        <li><span class="icon"><img className='pointimage' src={point} /></span><span>We value diversity and provide an inclusive environment where people from all background’s perspectives, and experiences learn, cooperate, and grow. We believe a varied academic atmosphere enhances education for all. </span> </li>
                    </ul>
                </div>
                <div class="degree-list-container">
                    <ul class="degree-list">
                        <h6 className="wow animate__fadeInUp text-danger" data-wow-duration="0.3s"> <b>Integrity and Ethical Conduct</b></h6>
                        <li><span class="icon"><img className='pointimage' src={point} /></span><span>Our academic community is built on integrity. We do ethical research, teaching, and interactions. All university staff and students are valued for their honesty, transparency, and accountability, which helps to foster a trustworthy and accountable environment. </span> </li>
                    </ul>
                </div>
                <div class="degree-list-container">
                    <ul class="degree-list">
                        <h6 className="wow animate__fadeInUp text-danger" data-wow-duration="0.3s"> <b>Global Outlook</b></h6>
                        <li><span class="icon"><img className='pointimage' src={point} /></span><span>We advocate     for global knowledge, intercultural understanding, and social responsibility. Our graduates have the ability to solve problems from a global perspective. </span> </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default CoreValuesPart;