import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import '../../../components/Styles/pragram.css';
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_opportunities';
import Internships_banner from './images/Internships_banner.jpg';






const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                  {/* <StudyBreadcrumb pageTitle="Internships" /> */}
                  <img src={Internships_banner} />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                <div class="container">
                                                   <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}> Internships :</b> <br />
                                                     <p className='name'>
                                                     Internships play a crucial role in bridging the gap between academic learning and real-world application. They offer students invaluable opportunities to gain hands-on experience, develop industry-specific skills and build professional networks. Through internships, students can explore potential career paths, understand workplace dynamics and enhance their resumes with practical achievements. Our Career Development Centre collaborates with leading companies and organizations across various sectors to provide a wide range of internship opportunities. These experiences not only help students apply their theoretical knowledge in a practical setting but also boost their employability by equipping them with the competencies demanded by today’s employers. Whether it's a summer internship or part-time engagement, our tailored internship offerings are designed to support students in making informed career decisions and achieving their professional goals.
                                                    </p>
                                                   </div>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;