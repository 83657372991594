import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_cse';
import Banner from './banner';




const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                   <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        
                                                        <p className='name'>The Department of Computer Science and Engineering (CSE) established in 2001, offers undergraduate, post-graduate, and doctoral programs. The department features well-established and equipped laboratories with high-end configurations and computing facilities, including 4 high-end servers, 6 laboratories, and 2 industry-sponsored labs. These labs support various programming languages and recent technologies like data mining tools, machine learning, and IoT. Additionally, there are specialized labs for postgraduate studies, research, and a computer center with high-speed internet connectivity.
                                                        </p>
                                                        <p className='name'>To enhance placement opportunities, the department has signed MOUs with several standard assessment organizations such as Co Cubes, AMCAT, and Elitmus. Additionally, inhouse technical team, known as Technical Hub, trains students in coding practices and problem-solving skills. The college is a Pearson certified center and has associations with numerous MNCs like Oracle Academy, CISCO, Infosys Campus Connect, SAP Student Academy Program, Red Hat Academy, and the APSSDC Skill Development Center. Most students receive training and certification in various new technologies and industry trends from the first year onwards. Many students secure placements in top companies with packages exceeding 10 lakhs, with an average package around 5 lakhs over the past two years.
                                                        </p>
                                                        <p className='name'>The passionate faculty encourage students to secure placements in MNCs and participate in coding competitions such as Google Kickstart, TCS CodeVita, Infosys InfyTQ, and Virtusa Neural Hack. They also support students in establishing startups and contributing to research articles. The department hosts ISTE and CSI student chapters that organize various technical events.
                                                        </p>
                                                       



                                                        <br />
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  VISION : </b> <br />

                                                        <p className='name'>To emerge as a competent Centre of excellence in the field of Computer Science and Engineering for industry and societal needs.</p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> MISSION:</b><br />
                                                        <p className='name'>
                                                            M1: Impart quality and value-based education.<br />
                                                            M2: Inculcate the inter-personal skills and professional ethics.<br />
                                                            M3: Enable research through state-of-the-art infrastructure.<br />
                                                            M4: Collaborate with industries, government, and professional societies.

                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;