import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_ce';
import Banner from './banner';





const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Ph.D Program </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>The Doctor of Philosophy (Ph.D.) program is intricately designed to provide research scholars with an enriching opportunity to deepen their understanding and expertise in their chosen areas of interest. This comprehensive program encompasses specialized courses and a research thesis, both of which play pivotal roles in shaping the academic journey of the participants. The research team are specialized in diverse research areas, including Structural Engineering, Water Resource Engineering, Geotechnical Engineering, and Offshore Marine Structures. Our state-of-the-art laboratories are equipped with advanced heavy machinery and testing equipment, such as Universal Testing Machines (UTM), Compression Testing Machines (CTM), Non-Destructive Testing (NDT) Equipment, Tri-axial Shear Testing Machines, Kaplan Turbines, Francis Turbines, and Pelton Wheel Testing Rigs</p>

                                                     </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;