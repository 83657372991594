import React, { useEffect } from 'react';



const Careers = () => {
  useEffect(() => {
    window.location.href = 'https://aec.edu.in/careers/';
  }, [])
  return (
  <></>    
  )
}

export default Careers;



