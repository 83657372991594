import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_mining';
import Banner from './banner';




const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        
                                                        <p className='name'>Established in 2014, the Department of Mining Engineering offers a unique and contemporary approach to engineering education, emphasizing the road less travelled for employment at the graduation level. Our curriculum is designed to provide a robust foundation in engineering principles, supported by state-of-the-art laboratories, including Mineral Engineering, Mine Environmental Engineering, Metallurgy, Mining Geology, and Mine Surveying labs, alongside advanced computational facilities.
                                                        </p>
                                                        <p className='name'>Our curriculum development is significantly influenced by the insights gained from our board of studies meetings, which include members from premier institutes like IITs and NITs, as well as industry experts. This collaborative approach ensures that our curriculum remains relevant and aligned with industry standards.</p>
                                                        <p className='name'>In the context of competitive exams like the GATE (Graduate Aptitude Test in Engineering), the department conducts specialized coaching sessions and workshops. These sessions focus on academic preparation while also providing insights into exam patterns, time management strategies, and effective problem-solving techniques. Consequently, we boast a strong alumni presence in leading industry players such as Coal India Limited, NTPC, Ramco Cements, and Indian Cements, etc.</p>
                                                        <p className='name'>The department fosters a culture of innovation, research, and collaboration, preparing students for the challenges and opportunities in the ever-evolving field of mining engineering. Our holistic education approach goes beyond traditional classroom settings, offering various extracurricular activities, including industry seminars and workshops, field visits and site tours, guest lectures, internship programs, student research projects, competitions and hackathons, professional development workshops, and active student clubs and societies.
                                                        </p>
                                                        <p className='name'>We also facilitate interactions with successful alumni who have pursued higher education in renowned institutions, providing current students with valuable insights and advice. This connection helps bridge the gap between academic learning and real-world application, ensuring our graduates are well-equipped for their professional journeys.</p>



                                                        <br />
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  VISION : </b> <br />

                                                        <p className='name'>To prepare the graduates in the major fields of mining engineering at par with international standards.</p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> MISSION:</b><br />
                                                        <p className='name'>
                                                            M1: By upgrading mining engineering education through training of faculties regularly.<br />
                                                            M2: By providing state of the art laboratory facilities & constantly updating it.<br />
                                                            M3: By exposing the real time technologies practiced in mining industries.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;