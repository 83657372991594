import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
const backendUrl = "https://adityauniversity.in:4001/api/"

const Admission = () => {

  const navigate = useNavigate();

  const [photo, setPhoto] = useState();
  const [Certificate, setCCertificate] = useState();
  const [AAcademic_Certificates, setAcademic_Certificates] = useState();
  const [ANET_SET_GATE_Score, setNET_SET_GATE_Score] = useState();
  const [AProfessional_Experience, setProfessional_Experience] = useState();
  const [AFirst_Page_Publication_file, setFirst_Page_Publication_file] = useState();
  const [AObjection_certificate_file, setObjection_certificate_file] = useState();
  const [AResearch_plan_file, setResearch_plan_file] = useState();
  const [experienceRows, setExperienceRows] = useState([
    { Name_Of_Organation: '', Desigination: '', Start_Date: '', End_Date: '', Duration: '' }
  ]);

  const [publicationsRows, setPublications] = useState([
    {
      sno: '', Title_of_Paper: '', Name_Of_Journal: '', Issue_No: '', Publication_year: ''
    }
  ]);

  const [UsersApplicationIds, setUsersApplicationIds] = useState([]);
  const [backDetails, setBackDetails] = useState();
  const location = useLocation();
  const { state } = location;
  useEffect(() => {
    // const backDetails = location.state;
    setBackDetails(state);
    if (state != undefined) {
      console.log(state)

      setExperienceRows(state.Employee_Experience)
      setPublications(state.Publication)

      setPhoto(state.photo)
      setCCertificate(state.CCertificate)
      setAcademic_Certificates(state.Academic_Certificates)
      setNET_SET_GATE_Score(state.NET_SET_GATE_Score)
      setProfessional_Experience(state.Professional_Experience)
      setFirst_Page_Publication_file(state.First_Page_Publication_file)
      setResearch_plan_file(state.Research_plan_file)
      // console.log(state.CCertificate.name)
    }
  }, [state])

  useEffect(() => {
    axios.get("https://adityauniversity.in:4001/api/GetApplicationByid")
      .then((res) => {
        // console.log(res.data)
        setUsersApplicationIds(res.data);
      }).catch((er) => { console.log(er) })
  }, [])

  function getRandomNumberNotInList(min, max, list) {
    let randomNumber;
    do {
      randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    } while (list.includes(randomNumber));
    return randomNumber;
  }
  let excludedNumbers = UsersApplicationIds;
  let minNumber = 1;
  let maxNumber = 10000;
  const currentYear = new Date().getFullYear();
  const month = new Date().getMonth() + 1;

  let randomNumber = getRandomNumberNotInList(minNumber, maxNumber, excludedNumbers);
  let applicationId = `${currentYear}${month}${randomNumber}`;
  // console.log("Application_id:", applicationId);

  const [regdata, setRegdata] = useState({
    Application_id: applicationId,
    Name_of_Applicant: '',
    Name_of_Father: '',
    Gender: '',
    Category: '',
    Date_Of_Birth: '',
    Nationality: '',
    Email: '',
    ContactNo: '',
    Address: '',

    SSC_X_Standard_Place: '',
    SSC_X_Standard_Board: '',
    SSC_X_Standard_Year: '',
    SSC_X_Standard_Percentage: '',

    HSC_XII_Standard_Place: '',
    HSC_XII_Standard_Board: '',
    HSC_XII_Standard_Year: '',
    HSC_XII_Standard_Percentage: '',

    Bachelors_Degree_Place: '',
    Bachelors_Degree_Board: '',
    Bachelors_Degree_Year: '',
    Bachelors_Degree_Percentage: '',

    Masters_Degree_Place: '',
    Masters_Degree_Board: '',
    Masters_Degree_Year: '',
    Masters_Degree_Percentage: '',

    Other_Particulars: '',
    Research_Plan: '',
    Scopus_URL: '',
    Signature: '',
    Interested: '',
    net_score: '',
    published: '',
    department: '',





  });

  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  // 1.Personal Details:
  const applicantRef = useRef(null);
  const fatherRef = useRef(null);
  const genderRef = useRef(null);
  const categoryRef = useRef(null);
  const date_Of_birthRef = useRef(null);
  const nationalityRef = useRef(null);
  const emailRef = useRef(null);
  const contactnoRef = useRef(null);
  const addressRef = useRef(null);
  // 2. Academic Details
  const sscplaceRef = useRef(null);
  const sscboardRef = useRef(null);
  const sscyearRef = useRef(null);
  const sscpercentageRef = useRef(null);

  const hscplaceRef = useRef(null);
  const hscboardRef = useRef(null);
  const hscyearRef = useRef(null);
  const hscpercentageRef = useRef(null);

  const bachelorsplaceRef = useRef(null);
  const bachelorsboardRef = useRef(null);
  const bachelorsyearRef = useRef(null);
  const bachelorspercentageRef = useRef(null);

  const mastersplaceRef = useRef(null);
  const mastersboardRef = useRef(null);
  const mastersyearRef = useRef(null);
  const masterspercentageRef = useRef(null);

  const other_particularsRef = useRef(null);
  const research_planRef = useRef(null);
  const interestedRef = useRef(null);
  const scopus_urlRef = useRef(null);
  const signatureRef = useRef(null);

  const name_of_organationRef = useRef(null);
  const desiginationRef = useRef(null);
  const start_dateRef = useRef(null);
  const end_dateRef = useRef(null);

  const sno_ref = useRef(null);
  const title_of_paper_ref = useRef(null);
  const name_of_journal_ref = useRef(null);
  const issue_no_ref = useRef(null);
  const publication_year_ref = useRef(null);



  const photoRef = useRef(null);
  const certificateRef = useRef(null);
  const academic_certificatesRef = useRef(null);
  const score_Ref = useRef(null);
  const professional_experienceRef = useRef(null);
  const publication_fileRef = useRef(null);
  const upload_research_planRef = useRef(null);
  const department_ref = useRef(null);




  // file uploding validation
  const publishedRef = useRef(null);

  const validateForm = () => {
    const validationErrors = {};
    const dataToValidate = backDetails || regdata;

    const photo = (photoRef.current);
    console.log(!dataToValidate.Photo && photo.files.length === 0)
    console.log(photo.files.length === 0)

    // if (!dataToValidate.Photo && photo.files.length === 0) {
    //   validationErrors.Photo = 'Photo is required';
    // }
    if (photo.files.length === 0) {
      validationErrors.Photo = 'Photo is required';
    }



    if (!dataToValidate.Name_of_Applicant.trim()) {
      validationErrors.Name_of_Applicant = 'Name of Applicant is required';
    }

    if (!dataToValidate.Academic_Certificates && Academic_Certificates.files.length == 0) {
      validationErrors.Academic_Certificates = 'file is required';
    }
    // if (!dataToValidate.NET_SET_GATE_Score && NET_SET_GATE_Score.files.length == 0) {
    //   validationErrors.NET_SET_GATE_Score = 'file is required';
    // }
    // if (!dataToValidate.Professional_Experience && Professional_Experience.files.length == 0) {
    //   validationErrors.Professional_Experience = 'file is required';
    // }
    if (!dataToValidate.First_Page_Publication_file && First_Page_Publication_file.files.length == 0) {
      validationErrors.First_Page_Publication_file = 'file is required';
    }
    // if (!dataToValidate.Research_plan_file && Research_plan_file.files.length == 0) {
    //   validationErrors.Research_plan_file = 'file is required';
    // }

    for (let i = 0; i < experienceRows.length; i++) {
      if (experienceRows[i].Name_Of_Organation == "" || experienceRows[i].Desigination == "" || experienceRows[i].Start_Date == "" || experienceRows[i].End_Date == "" || experienceRows[i].Duration == "") {
        validationErrors.Name_Of_Organation = 'Name of the organation is required';
        validationErrors.Desigination = 'Desigination is required';
        validationErrors.Start_Date = 'Start Date is required';
        validationErrors.End_Date = 'End Date is required';
      }
    }
    for (let i = 0; i < publicationsRows.length; i++) {
      if (publicationsRows[i].sno == "" || publicationsRows[i].Title_of_Paper == "" || publicationsRows[i].Name_Of_Journal == "" || publicationsRows[i].Issue_No == "" || publicationsRows[i].Publication_year == "") {
        validationErrors.sno = 'Sno is required';
        validationErrors.Title_of_Paper = 'Title of the paper is required';
        validationErrors.Name_Of_Journal = 'Name of the Journal is required';
        validationErrors.Issue_No = 'Vol.no is required';
        validationErrors.Publication_year = 'Year is required';

      }
    }

    if (!dataToValidate.Name_of_Father.trim()) {
      validationErrors.Name_of_Father = 'Name of Father is required';
    }
    if (!dataToValidate.Gender) {
      validationErrors.Gender = 'Gender is required';
    }
    if (!dataToValidate.Category) {
      validationErrors.Category = 'Category is required';
    }

    if (!dataToValidate.Date_Of_Birth) {
      validationErrors.Date_Of_Birth = 'Date Of Birth is required';
    }
    if (!dataToValidate.Nationality) {
      validationErrors.Nationality = 'Nationality is required';
    }

    if (!dataToValidate.Email) {
      validationErrors.Email = 'Email id is required';
    } else if (!validateEmail(dataToValidate.Email)) {
      validationErrors.Email = 'Email id is not valid';
    }
    if (!dataToValidate.ContactNo) {
      validationErrors.ContactNo = 'Contact number is required';
    } else if (!validateContactNo(dataToValidate.ContactNo)) {
      validationErrors.ContactNo = 'Contact number must be exactly 10 digits';
    }
    if (!dataToValidate.Address) {
      validationErrors.Address = 'Address is required';
    }
    if (!dataToValidate.SSC_X_Standard_Place) {
      validationErrors.SSC_X_Standard_Place = 'Scholl name is required';
    }
    if (!dataToValidate.SSC_X_Standard_Board) {
      validationErrors.SSC_X_Standard_Board = 'Board/University name is required';
    }
    if (!dataToValidate.SSC_X_Standard_Year) {
      validationErrors.SSC_X_Standard_Year = 'Year of completion is required';
    }
    if (!dataToValidate.SSC_X_Standard_Percentage) {
      validationErrors.SSC_X_Standard_Percentage = 'Percentage/CGPA is required';
    }

    if (!dataToValidate.HSC_XII_Standard_Place) {
      validationErrors.HSC_XII_Standard_Place = 'college name is required';
    }
    if (!dataToValidate.HSC_XII_Standard_Board) {
      validationErrors.HSC_XII_Standard_Board = 'Board/University name is required';
    }
    if (!dataToValidate.HSC_XII_Standard_Year) {
      validationErrors.HSC_XII_Standard_Year = 'Year of completion is required';
    }
    if (!dataToValidate.HSC_XII_Standard_Percentage) {
      validationErrors.HSC_XII_Standard_Percentage = 'Percentage/CGPA is required';
    }

    if (!dataToValidate.Bachelors_Degree_Place) {
      validationErrors.Bachelors_Degree_Place = 'College name is required';
    }
    if (!dataToValidate.Bachelors_Degree_Board) {
      validationErrors.Bachelors_Degree_Board = 'Board/University name is required';
    }
    if (!dataToValidate.Bachelors_Degree_Year) {
      validationErrors.Bachelors_Degree_Year = 'Year of completion is required';
    }
    if (!dataToValidate.Bachelors_Degree_Percentage) {
      validationErrors.Bachelors_Degree_Percentage = 'Percentage/CGPA is required';
    }
    if (!dataToValidate.Masters_Degree_Place) {
      validationErrors.Masters_Degree_Place = 'College name is required';
    }
    if (!dataToValidate.Masters_Degree_Board) {
      validationErrors.Masters_Degree_Board = 'Board/University name is required';
    }
    if (!dataToValidate.Masters_Degree_Year) {
      validationErrors.Masters_Degree_Year = 'Year of completion is required';
    }
    if (!dataToValidate.Masters_Degree_Percentage) {
      validationErrors.Masters_Degree_Percentage = 'Percentage/CGPA is required';
    }
    if (!dataToValidate.Other_Particulars) {
      validationErrors.Other_Particulars = 'Other Particulars is required';
    }
    // if (!dataToValidate.Research_Plan) {
    //   validationErrors.Research_Plan = 'Title of the proposed research topic is required';
    // }
    if (!dataToValidate.Interested) {
      validationErrors.Interested = 'Interested is required';
    }
    // if (!dataToValidate.Scopus_URL) {
    //   validationErrors.Scopus_URL = 'Scopus URL is required';
    // }
    if (!dataToValidate.Signature) {
      validationErrors.Signature = 'Signature is required';
    }
    if (!dataToValidate.published) {
      validationErrors.published = 'field is required';
    }
    if (!dataToValidate.department) {
      validationErrors.department = 'field is required';
    }


    return validationErrors;
  }
  const validateContactNo = (value) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(value);
  };
  const validateEmail = (value) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(value);
  };


  const handlePreview = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);

      if (validationErrors.Name_of_Applicant) {
        applicantRef.current.focus();
        return;
      } else if (validationErrors.Name_of_Father) {
        fatherRef.current.focus();
        return;
      } else if (validationErrors.Gender) {
        genderRef.current.focus();
        return;
      }
      else if (validationErrors.Category) {
        categoryRef.current.focus();
        return;
      }
      else if (validationErrors.Photo) {
        photoRef.current.focus();
        return;
      }
      else if (validationErrors.CCertificate) {
        certificateRef.current.focus();
        return;
      }
      else if (validationErrors.Academic_Certificates) {
        academic_certificatesRef.current.focus();
        return;
      }
      else if (validationErrors.NET_SET_GATE_Score) {
        score_Ref.current.focus();
        return;
      }
      else if (validationErrors.Professional_Experience) {
        professional_experienceRef.current.focus();
        return;
      }
      else if (validationErrors.First_Page_Publication_file) {
        publication_fileRef.current.focus();
        return;
      }
      else if (validationErrors.Research_plan_file) {
        upload_research_planRef.current.focus();
        return;
      }
      else if (validationErrors.Date_Of_Birth) {
        date_Of_birthRef.current.focus();
        return;
      }
      else if (validationErrors.Nationality) {
        nationalityRef.current.focus();
        return;
      }
      else if (validationErrors.Email) {
        emailRef.current.focus();
        return;
      }
      else if (validationErrors.ContactNo) {
        contactnoRef.current.focus();
        return;
      }
      else if (validationErrors.Address) {
        addressRef.current.focus();
        return;
      }

      else if (validationErrors.SSC_X_Standard_Place) {
        sscplaceRef.current.focus();
        return;
      }
      else if (validationErrors.SSC_X_Standard_Board) {
        sscboardRef.current.focus();
        return;
      }
      else if (validationErrors.SSC_X_Standard_Year) {
        sscyearRef.current.focus();
        return;
      }
      else if (validationErrors.SSC_X_Standard_Percentage) {
        sscpercentageRef.current.focus();
        return;
      }

      else if (validationErrors.HSC_XII_Standard_Place) {
        hscplaceRef.current.focus();
        return;
      }
      else if (validationErrors.HSC_XII_Standard_Board) {
        hscboardRef.current.focus();
        return;
      }
      else if (validationErrors.HSC_XII_Standard_Year) {
        hscyearRef.current.focus();
        return;
      }
      else if (validationErrors.HSC_XII_Standard_Percentage) {
        hscpercentageRef.current.focus();
        return;
      }

      else if (validationErrors.Bachelors_Degree_Place) {
        bachelorsplaceRef.current.focus();
        return;
      }
      else if (validationErrors.Bachelors_Degree_Board) {
        bachelorsboardRef.current.focus();
        return;
      }
      else if (validationErrors.Bachelors_Degree_Year) {
        bachelorsyearRef.current.focus();
        return;
      }
      else if (validationErrors.Bachelors_Degree_Percentage) {
        bachelorspercentageRef.current.focus();
        return;
      }

      else if (validationErrors.Masters_Degree_Place) {
        mastersplaceRef.current.focus();
        return;
      }
      else if (validationErrors.Masters_Degree_Board) {
        mastersboardRef.current.focus();
        return;
      }
      else if (validationErrors.Masters_Degree_Year) {
        mastersyearRef.current.focus();
        return;
      }
      else if (validationErrors.Masters_Degree_Percentage) {
        masterspercentageRef.current.focus();
        return;
      }

      else if (validationErrors.Other_Particulars) {
        other_particularsRef.current.focus();
        return;
      }
      else if (validationErrors.Research_Plan) {
        research_planRef.current.focus();
        return;
      }
      else if (validationErrors.Interested) {
        interestedRef.current.focus();
        return;
      }
      else if (validationErrors.Scopus_URL) {
        scopus_urlRef.current.focus();
        return;
      }
      else if (validationErrors.Signature) {
        signatureRef.current.focus();
        return;
      }
      else if (validationErrors.published) {
        publishedRef.current.focus();
        return;
      }
      else if (validationErrors.Name_Of_Organation) {
        name_of_organationRef.current.focus();
        return;
      }
      else if (validationErrors.Desigination) {
        desiginationRef.current.focus();
        return;
      }
      else if (validationErrors.Start_Date) {
        start_dateRef.current.focus();
        return;
      }
      else if (validationErrors.End_Date) {
        end_dateRef.current.focus();
        return;
      }

      else if (validationErrors.sno) {
        sno_ref.current.focus();
        return;
      }
      else if (validationErrors.Title_of_Paper) {
        title_of_paper_ref.current.focus();
        return;
      }
      else if (validationErrors.Name_Of_Journal) {
        name_of_journal_ref.current.focus();
        return;
      }
      else if (validationErrors.Issue_No) {
        issue_no_ref.current.focus();
        return;
      }
      else if (validationErrors.Publication_year) {
        publication_year_ref.current.focus();
        return;
      }

      else if (validationErrors.department) {
        department_ref.current.focus();
        return;
      }

      return true;
    }


    const formData = new FormData();
    regdata['Photo'] = photo;
    regdata['CCertificate'] = Certificate;
    regdata['Academic_Certificates'] = AAcademic_Certificates;
    regdata['NET_SET_GATE_Score'] = ANET_SET_GATE_Score;
    regdata['Professional_Experience'] = AProfessional_Experience;
    regdata['First_Page_Publication_file'] = AFirst_Page_Publication_file;
    regdata['Objection_certificate_file'] = AObjection_certificate_file;
    regdata['Research_plan_file'] = AResearch_plan_file;

    regdata['Employee_Experience'] = experienceRows;
    regdata['Publication'] = publicationsRows;
    if (backDetails) {
      backDetails.Employee_Experience = experienceRows;
      backDetails.Publication = publicationsRows;

      backDetails['Photo'] = photo;
      backDetails['CCertificate'] = Certificate;
      backDetails['Academic_Certificates'] = AAcademic_Certificates;
      backDetails['NET_SET_GATE_Score'] = ANET_SET_GATE_Score;
      backDetails['Professional_Experience'] = AProfessional_Experience;
      backDetails['First_Page_Publication_file'] = AFirst_Page_Publication_file;
      backDetails['Objection_certificate_file'] = AObjection_certificate_file;
      backDetails['Research_plan_file'] = AResearch_plan_file;
    }

    const dataDetails = {
      Employee_Experience: experienceRows,
      Publication: publicationsRows
    }
    formData.append('dataDetails', JSON.stringify(dataDetails));
    Object.keys(regdata).forEach(key => {
      formData.append(key, regdata[key]);
    });
    // console.log(dataDetails)


    navigate('/Review_admission_form', { state: backDetails ? backDetails : regdata });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(backDetails)
    if (backDetails != undefined) {
      setBackDetails(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else {
      console.log(regdata)
      setRegdata(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const [imageSrc, setImageSrc] = useState(null);

  // useEffect(() => {
  //   const storedImage = localStorage.getItem('uploadedImage');
  //   if (storedImage && photo) {
  //     setImageSrc(storedImage);
  //   }
  // });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setPhoto(file)
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const maxSize = 100 * 1024; // 100 KB in bytes
        if (file.size < maxSize) {
          setImageSrc(reader.result);
          localStorage.setItem('uploadedImage', reader.result);
        }

      };

      reader.readAsDataURL(file);

      // Check file extensions
      const filePath = file.name;
      const fileExtension = filePath.split('.').pop().toLowerCase(); // Convert to lowercase to handle different case extensions
      if (fileExtension !== 'jpeg' && fileExtension !== 'jpg' && fileExtension !== 'png') {
        alert("Only JPEG, JPG, and PNG files are allowed.");
        event.target.value = ''; // Clear the file input field
        return; // Exit the function early if file extension is not allowed
      }
      const maxSize = 100 * 1024; // 100 KB in bytes
      if (file.size > maxSize) {
        alert("File size should not exceed 100 KB.");
        event.target.value = ''; // Clear the file input field
        return; // Exit the function early if file size exceeds the limit
      }

    }
  };

  const CertificateChange = (event) => {
    const file = event.target.files[0];
    setCCertificate(file)
    if (file) {
      const filePath = file.name;
      const fileExtension = filePath.split('.').pop().toLowerCase();
      if (fileExtension !== 'pdf') {
        alert("Only PDF files are allowed.");
        event.target.value = '';
        return;
      }
      const maxSize = 100 * 1024; // 100 KB in bytes
      if (file.size > maxSize) {
        alert("File size should not exceed 100 KB.");
        event.target.value = '';
        return;
      }
    }
  }


  const Academic_Certificateschange = (event) => {
    const file = event.target.files[0];
    setAcademic_Certificates(file)
    if (file) {
      // Check file extensions
      const filePath = file.name;
      const fileExtension = filePath.split('.').pop().toLowerCase(); // Convert to lowercase to handle different case extensions
      if (fileExtension !== 'pdf') {
        alert("Only PDF files are allowed.");
        event.target.value = ''; // Clear the file input field
        return; // Exit the function early if file extension is not allowed
      }
      const maxSize = 400 * 1024; // 400 KB in bytes
      if (file.size > maxSize) {
        alert("File size should not exceed 400 KB.");
        event.target.value = ''; // Clear the file input field
        return; // Exit the function early if file size exceeds the limit
      }
    }
  }

  const NET_SET_GATE_Scorechange = (event) => {
    const file = event.target.files[0];
    setNET_SET_GATE_Score(file)
    if (file) {
      // Check file extensions
      const filePath = file.name;
      const fileExtension = filePath.split('.').pop().toLowerCase(); // Convert to lowercase to handle different case extensions
      if (fileExtension !== 'pdf') {
        alert("Only PDF files are allowed.");
        event.target.value = ''; // Clear the file input field
        return; // Exit the function early if file extension is not allowed
      }
      const maxSize = 100 * 1024; // 150 KB in bytes
      if (file.size > maxSize) {
        alert("File size should not exceed 100 KB.");
        event.target.value = ''; // Clear the file input field
        return; // Exit the function early if file size exceeds the limit
      }
    }
  }
  const Professional_Experiencechange = (event) => {
    const file = event.target.files[0];
    setProfessional_Experience(file)
    if (file) {
      // Check file extensions
      const filePath = file.name;
      const fileExtension = filePath.split('.').pop().toLowerCase(); // Convert to lowercase to handle different case extensions
      if (fileExtension !== 'pdf') {
        alert("Only PDF files are allowed.");
        event.target.value = ''; // Clear the file input field
        return; // Exit the function early if file extension is not allowed
      }
      const maxSize = 200 * 1024; // 150 KB in bytes
      if (file.size > maxSize) {
        alert("File size should not exceed 200 KB.");
        event.target.value = ''; // Clear the file input field
        return; // Exit the function early if file size exceeds the limit
      }
    }
  }
  const First_Page_Publication_filechange = (event) => {
    const file = event.target.files[0];
    setFirst_Page_Publication_file(file)
    if (file) {
      // Check file extensions
      const filePath = file.name;
      const fileExtension = filePath.split('.').pop().toLowerCase(); // Convert to lowercase to handle different case extensions
      if (fileExtension !== 'pdf') {
        alert("Only PDF files are allowed.");
        event.target.value = ''; // Clear the file input field
        return; // Exit the function early if file extension is not allowed
      }
      const maxSize = 300 * 1024; // 150 KB in bytes
      if (file.size > maxSize) {
        alert("File size should not exceed 300 KB.");
        event.target.value = ''; // Clear the file input field
        return; // Exit the function early if file size exceeds the limit
      }
    }
  }

  const Objection_certificate_filechange = (event) => {
    const file = event.target.files[0];
    setObjection_certificate_file(file)
  }
  const Research_plan_filechange = (event) => {
    const file = event.target.files[0];
    setResearch_plan_file(file)
    if (file) {
      // Check file extensions
      const filePath = file.name;
      const fileExtension = filePath.split('.').pop().toLowerCase(); // Convert to lowercase to handle different case extensions
      if (fileExtension !== 'pdf') {
        alert("Only PDF files are allowed.");
        event.target.value = ''; // Clear the file input field
        return; // Exit the function early if file extension is not allowed
      }
      const maxSize = 200 * 1024; // 150 KB in bytes
      if (file.size > maxSize) {
        alert("File size should not exceed 200 KB.");
        event.target.value = ''; // Clear the file input field
        return; // Exit the function early if file size exceeds the limit
      }
    }
  }







  const styles = {
    imageDisplay: {
      width: '150px',
      height: '150px',
      border: '1px solid #198754',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  };
  //-----------------image functionality stop

  // professional experience --------------------------------------------- Start
  //professional experience functionality


  // Function to handle adding a new row
  const handleAddRow = () => {
    setExperienceRows([
      ...experienceRows,
      { Name_Of_Organation: '', Desigination: '', Start_Date: '', End_Date: '', Duration: '' }
    ]);
  };

  // Function to delete removing a row
  const handleDeleteRow = (index) => {
    const newRows = experienceRows.filter((row, i) => i !== index);
    setExperienceRows(newRows);
  };

  // Function to handle changes in input fields
  const handleChange_1 = (index, e) => {
    const { name, value } = e.target;
    const updatedRows = [...experienceRows];

    console.log(experienceRows);

    updatedRows[index][name] = value;
    // Calculate duration if both start date and end date are available
    if (name === 'Start_Date' || name === 'End_Date') {
      const startDate = new Date(updatedRows[index].Start_Date);
      const endDate = new Date(updatedRows[index].End_Date);

      if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
        // Calculate years, months, and days difference
        const startYear = startDate.getFullYear();
        const startMonth = startDate.getMonth();
        const startDay = startDate.getDate();

        const endYear = endDate.getFullYear();
        const endMonth = endDate.getMonth();
        const endDay = endDate.getDate();

        let diffYears = endYear - startYear;
        let diffMonths = endMonth - startMonth;
        let diffDays = endDay - startDay;

        // Adjust for negative days
        if (diffDays < 0) {
          diffMonths--;
          const tempEndDate = new Date(endDate);
          tempEndDate.setMonth(endDate.getMonth() - 1);
          diffDays = Math.floor((endDate - tempEndDate) / (1000 * 60 * 60 * 24));
        }

        // Adjust for negative months
        if (diffMonths < 0) {
          diffYears--;
          diffMonths += 12;
        }

        // Update the duration in the updatedRows array
        updatedRows[index].Duration = `${diffYears} years ${diffMonths} months ${diffDays} days`;
      }
    }
    setExperienceRows(updatedRows);
  };
  //end---------------------------------------------------

  // publications ------------------------------------------------- start
  //publications functionality


  // Function to handle adding a new row
  const handle_Add_Row = () => {
    setPublications([
      ...publicationsRows,
      {
        sno: '',
        Title_of_Paper: '',
        Name_Of_Journal: '',
        Issue_No: '',
        Publication_year: ''
      }
    ]);
  };
  // Function to handle changes in input fields
  const handleInputChange = (index, event) => {
    const values = [...publicationsRows];
    values[index][event.target.name] = event.target.value;
    setPublications(values);
    console.log(publicationsRows);
  };


  // Function to handle removing a row
  const handle_Delete_Row = index => {
    const list = [...publicationsRows];
    list.splice(index, 1);
    setPublications(list);
  };
  // -------------------------------------------------end

  return (
    <>
      <Header parentMenu='page' menuCategoryEnable='enable' />
      <form onSubmit={handlePreview}>

        <div style={{ background: '#f2f4f9', backdropFilter: 'blur(10px)', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }} className="container mt-5 mb-5 ">
          <h2 style={{ textAlign: 'center' }} className="mt-5 mb-4"><b>Application for Admission into Ph.D.</b></h2>

          {/*personal details*/}
          <div className="personal_details mt-5">
            <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>1.Personal Details:</p></strong>
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="name">Name of the Applicant <b style={{ color: 'red' }}>*</b></label>
                <input type="text" className="form-control" ref={applicantRef} id="Name_of_Applicant" name="Name_of_Applicant" defaultValue={backDetails?.Name_of_Applicant || regdata.Name_of_Applicant} onChange={handleChange} />
                {errors.Name_of_Applicant && <span style={{ color: 'red' }}>{errors.Name_of_Applicant}</span>}
                <div className="form-group">
                  <label htmlFor="guardian">Name of the Father/Guardian <b style={{ color: 'red' }}>*</b></label>
                  <input type="text" className="form-control" ref={fatherRef} id="Name_of_Father" name="Name_of_Father" defaultValue={backDetails?.Name_of_Father || regdata.Name_of_Father} onChange={handleChange} />
                  {errors.Name_of_Father && <span style={{ color: 'red' }}>{errors.Name_of_Father}</span>}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="gender">Gender <b style={{ color: 'red' }}>*</b></label><br />
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="male" name="Gender" ref={genderRef} value="male" checked={backDetails?.Gender === "male" || regdata.Gender === "male"}
                      onChange={handleChange} />
                    <label className="form-check-label" htmlFor="male">Male</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="female" name="Gender" ref={genderRef} value="female" checked={backDetails?.Gender === "female" || regdata.Gender === "female"} onChange={handleChange} />
                    <label className="form-check-label" htmlFor="female">Female</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="others" name="Gender" ref={genderRef} value="others" checked={backDetails?.Gender === "others" || regdata.Gender === "others"} onChange={handleChange} required />
                    <label className="form-check-label" htmlFor="others">Others</label>
                  </div>
                </div>
                {errors.Gender && <span style={{ color: 'red' }}>{errors.Gender}</span>}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="guardian">Upload Photograph <b style={{ color: 'red' }}>*</b></label>
                <div className='row d-flex flex-row align-items-between'>
                  <div className='col-md-6 '>
                    <input type="file" accept="image/*" name='Photo' ref={photoRef} defaultValue={backDetails?.Photo || regdata.Photo} onChange={handleImageChange} />
                    {errors.Photo && <span style={{ color: 'red' }}><br /> {errors.Photo}</span>}
                    <br />
                    <b style={{ color: 'red' }}>NOTE :</b> <br />
                    1. Please Upload <b>(PNG or JPG or JPEG) </b>Only.<br />
                    2. File Size Should not Exceed <b>100</b> KB <br />
                    {/* {backDetails ? (
                      <span ><b style={{ color: 'green' }}>Photoname:</b> {state.Photo ? state.Photo.name : "No file"}</span>
                    ) : (
                      
                      <span></span>
                    )} */}
                  </div>
                  <div className='col-md-6' id="image-display" style={styles.imageDisplay} >
                    {imageSrc ? <img src={imageSrc} alt="Uploaded" style={styles.image} /> : 'No file chosen'}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="gender">Category <b style={{ color: 'red' }}>*</b></label><br />
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="sc" name="Category" ref={categoryRef} value="sc" checked={backDetails?.Category === "sc" || regdata.Category === "sc"}
                    onChange={handleChange} />
                  <label className="form-check-label" htmlFor="sc">SC</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="st" name="Category" ref={categoryRef} value="st" checked={backDetails?.Category === "st" || regdata.Category === "st"}
                    onChange={handleChange} />
                  <label className="form-check-label" htmlFor="st">ST</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="bc" name="Category" ref={categoryRef} value="bc" checked={backDetails?.Category === "bc" || regdata.Category === "bc"}
                    onChange={handleChange} />
                  <label className="form-check-label" htmlFor="bc">BC</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="others" name="Category" ref={categoryRef} value="others" checked={backDetails?.Category === "others" || regdata.Category === "others"} onChange={handleChange} />
                  <label className="form-check-label" htmlFor="others">OTHERS</label>
                </div>

                {errors.Category && <span style={{ color: 'red' }}><br /> {errors.Category}</span>}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="certificate">Category Certificate <b style={{ color: 'red' }}></b></label><br></br>
                <input type="file" className="form-control-file" id="CCertificate" name="CCertificate" ref={certificateRef} defaultValue={backDetails?.CCertificate || regdata.CCertificate} onChange={CertificateChange} accept=".pdf" />
                {errors.CCertificate && <span style={{ color: 'red' }}><br /> {errors.CCertificate}</span>}
                {backDetails ? (
                  <span ><b style={{ color: 'green' }}>Filename:</b> {state.CCertificate ? state.CCertificate.name : "No file"}</span>
                ) : (
                  // Handle other cases if needed
                  <span></span>
                )}

                <br />

                <b style={{ color: 'red' }}>NOTE :</b> <br />
                1. Please Upload <b>(PDF File) </b>Only.<br />
                2. File Size Should not Exceed <b>100</b> KB
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="dob">Date of Birth <b style={{ color: 'red' }}>*</b></label>
                <input type="date" className="form-control" id="Date_Of_Birth" name="Date_Of_Birth" ref={date_Of_birthRef} defaultValue={backDetails?.Date_Of_Birth || regdata.Date_Of_Birth} onChange={handleChange} />
                {errors.Date_Of_Birth && <span style={{ color: 'red' }}>{errors.Date_Of_Birth}</span>}

              </div>
              <div className="form-group col-md-6">
                <label htmlFor="nationality">Nationality <b style={{ color: 'red' }}>*</b></label>
                <input type="text" className="form-control" id="Nationality" name="Nationality" ref={nationalityRef} defaultValue={backDetails?.Nationality || regdata.Nationality} onChange={handleChange} />
                {errors.Nationality && <span style={{ color: 'red' }}>{errors.Nationality}</span>}
              </div>
            </div>
            <div className='row'>
              <div className="form-group col-md-6">
                <label htmlFor="email">Email <b style={{ color: 'red' }}>*</b></label>
                <input type="email" className="form-control" id="Email" name="Email" ref={emailRef} defaultValue={backDetails?.Email || regdata.Email} onChange={handleChange} />
                {errors.Email && <span style={{ color: 'red' }}>{errors.Email}</span>}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="contactNumber">Contact No <b style={{ color: 'red' }}>*</b></label>
                <input type="tel" className="form-control" id="ContactNo" name="ContactNo" ref={contactnoRef} defaultValue={backDetails?.ContactNo || regdata.ContactNo} onChange={handleChange} />
                {errors.ContactNo && <span style={{ color: 'red' }}>{errors.ContactNo}</span>}
              </div>
              <div className='row'>
                <div className="form-group">
                  <label htmlFor="address">Address <b style={{ color: 'red' }}>*</b></label>
                  <textarea className="form-control" id="Address" name="Address" ref={addressRef} rows="3" defaultValue={backDetails?.Address || regdata.Address} onChange={handleChange} />
                  {errors.Address && <span style={{ color: 'red' }}>{errors.Address}</span>}

                </div>
              </div>
            </div>
          </div><br></br>


          {/*academic details*/}
          <div className="academic_details mt-5">
            <div className='row'>
              <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>2. Academic Details  </p></strong>
            </div>
            <div class="container mt-4">
              <div className="row">
                <div className="col-md-2">
                  <label>SSC/ X Standard <b style={{ color: 'red' }}>*</b> </label>
                </div>
                <div className="col-md-3">
                  <input type="text" className="form-control" name="SSC_X_Standard_Place" ref={sscplaceRef} placeholder='School/College and Place' defaultValue={backDetails?.SSC_X_Standard_Place || regdata.SSC_X_Standard_Place} onChange={handleChange} />
                  {errors.SSC_X_Standard_Place && <span style={{ color: 'red' }}>{errors.SSC_X_Standard_Place}</span>}
                </div>
                <div className="col-md-2">
                  <input type="text" className="form-control" name="SSC_X_Standard_Board" ref={sscboardRef} placeholder='Board/University' defaultValue={backDetails?.SSC_X_Standard_Board || regdata.SSC_X_Standard_Board} onChange={handleChange} />
                  {errors.SSC_X_Standard_Board && <span style={{ color: 'red' }}>{errors.SSC_X_Standard_Board}</span>}

                </div>
                <div className="col-md-2">
                  <input type="text" className="form-control" name="SSC_X_Standard_Year" ref={sscyearRef} placeholder='Year of completion' defaultValue={backDetails?.SSC_X_Standard_Year || regdata.SSC_X_Standard_Year} onChange={handleChange} />
                  {errors.SSC_X_Standard_Year && <span style={{ color: 'red' }}>{errors.SSC_X_Standard_Year}</span>}

                </div>
                <div className="col-md-3">
                  <input type="text" className="form-control" name="SSC_X_Standard_Percentage" ref={sscpercentageRef} placeholder='Percentage/CGPA' defaultValue={backDetails?.SSC_X_Standard_Percentage || regdata.SSC_X_Standard_Percentage} onChange={handleChange} />
                  {errors.SSC_X_Standard_Percentage && <span style={{ color: 'red' }}>{errors.SSC_X_Standard_Percentage}</span>}

                </div>
              </div><br />
              <div className="row">
                <div className="col-md-2">
                  <label>HSC / XII Standard <b style={{ color: 'red' }}>*</b> </label>
                </div>
                <div className="col-md-3">
                  <input type="text" className="form-control" name="HSC_XII_Standard_Place" ref={hscplaceRef} placeholder='School/College and Place' defaultValue={backDetails?.HSC_XII_Standard_Place || regdata.HSC_XII_Standard_Place} onChange={handleChange} />
                  {errors.HSC_XII_Standard_Place && <span style={{ color: 'red' }}>{errors.HSC_XII_Standard_Place}</span>}

                </div>
                <div className="col-md-2">
                  <input type="text" className="form-control" name="HSC_XII_Standard_Board" ref={hscboardRef} placeholder='Board/University' defaultValue={backDetails?.HSC_XII_Standard_Board || regdata.HSC_XII_Standard_Board} onChange={handleChange} />
                  {errors.HSC_XII_Standard_Board && <span style={{ color: 'red' }}>{errors.HSC_XII_Standard_Board}</span>}

                </div>
                <div className="col-md-2">
                  <input type="text" className="form-control" name="HSC_XII_Standard_Year" ref={hscyearRef} placeholder='Year of completion' defaultValue={backDetails?.HSC_XII_Standard_Year || regdata.HSC_XII_Standard_Year} onChange={handleChange} />
                  {errors.HSC_XII_Standard_Year && <span style={{ color: 'red' }}>{errors.HSC_XII_Standard_Year}</span>}

                </div>
                <div className="col-md-3">
                  <input type="text" className="form-control" name="HSC_XII_Standard_Percentage" ref={hscpercentageRef} placeholder='Percentage/CGPA' defaultValue={backDetails?.HSC_XII_Standard_Percentage || regdata.HSC_XII_Standard_Percentage} onChange={handleChange} />
                  {errors.HSC_XII_Standard_Percentage && <span style={{ color: 'red' }}>{errors.HSC_XII_Standard_Percentage}</span>}

                </div>
              </div><br />
              <div className="row">
                <div className="col-md-2">
                  <label>Bachelors' Degree <b style={{ color: 'red' }}>*</b></label>
                </div>
                <div className="col-md-3">
                  <input type="text" className="form-control" name="Bachelors_Degree_Place" ref={bachelorsplaceRef} placeholder='School/College and Place' defaultValue={backDetails?.Bachelors_Degree_Place || regdata.Bachelors_Degree_Place} onChange={handleChange} />
                  {errors.Bachelors_Degree_Place && <span style={{ color: 'red' }}>{errors.Bachelors_Degree_Place}</span>}

                </div>
                <div className="col-md-2">
                  <input type="text" className="form-control" name="Bachelors_Degree_Board" ref={bachelorsboardRef} placeholder='Board/University' defaultValue={backDetails?.Bachelors_Degree_Board || regdata.Bachelors_Degree_Board} onChange={handleChange} />
                  {errors.Bachelors_Degree_Board && <span style={{ color: 'red' }}>{errors.Bachelors_Degree_Board}</span>}

                </div>
                <div className="col-md-2">
                  <input type="text" className="form-control" name="Bachelors_Degree_Year" ref={bachelorsyearRef} placeholder='Year of completion' defaultValue={backDetails?.Bachelors_Degree_Year || regdata.Bachelors_Degree_Year} onChange={handleChange} />
                  {errors.Bachelors_Degree_Year && <span style={{ color: 'red' }}>{errors.Bachelors_Degree_Year}</span>}

                </div>
                <div className="col-md-3">
                  <input type="text" className="form-control" name="Bachelors_Degree_Percentage" ref={bachelorspercentageRef} placeholder='Percentage/CGPA' defaultValue={backDetails?.Bachelors_Degree_Percentage || regdata.Bachelors_Degree_Percentage} onChange={handleChange} />
                  {errors.Bachelors_Degree_Percentage && <span style={{ color: 'red' }}>{errors.Bachelors_Degree_Percentage}</span>}

                </div>
              </div><br />
              <div className="row">
                <div className="col-md-2">
                  <label>Masters' Degree <b style={{ color: 'red' }}>*</b> </label>
                </div>
                <div className="col-md-3">
                  <input type="text" className="form-control" name="Masters_Degree_Place" ref={mastersplaceRef} placeholder='School/College and Place' defaultValue={backDetails?.Masters_Degree_Place || regdata.Masters_Degree_Place} onChange={handleChange} />
                  {errors.Masters_Degree_Place && <span style={{ color: 'red' }}>{errors.Masters_Degree_Place}</span>}

                </div>
                <div className="col-md-2">
                  <input type="text" className="form-control" name="Masters_Degree_Board" ref={mastersboardRef} placeholder='Board/University' defaultValue={backDetails?.Masters_Degree_Board || regdata.Masters_Degree_Board} onChange={handleChange} />
                  {errors.Masters_Degree_Board && <span style={{ color: 'red' }}>{errors.Masters_Degree_Board}</span>}

                </div>
                <div className="col-md-2">
                  <input type="text" className="form-control" name="Masters_Degree_Year" ref={mastersyearRef} placeholder='Year of completion' defaultValue={backDetails?.Masters_Degree_Year || regdata.Masters_Degree_Year} onChange={handleChange} />
                  {errors.Masters_Degree_Year && <span style={{ color: 'red' }}>{errors.Masters_Degree_Year}</span>}

                </div>
                <div className="col-md-3">
                  <input type="text" className="form-control" name="Masters_Degree_Percentage" ref={masterspercentageRef} placeholder='Percentage/CGPA' defaultValue={backDetails?.Masters_Degree_Percentage || regdata.Masters_Degree_Percentage} onChange={handleChange} />
                  {errors.Masters_Degree_Percentage && <span style={{ color: 'red' }}>{errors.Masters_Degree_Percentage}</span>}

                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-6 d-flex flex-direction-row'>
                  <p>Merge all certificates: SSC, Intermediate, UG and PG into single PDF</p>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="photo">Upload Academic Certificates <b style={{ color: 'red' }}>*</b></label><br></br>
                  <input type="file" className="form-control-file" id="Academic_Certificates" name="Academic_Certificates" ref={academic_certificatesRef} accept=".pdf" defaultValue={backDetails?.Academic_Certificates || regdata.Academic_Certificates} onChange={Academic_Certificateschange} />
                  {errors.Academic_Certificates && <span style={{ color: 'red' }}><br /> {errors.Academic_Certificates}</span>}


                  {backDetails ? (
                    <span ><b style={{ color: 'green' }}>Filename:</b> {state.Academic_Certificates ? state.Academic_Certificates.name : "No file"}</span>
                  ) : (
                    // Handle other cases if needed
                    <span></span>
                  )}
                  <br />
                  <b style={{ color: 'red' }}>NOTE :</b> <br />
                  1. Please Upload <b>(PDF File) </b>Only.<br />
                  2. File Size Should not Exceed <b>400</b> KB
                </div>
              </div>
            </div>
          </div>


          {/*net set gate score*/}
          <div className='net_set_gate_score mt-5'>
            <div className='row'>
              <div className="form-group col-md-6">
                <div className='col-md-6'>
                  <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>3.NET/SET/GATE Score  </p></strong>
                </div>

              </div>
              <div className="form-group col-md-6">
                <div className='col-md-3'>
                  {/* <label htmlFor="net_score">NET Score</label> */}
                  <input type="number" className="form-control" id="net_score" name="net_score" defaultValue={backDetails?.net_score || regdata.net_score} onChange={handleChange} />
                </div>
                <label htmlFor="photo">Upload NET/SET/GATE Score Card </label><br></br>
                <input type="file" className="form-control-file" id="NET_SET_GATE_Score" name="NET_SET_GATE_Score" ref={score_Ref} accept=".pdf" defaultValue={backDetails?.NET_SET_GATE_Score || regdata.NET_SET_GATE_Score} onChange={NET_SET_GATE_Scorechange} />
                {errors.NET_SET_GATE_Score && <span style={{ color: 'red' }}><br /> {errors.NET_SET_GATE_Score}</span>}
                {backDetails ? (
                  <span ><b style={{ color: 'green' }}>Filename:</b> {state.NET_SET_GATE_Score ? state.NET_SET_GATE_Score.name : "No file"}</span>
                ) : (
                  // Handle other cases if needed
                  <span></span>
                )}
                <br />
                <b style={{ color: 'red' }}>NOTE :</b> <br />
                1. Please Upload <b>(PDF File) </b>Only.<br />
                2. File Size Should not Exceed <b>100</b> KB
              </div>
            </div>
          </div>


          {/*Professional Experience*/}
          <div className='professional_experience mt-5'>
            <div className='row'>
              <div className='col-md-6'>
                <strong>
                  <p style={{ fontSize: '20px', color: '#d3143f' }}>4.Professional Experience </p>
                </strong>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="certificates">Upload Professional Experience Certificates </label><br />
                <input type="file" className="form-control-file" id="Professional_Experience" name="Professional_Experience" ref={professional_experienceRef} accept=".pdf" defaultValue={backDetails?.Professional_Experience || regdata.Professional_Experience} onChange={Professional_Experiencechange} />
                {errors.Professional_Experience && <span style={{ color: 'red' }}><br /> {errors.Professional_Experience}</span>}
                {backDetails ? (
                  <span ><b style={{ color: 'green' }}>Filename:</b> {state.Professional_Experience ? state.Professional_Experience.name : "No file"}</span>
                ) : (
                  // Handle other cases if needed
                  <span></span>
                )}
                <br />
                <b style={{ color: 'red' }}>NOTE :</b> <br />
                1. Please Upload <b>(PDF File) </b>Only.<br />
                2. File Size Should not Exceed <b>200</b> KB

              </div>
            </div>
            <div className="container mt-4" id="experience-container">

              {experienceRows.map((regdata, index) => (
                <div className="row experience-row mb-2" key={index}>
                  <div className="col-md-3">
                    <input type="text" className="form-control" name="Name_Of_Organation" ref={name_of_organationRef} defaultValue={backDetails?.Name_Of_Organation || regdata.Name_Of_Organation} onChange={e => handleChange_1(index, e)} placeholder="Name of the Institution/Organization" />
                    {errors.Name_Of_Organation && <span style={{ color: 'red' }}>{errors.Name_Of_Organation}</span>}
                  </div>
                  <div className="col-md-3">
                    <input type="text" className="form-control" name="Desigination" ref={desiginationRef} defaultValue={backDetails?.Desigination || regdata.Desigination} onChange={e => handleChange_1(index, e)}
                      placeholder="Designation" />
                    {errors.Desigination && <span style={{ color: 'red' }}>{errors.Desigination}</span>}
                  </div>
                  <div className="col-md-2">
                    <input type="date" className="form-control from_date" name="Start_Date" ref={start_dateRef} defaultValue={backDetails?.Start_Date || regdata.Start_Date} onChange={e => handleChange_1(index, e)} />
                    {errors.Start_Date && <span style={{ color: 'red' }}>{errors.Start_Date}</span>}

                  </div>
                  <div className="col-md-2">
                    <input type="date" className="form-control to_date" name="End_Date" ref={end_dateRef} defaultValue={backDetails?.End_Date || regdata.End_Date} onChange={e => handleChange_1(index, e)} />
                    {errors.End_Date && <span style={{ color: 'red' }}>{errors.End_Date}</span>}

                  </div>

                  <div className="col-md-1">
                    <input type="text" className="form-control duration" name="Duration" defaultValue={backDetails?.Duration || regdata.Duration} placeholder="Duration"
                      readOnly style={{ backgroundColor: 'rgb(17 18 18 / 10%)' }} />
                  </div>

                  <div className="col-md-1 d-flex align-items-center">
                    <button type="button" className="btn btn-danger" onClick={() => handleDeleteRow(index)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>
            <button type="button" className="btn btn-primary mt-3" onClick={handleAddRow}>Add</button>
          </div>


          {/*Publications*/}
          <div className='Publications mt-5'>
            <div className='row'>
              <div className='col-md-6'>
                <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>5. Publications  </p></strong>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="photo">Upload First Page of Publication <b style={{ color: 'red' }}>*</b></label><br></br>
                <input type="file" className="form-control-file" id="First_Page_Publication_file" name="First_Page_Publication_file" ref={publication_fileRef} accept=".pdf" defaultValue={backDetails?.First_Page_Publication_file || regdata.First_Page_Publication_file} onChange={First_Page_Publication_filechange} />
                {errors.First_Page_Publication_file && <span style={{ color: 'red' }}><br /> {errors.First_Page_Publication_file}</span>}

                {backDetails ? (
                  <span ><b style={{ color: 'green' }}>Filename:</b> {state.First_Page_Publication_file ? state.First_Page_Publication_file.name : "No file"}</span>
                ) : (
                  // Handle other cases if needed
                  <span></span>
                )}
                <br />
                <b style={{ color: 'red' }}>NOTE :</b> <br />
                1. Please Upload <b>(PDF File) </b>Only.<br />
                2. File Size Should not Exceed <b>300</b> KB
              </div>
            </div>
            <br></br>
            <div className='row'>
              <div className="form-group col-md-12 d-flex flex-row">
                <label>Whether the applicant has published any Journal papers / Books / Book Chapters<b style={{ color: 'red' }}>*</b> : &nbsp;&nbsp; </label><br />
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="yes" name="published" ref={publishedRef} value="yes" checked={backDetails?.published === "yes" || regdata?.published === "yes"}
                    onChange={handleChange} />
                  <label className="form-check-label" htmlFor="subject1"> YES</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="no" name="published" ref={publishedRef} value="no" checked={backDetails?.published === "no" || regdata?.published === "no"}
                    onChange={handleChange} />
                  <label className="form-check-label" htmlFor="subject2"> NO</label>
                </div>
                {errors.published && <span style={{ color: 'red' }}>{errors.published}</span>}

              </div>
              <p>Enlist only Scopus indexed / SCIE / UGC<b style={{ color: 'red' }}>*</b></p>
            </div>
            <div className="container mt-4" id="publications-container">
              {publicationsRows.map((regdata, index) => (
                <div className="row publication-row mb-2" key={index}>
                  <div className="col-md-1">
                    <input type="number" className="form-control" name="sno" ref={sno_ref} defaultValue={backDetails?.sno || regdata.sno} onChange={e => handleInputChange(index, e)} placeholder='S. No.' />
                    {errors.sno && <span style={{ color: 'red' }}>{errors.sno}</span>}
                  </div>
                  <div className="col-md-3">
                    <input type="text" className="form-control" name="Title_of_Paper" ref={title_of_paper_ref} defaultValue={backDetails?.Title_of_Paper || regdata.Title_of_Paper} onChange={e => handleInputChange(index, e)} placeholder='Title of the paper' />
                    {errors.Title_of_Paper && <span style={{ color: 'red' }}>{errors.Title_of_Paper}</span>}

                  </div>
                  <div className="col-md-3">
                    <input type="text" className="form-control" name="Name_Of_Journal" ref={name_of_journal_ref} defaultValue={backDetails?.Name_Of_Journal || regdata.Name_Of_Journal} onChange={e => handleInputChange(index, e)} placeholder='Name of the Journal' />
                    {errors.Name_Of_Journal && <span style={{ color: 'red' }}>{errors.Name_Of_Journal}</span>}

                  </div>
                  <div className="col-md-2">
                    <input type="number" className="form-control" name="Issue_No" ref={issue_no_ref} defaultValue={backDetails?.Issue_No || regdata.Issue_No} onChange={e => handleInputChange(index, e)} placeholder='Vol. No. &' />
                    {errors.Issue_No && <span style={{ color: 'red' }}>{errors.Issue_No}</span>}

                  </div>
                  <div className="col-md-2">
                    <input type="text" className="form-control" name="Publication_year" ref={publication_year_ref} defaultValue={backDetails?.Publication_year || regdata.Publication_year} onChange={e => handleInputChange(index, e)} placeholder='Year' />
                    {errors.Publication_year && <span style={{ color: 'red' }}>{errors.Publication_year}</span>}

                  </div>
                  <div className="col-md-1 d-flex align-items-center">
                    <button type="button" className="btn btn-danger" onClick={() => handle_Delete_Row(index)}>Delete</button>
                  </div>
                </div>
              ))}
              <button type="button" className="btn btn-primary mt-3" onClick={handle_Add_Row}>Add</button>
            </div>
            <br />
            <div className='row'>
              <div className="form-group col-md-12">
                <label htmlFor="address">Any other particulars the applicant would like to present:<b style={{ color: 'red' }}>*</b></label>
                <textarea className="form-control" id="Other_Particulars" ref={other_particularsRef} name="Other_Particulars" defaultValue={backDetails?.Other_Particulars || regdata.Other_Particulars} onChange={handleChange} />
                {errors.Other_Particulars && <span style={{ color: 'red' }}>{errors.Other_Particulars}</span>}

              </div>
            </div>
          </div>

          {/*Upload Research Plan*/}
          <div className='Upload_Research_Plan mt-5'>
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="name"><strong><p style={{ fontSize: '20px', color: '#d3143f' }}>6.Upload Research Plan </p></strong></label>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="photo">Upload Research Plan  </label><br></br>
                <input type="file" className="form-control-file" id="Research_plan_file" name="Research_plan_file" ref={upload_research_planRef} defaultValue={backDetails?.Research_plan_file || regdata.Research_plan_file} onChange={Research_plan_filechange} accept=".pdf" />
                {errors.Research_plan_file && <span style={{ color: 'red' }}><br /> {errors.Research_plan_file}</span>}

                {backDetails ? (
                  <span ><b style={{ color: 'green' }}>Filename:</b> {state.Research_plan_file ? state.Research_plan_file.name : "No file"}</span>
                ) : (
                  // Handle other cases if needed
                  <span></span>
                )}

                <br />
                <b style={{ color: 'red' }}>NOTE :</b> <br />
                1. Please Upload <b>(PDF File) </b>Only.<br />
                2. File Size Should not Exceed <b>200</b> KB
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="research">Broad theme or Title of the proposed research topic </label>
                <input type="text" className="form-control" id="Research_Plan" name="Research_Plan" ref={research_planRef} defaultValue={backDetails?.Research_Plan || regdata.Research_Plan} onChange={handleChange} />
                {errors.Research_Plan && <span style={{ color: 'red' }}>{errors.Research_Plan}</span>}
                <p>Abstract, objectives, Literature Survey, Scope and Timelines, expected Conclusions</p>

              </div>
              <div className="form-group col-md-6">
                <label htmlFor="research">Interested in Full Time / Part Time <b style={{ color: 'red' }}>*</b> </label>
                <select className="form-control" id="Interested" name="Interested" ref={interestedRef} value={backDetails?.Interested || regdata.Interested} onChange={handleChange} required>
                  <option value="">Select an option</option>
                  <option value="Full Time">Full Time</option>
                  <option value="Part Time">Part Time</option>
                </select>
                {errors.Interested && <span style={{ color: 'red' }}>{errors.Interested}</span>}

              </div>
              <div className="form-group col-md-6">
                <label htmlFor="research">Discipline Applying for <b style={{ color: 'red' }}>*</b> </label>
                <select className="form-control" id="department" name="department" ref={department_ref} value={backDetails?.department || regdata.department} onChange={handleChange} required>
                  <option value="">Select an option</option>
                  <option value="EEE">EEE</option>
                  <option value="ECE">ECE</option>
                  <option value="CSE">CSE</option>
                  <option value="MECHANICAL">MECHANICAL</option>
                  <option value="CIVIL">CIVIL</option>
                  <option value="AGRICULTURE">AGRICULTURE</option>
                  <option value="PETROLEUM">PETROLEUM</option>
                  <option value="MINING">MINING</option>
                  <option value="MANAGEMENT">MANAGEMENT</option>
                  <option value="COMPUTER APPLICATIONS">COMPUTER APPLICATIONS</option>
                  <option value="MATHEMATICS ">MATHEMATICS </option>
                  <option value="PHYSISCS">PHYSISCS</option>
                  <option value="CHEMISTRY">CHEMISTRY</option>
                  <option value="ENGLISH">ENGLISH</option>
                </select>
                {errors.department && <span style={{ color: 'red' }}>{errors.department}</span>}

              </div>
            </div>
          </div>

          {/*Scopusurl*/}
          <div className='Scopus_url mt-5'>
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="name"><strong><p style={{ fontSize: '20px', color: '#d3143f' }}>7.Scopus URL  </p></strong></label>
                <input type="text" className="form-control" id="Scopus_URL" name="Scopus_URL" ref={scopus_urlRef} value={backDetails?.Scopus_URL || regdata.Scopus_URL} onChange={handleChange} />
                {errors.Scopus_URL && <span style={{ color: 'red' }}>{errors.Scopus_URL}</span>}

              </div>
              <div className='col-md-6'>
                <label htmlFor="name"><strong><p style={{ fontSize: '20px', color: '#d3143f' }}>8.Declaration <b style={{ color: 'red' }}>*</b> </p></strong></label>
                <input type="text" className="form-control" id="Signature" name="Signature" ref={signatureRef} placeholder='Type Full Name in place of Signature' value={backDetails?.Signature || regdata.Signature} onChange={handleChange} />
                {errors.Signature && <span style={{ color: 'red' }}>{errors.Signature}</span>}
                <p>
                  <b>1.</b> I hereby declare that the information provided by me is true and correct to the best of my knowledge and belief.<br />
                  <b>2.</b>. I hereby declare that I abide by the rules and regulations of the Aditya University regarding <br />Ph.D. admissions
                </p>


              </div>

            </div>
          </div>

          {/*submit button*/}
          <div className=''><center><span type='submit' onClick={handlePreview} className='btn btn-danger'> Preview</span></center></div>
        </div>

        <FooterTwo />
      </form>
    </>
  );
}

export default Admission;
