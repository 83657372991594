import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../components/Breadcrumb/StudyBreadcrumbs';
import ScrollToTop from '../../components/ScrollTop';
import HostelFacilitiesMain from './HostelFacilitiesMain';

// import Logo from '../../assets/images/logos/logo2.png';

const  HostelFacilities = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
                // headerNormalLogo={Logo}
                // headerStickyLogo={Logo}
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    
                    <StudyBreadcrumb pageTitle="Hostel Facilities" />

                    <HostelFacilitiesMain />

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default  HostelFacilities;