import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import water_repellent3 from './images/water_repellent3.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">

                    <StudyBreadcrumb pageTitle="Automobile Engineering" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}>Brightness Enhancing and Super Water-Repellent Composition for Transparent Films</b> <br />
                                                    <b className='name'>Theme:</b>
                                                    <p className='name'>
                                                    An innovative work on polymerizable compositions, and in specific relates to a composition for transparent films that provides enhanced brightness and extreme hydrophobic properties.
                                                    </p>
                                                    <b className='name'>Background:</b>
                                                    <p className='name'>
                                                    Headlights or headlamps are one of the most essential features of an automotive vehicle. They play a very significant role in ensuring safety and drivability. Ever since the advent of the automobile, automotive manufacturers are striving to improve roadway illumination by various vehicle headlight configurations to improve the drivability and safety aspects of operating the vehicle in dark or day light or otherwise low visibility conditions such as night-time driving conditions. Driving during low visibility conditions leads to improper vision to the drivers and causes accidents. Numerous automotive vehicle headlight configurations and systems are proposed to automatically control the state of a vehicle's headlights. For example, many vehicles today include daytime running lights where the vehicle's headlights are illuminated continuously during daylight to make the vehicle more noticeable to other vehicles. Many vehicles also include headlights that automatically become active when low light level conditions are detected.
                                                    </p>

                                                    <p className='name'>
                                                    Automotive headlamps, constructed using different components are likely affected by haziness, dirtiness, fogginess, cloudiness, or white patches caused by oxidation, chemical attack, water vapors, flying debris, and dirt. Yellowness is mostly caused by the liberation of lower-temperature volatile organic compounds due to thermal decomposition and oxidation of lower molecular weight additives present in the polymers. The liberation of lower temperature volatile organic components and their deposition onto the inside layer of the outer lens result in the yellowness issue in the headlamp. This yellowness issue makes the headlamps dull and decreases the brightness intensity.
                                                    </p>

                                                    <b className='name'>Our Invention</b>
                                                    <p className='name'>
                                                    The present disclosure proposes a composition for transparent films that provides enhanced brightness and extreme hydrophobic properties. The composition comprises single crystal phosphorus a first monomer a second monomer a cross-linking agent a photo initiator and a copolymer solution. The composite solution is sprayed on the transparent film to achieve enhanced brightness and extreme hydrophobicity on the transparent film. The transparent film coated with the composite solution is utilized for automobile headlamps or any other light-emitting apparatus outer lens and thereof to enhance brightness and extreme hydrophobicity for the outer lens.
                                                    </p>
                                                    
                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>

                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Optical Films and Displays </span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Automotive Industry </span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Solar Panels and Photovoltaics</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Architectural Glass and Windows</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Consumer Electronics </span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Aviation and Aerospace</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Packaging and Protective Films</span><br />
                                                    </p>
                                                    <b className='name'>IP Status </b>
                                                    <p className='name'>
                                                    The invention is the subject of a Granted Patent No.429811 IP India
                                                    </p>
                                                    <p className='name'>
                                                    Application No. 202241052363
                                                    </p>
                                                    <b className='name'>Inventors </b>
                                                    <p className='name'>
                                                    M S R Sekhar
                                                    </p>
                                                    <center>
                                                        <img src={water_repellent3} /><br></br>
                                                        <b className='name'>Brightness Enhancing and Super Water-Repellent Composition  </b>
                                                    </center>
                                                    <br />

                                                    <b className='name'>Contact us</b>
                                                    <p className='name'>
                                                    If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at :<b>dean_rc@adityauniversity.in</b> </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;