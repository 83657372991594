import React, { useState } from 'react';
import axios from 'axios';
const backendUrl = "https://adityauniversity.in:4001/api/"
const Apply = () => {
    const [showModal, setShowModal] = useState(false);

    const [userdata, setUserdata] = useState({
        name: '',
        email: '',
        state: '',
        country: '',
        phone: '',
        branch: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(process.env.REACT_APP_DATABASEURL);
        try {
            const res = await axios.post(backendUrl+"add_Enquiry_user", userdata);
            if (res.status === 201) {
                setUserdata({
                    name: "",
                    email: "",
                    state: "",
                    country: "",
                    phone:"",
                    branch:"",
                });
                alert("Form submitted successfully!");
                handleClose(); // Close modal after submission
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    const handleApplyClick = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserdata(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="applybuttion_internation_22" style={{ display: 'block' }}>
                        <button type="button" className="applybuttion_internation_2 btn btn-primary" onClick={handleApplyClick}>
                            Enquire Now!
                        </button>
                    </div>
                </div>
            </div>

            {showModal && (
                <div className="modal fade show" style={{ display: 'block', zIndex: '11111' }}>
                    <div className="modal-dialog modal-dialog-centered modal-lg"> 
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"><center><b>Enquiry Form</b></center></h5>
                                <button type="button" className="btn-close" onClick={handleClose}></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Your Name</label>
                                        <input type="text" className="form-control" name="name" id="name" value={userdata.name} onChange={handleChange}  required/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="text" className="form-control" name="email" id="email" value={userdata.email} onChange={handleChange} required/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="state" className="form-label">State</label>
                                        <input type="text" className="form-control" name="state" id="state" value={userdata.state} onChange={handleChange} required/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="country" className="form-label">Your Country</label>
                                        <input type="text" className="form-control" name="country" id="country" value={userdata.country} onChange={handleChange} required/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label">Phone</label>
                                        <input type="text" className="form-control" name="phone" id="phone" value={userdata.phone} onChange={handleChange} required/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="branch" className="form-label">Branch</label>
                                        <input type="text" className="form-control" name="branch" id="branch" value={userdata.branch} onChange={handleChange} required/>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-primary">Get It Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Apply;
