import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
    return (
        <div className="col-md-3 col-sm-3 col-0">
            <div className="side-navbar">
                <nav className="navbar">
                    <ul className="navbar-nav">
                        <li className="navbar-title text-center">
                            <a className="navbar-brand text-light1" style={{ padding: '12px 129px' }} href="#">Explore </a>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/collaborations' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/collaborations">Institute Collaborations</Link>
                        </li>
                        <li className={`nav-item dropdown ${window.location.pathname.startsWith('/industry') ? 'active' : ''}`}>
                            <Link className="nav-link" to="/industry">Industry Collaborations</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Sidebar;
