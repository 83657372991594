import React from 'react';
import { useState } from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_ds';
import Banner from './banner';
// import CePopUp from "../../../../../components/Popup/DS/DSPopUp";






const CEview = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    {/* <CePopUp />  */}
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  B.Tech </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>The Department offers B.Tech CSE(Data Science) program with a current intake of 180 students. This program aims at building engineering graduates to be skilled Data Scientists. Leveraging the continuously expanding landscape of business data, the Department ensures that students develop a solid foundation in theory and gain practical, project-based experience with various data science tools and technologies. Additionally, the Department is deeply committed to providing comprehensive knowledge in mathematics, business systems, and engineering sciences. In today's data-driven technological world, the Department ensures students are well-versed in theoretical principles, hands-on project work, and domain expertise across mathematics, business systems, and engineering sciences.</p>

                                                        <p className='name'>The four-year undergraduate curriculum includes a detailed delivery of Major (Core), Multidisciplinary, Ability Enhancement Courses, Skill Enhancement Courses, Value Added Courses and Audit/Mandatory Courses. In addition to these courses, students are offered minor degrees in 21 different streams. Overall, the B.Tech CSE(Data Science)program provides a comprehensive and practical approach to Data Science education, with a focus on data analytics, machine learning, data visualization, and data mining, a cutting-edge curriculum, industry partnerships, hands-on learning, research opportunities, and internship opportunities.</p>
                                                        <p className='name'>

                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Computer Science and Engineering (Data Science) <br />
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Computer Science and Engineering (Data Science) with minor degree in
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            
                                                            
                                                            
                                                            {/* <span className='name'> <img src={point1} style={{ height: "25px" }} /> Data Engineering <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Business Analytics <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Infrastructure Technology <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Technology for Digital Resilience <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Smart ICT Infrastructure (Electronics) <b style={{ color: "#328afc", fontSize: "17px" }}>  (Industry Integrated Program - L&T) </b></span><br />
                                                            
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Networks & Security</span><br />
                                                           
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Automation & Robotics</span><br />
                                                            
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Smart ICT Infrastructure (Electrical)</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Social Science</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Bio Medical Engineering</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Legal Studies</span><br /></p> */}

                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Cognitive Analytics</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Mobile and Web Application Development</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Cyber Security <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Agile Methodology with DevOps Programming <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Cloud Infrastructure <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Partners - CISCO, Red hat, Oracle, AWS, Google & Microsoft) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Rapid Application Development <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Partners - PEGA, Oracle, Microsoft & Salesforce) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Entrepreneurship Development & Incubation</span><br />
                                                            </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;