import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';
import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
import SideManu from '../sidebar_international';

const Eligibility = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Guidelines for Visa & Medical Insurance" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div class="container text-justify">
                                                    <br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Guidelines for Visa & Medical Insurance</b> <br />
                                                    <p style={{ textAlign: "justify" }}>
                                                        <center><b> <span className='name'> <img src={point1} style={{ height: "25px" }} /> GUIDELINES FOR VISA </span><br /></b></center>
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Once Admission Letter / Visa Letter has been provided student have to without delay apply for Student Visa at Indian Embassy / High Commission.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Ensure that your Student Visa is endorsed to Aditya Educational Institutions.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Ensure that you have applied for Visa well in advance and time. It usually takes 3-8 weeks to obtain the Indian Visa thus it is advisable to apply for the visa accordingly and consult the Indian High Commission/Embassy if required.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Change of University or Institution cannot be considered. In this case, you would be obliged to return to your home country and apply for a new visa.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Ensure that if you enter India by Student Visa authorized to Aditya Educational Institutions, then it is your liability to ensure that you directly join and report to the Institution. The Visa approved on the name of the Institute or obtained from Institute documents cannot be used for any other reason like employment, admission to any other university/college/institute/academy, etc. or for non-regular (distance/online) mode of education.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Once on reaching the Aditya Campus, students have to get the visa verified by the International Office and have to deposit a copy of the valid permit at International Relations Division. It is the liability of the student to make sure that during his/her study period student is on a valid visa.</span><br />
                                                    </p>
                                                    <br />
                                                    <p style={{ textAlign: "justify" }}>
                                                        <center><b> <span className='name'> <img src={point1} style={{ height: "25px" }} />  GUIDELINES FOR VISA EXTENSION AND FRO/FRRO </span><br /></b></center>
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  It is compulsory for all International students except those from Nepal and Bhutan to get their Student Visa registered at FRRO/FRO Kakinada within 14 days of coming to India.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  International Relations Department of Aditya Educational Institutions will guide and support Student with all the necessary procedures required for FRO/FRRO Registration.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  All International students except for those from Nepal and Bhutan have to fill online application for Registration Certificate (RC) & Residential Permit (RP) on coming to India.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  In case the initial visa is not authorized for the entire period of the program or student has to extend the reside because he/she is not able to complete the application in the specific time then the student should apply for the extension of the visa before the visa expires.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  visa Extension for stay visa, Residence permit Extension (RP Extension), change of address, change of course & change of Institution and OFFLINE for No Objection Certificate (NOC) for traveling outside India in Foreigner’s Registration Office (FRO) in Kakinada and Foreigner’s Regional Registration Office (FRRO) in Hyderabad.</span><br />

                                                    </p>
                                                    <br />
                                                    <p style={{ textAlign: "justify" }}>
                                                        <center><b> <span className='name'> <img src={point1} style={{ height: "25px" }} />  GUIDELINES FOR MEDICAL INSURANCE </span><br /></b></center>
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  Guideline for Hospitalization under Cashless Mediclaim Policy Hospitalization under Cashless Mediclaim Policy shall mean admission in a listed Hospital/Nursing Home in India upon the written advice of a Medical Practitioner for a minimum period of 24 consecutive hours. The patient is not covered for OPD charges paid by him if not hospitalized. The time limit of 24 hours will not be applicable for following surgeries / procedures</span><br />
                                                        <p style={{ textAlign: "justify" }}>
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Anti Rabies Vaccination Hysterectomy</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Appendectomy Inguinal/Ventral/Umbilical/Femoral Hernia repair</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Coronary Angiography Lithotripsy (Kidney Stone Removal)</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Coronary Angioplasty Parenteral Chemotherapy</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Dental surgery following an accident Piles / Fistula</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Dilatation & Curettage (D & C) of Cervix Prostate</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Eye surgery Radiotherapy</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Fracture / dislocation excluding hairline</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> fracture</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Sinusitis</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Gastrointestinal Tract system Stone in Gall Bladder, Pancreas, and Bile Duct</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Haemo-Dialysis Tonsillectomy,</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Hydrocele Urinary Tract System OR any other Surgeries / Procedures agreed by TPA/Company which require less than 24 hours hospitalization due to advancement in Medical Technology. Diseases which are Permanent Excluded under Cashless Mediclaim Polic1y (The Insurance Company is free to add/delete any disease(s) under its policy) Any medical expenses incurred for or arising out of </span><br />

                                                        </p>
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> War invasion, Act of foreign enemy, War like operations, Nuclear weapons, ionizing radiation, contamination by radio activity, by any nuclear fuel or nuclear waste or from the combustion of nuclear fuel.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Circumcision, cosmetic or aesthetic treatment, plastic surgery unless required to treat injury or illness.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Vaccination & Inoculation.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Cost of braces, equipment or external prosthetic devices, non-durable implants, eyeglasses, Cost of spectacles and contact lenses, hearing aids including cochlear implants, durable medical equipments.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> All types of Dental treatments except arising out of an accident.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Convalescence, general debility, 'Run-down' condition or rest cure, obesity treatment and its complications, congenital external disease/defects or anomalies, treatment relating to all psychiatric and psychosomatic disorders, infertility, sterility, use of intoxicating drugs/alcohol, use of tobacco leading to cancer.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Bodily injury or sickness due to willful or deliberate exposure to danger (except in an attempt to save human life), intentional self-inflicted injury, attempted suicide, arising out of non-adherence to medical advice.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Treatment of any Bodily injury sustained whilst or as a result of active participation in any hazardous sports of any kind.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Treatment of any bodily injury sustained whilst or as a result of participating in any criminal act.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Sexually transmitted diseases, any condition directly or indirectly caused due to or associated with Human T-Cell Lymphotropic Virus Type III (HTLB-III) or lymphotropathy Associated Virus (LAV) or the Mutants Derivative or Variation Deficiency syndrome or any syndrome or condition of a similar kind commonly referred to as AIDS.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Diagnosis, X-Ray or Laboratory examination not consistent with or incidental to the diagnosis of positive existence and treatment of any ailment, sickness or injury, for which confinement is required at a Hospital/Nursing Home. </span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Vitamins and tonics unless forming part of treatment for injury or disease as certified by the attending physician. </span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Treatment arising from or traceable to pregnancy, childbirth, miscarriage, abortion or complications of any of these including caesarean section, except abdominal operation for extra uterine pregnancy (Ectopic Pregnancy), which is proved by submission of ultra Sonographic Report and Certification by Gynecologist that it is life threatening. </span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Naturopathy Treatment.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Instrument used in treatment of Sleep Apnea Syndrome (C.P.A.P.) and continuous Peritoneal Ambulatory dialysis (C.P.A.D.) and Oxygen Concentrator for Bronchial Asthmatic condition.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Genetic disorders and stem cell implantation / surgery.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Domiciliary Hospitalization.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Treatment taken outside India.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Experimental Treatment. Unproven treatment (not recognized by Indian Medical Council).</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Change of treatment from one system to another unless recommended by the consultant / hospital under whom the treatment is taken.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> All non-medical expenses including convenience items for personal comfort such as telephone, television, Ayah, Private Nursing / Barber or beauty services, diet charges, baby food, cosmetics, tissue paper, diapers, sanitary pads, toiletry items etc. guest services and similar incidental expenses.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Service charges or any other charges levied by hospital, except registration/admission charges. </span><br />

                                                    </p><br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            </div>
            <FooterTwo />
        </>
    );
}

export default Eligibility;