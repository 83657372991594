import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import fractal_based2 from './images/fractal_based2.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                    <StudyBreadcrumb pageTitle="Advanced Engineering Applications" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                            <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>Fractal Based Multi Input and Multi Output (MIMO) Antenna for Wideband Applications</b> <br />
                                                    <b className='name'>Theme:</b>
                                                     <p className='name'>
                                                     An innovative work on fractal based multiple-input multiple-output (MIMO) antenna that makes use of flawed substrate for deployment in wideband applications.
                                                    </p>
                                                    <b className='name'>Background:</b>
                                                     <p className='name'>
                                                     Wireless communication systems have become pervasive and ubiquitous to the point where data rate and quality of service requirements have become comparable to those of wired communication systems. Although the 4G wireless communication system is presently undergoing intensive studies, the next generation communication i.e., fifth-generation (5G) is now drawing even more interest and attention. Because the universal standard for 5G communication system hasn’t been finalized, massive MIMO (multiple-input multiple-output) systems based on multi-antennas become one of the core technologies for 5G operation. Even though applying the massive MIMO technology to consumer devices can enhance its channel capacity, arranging a large number of antennas into a limited space will also lead to deteriorated isolations and efficiencies.
                                                    </p>
                                                    
                                                     <p className='name'>
                                                     Therefore, loading multiple antennas into consumer devices for 5G communication is presently a challenging topic for antenna engineers. Therefore, there is a need to design a multiple-input multiple-output (MIMO) antenna having characteristics that are within an acceptable range. There is also a need for designing a MIMO antenna having a simple design. There is a need to design a MIMO antenna with decreased dimensions compared to traditional antennas thereby producing symmetrical radiation from the radiating elements. There is a need to design an antenna that provides an increased bandwidth frequency with a small sized fractal antenna. There is a need to eliminate additional components such as capacitors, inductors and the like for designing the antenna.
                                                    </p>
                                                    
                                                    <b className='name'>Our Invention</b>
                                                    <p className='name'>
                                                    The present disclosure proposes a multiple input and multiple output (MIMO) antenna that makes use of flawed substrate for deployment in 5G sub: 6GHz applications. The fractal based MIMO antenna comprises a substrate a plurality of circular patches a primary layer a secondary layer a plurality of slots a feeding means and a wave port. The proposed miniaturized antenna is designed for wideband applications. The proposed low-cost MIMO antenna is uniquely designed with bore sight 10 patterns for high gain. The proposed antenna designs a defective ground structure (DGS) with a recess at the center to attain a wide bandwidth frequency. The proposed antenna occupies less space and provides high diversity and better radiation performance characteristics.
                                                    </p>
                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>
                                                   
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Improved Signal Coverage </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Increased Capacity </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Multi-band Operation</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Robustness to Environmental Conditions</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Wireless Sensor Networks</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Satellite and Aerospace Communications</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Military and Defense</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> 5G and Beyond</span><br />
                                                    </p>
                                                    <b className='name'>IP Status </b>
                                                    <p className='name'>
                                                    The invention is the subject of a Granted Patent No. 524363 IP India  
                                                    </p>
                                                    <p className='name'>
                                                    Application No. 202241067265
                                                    </p>
                                                    <b className='name'>Inventors </b>
                                                    <p className='name'>
                                                    Dr. A. Tathababu
                                                    </p>
                                                    <p className='name'>
                                                    V. Satyanarayana
                                                    </p>
                                                    <center>
                                                        <img src={fractal_based2} /><br></br>
                                                        <b className='name'> Fractal Based MIMO Antenna </b>
                                                    </center>
                                                    <br/>

                                                    <b className='name'>Contact us</b>
                                                     <p className='name'>
                                                     If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at:<b>dean_rc@adityauniversity.in</b>                                                      
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;