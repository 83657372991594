// import React, { useEffect } from 'react';



// const Careers = () => {
//   useEffect(() => {
//     window.location.href = 'https://aec.edu.in/icmes2025/';
//   }, [])
//   return (
//   <></>    
//   )
// }

// export default Careers;


import React from 'react';
// import Header from '../../components/Header';
// import FooterTwo from '../../components/Footer/FooterTwo';

const Careers = () => {
  return (
    <>
      {/* <Header parentMenu='page' menuCategoryEnable='enable' /> */}

                <div className="container-fluid">
                   <iframe src='https://aec.edu.in/icmes2025/'  style= {{ width:'100%',height:'960px'}}> </iframe>
                </div>

      {/* <FooterTwo /> */}
    </>
  );
}

export default Careers;



