import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_eee';
import Banner from './banner';





const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  M-Tech </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>The M.Tech. program in Power Electronics and Drives created in harmony with industry requirements, provides extensive opportunity and platform for students to work in multidisciplinary research fields. The PG program includes courses in a wide range of subjects in power electronics and energy conversion technologies and emerging power switching devices, electrical machines and their control, required to build any power electronic equipment with necessary controllers.  </p>

                                                        <p className='name'>The Program culminates with a project work in which the students are encouraged to work on specific areas involving design, simulation, analysis and testing of any power electronics system having research/industrial application values. Some of the facilities available with the department are advanced oscilloscopes, motor-generator set, Autotransformers, FPGA boards, IGBT modules, multilevel converters, etc.</p>

                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;