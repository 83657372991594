
import { Link } from 'react-router-dom';
import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import AboutBreadcrumb from '../../components/Breadcrumb/AboutBreadcrumbs';
import ScrollToTop from '../../components/ScrollTop';

// Image
import Deputy_Chancellor_image from '../../assets/images/about/Deputy_Chancellor.jpg';


const Deputy_Chancellor = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            // headerNormalLogo={Logo}
            // headerStickyLogo={Logo}
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <AboutBreadcrumb pageTitle="Deputy Pro-Chancellor's Message" />
                    <div class="about__area about2__area about3__area p-relative pb---60" style={{ marginTop: "40px" }}>
                        <div class="container">
                            <div class="row">
                                <div className="col-lg-4">
                                    <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                                        <img src={Deputy_Chancellor_image} alt="About" />
                                    </div>
                                </div>
                                <div className="col-lg-8" style={{backgroundColor: 'rgb(248, 249, 250)',padding:'20px'}}>
                                    <div className="about__content about__content1">
                                        <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify' }}>
                                        At Aditya University, our commitment to nurturing leaders and advancing knowledge is guided by our Vision of excellence in education, innovation in research, and dedicated service to society. We provide an environment that merges academic rigor with a culture of support, equipping our students for the challenges of a dynamic world.</p>
                                        <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify' }}>
                                        We emphasize interdisciplinary learning and practical exposure, preparing our students not only for academic success but for responsible global citizenship. By fostering a culture of innovation and sustainability, we contribute to impactful societal advancements. At Aditya University, we envision a future where education is a powerful driver of holistic growth and positive change. I invite you to join us in this journey of transformation and excellence, where every step is a stride toward realizing a shared vision of progress for our community, our nation, and our world.
                                        </p>
                                        <b style={{float:'right'}}>
                                            Best regards,<br/>
                                            Dr. M. Sreenivasa Reddy<br/>
                                            Deputy Pro-Chancellor<br/>
                                            Aditya university
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default Deputy_Chancellor;