import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import virtual_reality1 from './images/virtual_reality1.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                    <StudyBreadcrumb pageTitle="Advanced Engineering Applications" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                            <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>Virtual Reality Learning and Amusement System Based on Artificial Intelligence (AI) and IoT</b> <br />
                                                    <b className='name'>Theme:</b>
                                                     <p className='name'>
                                                     An innovative approach to develop a Virtual Reality Learning and Amusement System based on AI and IOT
                                                    </p>
                                                    <b className='name'>Background:</b>
                                                     <p className='name'>
                                                     The use of digital technologies to construct a realistic world is known as virtual reality (VR). In contrast to conventional user interfaces, virtual reality immerses the user in an experience. Users are submerged and able to connect with 3D environments rather than watching a screen in front of them. The machine is turned into a facilitator to this artificial reality by simulating as many senses as possible, including vision, sound, touch, and even smell
                                                    </p>
                                                     <p className='name'>
                                                     Near-real VR environments are only limited by the abundance of material and low cost processing resources. Virtual Reality systems that are convincing need more than just graphics. A person sense of space is based on both hearing and vision. In reality, humans respond to audio cues faster than they do to visual cues. Accurate ambient sounds and spatial characteristics are needed to produce fully realistic Virtual Reality experiences.
                                                    </p>
                                                    <b className='name'>Our Invention</b>
                                                    <p className='name'>
                                                    This disclosure relates to system that uses virtual reality to realize various learning and amusement space themes. The present invention provides integration of multiple functionalities which realizes cinematic panorama mode, a memory enrichment study room mode and thereby significantly reduces software and hardware implementation costs. This increases user immersion satisfaction and time consumption performance.
                                                    </p>
                                                     <p className='name'>
                                                     The stereo projection unit with panoramic immersion connected with the centralized control and play system unit and includes a user module, a speech recognition sensor, hybrid high definition display device. The convertible and adjustable massage chair accepts virtual reality figure workstation animation scene, and performs the appropriate operation, wherein the client server sub module in the communication network unit is responsible for linking clients, choosing to send or receive data to the appropriate clients based on client forms, and the client sub module is responsible for connecting to the server sub module and receiving or transmitting interactive data from the server sub module based on the types of interactive equipment attached to the said client sub module and wherein the said system when operating in memory enrichment intelligent learning mode provides a database learning resource interface framework and a recommendation based learning framework                                                       
                                                    </p>
                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Household application </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Mineral Water Plants to improve Drinking Water Quality </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} />  Health Benefits</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} />  Clinical Applications</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Enhanced Customer Experience </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} />  Sustainability</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} />  Workplace and Offices</span><br />
                                                    </p>
                                                    <b className='name'>IP Status </b>
                                                    <p className='name'>
                                                    The invention is the subject of a Granted Patent No. 2021103720 IP Australia   
                                                    </p>
                                                    <p className='name'>
                                                    Application No. 202141021933 
                                                    </p>
                                                    <b className='name'>Inventors </b>
                                                    <p className='name'>
                                                    Dr. S. Ramasree, Dr. A Vanathi 
                                                    </p>
                                                    <center>
                                                        <img src={virtual_reality1} /><br></br>
                                                        <b className='name'>Block diagram of the AI based virtual reality learning and amusement system </b>
                                                    </center>
                                                    <br/>
                                                    <b className='name'>Contact us</b>
                                                     <p className='name'>
                                                    If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at :<b>dean_rc@adityauniversity.in</b>                                                       
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;