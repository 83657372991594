import React from 'react';
import { useState } from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

import SideManu from './sidebar_cse';
import Banner from './banner';
import CSEPopUp from "../../../../../components/Popup/CSE/CSEPopUp";






const CEview = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    <CSEPopUp />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  B.Tech </b> </center><br />
                                                    <div class="about__content">

                                                        <p className='name'> The program has been accredited by NBA under Tier- I in the year 2023. The curricula of B. Tech -Computer Science and Engineering, have been designed to cater to the ever changing needs and demands of IT industry. In order to give due importance to applied as well as theoretical aspects of computing, the curricula focus on holistic learning and empowering students to develop the engineering skills for problem solving using computing sciences. The programme ensures commitment towards education and research in the field of Computer Science and Engineering through industrial collaborations. The choice-based system of electives gives a variety of choices for the students to develop expertise in their area of interest.
                                                            <br /><br />
                                                            Through major core courses like Data Structures, Software Engineering, operating Systems, Computer Organization, Computer Networks, Theory of Computation, Compiler Design, Artificial Intelligence and Machine Learning for professional study. Students can achieve advanced levels of technical expertise in one or more areas of concentration by selecting appropriate minor degree courses, honour degree courses, ability, skill enhancement and value-added courses. As an alternative, students might decide to broaden their interests by mixing courses from different departments.
                                                        </p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech in (Computer Science and Engineering)<br></br>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech in (Computer Science and Engineering) with Minor Specialization in
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Network Security</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Machine Intelligence</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Data Engineering</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Mobile Application Development</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Cyber Security <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Agile Methodology with DevOps Programming <b style={{ color: "#328afc", fontSize: "17px" }}> (Integrated Program - L&T) </b></span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Cloud Infrastructure <b style={{ color: "#328afc", fontSize: "17px" }}> (Certification Programs - CISCO, Red hat, Oracle, AWS, Google & Microsoft) </b></span><br />
                                                            <span className='name'> <span ><img src={point1} style={{ height: "25px" }} /></span> <span > Minor Stream in Rapid Application Development <b style={{ color: "#328afc", fontSize: "17px" }}> (Certification Programs - PEGA, Oracle, Microsoft & Salesforce) </b></span></span><br />
                                                        </p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech in (Computer Science and Engineering) with
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Civil  Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Electrical and Electronics Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Mechanical Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Electronics and Communication Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Petroleum Technology<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Mining Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Agricultural Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Entrepreneurship Development and Incubation<br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;