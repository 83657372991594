import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/iqac';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';

import SideManu from './sidebar_iqac';
// import image1 from './accreditations_images/nba.png';
// import image2 from './accreditations_images/naac.png';




const accreditations = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Internal Quality Assurance Cell" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <div class="degree-list-container">
                                                        <ul class="degree-list">
                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Develop quality benchmarks for all activities </span></li>
                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Propagate information of quality parameters </span></li>
                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Organize seminars/guest lectures/workshops on quality-related themes </span></li>
                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Documentation of activities/events </span></li>
                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Act as a nodal agency for quality-related activities </span></li>
                                                            <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Prepare and submit AQAR to NAAC annually </span></li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default accreditations;