import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_cse';
import coming_soon from '../../../../../assets/images/icons/coming_soon.png';

import Banner from './banner';





const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  M.Tech </b> </center><br />
                                                    <div class="about__content">
                                                        <div id="overview" class="container">
                                                            {/* <center><img src={coming_soon} style={{ height: "250px", width: "250px" }} /></center> */}
                                                            <p className='name'>
                                                                The Department of Computer Science & Engineering offers PG Programme M.Tech in Computer Science and Engineering started in the year 2008. This programme is established with an objective of producing prolific professionals to meet the growing needs in academic institutions. IT/IT enabled sectors and research institutions. The programme exposes students to a wide range of courses combined with specialized research which culminates in publications and dissertations. Further it provides the students the breadth and depth necessary for pursuing careers in academic and industries
                                                            </p>
                                                            <p className='name'>Department has a thriving Research Groups in the areas of,</p>
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Artificial Intelligence</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Machine Learning and Deep learning</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Natural Language Processing</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Image Processing</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Data Science</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Internet of Things</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Big Data Analytics</span><br />
                                                            <span className='name'><img src={point} style={{ height: "25px" }} /> Cyber Security and Blockchain</span><br />

                                                        </div>
                                                        <br/>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;