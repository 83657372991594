
import { Link } from 'react-router-dom';
import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import AboutBreadcrumb from '../../components/Breadcrumb/AboutBreadcrumbs';
import ScrollToTop from '../../components/ScrollTop';

// Image
import aboutImg from '../../assets/images/about/chancellor.jpg';
import shapeImg1 from '../../assets/images/about/shape_02.png';

const Chancellor = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            // headerNormalLogo={Logo}
            // headerStickyLogo={Logo}
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <AboutBreadcrumb pageTitle="Chancellor's Message" />
                    <div class="about__area about2__area about3__area p-relative pb---60" style={{ marginTop: "40px" }}>
                        <div class="container">
                            <div class="row">
                                <div className="col-lg-4">
                                    <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                                        <img src={aboutImg} alt="About" />
                                    </div>
                                </div>
                                <div className="col-lg-8" style={{backgroundColor: 'rgb(248, 249, 250)',padding:'20px'}}>
                                    <div className="about__content about__content1">
                                        {/* <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s" style={{color:"#D2093C"}}>Chancellor's Message </h2> */}
                                        <b style={{fontSize:'18px',color: "#D2093C"}}>Dreams alone won't bring success; it takes sweat, determination, and action
                                        </b>
                                        <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify' }}>
                                            Motivated by a vision of national development through education, I founded Aditya University to provide top-tier education while nurturing future leaders. Inspired by Charles Darwin's principle of adaptability, we've overcome challenges to create an environment where students thrive. At Aditya University, we pride ourselves on offering practical, industry-focused education that attracts the brightest minds. Our alumni's success in top companies worldwide is a testament to the dedication of our faculty, who instill in each student values of dedication, sincerity, and loyalty. Today. Aditya University stands as a testament to our commitment to nurturing efficient, confident, and globally minded professionals. With initiatives like the Global Business Incubation Centre and Technical Skill Development Institute, we equip our students to lead with innovation and foresight.</p>
                                        <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify' }}>
                                            Our dream and commitment lie in shaping every student's journey at Aditya University into a resounding success story.
                                        </p>
                                        <b style={{float:'right'}}>
                                            Best regards,<br/>
                                            Dr. N. Sesha Reddy<br/>
                                            Chancellor<br/>
                                            Aditya university
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default Chancellor;