import React, { useEffect, useState } from 'react';
import axios from 'axios';
import crypto from 'crypto-js';

const baseURL = process.env.REACT_APP_DATABASEURL;

const axioAPI = axios.create({
  baseURL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  }
});

const PaymentMain = () => {
  const [application, setApplication] = useState(null);

  useEffect(() => {
    const application_id = JSON.parse(localStorage.getItem('refId'));
    setApplication(application_id);
    console.log(application_id);
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async () => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    try {
      const result = await axioAPI.post("https://adityauniversity.in:4001/api/order", { amount: 100000 });
      console.log("Order Response:", result.data);

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      const { amount, order_id, currency } = result.data;

      const handlePayment = async (status, data = {}) => {
        console.log("application id is", application);
        if (application) {
          const applicationDetails = { application };
          console.log("this is application id", applicationDetails);
          const paymentResult = await axioAPI.post("https://adityauniversity.in:4001/api/payment/success", { status, data, applicationDetails });
          console.log("Payment Result:", paymentResult.data);
          alert(paymentResult.data.msg);
           window.location.href = '/PhD_admission_form';
        }
      };

      const options = {
        key: "rzp_live_Kmh34Xa4jArEXT",
        amount: amount.toString(),
        currency,
        name: "Aditya University",
        description: "Test Transaction",
        image: "https://adityauniversity.in/static/media/au_logo_new.b993a20be611bd3711d9.png",
        order_id,
        handler: async (response) => {
          console.log("Razorpay Response:", response);
        //   const succeeded = crypto.HmacSHA256(`${order_id}|${response.razorpay_payment_id}`, "w2lBtgmeuDUfnJVp43UpcaiT").toString() === response.razorpay_signature;

          if (response.razorpay_payment_id) {
            // alert("success");
           
            handlePayment('succeeded', {
              orderCreationId: order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            });
          } else {
            alert("fail");
            handlePayment('failed', {
              orderCreationId: order_id,
              razorpayPaymentId: response.razorpay_payment_id,
            });
          }
        },
        prefill: {
          name: "Aditya University",
          email: "aditya@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Aditya University",
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please check console for details.");
    }
  };

  return (
    <div className="react-login-page react-signup-page pt---120 pb---120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="registration-right-form">
              <form>
                <div className="row" style={{textAlign:'center'}}>
                  <div>
                    <button type="button" className="btn btn-md btn-primary" onClick={displayRazorpay}>
                       Payment 
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right">
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentMain;
