import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_aiml';
import coming_soon from '../../../../../assets/images/icons/coming_soon.png';
import Banner from './banner';
import Staff_details from '../commonpages/faculty_details';


const AIML_view = () => {
    const department = 'AI&ML';
    return (
        <>
        <Header
            parentMenu='page'
            menuCategoryEnable='enable'
        />
        <div class="react-wrapper">
            <div class="react-wrapper-inner">
                <Banner />
                <div className="high_quality-section pt---110 pb---120">
                    <div className="container">
                        <div className="react__title__section-all">
                            <div class="research" id="">
                                <div class="container">
                                    <div class="row m-0">
                                        <SideManu />
                                        <div class="col-md-1  col-sm-1 col-1"></div>
                                        <div class="col-md-8  col-sm-8" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                            <Staff_details department={department}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop />
        </div>
        <FooterTwo />
    </>
    );
}

export default AIML_view;