import { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import sliderImg1 from './images/1.jpg';
import sliderImg4 from './images/YAswanth chand.jpg';

import slider2 from './images/2.jpg';
import slider3 from './images/3.jpg';
import slider4 from './images/4.jpg';
import slider5 from './images/5.jpg';
import slider01 from './images/01.jpg';
import slider02 from './images/02.jpg';
import image31lpa from './images/new_japan.jpg';




import sliderImg2 from './images/statastics.jpg';
import sliderImg3 from './images/2025_placement.jpg';





const HomeSlider = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [videoId, setVideoId] = useState('');

    // Function to open modal with specific video ID
    const openModalWithVideo = (id) => {
        setVideoId(id);
        setIsOpen(true);
    };

    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
        autoplay:true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                }
            }
        ]
    };

    return (
        <>
            <div className="react-slider-part">
                <ModalVideo channel='youtube' isOpen={isOpen} videoId={videoId} onClose={() => setIsOpen(false)} />

                <div className="home-sliders home2">
                    <Slider {...sliderSettings}>
                    <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={slider01} alt="Slider Image 1" />
                                <img className="mobile"  src={slider01} alt="Slider Image 1" />
                            </div>
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg4} alt="Slider Image 1" />
                                <img className="mobile"  src={sliderImg4} alt="Slider Image 1" />
                            </div>
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={slider02} alt="Slider Image 1" />
                                <img className="mobile"  src={slider02} alt="Slider Image 1" />
                            </div>
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={image31lpa} alt="Slider Image 1" />
                                <img className="mobile"  src={image31lpa} alt="Slider Image 1" />
                            </div>
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg1} alt="Slider Image 1" />
                                <img className="mobile"  src={sliderImg1} alt="Slider Image 1" />
                            </div>
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={slider2} alt="Slider Image 1" />
                                <img className="mobile"  src={slider2} alt="Slider Image 1" />
                            </div>
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={slider3} alt="Slider Image 1" />
                                <img className="mobile"  src={slider3} alt="Slider Image 1" />
                            </div>
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={slider4} alt="Slider Image 1" />
                                <img className="mobile"  src={slider4} alt="Slider Image 1" />
                            </div>
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg3} alt="Slider Image 2" />
                                <img className="mobile"  src={sliderImg3} alt="Slider Image 2" />
                            </div>
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={slider5} alt="Slider Image 1" />
                                <img className="mobile"  src={slider5} alt="Slider Image 1" />
                            </div>
                        </div>
                       
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" src={sliderImg2} alt="Slider Image 2" />
                                <img className="mobile"  src={sliderImg2} alt="Slider Image 2" />
                            </div>
                        </div>
                       
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default HomeSlider;
