import React, { useState } from 'react';
import Header from '../../../../components/Header';
import FooterTwo from '../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../components/ScrollTop';

import "../../../../components/Styles/pragram.css";
import SideManu from './sidebar_eng';
import Modal from 'react-modal';  // Add a modal library for pop-up PDFs

import EEEimage from './images/eee.png';
import Cvimage from './images/cv.png';
import Mechimage from './images/mech.png';
import Eceimage from './images/ece.png';
import Cseimage from './images/cse.png';
import ITimage from './images/it.png';
import AIMLimage from './images/aiml.png';
import DSimage from './images/ds.png';
import PTimage from './images/pt.png';
import AGEimage from './images/age.png';
import Miningimage from './images/mining.png';

import Linkimage from './images/link.gif';

import Clpdf from './images/ug_curriculum/civil.pdf';
import EEEpdf from './images/ug_curriculum/eee.pdf';
import Mechpdf from './images/ug_curriculum/mech.pdf';
import ECEpdf from './images/ug_curriculum/ece.pdf';
import Csepdf from './images/ug_curriculum/cse.pdf';
import ITpdf from './images/ug_curriculum/it.pdf';
import AIMLpdf from './images/ug_curriculum/aiml.pdf';
import DSpdf from './images/ug_curriculum/ds.pdf';
import PTpdf from './images/ug_curriculum/pt.pdf';
import Miningpdf from './images/ug_curriculum/mining.pdf';
import AGEpdf from './images/ug_curriculum/age.pdf';


import CL1 from './images/civil_curriculum/1.pdf';
import CL2 from './images/civil_curriculum/2.pdf';
import CL3 from './images/civil_curriculum/3.pdf';
import CL4 from './images/civil_curriculum/4.pdf';
import CL5 from './images/civil_curriculum/5.pdf';
import CL6 from './images/civil_curriculum/6.pdf';
import CL7 from './images/civil_curriculum/7.pdf';
import CL8 from './images/civil_curriculum/8.pdf';
import CL9 from './images/civil_curriculum/9.pdf';

// import CL1 from './images/civil_curriculum/mcc.pdf';
// import CL2 from './images/civil_curriculum/msc.pdf';
// import CL3 from './images/civil_curriculum/mdc.pdf';
// import CL4 from './images/civil_curriculum/aec.pdf';
// import CL5 from './images/civil_curriculum/sec.pdf';
// import CL6 from './images/civil_curriculum/vac.pdf';
// import CL7 from './images/civil_curriculum/md.pdf';
// import CL8 from './images/civil_curriculum/uec.pdf';
// import CL9 from './images/civil_curriculum/auc.pdf';

import AIML1 from './images/aiml_curriculum/mcc.pdf';
import AIML2 from './images/aiml_curriculum/msc.pdf';
import AIML3 from './images/aiml_curriculum/mdc.pdf';
import AIML4 from './images/aiml_curriculum/aec.pdf';
import AIML5 from './images/aiml_curriculum/sec.pdf';
import AIML6 from './images/aiml_curriculum/vac.pdf';
import AIML7 from './images/aiml_curriculum/md.pdf';
import AIML8 from './images/aiml_curriculum/uec.pdf';
import AIML9 from './images/aiml_curriculum/auc.pdf';


import EEE1 from './images/eee_curriculum/mcc.pdf';
import EEE2 from './images/eee_curriculum/msc.pdf';
import EEE3 from './images/eee_curriculum/mdc.pdf';
import EEE4 from './images/eee_curriculum/aec.pdf';
import EEE5 from './images/eee_curriculum/sec.pdf';
import EEE6 from './images/eee_curriculum/vac.pdf';
import EEE7 from './images/eee_curriculum/md.pdf';
import EEE8 from './images/eee_curriculum/uec.pdf';
import EEE9 from './images/eee_curriculum/auc.pdf';

import PT1 from './images/pt_curriculum/mcc.pdf';
import PT2 from './images/pt_curriculum/msc.pdf';
import PT3 from './images/pt_curriculum/mdc.pdf';
import PT4 from './images/pt_curriculum/aec.pdf';
import PT5 from './images/pt_curriculum/sec.pdf';
import PT6 from './images/pt_curriculum/vac.pdf';
import PT7 from './images/pt_curriculum/md.pdf';
import PT8 from './images/pt_curriculum/uec.pdf';
import PT9 from './images/pt_curriculum/auc.pdf';

import IT1 from './images/it_curriculum/mcc.pdf';
import IT2 from './images/it_curriculum/msc.pdf';
import IT3 from './images/it_curriculum/mdc.pdf';
import IT4 from './images/it_curriculum/aec.pdf';
import IT5 from './images/it_curriculum/sec.pdf';
import IT6 from './images/it_curriculum/vac.pdf';
import IT7 from './images/it_curriculum/md.pdf';
import IT8 from './images/it_curriculum/uec.pdf';
import IT9 from './images/it_curriculum/auc.pdf';

import DS1 from './images/ds_curriculum/mcc.pdf';
import DS2 from './images/ds_curriculum/msc.pdf';
import DS3 from './images/ds_curriculum/mdc.pdf';
import DS4 from './images/ds_curriculum/aec.pdf';
import DS5 from './images/ds_curriculum/sec.pdf';
import DS6 from './images/ds_curriculum/vac.pdf';
import DS7 from './images/ds_curriculum/md.pdf';
import DS8 from './images/ds_curriculum/uec.pdf';
import DS9 from './images/ds_curriculum/auc.pdf';

import MECH1 from './images/mech_curriculum/mcc.pdf';
import MECH2 from './images/mech_curriculum/msc.pdf';
import MECH3 from './images/mech_curriculum/mdc.pdf';
import MECH4 from './images/mech_curriculum/aec.pdf';
import MECH5 from './images/mech_curriculum/sec.pdf';
import MECH6 from './images/mech_curriculum/vac.pdf';
import MECH7 from './images/mech_curriculum/md.pdf';
import MECH8 from './images/mech_curriculum/uec.pdf';
import MECH9 from './images/mech_curriculum/auc.pdf';

import ECE1 from './images/ece_curriculum/mcc.pdf';
import ECE2 from './images/ece_curriculum/msc.pdf';
import ECE3 from './images/ece_curriculum/mdc.pdf';
import ECE4 from './images/ece_curriculum/aec.pdf';
import ECE5 from './images/ece_curriculum/sec.pdf';
import ECE6 from './images/ece_curriculum/vac.pdf';
import ECE7 from './images/ece_curriculum/md.pdf';
import ECE8 from './images/ece_curriculum/uec.pdf';
import ECE9 from './images/ece_curriculum/auc.pdf';

import CSE1 from './images/cse_curriculum/mcc.pdf';
import CSE2 from './images/cse_curriculum/msc.pdf';
import CSE3 from './images/cse_curriculum/mdc.pdf';
import CSE4 from './images/cse_curriculum/aec.pdf';
import CSE5 from './images/cse_curriculum/sec.pdf';
import CSE6 from './images/cse_curriculum/vac.pdf';
import CSE7 from './images/cse_curriculum/md.pdf';
import CSE8 from './images/cse_curriculum/uec.pdf';
import CSE9 from './images/cse_curriculum/auc.pdf';

import Mining1 from './images/mining_curriculum/mcc.pdf';
import Mining2 from './images/mining_curriculum/msc.pdf';
import Mining3 from './images/mining_curriculum/mdc.pdf';
import Mining4 from './images/mining_curriculum/aec.pdf';
import Mining5 from './images/mining_curriculum/sec.pdf';
import Mining6 from './images/mining_curriculum/vac.pdf';
import Mining7 from './images/mining_curriculum/md.pdf';
import Mining8 from './images/mining_curriculum/uec.pdf';
import Mining9 from './images/mining_curriculum/auc.pdf';


import Age1 from './images/age_curriculum/mcc.pdf';
// import Age2 from './images/age_curriculum/msc.pdf';
import Age3 from './images/age_curriculum/mdc.pdf';
import Age4 from './images/age_curriculum/aec.pdf';
import Age5 from './images/age_curriculum/sec.pdf';
import Age6 from './images/age_curriculum/vac.pdf';
import Age7 from './images/age_curriculum/md.pdf';
// import Age8 from './images/age_curriculum/uec.pdf';
import Age9 from './images/age_curriculum/auc.pdf';











// Sample program data array
const programData = [
    { 
        id: 0, 
        title: 'Civil Engineering', 
        image: Cvimage, 
        pdf: Clpdf,
        filecount:'10',   
        curriculum_name: ['Major Core Courses (MCC)','Multidisciplinary Courses (MDC)','Ability Enhancement Courses (AEC)','Skill Enhancement Courses (SEC)','Value Added Courses (VAC)','Audit Courses (AUC)','Minor Stream Courses (MSC)','University Elective Courses (UEC)','Minor Degree'], 
        curriculum: [CL1,CL3,CL4,CL5,CL6,CL7,CL8,CL2,CL9] 
    },
    { 
        id: 1, 
        title: 'Electrical and Electronics Engineering',
        image: EEEimage, pdf: EEEpdf,
        filecount:'10',   
        curriculum_name: ['Major Core Courses (MCC)', 'Minor Stream Courses (MSC)','Multidisciplinary Courses (MDC) ','Ability Enhancement Courses (AEC) ','Skill Enhancement Courses (SEC) ','Value Added Courses (VAC)','University Elective Courses (UEC)','Audit Courses (AUC)','Minor Degree'], 
        curriculum: [EEE1,EEE2,EEE3,EEE4,EEE5,EEE6,EEE8,EEE9,EEE7] 
    },

    { id: 2, title: 'Mechanical Engineering', image: Mechimage, pdf: Mechpdf,
        filecount:'10',   
        curriculum_name: ['Major Core Courses (MCC)', 'Minor Stream Courses (MSC)','Multidisciplinary Courses (MDC) ','Ability Enhancement Courses (AEC) ','Skill Enhancement Courses (SEC) ','Value Added Courses (VAC)','University Elective Courses (UEC)','Audit Courses (AUC)','Minor Degree'], 
        curriculum: [MECH1,MECH2,MECH3,MECH4,MECH5,MECH6,MECH8,MECH9,MECH7] 
     },
    { id: 3, title: 'Electronics and Communication Engineering', image: Eceimage, pdf: ECEpdf,
        filecount:'10',   
        curriculum_name: ['Major Core Courses (MCC)', 'Minor Stream Courses (MSC)','Multidisciplinary Courses (MDC) ','Ability Enhancement Courses (AEC) ','Skill Enhancement Courses (SEC) ','Value Added Courses (VAC)','University Elective Courses (UEC)','Audit Courses (AUC)','Minor Degree'], 
        curriculum: [ECE1,ECE2,ECE3,ECE4,ECE5,ECE6,ECE8,ECE9,ECE7] 
     },
    { id: 4, title: 'Computer Science and Engineering', image: Cseimage, pdf: Csepdf ,
        filecount:'10',   
        curriculum_name: ['Major Core Courses (MCC)', 'Minor Stream Courses (MSC)','Multidisciplinary Courses (MDC) ','Ability Enhancement Courses (AEC) ','Skill Enhancement Courses (SEC) ','Value Added Courses (VAC)','University Elective Courses (UEC)','Audit Courses (AUC)','Minor Degree'], 
        curriculum: [CSE1,CSE2,CSE3,CSE4,CSE5,CSE6,CSE8,CSE9,CSE7]  
    },

    { id: 5, title: 'Information Technology', image: ITimage, pdf: ITpdf ,
        filecount:'10',   
        curriculum_name: ['Major Core Courses (MCC)', 'Minor Stream Courses (MSC)','Multidisciplinary Courses (MDC) ','Ability Enhancement Courses (AEC) ','Skill Enhancement Courses (SEC) ','Value Added Courses (VAC)','University Elective Courses (UEC)','Audit Courses (AUC)','Minor Degree'], 
        curriculum: [IT1,IT2,IT3,IT4,IT5,IT6,IT8,IT9,IT7] 
    },

    { id: 6, title: 'Artificial Intelligence and Machine Learning', image: AIMLimage, pdf: AIMLpdf ,
        filecount:'10',   
        curriculum_name: ['Major Core Courses (MCC)', 'Minor Stream Courses (MSC)','Multidisciplinary Courses (MDC) ','Ability Enhancement Courses (AEC) ','Skill Enhancement Courses (SEC) ','Value Added Courses (VAC)','University Elective Courses (UEC)','Audit Courses (AUC)','Minor Degree'], 
        curriculum: [AIML1,AIML2,AIML3,AIML4,AIML5,AIML6,AIML8,AIML9,AIML7] 

    },

    { id: 7, title: 'Data Science', image: DSimage, pdf: DSpdf,
        filecount:'10',   
        curriculum_name: ['Major Core Courses (MCC)', 'Minor Stream Courses (MSC)','Multidisciplinary Courses (MDC) ','Ability Enhancement Courses (AEC) ','Skill Enhancement Courses (SEC) ','Value Added Courses (VAC)','University Elective Courses (UEC)','Audit Courses (AUC)','Minor Degree'], 
        curriculum: [DS1,DS2,DS3,DS4,DS5,DS6,DS8,DS9,DS7,] 
    },

    { id: 8, title: 'Petroleum Technology', image: PTimage, pdf: PTpdf,
        filecount:'10',   
        curriculum_name: ['Major Core Courses (MCC)', 'Minor Stream Courses (MSC)','Multidisciplinary Courses (MDC) ','Ability Enhancement Courses (AEC) ','Skill Enhancement Courses (SEC) ','Value Added Courses (VAC)','University Elective Courses (UEC)','Audit Courses (AUC)','Minor Degree'], 
        curriculum: [PT1,PT2,PT3,PT4,PT5,PT6,PT8,PT9,PT7,] 
    },

    { id: 9, title: 'Mining Engineering', image: Miningimage, pdf: Miningpdf ,
        filecount:'10',   
        curriculum_name: ['Major Core Courses (MCC)', 'Minor Stream Courses (MSC)','Multidisciplinary Courses (MDC) ','Ability Enhancement Courses (AEC) ','Skill Enhancement Courses (SEC) ','Value Added Courses (VAC)','University Elective Courses (UEC)','Audit Courses (AUC)','Minor Degree'], 
        curriculum: [Mining1,Mining2,Mining3,Mining4,Mining5,Mining6,Mining8,Mining9,Mining7] 
    },
    { id: 10, title: 'Agricultural Engineering', image: AGEimage, pdf: AGEpdf,
        filecount:'10',  
        curriculum_name: ['Major Core Courses (MCC)','Multidisciplinary Courses (MDC) ','Ability Enhancement Courses (AEC) ','Skill Enhancement Courses (SEC) ','Value Added Courses (VAC)','Audit Courses (AUC)','Minor Degree'], 
        curriculum: [Age1,Age3,Age4,Age5,Age6,Age9,Age7] 
    },
];

const Engoverview = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const openModal = (pdf) => {
        setSelectedPdf(pdf);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedPdf(null);
    };

    return (
        <>
            <Header parentMenu='page' menuCategoryEnable='enable' />
            <div className="react-wrapper">
                <div className="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="School of Engineering" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div className="research">
                                    <div className="container">
                                        <div className="row m-0">
                                            <SideManu />
                                            <div className="col-md-1 col-sm-1 col-2"></div>
                                            <div className="col-md-8 col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div className="container">
                                                    <div className="about__content">
                                                        <div>
                                                        {programData.map(({ id, title, image, pdf, curriculum, curriculum_name ,filecount}) => (
                                                                <React.Fragment key={id}>
                                                                    <button className={`accordion ${activeIndex === id ? 'active' : ''}`} onClick={() => toggleAccordion(id)}>
                                                                        <img src={image} style={{ height: '40px' }} alt={title} />
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;{title}
                                                                        <span className="symbol">{activeIndex === id ? '▲' : '▼'}</span>
                                                                    </button>
                                                                    <div className="panel" style={{ display: activeIndex === id ? 'block' : 'none', marginBottom: '14px' }}>
                                                                        
                                                                        <br />
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ textAlign: 'center' }}>Program</th>
                                                                                    <th style={{ textAlign: 'center' }}>2024-25</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td rowspan={filecount}>B.Tech ({title})</td>
                                                                                    <td>
                                                                                        {/* <a href={pdf} target="_blank" style={{ color: "#000" }} className='namehover'> Curriculum </a> */}
                                                                                        <span onClick={() => openModal(pdf)} style={{ color: "#000", cursor: 'pointer' }}>
                                                                                            Curriculum
                                                                                        </span>
                                                                                        <img src={Linkimage} style={{ height: '25px' }} alt="link icon" />
                                                                                    </td>
                                                                                </tr>

                                                                                
                                                                                {curriculum && curriculum_name && curriculum.map((curr, index) => (
                                                                                    <tr key={index}>
                                                                                        
                                                                                        <td>
                                                                                            <span onClick={() => openModal(curr)} style={{ color: "#000", cursor: 'pointer' }}>
                                                                                            {curriculum_name[index]}
                                                                                            </span>
                                                                                            <img src={Linkimage} style={{ height: '25px' }} alt="link icon" />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                        <br />
                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

            {/* Modal for PDF Display */}
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Curriculum PDF" className="Modal">
                <br/>
                <button onClick={closeModal} className="close-button" style={{
                        float: 'right',
                        background: '#1f3860',
                        color: 'white',
                        height: '39px',
                        width: '79px',
                        padding: '3px'
                }}><b>Close</b></button>
                {selectedPdf && (
                   <center>
                      <br/><iframe src={`${selectedPdf}#toolbar=0`} title="Curriculum PDF" width="60%" height="700px" />
                    </center>
                )}
            </Modal>
            
        </>
    );
};

export default Engoverview;
