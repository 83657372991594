import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';


const H3 = styled.h3
    `color: #D2093C;
font-weight: 550;
`




const OurResearchPart = () => {
    const tabStyle = 'nav nav-tabs';

    return (
        <div class="about__area about2__area about3__area p-relative pb---60" style={{ marginTop: "40px" }}>
            <div class="container">
                <div class="row">
                    {/* <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img class="react__shape__1" src={shapeImg1} alt="Shape Image" />
                            <img src={aboutImg} alt="About" />
                        </div>
                    </div> */}
                    <div class="col-lg-12">
                        <div class="about__content">
                            <p class="about__paragraph wow animate__fadeInUp" data-wow-duration="0.7s" style={{ textAlign: 'justify' }}> We believe in the transformative power of research to shape a better future for society. Our Research & Development Cell serves as a dynamic center for exploration, innovation, and collaboration in the realms of science and technology</p>
                            <div>
                                <H3 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Our Mission</H3>
                                <p class="about__paragraph wow animate__fadeInUp" data-wow-duration="0.7s" style={{ textAlign: 'justify' }}>We are committed to conducting pioneering research that addresses pressing academic and societal challenges. Our mission is to generate innovative ideas and solutions that contribute to the betterment of humanity.</p>
                            </div>

                            <div>
                                <H3 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Support for Faculty and Students</H3>
                                <p class="about__paragraph wow animate__fadeInUp" data-wow-duration="0.7s" style={{ textAlign: 'justify' }}> We provide extensive support for faculty and students to pursue research across all disciplines. From state-of-the-art facilities to mentorship from eminent researchers, we empower our academic community to push the boundaries of knowledge. Seed money grant is being provided to encourage the faculty to implement their ideas. Faculty and students are sponsored to participate in conferences and incentives are provided to faculty for their research publications</p>
                            </div>

                            <div>
                                <H3 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Research Excellence</H3>
                                <p class="about__paragraph wow animate__fadeInUp" data-wow-duration="0.7s" style={{ textAlign: 'justify' }}>We offer unparalleled opportunities for staff and students to work alongside experts from industry, gaining invaluable insights and real-world experience. Our strong partnerships with leading companies ensure that our research remains relevant and impactful. We are open to collaboration with foreign universities and industries to foster innovation and address global challenges. This commitment fosters a culture of continuous learning and growth.</p>
                            </div>

                            <div>
                                <H3 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Innovation Ecosystem</H3>
                                <p class="about__paragraph wow animate__fadeInUp" data-wow-duration="0.7s" style={{ textAlign: 'justify' }}>Aditya University is a thriving hub of research and innovation, with a robust ecosystem that encourages creativity and exploration. Our efforts have resulted in the publication of over 250 patents, demonstrating our dedication to translating ideas into tangible solutions for societal problems</p>
                            </div>
                            <div>
                                <div className="high_quality-section pt---110 pb---120">
                                    <div className="container">
                                        <div className="react__title__section-all">
                                            <div className='col-md-12'>
                                                <H3 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Funded Projects</H3>
                                                <br />
                                                <Tabs>
                                                    <div className="react-tab-gird wow animate__fadeInUp" data-wow-duration="0.3s">
                                                        <TabList className={tabStyle}>
                                                            <Tab >
                                                                <button disabled={true}>
                                                                    <em className="text">Department of Science & Technology (DST) granted Rs. 473.60 Lakhs for the establishment of Technology Business Incubator.</em>
                                                                </button>
                                                            </Tab>
                                                            <Tab >
                                                                <button disabled={true}>
                                                                    <em className="text">Coir Board under Ministry of MSME granted Rs. 341.38 Lakhs for the implementation of Coir Cluster at Amalapuram.</em>
                                                                </button>
                                                            </Tab>
                                                            <Tab >
                                                                <button disabled={true}>
                                                                    <em className="text">Coir Board under Ministry of MSME granted Rs. 411.19 Lakhs for the implementation of Coir Cluster at Kadiyapulanka</em>
                                                                </button>
                                                            </Tab>
                                                            <Tab >
                                                                <button disabled={true}>
                                                                    <em className="text">Department of Science & Technology (DST) granted Rs. 16.74 Lakh for the Project Bibliometric mapping of Solid Waste Management in India from 1986-2016.</em>
                                                                </button>
                                                            </Tab>
                                                            <Tab >
                                                                <button disabled={true}>
                                                                    <em className="text">Science for Equity Empowerment and Development (SEED) Division under the Department of Science and Technology granted Rs. 226.0 Lakhs for Science Technology and Innovation Hub for Development of Scheduled Caste Communities in Amalapuram Mandal</em>
                                                                </button>
                                                            </Tab>
                                                            <Tab >
                                                                <button disabled={true}>
                                                                    <em className="text">Ministry of MSME sanctioned Rs. 100 Lakhs for the establishment of IP Facilitation Center.</em>
                                                                </button>
                                                            </Tab>
                                                        </TabList>
                                                    </div>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <H3 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Patents Granted</H3>

                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr class="tableizer-firstrow">
                                                <th style={{ textAlign: 'center', background: '#6f90c3', color: 'white' }}>S.No.</th>
                                                <th style={{ textAlign: 'center', background: '#6f90c3', color: 'white' }}>Patent Application No <br /> & Sanctioning Authority</th>
                                                <th style={{ textAlign: 'center', background: '#6f90c3', color: 'white' }}>Title of the Patent</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>1</td>
                                                <td>202141021933 <br /> IP Australia</td>
                                                <td>Virtual Reality Learning And Amusement System Based On Artificial Intelligence (AI) And IoT.</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>2</td>
                                                <td>2021105088 <br />IP Australia</td>
                                                <td>Performance and Emission Analysis of Direct Injection Diesel Engine Fuelled by Biodiesel with Al2O3 Nano Additives.</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>3</td>
                                                <td>20 2022 102 057.4 <br />Federal Republic of Germany</td>
                                                <td>A system for studying the performance of a photovoltaic panel under shady conditions.</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>4</td>
                                                <td>201841038721 <br />IP India</td>
                                                <td>Development of Clutch based Mechanical oriented Side stand Retrieval system mechanism for 2 wheelers and Method thereof.</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>5</td>
                                                <td>202241043771 <br />IP India</td>
                                                <td>Wearable Device and Method for Treatment of Plantar Fasia.</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>6</td>
                                                <td>202241052363<br />IP India</td>
                                                <td>Brightness Enhancing and Super Water-Repellent Composition for Transparent Films</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>7</td>
                                                <td>202241050493 <br />IP India</td>
                                                <td>Bottle Configuration System for Preparing Beverages and Method Thereof</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>8</td>
                                                <td>202241059286 <br />IP India</td>
                                                <td>An Automatic Extendable Rear Seat for a Motorcycle</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>9</td>
                                                <td>201941000382IP  <br />IP India</td>
                                                <td>A Clutch Restriction System</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>10</td>
                                                <td>202241067271 <br />IP India</td>
                                                <td>A Floor Drain Trap with an Automatic Waste Cleaning System and Method Thereof</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>11</td>
                                                <td>202341004501 <br />IP India</td>
                                                <td>A Container with a Treatment System for Ion Balancing and Mineralizing Water and Method Thereof</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>12</td>
                                                <td>202241075066 <br />IP India</td>
                                                <td>An Automatic Sanitizer Dispensing Accessory for Door Handles</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>13</td>
                                                <td>201941027270 <br />IP India</td>
                                                <td>Hydraulic System Based Clutch Lever Restriction for Two Wheeler and Method There of</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>14</td>
                                                <td>202241059287 <br />IP India</td>
                                                <td>Cooking System with Automatic Level Adjustment Mechanism of Containers and Method Thereof</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>15</td>
                                                <td>201841028064 <br />IP India</td>
                                                <td>Sugarcane Harvesting Machine</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>16</td>
                                                <td>202241059289 <br />IP India</td>
                                                <td>Automatic Sorting Trash Bin and Method Thereof</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>17</td>
                                                <td>202241067275 <br />IP India</td>
                                                <td>A Height Adjustable Seat Apparatus for a Toilet Commode and Method Thereof</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>18</td>
                                                <td>202241050495 <br />IP India</td>
                                                <td>Multi-Chamber Mop Bucket for Cleaning and Squeezing Mop Head and Method Thereof</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>19</td>
                                                <td>202241067265 <br />IP India</td>
                                                <td>Fractal Based MultiInput and MultiOutput (MIMO) Antenna for Wideband Applications</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>20</td>
                                                <td>202241075060 <br />IP India</td>
                                                <td>A Multi-Chambered Bottle Configuration for Preparing Beverages</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>21</td>
                                                <td>202141050440<br />IP India</td>
                                                <td>Estimating Method for Optimal Placement of Distributed Generation Unit</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>22</td>
                                                <td>201841039661<br />IP India</td>
                                                <td>An Accelerator Restriction System</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                            </div>


                            <div className="campus_sec1 row">
                                <div className="col-lg-6">
                                    <div className="text-center">
                                        <Link to="../uploads/research_articles_published.pdf" className="more-about" target="blank"> Click here for details of Research Articles Published  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="text-center">
                                        <Link to="../uploads/patents_published.pdf" className="more-about" target="blank"> Click here for details of Patents Published <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurResearchPart;