import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_ece';
import Banner from './banner';




const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        
                                                        <p className='name'>The Department of Electronics and Communication Engineering is dedicated to achieving excellence by prioritizing industry needs and employing learner-centric teaching methods. With a faculty comprising qualified and experienced professionals, alongside state-of-the-art academic facilities, the department provides an immersive learning experience for the students. The department offers specialized training in advanced technologies through facilities such as the Texas Instruments Innovation Lab, E-Yantra Robotics Lab, and Intel Intelligent Systems Lab. Internship opportunities with esteemed companies such as BHEL, ONGC, Hindustan Shipyard, BSNL, Airports Authority of India, DLRL, and RINL enable students to gain valuable practical experience.
                                                        </p>
                                                        <p className='name'>Established in 2001 with an initial intake of 60 students, the Department of Electronics and Communication Engineering (ECE) has since expanded to accommodate 240 students. It also offers a Master’s program in VLSI Design with a current intake of 12 students. The department boasts dedicated faculty and well-equipped laboratories, including the Electronic Devices and Circuits Lab, Microwave Engineering and Optical Communication Lab, Digital Signal Processing Lab, and Project Lab, providing a platform for practical learning.
                                                        </p>
                                                        <p className='name'>The Department of ECE has an IETE student forum, under which professional activities such as guest lectures, workshops, seminars, and the technical festival SPARK (under the umbrella of VEDA) are organized to enrich students' knowledge. To enhance placements, the department has a strong technical team (Technical Hub) dedicated to training students in coding practices and problem-solving skills.
                                                        </p>
                                                        <p className='name'>The department has signed MOUs with Electropro Pvt. Ltd., Edgate Technologies & Texas Instruments, and Tessolve Semiconductor Pvt. Ltd. Additionally, the college has MoUs with the Andhra Pradesh State Skill Development Centre (APSSDC), Applied Robot Control (ARC), AWS Educate, UiPath, CISCO, Salesforce, Oracle Academy, among others. Many students have been trained and certified in various new technologies and trends necessary for the current industries from their first year onwards. We are proud to share that some of our students have been placed with the highest package of 12 lakhs.
                                                        </p>
                                                        



                                                        <br />
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  VISION : </b> <br />

                                                        <p className='name'>To become a center of excellence in the field of Electronics and Communication Engineering with technological capability, professional commitment and social responsibility</p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> MISSION:</b><br />
                                                        <p className='name'>
                                                            M1: Provide quality education, well-equipped laboratory facilities and industry collaboration.<br />
                                                            M2:Promote cutting edge technologies to serve the needs of the society and industry through innovative research.<br />
                                                            M3: Inculcate professional ethics and personality development skills.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;