import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_mech';
import Banner from './banner';




const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        
                                                        <p className='name'>The Department of Mechanical Engineering is renowned for its commitment to quality teaching, research, and practical training. With faculty comprising seasoned professionals and state-of-the-art facilities, we offer an enriching learning environment. Students actively participate in professional activities through various clubs and benefit from exclusive internships with esteemed companies such as TATA Motors, Hindustan Shipyard, BHEL, Bhilai Steel Plant, Bokaro Thermal Power Station, and NALCO Mining Division, enhancing their practical experience.
                                                        </p>
                                                        <p className='name'>The department's slogan, "Managing moving machines of the world!" encapsulates its pivotal role in shaping a world that runs on machines. It has earned high repute through quality teaching by highly qualified and experienced faculty with numerous research publications in reputed journals like ASME, ELSEVIER, etc., and rigorous practical training that fosters an atmosphere of excitement throughout the study journey.
                                                        </p>
                                                        <p className='name'>Emphasis is placed on hands-on training, supported by simulation packages such as CATIA, ANSYS, I-DEAS, PRO-E, UNIGRAPHICS, and SOLID WORKS. Professional activities include guest lectures, industrial visits, workshops, seminars, technical festivals like VEDA, and programs under Mechanical Engineering Students Association (MESA), the collegiate club of the Society of Automotive Engineers (SAE), and the Auto Gear Club.
                                                        </p>
                                                        <p className='name'>Projects like Go-Kart, Quad Bike, Solar-Powered Wheelchair, LPG Bike, Reaping Machine, and others have received appreciation in various state and national level events, showcasing the department's focus on highly relevant innovations.
                                                        </p>
                                                        <p className='name'>Students are encouraged to undertake internships in renowned industries such as TATA Motors PVT. LTD., Hindustan Shipyard, BHEL PVT. LTD., Bhilai Steel Plant, Bokaro Thermal Power Station, NALCO Mining Division, among others, further enriching their practical exposure and industry readiness.</p>



                                                        <br />
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  VISION : </b> <br />

                                                        <p className='name'>To be a preferred knowledge hub in Mechanical Engineering towards critical thinking, quality research and innovation</p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> MISSION:</b><br />
                                                        <p className='name'>
                                                            M1: Provide infrastructure for design and development of modern-day solutions.<br />
                                                            M2: Impart leadership, critical thinking and innovation to address the societal needs.<br />
                                                            M3: Collaborate with industry, academia, & R&D organizations for excellence in teaching, research and consultancy services.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;