import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
    
    return (
        <div className="col-md-3 col-sm-3 col-0">
            <div className="side-navbar">
                <nav className="navbar">
                    <ul className="navbar-nav">
                        <li className="navbar-title text-center">
                            <a className="navbar-brand text-light1" style={{ padding: '12px 124px' }} href="#">Explore </a>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/aditya_learning_academy' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/aditya_learning_academy">About</Link>
                        </li>
                        <li className={`nav-item dropdown ${window.location.pathname.startsWith('/objectives') ? 'active' : ''}`}>
                            <Link className="nav-link" to="/objectives">Objectives</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/comprehensive_support_services' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/comprehensive_support_services">Comprehensive Support Services</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/academy_team' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/academy_team">Team</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/academy_events' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/academy_events">Events</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Sidebar;
