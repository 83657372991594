import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';
import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
import SideManu from '../sidebar_international';

const Eligibility = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Admission Rules" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div class="container text-justify">
                                                    <br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Admission Rules</b> <br/>
                                                    <p style={{textAlign:"justify"}}>
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Important Information Related To Admission</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> 1. Applicants in their own interest must read and understand all the information published on the Institute website and otherwise published by the Institute from time to time.</span><br />

                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> 2. The eligibility criterion for all programs for international applicants is minimum 50% in the qualifying examination and having studied the pre-requisite subjects for admission in to the desired program </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> 3. Issue of offer letter (conditional or unconditional) or any information /guidance/ facilitation provided by the Institute should not be considered as confirmation of admission or access to any facility of Institute. </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> 4. The institute reserves the right to add, withdraw or change any program or tuition fees at any time, without any notice, information contained herein or otherwise published or announced by the Institute as well as any provision or facility as and if deemed necessary, No responsibility will be accepted by the Institute for hardship or expenses incurred by the applicant or any person(s) on this account, no matter how they are caused.</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> 5. For the purpose of equivalency of the qualifying exam, the qualifications as recognized by  Association of Indian Universities at the first time  shall be considered. For any qualification not recognized by  Association of Indian Universities at the first time, equivalency proof has to be provided by the applicant from the Indian High Commission/ Consulate in their respective country or other concerned statutory body as applicable </span><br />
                                                    </p>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            </div>
            <FooterTwo />
        </>
    );
}

export default Eligibility;