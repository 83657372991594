import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import Technology_1 from './images/technology_1.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                    <StudyBreadcrumb pageTitle="Technology for Disabled and Elderly" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                            <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>A Height Adjustable Seat Apparatus for a Toilet Commode and Method Thereof</b> <br />
                                                    <b className='name'>Theme:</b>
                                                     <p className='name'>
                                                     An innovative toilet seat accessories, and in specific, relates to a height adjustable toilet seat apparatus designed for elderly and physically challenged people to sit conveniently.
                                                    </p>
                                                    <b className='name'>Background:</b>
                                                     <p className='name'>
                                                     Typically, toilet seats are fixed at a certain angle height which is suitable for physically able people, however such standard toilet seats may not be suitable for disabled or elderly persons or children in the same household. Conventional toilet seats are characteristically low to the ground, making it very difficult for physically disabled or illness weakened persons to use the toilet without the assistance of a nurse or other individual.<br></br>
                                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Therefore, there is a need for a novel toilet seat apparatus that can provide height and angle adjustment, a seat that can be mounted and fit on any commode shape, a self-cleaning facility and facility to easily unlock and remove from the commode. 
                                                    </p>
                                                    <b className='name'>Our Invention:</b>
                                                     <p className='name'>
                                                     An apparatus and method for locking and adjusting height and angle of a toilet seat for a toilet commode is disclosed. The apparatus comprises a locking unit an adjustable unit and a seating unit. The height adjustable toilet seat apparatus is designed for elderly and physically challenged people to sit conveniently. The proposed apparatus fits on any type of commode design such as elliptical, rectangular, square, and circular-shaped commodes. The apparatus is self-cleaned and requires minimal water consumption. The proposed apparatus can enable height and angle adjustment for allowing various elevated positions. The proposed apparatus is used for raising and lowering a toilet seat that can be easily adapted to existing toilet hardware and be easily operated by children, elderly people and physically challenged people.
                                                    </p>
                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Post-Surgery or Injury</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Rehabilitation Centres</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Customizable Height</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Assisted Living Facilities</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Stability and Safety Features</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Compatibility and Installation</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Improved Comfort and Safety</span><br />
                                                    </p>
                                                    <b className='name'>IP Status</b>
                                                     <p className='name'>
                                                     The invention is the subject of a Granted Patent 518102 IP India
                                                     <br></br>
                                                     Application No. 202241067275                                                       
                                                    </p>
                                                    <b className='name'>Inventors</b>
                                                     <p className='name'>
                                                     V. S. N. Ch. Dattu
                                                    </p>
                                                    <center>
                                                        <img src={Technology_1} /><br></br>
                                                        <b className='name'>A Height Adjustable Seat Apparatus for a Toilet Commode</b>
                                                    </center>
                                                    <br/>
                                                    <b className='name'>Contact us</b>
                                                     <p className='name'>
                                                     If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at :<b>dean_rc@adityauniversity.in</b>                                                      
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;