import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';
import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
import SideManu from '../sidebar_international';

const Eligibility = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Post arrival Information" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div class="container text-justify">
                                                    <br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Visa Assistance</b> <br />
                                                    <p style={{ textAlign: "justify" }}>
                                                        <b className='name' style={{ textAlign: "justify" }}> On your arrival in India, there are certain points that you should keep in mind. </b><br /><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> <b>Customs Formalities: </b>International students in India are usually required to make an oral baggage declaration of the ownership of baggage and foreign currency as soon as they land in India.Under this, they also need to get the Currency Declaration Form from the Customs. The students must also fill in the Disembarkation Card (Arrival card) handed over to them by the airline during the course of the flight. </span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> <b>Clearance:</b>
                                                            There are two channels for approval
                                                        </span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> <b>Green Channel:</b>For passengers not in possession of any dutiable articles or unaccompanied baggage. </span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> <b>	Red Channel:</b> For passengers in possession of dutiable articles or unaccompanied baggage or high-value articles. High-value items are to be entered on the tourist Baggage Re-Export Form. </span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> ADITYA organizes for pick-up provision for the international students in a group of minimum eight students from Vishakhapatnam or Vijayawada Airport on the designated dates. To benefit this facility, a student must request and register for the facility at least two weeks earlier of coming and present the entire information of flight. Also, pick-up provision is open to all international students from the Rajahmundry Airport(Domestic Airport)/ Railway Station or Samalkot railway station or bus terminals, for which prior information and registration is compulsory.</span><br />
                                                    


                                                    </p>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            </div>
            <FooterTwo />
        </>
    );
}

export default Eligibility;