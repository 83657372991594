import React from 'react';
import Student_testimonials from '../students/opportunities/studenttesti_monials';
import Milestons from './milestons.js';


const Page = () => {
 

    return (
        <>
            <div className="container-fluid" style={{ backgroundColor: "#f8f9fa" }}>
                <div className="react-wrapper-inner">
                    <div className="high_quality-section">
                        <br/>
                        <center>
                            <div className="container-fluid">
                               <div className="row">
                                   <div className="col-md-6">
                                     <h2>Milestone Notifications</h2>
                                  </div>
                                  <div className="col-md-6">
                                    <h2>Proud Moments of Adityans</h2>
                                    
                                  </div>
                                </div>
                                <div className="row">
                                   <div className="col-md-6">
                                     <Milestons/>
                                  </div>
                                  <div className="col-md-6">
                                     <Student_testimonials/>
                                  </div>
                                </div>
                            </div>
                        </center>
                    </div>
                </div>
            </div>

           
        </>
    );
}

export default Page;
