import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_agri';
import Banner from './banner';




const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        
                                                        <p className='name'>The Department of Agricultural Engineering at Aditya is dedicated to educating and training students to support and maintain the pace of agricultural development in the country. Emphasizing job opportunities in government and allied sectors, the department focuses on enhancing students' technical skills relevant to the agricultural industry. With campus recruitment training beginning in the second year, the department ensures students are well-prepared for their careers.
                                                        </p>
                                                        <p className='name'>The department boasts a team of qualified faculty, modern facilities, and a 5-acre farm for hands-on learning. Laboratories are equipped with state-of-the-art equipment, including the Soil Science and Agronomy Lab, Farm Machinery Lab, Agricultural Process Engineering Lab, Soil Water Conservation Lab, and Dairy and Food Engineering Lab.
                                                        </p>
                                                        <p className='name'>Collaborating with the Indian Society of Agricultural Engineers (ISAE), students stay informed about the latest trends in agricultural engineering. To further enhance the quality of education, the department frequently organizes industrial tours, providing students with real-time exposure to diverse landscapes, agricultural methods, and industry experts. This practical knowledge is applied in cultivating rice, vegetables, cereal crops, and more on the department's designated 5-acre land under staff guidance.
                                                        </p>
                                                        <br />
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  VISION : </b> <br />

                                                        <p className='name'>To make the Agricultural Engineering education known for its contribution to agriculture and allied fields in making agriculture more sustainable and profitable.</p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> MISSION:</b><br />
                                                        <p className='name'>
                                                            M1: Implementation of new technologies for the farmers on sustainable food production through precision agriculture and mechanized food processing.<br />
                                                            M2: Educating the students to integrate knowledge of agricultural engineering fundamentals and design of systems involved in food production, processing, storage, handling, distribution and use of food.<br />
                                                            M3: Developing the good atmosphere/foundation between the students and faculty to perform and lead engineering projects and make significant contributions for the benefit farming community.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;