import React from 'react';
import Slider from "react-slick";

import SectionTitle from '../../components/SectionTitle'
import SingleTestimonial from '../../components/Testimonial/SingleTestimonial';

import ChandraImg from '../../assets/images/testimonial/20A91A05A2MSARATHCHANDRA_CSE.jpg';
import PrasannaImg from '../../assets/images/testimonial/20A91A0429MDURGAPRASANNA_ECE.jpg';
import KalyanImg from '../../assets/images/testimonial/20A91A2712MSRIDURGAKALYAN_PT.jpg';
import KumariImg from '../../assets/images/testimonial/21A95A0138VLKRISHNAKUMARI_CE.jpg';
import comaImg from '../../assets/images/testimonial/coma.png';

const Testimonial = () => {

    const testimonialSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                }
            },
            {

                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                }
            }
        ]
    };

    return (
        <>
            <div className="react-clients react-clientso home-testimonial pt---90 pb---120">
                <div className="container">
                    <SectionTitle Title="What our student saying" />
                    <div className="client-slider wow animate__fadeInUp" data-wow-duration="0.3s">
                        <Slider {...testimonialSettings}>
                            <SingleTestimonial
                                itemClass=""
                                itemImg={ChandraImg }
                                Title="M Sarath Chandra, CSE"
                                Designation="Student"
                                Desc="The greatest success for an engineering student would be to get an opportunity to kick start their career in a reputed organization with a good package. This I was able to find in Aditya which has given me the best by driving out the best from me. Aditya made me to take life seriously and challengingly in the campus. It trained me to be always in win-win situation and helped me get a job in MNC, CONTROL’s with a huge package. The vast hands-on experience and enriching helped me bag this job. Thank you Aditya for shaping me and my skills in both academic and non-academic activities."
                                comaImg={comaImg}
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={PrasannaImg }
                                Title="M Durga Prasanna, ECE"
                                Designation="Student"
                                Desc="Life in Aditya is cozy and the warmth of the management, faculty and other staff makes me and my parents to be a part of this good college. The superiors take utmost care to hone the skills of students in all aspects. Administration is great because we have all the activities for the students. It was my immense luck and fortune to be the part of this abode of learning. It helped me to grow. The entire faculty and department leaves no stone unturned to shape one’s future."
                                comaImg={comaImg}
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={KalyanImg }
                                Title="M Sri Durga Kalyan, PT"
                                Designation="Student"
                                Desc="A conducive environment marked my learning at Aditya college. A fine balance of academics and co-curricular activities made me to rethink about my future fostering my ideas to reality. A great support rendered by the teaching fraternity leveraged my skills to prove my talent researching fossil fuels to bio fuels."
                                comaImg={comaImg}
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={KumariImg }
                                Title="V L Krishna Kumari, CE"
                                Designation="Student"
                                Desc="It is a pride to be a civil student of Aditya College- A Benchmark for excellence. It nurtures the student’s ability to maneuver their thoughts to accomplish their tasks. Besides education it imbibes the individual traits and skills to be assertive in all walks of life. It builds the future with a strong foundation to sustain and rise high in their profession."
                                ratingCount='4.9'
                                reviewCount='14'
                                comaImg={comaImg}
                            />
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Testimonial