import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_ece';
import Banner from './banner';





const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Ph.D Program </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>The Ph.D. degree is awarded to acknowledge exceptional academic accomplishments exhibited via independent study and the practical application of knowledge to address technological, scientific, and social issues. Creative and productive inquiry is the basic requirement underlying research work. Skilled faculties who hold doctorates in VLSI, IoT, Embedded Systems, Wireless Communication, Signal Processing, Image Processing, and Speech Processing from prestigious institutions such as IITs, NITs, or others. The academic program leading to the degree involves fulfilling course credit requirements, residential requirements and a thesis giving a critical account of the research carried out, in any of the areas such as VLSI, Communication system, RF Engineering, Image processing, Signal Processing, and many more.</p>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;