import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import motorcycle4 from './images/motorcycle4.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                    <StudyBreadcrumb pageTitle="Automobile Engineering" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                            <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>An Automatic Extendable Rear Seat for a Motorcycle</b> <br />
                                                    <b className='name'>Theme:</b>
                                                     <p className='name'>
                                                     An innovative work to develop a motorcycle seat assembly, and in specific, relates to an automatic extendable rear seat attached to a motorcycle that provides comfort for the pillion rider while traveling.
                                                    </p>
                                                    <b className='name'>Background:</b>
                                                     <p className='name'>
                                                     Long distance motorcycle travelling has become a popular recreational activity in developed countries. The passenger seat of a motorcycle is small and built to complement the motorcycle's sleek appearance. This form of function strategy improves the rider's aesthetic value while having significant negative effects on the passenger. In conventional rear seats, the seats do not provide comfort and security for the passengers or pillion riders during a ride or long rides.
                                                    </p>
                                                    
                                                     <p className='name'>
                                                     The existing seat extension devices do not provide support for long distance motorbike excursions that frequently result in the back pain and muscle tenderness. Therefore, there is a need for a rear seat that provides additional support and comfort for the pillion rider while travelling. There is a need for a rear seat that provides security for the passengers or pillion riders while adjusting in the seat during rides. There is a need for a rear seat mechanism that provides support for long distance motorbike excursions that frequently result in the back pain and muscle tenderness.
                                                    </p>
                                                    
                                                    <b className='name'>Our Invention</b>
                                                    <p className='name'>
                                                    The present disclosure proposes an automatic extendable rear seat attached to a motorcycle that provides comfort for the pillion rider while traveling. The automatic seat extendable device comprises a housing, at least one extendable support at least one sensing unit and a control unit. The proposed rear seat supporting device enables seat extension horizontally and provides support to the thighs of the pillion rider. The proposed rear seat extendable device monitors the sitting position of the pillion rider. The proposed rear seat extendable device enables the motorcycle to start the engine based on the sitting position of the pillion rider. The proposed automatic rear seat extendable device allows the extension without affecting the vehicle balancing factor.
                                                    </p>
                                            
                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>
                                                   
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Enhanced Passenger Comfort </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Increased Safety  </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Expanded Market</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Tourism and Leisure</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Urban Mobility Solutions </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Technology Integration</span><br />
                                                    
                                                    </p>
                                                    <b className='name'>IP Status </b>
                                                    <p className='name'>
                                                    The invention is the subject of a Granted Patent No. 470047 IP India
                                                    </p>
                                                    <p className='name'>
                                                    Application No. 202241059286
                                                    </p>
                                                    <b className='name'>Inventors </b>
                                                    <p className='name'>
                                                    Chitturi S S N Murthy
                                                    </p>
                                                    <center>
                                                        <img src={motorcycle4} /><br></br>
                                                        <b className='name'>An Automatic Extendable Rear Seat </b>
                                                    </center>
                                                    <br/>

                                                    <b className='name'>Contact us</b>
                                                     <p className='name'>
                                                     If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at :<b>dean_rc@adityauniversity.in</b>                                                       
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;