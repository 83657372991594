import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PaymentMain from './PaymentMain';
import ScrollToTop from '../../components/ScrollTop';



const Payment = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
                // headerNormalLogo={Logo}
                // headerStickyLogo={Logo}
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <PaymentMain />
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <Footer />

        </>
    );
}


export default Payment;

