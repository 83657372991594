import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';
import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
import SideManu from '../sidebar_international';

const Eligibility = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Admission Process" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div class="container text-justify">
                                                    <br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Admission Process</b> <br/>
                                                    <p style={{textAlign:"justify"}}>
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> <b> STEP - 1</b> Register yourself by visiting https://adityauniversity.in/international/ and clicking on new registration.</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> <b> STEP - 2</b> Fill the application form and upload the required documents for eligibility verification. You shall be issued with a Unique ID which will be required for further communication with the university.</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> <b> STEP - 3</b> After the eligibility verification by the eligibility council, an offer letter (Conditional / Unconditional) shall be issued and sent to your registered email id.</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> <b> STEP - 4</b> Once you receive the offer letter, make the payment as prescribed in the offer letter by bank transfer/ wire transfer/ online payment and submit the receipt along with your System ID at global@adityauniversity.in</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> <b> STEP - 5</b> After the confirmation of the fees received by the Aditya accounts office, an Acceptance letter ( visa letter) and a bonafide letter will be issued which is required to apply for the Student Visa at the nearest Indian Embassy/ High Commission in your home country. </span><br />
                                                    </p>
                                                 <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            </div>
            <FooterTwo />
        </>
    );
}

export default Eligibility;