import React from 'react';
import { Link } from 'react-router-dom';
const Sidebar = () => {
    return (
        <div class="col-md-3 col-sm-3 col-0">
            <div class="side-navbar">
                <nav class="navbar">
                    <ul class="navbar-nav">
                        <li class="navbar-title text-center">
                            <a class="navbar-brand text-light1 " href="#" style={{padding: '12px 43px 12px 59px'}}>School of Engineering</a>
                        </li>
                        {/* <li className={`nav-item ${window.location.pathname === '/diploma_programs' || window.location.pathname === '/programsoffered' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/diploma_programs">Diploma Programs</Link>
                        </li> */}
                        <li className={`nav-item ${window.location.pathname === '/school_of_engineering' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/school_of_engineering">Overview</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/academic_calendar' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/academic_calendar">Academic Calendar</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/ug_regulations' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/ug_regulations">UG Regulations</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/pg-regulations' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/pg-regulations">PG Regulations</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/ug_program_curriculum' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/ug_program_curriculum">UG Program Curriculum</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/pg_program_curriculum' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/pg_program_curriculum">PG Program Curriculum</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/HBS' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/HBS"> Freshman Engineering</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/Civil' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/Civil"> Civil Engineering</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/EEE' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/EEE"> Electrical and Electronics Engineering</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/Mechanical' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/Mechanical"> Mechanical Engineering</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/ECE' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/ECE"> Electronics and Communication Engineering</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/CSE' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/CSE"> Computer Science and Engineering</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/IT' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/IT"> Information Technology</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/AIML' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/AIML"> Artificial Intelligence and Machine Learning</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/DS' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/DS"> Data Science</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/Petroleum' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/Petroleum"> Petroleum Technology</Link>
                        </li> 
                        <li className={`nav-item ${window.location.pathname === '/Mining' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/Mining"> Mining Engineering</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/Agricultural' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/Agricultural"> Agricultural Engineering</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/computer_applications' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/computer_applications"> Computer Applications</Link>
                        </li>

                        

                    <br/>
                        
                    </ul>
                </nav>
                    <div className="campus_sec1 row">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <Link to="/academics" className="more-about" ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg> Home </Link>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default Sidebar;