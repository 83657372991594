import React, { useState } from 'react';
import Header from '../../../../components/Header';
import FooterTwo from '../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../components/ScrollTop';
import "../../../../components/Styles/pragram.css";
import point from '../../../../assets/images/icons/image.png';
import SideManu from './sidebar_eng';
import Linkimage from './images/link.gif';
import Curriculumimage from './images/pg_curriculum/curriculum.png';
import MCApdf from './images/mca.pdf';
import CSE from './images/pg_curriculum/cse_curriculum.pdf';
import PED from './images/pg_curriculum/ped_curriculam.pdf';
import SE from './images/pg_curriculum/se_curriculam.pdf';
import TE from './images/pg_curriculum/te_urriculam.pdf';
import VLSD from './images/pg_curriculum/vlsd_curriculam.pdf';








const Engoverview = () => {
    const [activeIndex, setActiveIndex] = useState(0); // Initialize with 0 to make the first item open by default
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="School of Engineering" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <div class="about__content">
                                                        <div>
                                                            <button className={`accordion ${activeIndex === 0 ? 'active' : ''}`} onClick={() => toggleAccordion(0)}>
                                                            <img src={Curriculumimage} style={{height:'40px'}} />&nbsp;&nbsp;&nbsp;&nbsp;M.Tech
                                                                <span className="symbol">{activeIndex === 0 ? '▲' : '▼'}</span>
                                                            </button>
                                                            <div className="panel" style={{ display: activeIndex === 0 ? 'block' : 'none' }}>
                                                                <br/>
                                                                <table style={{width:"100%"}}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{textAlign:'center'}}>Program</th>
                                                                            <th style={{textAlign:'center'}}>2024</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr>
                                                                       <td>CSE (Computer Science and Engineering) </td>
                                                                       <td><a href={CSE} target="_blank" style={{ color: "#000" }} className='namehover'> Curriculum </a><img src={Linkimage} style={{ height: '25px' }} /></td>
                                                                   </tr>
                                                                   <tr>
                                                                       <td>PED (Power Electronics and Drives)</td>
                                                                       <td><a href={PED} target="_blank" style={{ color: "#000" }} className='namehover'> Curriculum </a><img src={Linkimage} style={{ height: '25px' }} /></td>
                                                                   </tr>
                                                                   <tr>
                                                                       <td>SE (Structural Engineering)</td>
                                                                       <td><a href={SE} target="_blank" style={{ color: "#000" }} className='namehover'> Curriculum </a><img src={Linkimage} style={{ height: '25px' }} /></td>
                                                                   </tr>
                                                                   <tr>
                                                                       <td>TE (Thermal Engineering)</td>
                                                                       <td><a href={TE} target="_blank" style={{ color: "#000" }} className='namehover'> Curriculum </a><img src={Linkimage} style={{ height: '25px' }} /></td>
                                                                   </tr>
                                                                   <tr>
                                                                       <td>VLSID (VLSI Design)</td>
                                                                       <td><a href={VLSD} target="_blank" style={{ color: "#000" }} className='namehover'> Curriculum </a><img src={Linkimage} style={{ height: '25px' }} /></td>
                                                                   </tr>
                                                                    </tbody>
                                                                </table>
                                                                <br/>
                                                            </div>

                                                            <button className={`accordion ${activeIndex === 1 ? 'active' : ''}`} onClick={() => toggleAccordion(1)}>
                                                            <img src={Curriculumimage} style={{height:'40px'}} />&nbsp;&nbsp;&nbsp;&nbsp;MCA
                                                                <span className="symbol">{activeIndex === 1 ? '▲' : '▼'}</span>
                                                            </button>
                                                            <div className="panel" style={{ display: activeIndex === 1 ? 'block' : 'none' }}>
                                                            <br/>
                                                                <table style={{width:"100%"}}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{textAlign:'center'}}>Program</th>
                                                                            <th style={{textAlign:'center'}}>2024</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Master of Computer Applications</td>
                                                                            <td><a href={MCApdf} target="_blank" style={{color:"#000"}} className='namehover'> Curriculum </a><img src={Linkimage} style={{height:'25px'}} /></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <br/>
                                                            </div>

                                                           
                                                            
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default Engoverview;