import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_mining';
import coming_soon from '../../../../../assets/images/icons/coming_soon.png';

import Banner from './banner';





const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                   {/* <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> Ph.D Program </b> */}
                                                    <div class="about__content">
                                                    <p className='name'>Our PhD program in Mining Engineering is designed to equip students with the advanced knowledge and skills necessary to address the complex challenges facing the global mining industry. This rigorous program focuses on cutting-edge research and innovation in various aspects of mining engineering, including, Mine Design and Planning, Rock Mechanics and Geotechnical Engineering, Mine Ventilation and Environmental Engineering, Mineral Processing, Mining Automation and Digitalization, Sustainable and Green Mining </p>
                                                    <p className='name'>Our faculty members are deeply involved in pioneering research initiatives within the field of mining engineering. This commitment provides our students with invaluable opportunities to work on cutting-edge projects, fostering their academic growth and professional development. Graduates of our PhD program are well-prepared for pursuing careers in academic/ research positions at institutions, contributing to policy development and regulation of the mining industry. Join our PhD program and become a leader in the future of mining engineering. </p>

                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;