import React from 'react';
import Testimonial from './testimonial';
import Service from './ServiceSection';



import Blog from './BlogSection';
import About from './AboutSection';
import Milestons from './milestons';

//  import Student_testimonials from '../students/opportunities/studenttesti_monials';

 import Milestons_page from './milestonsmainpage';



// import HomeSlider from './SliderSection';
import Campus from './CampusSection';
import Enquire from './Enquire';
import Popup from './popup';



// import Course from './CourseSection';
// import Event from './EventSection';
// import Placement from './placement';

import Counter from './CounterSection';
// import Testimonial from './TestimonialSection';
import ScrollToTop from '../../components/ScrollTop';
import AdmissionBtn from '../../components/AdmissionBtn/AdmissionBtn';

const HomeMain =() => {
		return (
			<>
				<div className="react-wrapper">
            		<div className="react-wrapper-inner">
						{/* SliderSection-start */}
						{/* <Popup /> */}
						{/* <AdmissionBtn/> */}
						{/* <Enquire /> */}
						{/* <HomeSlider /> */}
						{/* SliderSection-start */}

						{/* Service-area-start */}
						{/* <About /> */}
						{/* <Student_testimonials /> */}

						{/* <Milestons_page/> */}
						{/* <Placement/> */}
						

						<Testimonial/>
						<Service />
						{/* Service-area-end */}

						{/* About-area-start */}
						{/* About-area-end */}

						{/* Campus-area-start */}
						
						{/* Campus-area-end */}

						{/* Course-area-start */}
						{/* <Course /> */}
						{/* Course-area-end */}

						{/* Counter-area-start */}
						{/* <Counter /> */}
						{/* Counter-area-end */}

						{/* Event-area-start */}
						{/* <Event /> */}
						{/* Event-area-end */}

						{/* testmonial-area-start */}
						{/* <Testimonial /> */}
						{/* testmonial-area-end */}

						{/* blog-area-start */}
						{/* <Blog /> */}
						{/* blog-area-end */}

						{/* scrolltop-start */}
						<ScrollToTop
							scrollClassName="home react__up___scroll"
						/>
						{/* scrolltop-end */}
					</div>
				</div>

			</>
		);
	}

export default HomeMain;