import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import mineralizing7 from './images/mineralizing7.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">

                <StudyBreadcrumb pageTitle="Healthcare" />
                 <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}>A Container with a Treatment System for Ion Balancing and Mineralizing Water and Method Thereof</b> <br />
                                                    <b className='name'>Theme:</b>
                                                    <p className='name'>
                                                    An innovative work to develop a container with a low-cost water treatment system for rapid ion balancing and water mineralization
                                                    </p>
                                                    <b className='name'>Background:</b>
                                                    <p className='name'>
                                                    Filtering and purifying water to make it suitable for drinking has been an ongoing challenge throughout the world, particularly in developing countries. Sources of drinking water can be naturally contaminated, as by biological impurities, and/or contaminated by chemical and other non-naturally occurring sources..
                                                    </p>

                                                    <p className='name'>
                                                    In existing technology, a multi-stage water purification device is disclosed. The multi-stage water purification device includes an untreated water compartment having an untreated water inlet and a treated water compartment formed from a resilient material. The compartments are segregated from one another. An outlet is in flow communication with the treated water compartment. A first unidirectional valve is disposed between the treated water compartment and the outlet and is configured to permit flow out of the treated water compartment
                                                    </p>

                                                    <b className='name'>Our Invention</b>
                                                    <p className='name'>
                                                    In order to overcome the above deficiencies, the present disclosure is to solve the technical problem to provide a container with a low-cost water treatment system for rapid ion balancing and water mineralization. In the first step, the consumable water is received and stored in a water storage chamber. In the next step, a PH meter is used to detect a PH value of the consumable water to know the acidic or basic nature of the consumable water. In the next step, a control unit may be configured to operate the a number of valves of either a negative ion generating unit or a positive ions generating unit based on the PH values of the consumable water
                                                    </p>
                                                    <p className='name'>
                                                    Later, in the next step, an ion-balancing system may be configured to neutralise the consumable water in the water storage chamber either by supplying negative ions or positive ions based on the PH values. In the next step, the filtering system may be configured to filter the neutralized water by utilizing a several filter layers. A water treated chamber may beconfigured to receive the neutralized water through several layers of filters from the water storage chamber. In the final step, a mineralizing system may be configured to balance the filtered water by mixing minerals in the storage chamber to obtain mineralized water for user consumption.
                                                    </p>
                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>

                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Household application </span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Improved Drinking Water Quality </span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Health Benefits</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Clinical Applications</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Enhanced Customer Experience </span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Sustainability</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> Workplace and Offices</span><br />
                                                    </p>
                                                    <b className='name'>IP Status </b>
                                                    <p className='name'>
                                                    The invention is the subject of a Granted Patent 498201 IP India.
                                                    </p>
                                                    <p className='name'>
                                                    Application No. 202341004501 IP India
                                                    </p>
                                                    <b className='name'>Inventors </b>
                                                    <p className='name'>
                                                    P Srihari Datta Bhima Raju
                                                    </p>
                                                    <center>
                                                        <img src={mineralizing7} /><br></br>
                                                        <b className='name'>Schematic view of a container with a treatment system  </b>
                                                    </center>
                                                    <br />
                                                    <b className='name'>Contact us</b>
                                                    <p className='name'>
                                                    If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at:<b>dean_rc@adityauniversity.in</b> 
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;