import React from 'react';
import Slider from 'react-slick';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import '../../../components/Styles/pragram.css';

import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
import PlacementSlider from './placement_slider';


import SideManu from './sidebar_opportunities';

const DiplomaPrograms = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div className="react-wrapper">
                <div className="react-wrapper-inner">
                <PlacementSlider />
                    {/* <StudyBreadcrumb pageTitle="Placement Policy" /> */}

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div className="research">
                                    <div className="container">
                                        <div className="row m-0">
                                            <SideManu />
                                            <div className="col-md-1 col-sm-1 col-2"></div>
                                            <div className="col-md-8 col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div className="container">
                                                    <center>
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}>Students Testimonials:</b>
                                                        <br /><br />
                                                    </center>
                                                    <div className="scrolling-container">
                                                        <Slider {...settings}>
                                                            <div>
                                                                <iframe src="https://www.youtube.com/embed/O9B8MEUW7cM" allowFullScreen title="Video 1"></iframe>
                                                            </div>
                                                            {/* <div>
                                                                <iframe src="https://www.youtube.com/embed/5ToTnkUljj0" allowFullScreen title="Video 2"></iframe>
                                                            </div>
                                                            <div>
                                                                <iframe src="https://www.youtube.com/embed/eru0cvUNOPo" allowFullScreen title="Video 3"></iframe>
                                                            </div> */}
                                                            <div>
                                                                <iframe src="https://www.youtube.com/embed/ocfXp3nY8RU" allowFullScreen title="Video 4"></iframe>
                                                            </div>
                                                            <div>
                                                                <iframe src="https://www.youtube.com/embed/2Cd13B4d9rk" allowFullScreen title="Video 4"></iframe>
                                                            </div>
                                                            <div>
                                                                <iframe src="https://www.youtube.com/embed/NbmJCjQo7-Y" allowFullScreen title="Video 4"></iframe>
                                                            </div>
                                                        </Slider>
                                                    </div>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />
        </>
    );
}

export default DiplomaPrograms;
