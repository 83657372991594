import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';
import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
import SideManu from '../sidebar_international';

const Eligibility = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Visa Assistance" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div class="container text-justify">
                                                    <br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Visa Assistance</b> <br />
                                                    <p style={{ textAlign: "justify" }}>
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  Foreign Nationals desirous of coming into India are required to possess a valid passport of their country and a valid Indian Visa. The only exceptions are nationals of Nepal and Bhutan who can enter India without a visa.(Note: Nepalese nationals need a visa if entering India from China). </span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  To study in India it is mandatory for an international student to procure an Indian Student Visa.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  Visas can be applied for in person or by post (in certain High Commissions/Embassies, it is responsibility of the student to check with particular embassy on postal visa application) to the High Commission of India based in the country from where the candidate intends to depart for India.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  Applicant need to mention the name of the Institute while applying for the visa, which is then mentioned on the visa.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  International students taking admission at Aditya Educational Institute should make sure that the Student Visa is endorsed to Aditya Educational Institute by the visa issuing authority.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  A request for change of Institute or institution subsequently made cannot be considered. In this case you would be required to go back to your home country and apply for a new visa.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  Also, if you have entered India on basis of documents provided by the Institute then it is your responsibility to ensure that you directly join and report to the Institute. The Visa endorsed on the name of the Institute or obtained on the basis of Institute documents cannot be used for any other purpose like employment , admission to any other Institute/college/institute/academy etc or for non-regular (distance/online) mode of education.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  After reaching the Institute students have to get the visa verified by the Institute and have to deposit a copy of the valid visa.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  It is the responsibility of the student to ensure that throughout his/her study period student is on valid visa.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  In case the initial visa is not endorsed for the complete duration of the programme or student has to extend the stay because he/she is not able to complete the programme in the stipulated time then the student should apply for the extension of the visa before visa expires.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  It is the responsibility of the student to ensure that visa should be applied well in advance and time. It generally takes 3-8 weeks to get the Indian Visa thus it is advisable to apply for the visa accordingly and consult the Indian High Commission/Embassy if required.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  In case a student reports to the Institute late because of the Visa/Tickets Availability or any other personal issue, no relaxation related to attendance or any favour for completing the assignment, submitting the missed assignments, sitting for the missed examination(s), relaxation in the marks etc. will be given.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  Students joining the Institute after the prescribed time period may be denied admission. In such cases the fees etc. deposited by the applicant would be forfeited and further no claim for the expense made for the travelling, visa or any other expense made or hardship suffered by the student will be entertained.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  Even, after joining the Institute if the student leaves the country then it is the responsibility of the student to report back at the Institute on time. It some cases where visa extension is required it is the responsibility of the student to ensure to get the valid visa extension on time. In case student reports to the Institute late because of the Visa/Tickets Availability or any other personal issue then student will not be provided any relaxation related to attendance, extra time to complete the assignments, opportunity to submit the missed assignments, opportunity to sit for the missed examination(s), relaxation in the marks etc.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  In case student is expelled from the Institute or the student decides to leave the programme then an affidavit has to be submitted to the Institute that he/she will leave India immediately and will not use visa issued on the basis of AEI documents to stay in India.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  Institute may debar the student to continue attending classes or staying in the Institute residential facility if student does not maintain a valid visa/ FRRO or does not follow any guidelines issued by the Govt. of India/ Govt. of Andhra/Institute/ any other authority from time to time.</span><br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />  For loss of attendance/ academics or any expenditure so occurred because of such action by the Institute, the student himself will be responsible and no relaxation or benefit may be provided in such cases and all expenditure has to be born by the student.</span><br />

                                                    </p>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            </div>
            <FooterTwo />
        </>
    );
}

export default Eligibility;