import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_eee';
import Banner from './banner';
// import EEEPopUp from "../../../../../components/Popup/EEE/EEEPopUp";






const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    {/* <EEEPopUp /> */}
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  B-Tech </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>The UG programme Electrical and Electronics Engineering (EEE) lays emphasis on strong theoretical background on electrical engineering concepts in addition to providing avenues for divergent applications related to electrical energy, innovation in its generation, transmission, distribution, renewable resources, and efficient devices.</p>

                                                        <p className='name'>The curriculum of the programme is formulated in close collaboration with leading experts from industries to ensure effective bridging of the gap between industry and academia and in addition to improve the quality of life, support economic growth, and address societal problems. The curriculum facilitates minor streams including L&T and AI related courses. The course provides the students with the essential hands-on experience to tackle real-time industry related challenges. </p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} /> B.Tech - Electrical and Electronics Engineering <br />
                                                            <img src={point} style={{ height: "25px" }} /> B.Tech - Electrical and Electronics Engineering with minor Specialization in
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Intelligent and Integrated Power Systems.  <br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Power Electronics and Energy Systems.  <br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Advanced Specialization on Electric Vehicles (Electrical)<b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T).</b> <br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Design of Electrical System for Smart Buildings  <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T).</b> <br />
                                                        </p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} /> B.Tech - Electrical and Electronics Engineering with
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Civil  Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Mechanical Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Electronics and Communication Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Computer Science and Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Data Science<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Artificial Intelligence and Machine Learning<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Petroleum Technology<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Mining Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Agricultural Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Entrepreneurship Development and Incubation<br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;