import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';
import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
import SideManu from '../sidebar_international';
import afghanistan from './images/afghanistan.png';
import India from './images/india.png';
import Bank_details from './images/bank_details.png';




import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const Eligibility = (props) => {
    let tab1 = "SAARC  Tuition & Accommodation Fees",
        tab2 = "International  Tuition & Accommodation Fees "
    const tabStyle = 'nav nav-tabs';


    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div className="back__course__page_grid react-courses__single-page pb---16 pt---110">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Fee Particulars" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div class="container text-justify">
                                                    <br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Fee Particulars</b> <br />
                                                    <Tabs>
                                                        <div className="course-single-tab">
                                                            <TabList className={tabStyle}>
                                                                <Tab><button>{tab1}</button></Tab>
                                                                <Tab><button>{tab2}</button></Tab>

                                                            </TabList>

                                                            <div>
                                                                <TabPanel>
                                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Students Tuition & Accommodation Fees</b> <br />
                                                                    <p style={{ textAlign: "justify" }}>
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> For details about tuition fees and accommodation fees click the below link</span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> SAARC Countries Students Tuition & Accommodation Fees</span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> SAARC Countries </span><br />
                                                                        <p class="name" style={{ padding: '14px'}}>
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> India <img src={India} style={{ height: "25px" }} /></span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Afghanistan </span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Bangladesh</span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Bhutan </span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Maldives </span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Nepal </span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Pakistan </span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Sri Lanka </span><br />
                                                                        </p>
                                                                    </p>
                                                                    <br />
                                                                </TabPanel>
                                                                <TabPanel>
                                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}> International Students Tuition And Accommodation Fees</b> <br />
                                                                    <p style={{ textAlign: "justify" }}>
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> For details about tuition fees and accommodation fees click the below link</span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} />International Students Tuition And Accommodation Fees</span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> ICICI Bank Details </span><br />
                                                                        <p class="name" style={{ padding: '14px'}}>
                                                                            
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> BANK NAME: ICICI BANK </span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> A/C HOLDER NAME:  ADITYA ACADEMY</span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> A/C NO: 067801000112 </span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> IFSC CODE: ICIC0000678 </span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> BRANCH CODE: 000678 </span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> SWIFT CODE: ICICINBBCTS </span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> MICR CODE: 534229522 </span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> ADDRESS: PEDDARPUAM, EAST GODAVARI DISTRICT, ANDHRA PRADESH. </span><br />
                                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} />To download the ICICI Bank Details <a href="../uploads/bank_details.png" download> <b style={{color:"red"}}> Click here</b></a></span>

                                                                        </p>
                                                                    </p>
                                                                    <br />

                                                                </TabPanel>

                                                            </div>
                                                        </div>
                                                    </Tabs>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            </div>
            <FooterTwo />
        </>
    );
}

export default Eligibility;