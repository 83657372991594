import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import '../../../components/Styles/pragram.css';
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_opportunities';
import T_connect_image from './images/t_connect.png';
import Driveready from './images/driveready.png';
import skillup from './images/skillup.png';
import becomecoder from './images/becomecoder.png';
import ignitecoder from './images/ignitecoder.png';
import owlcoder from './images/owlcoder.png';
import projectspace from './images/projectspace.png';
import internship from './images/internship.png';
import grow from './images/grow.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Trainings" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> Trainings :</b> <br />
                                                    <p className='name'>
                                                        Enhance your skills with our diverse training programs. We offer certification courses and hands-on sessions that cover both technical and soft skills, preparing you to meet the demands of today’s dynamic job market.<br />

                                                        Our comprehensive training programs are designed to equip students with the skills and knowledge required to excel in various competitive recruitment tests such as Campus Recruitment Training (CRT), Co-Cubes, AMCAT and eLitmus. These programs include intensive modules on quantitative aptitude, logical reasoning, verbal ability and domain-specific technical skills. Through a blend of theoretical instruction and practical exercises, students are prepared to face the challenges of these tests confidently. Our curriculum is regularly updated to reflect the latest trends and patterns in these assessments, ensuring that our students have a competitive edge. Additionally, we provide mock tests and personalized feedback to help students identify and improve their weak areas, enhancing their overall performance in actual recruitment processes.<br />

                                                        we offer a wide range of industry recognized global certifications. Our training programs are curated keeping various certification in mind and are led by our team of certified trainers. Our programs are designed to here to help you get certified in the most efficient and educative way possible. We offer some of the most popular certifications from vendors like Cisco, Red hat, Amazon, Microsoft, Oracle and Palo alto.
                                                    </p>
                                                    <div class="row col-md-12">
                                                        <div class="col-md-4  col-sm-4 col-4">
                                                        <Link className="nav-link" to="https://technicalhub.io/"><img src={T_connect_image} /> </Link>
                                                        </div>
                                                        <div class="col-md-8  col-sm-8 col-8">
                                                            <p className='name'>
                                                                The program is exclusively organized to the FRESHMEN to delve into the cutting - edge technologies like AWS cloud computing, coding, AI, AR, VR, Robotics and many more. They learn about their significance and opportunities and allow them to choose their career path
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div class="row col-md-12">
                                                        <div class="col-md-4  col-sm-4 col-4">
                                                        <Link className="nav-link" to="https://technicalhub.io/"> <img src={Driveready} /> </Link>
                                                        </div>
                                                        <div class="col-md-8  col-sm-8 col-8">
                                                            <p className='name'>
                                                            Drive Ready is a program offering attendees a choice of technology and coding language training aligned with a Globally recognized certification. With a hybrid training approach, it prepares them to excel in interviews and offers Job – Specific skill development to kick start a strong career in the industry.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div class="row col-md-12">
                                                        <div class="col-md-4  col-sm-4 col-4">
                                                        <Link className="nav-link" to="https://technicalhub.io/"><img src={skillup} /></Link>
                                                        </div>
                                                        <div class="col-md-8  col-sm-8 col-8">
                                                            <p className='name'>
                                                            Skill up coder is designed to bridge the gap between basic and advanced programming skills. They learn to write more complex syntax, tackle real-world problem-solving challenges which help the trainees gain better knowledge
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div class="row col-md-12">
                                                        <div class="col-md-4  col-sm-4 col-4">
                                                        <Link className="nav-link" to="https://technicalhub.io/"><img src={becomecoder} style={{marginTop:"-12px"}}/> </Link>
                                                        </div>
                                                        <div class="col-md-8  col-sm-8 col-8">
                                                            <p className='name'>
                                                            The program is exclusively tailored to the trainees who have mastered basic syntax and can progress to advanced logic training and can work effectively on intermediate level of DATA STRUCTURES and ALGORITHMS
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row col-md-12">
                                                        <div style={{marginTop:"-60px",}}  class="col-md-4  col-sm-4 col-4">
                                                        <Link className="nav-link" to="https://technicalhub.io/"> <img src={ignitecoder} /> </Link>
                                                        </div>
                                                        <div class="col-md-8  col-sm-8 col-8">
                                                            <p className='name'>
                                                            Ignite coder is a program tailored to assist freshmen in grasping the foundational concepts of programming and becoming familiar with the programming languages such as C, JAVA, PYTHON, Web Technologies and fundamentals of Data Structures
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row col-md-12">
                                                        <div class="col-md-4  col-sm-4 col-4">
                                                        <Link className="nav-link" to="https://technicalhub.io/">  <img src={owlcoder} /> </Link>
                                                        </div>
                                                        <div class="col-md-8  col-sm-8 col-8">
                                                            <p className='name'>
                                                            The Owl Coder program is intricately designed to conform to industry expectations and standards. It focuses on refining advanced logical skills, delivering thorough training in project-specific tools, promoting active involvement in open hackathons, and providing avenues to contribute to real-world AI projects. Developed by an industry expert and instructed by a competitive coder, this program offers a comprehensive approach to skill development and practical experience in the field for 60 days.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br/><br/>
                                                    <div class="row col-md-12">
                                                        <div style={{marginTop:"-30px",}} class="col-md-4  col-sm-4 col-4">
                                                        <Link className="nav-link" to="https://technicalhub.io/"><img style={{height:"190px",width:"200px"}} src={projectspace} /> </Link>
                                                        </div>
                                                        <div class="col-md-8  col-sm-8 col-8 mb-5">
                                                            <p className='name'>
                                                            Project space is a feature rich environment designed to encourage individuals to develop and showcase real time problems and further find effective solutions using the technology they have learnt. This helps every individual moving closing to their dream job
                                                            </p>
                                                        </div>
                                                    </div> <br/><br/>
                                                    <div class="row col-md-12">
                                                        <div class="col-md-4  col-sm-4 col-4">
                                                        <Link className="nav-link" to="https://technicalhub.io/">  <img src={internship} /> </Link>
                                                        </div>
                                                        <div class="col-md-8  col-sm-8 col-8">
                                                            
                                                            <p className='name'>
                                                            Intern with us on campus, learn from senior team member, gain mentorship skills and a stipend. They also earn the certificate and offer them full time roles based on the performance
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br /><br />
                                                    <div class="row col-md-12">
                                                        <div style={{marginTop:"-40px"}} class="col-md-4  col-sm-4 col-4 mb-5">
                                                        <Link className="nav-link" to="https://technicalhub.io/"> <img src={grow} /> </Link>
                                                        </div>
                                                        <div class="col-md-8  col-sm-8 col-8">
                                                            <p className='name'>
                                                            GROW is a focused initiative designed to support and empower women pursuing careers in programming. It ensures that women have equal access to opportunities and can thrive in this dynamic and innovative industry
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <center> <b  className='name'>Technologies Offered</b></center>
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Generative AI</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Pega</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> VLSI (Very Large Scale Integration)</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Multi-cloud computing</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Embedded Systems</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> AWS Cloud DevOps</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Azure Cloud DevOps</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Google Cloud DevOps</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Full Stack Development</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> React Native</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> AWS Cloud Developing</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Google Flutter</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Cybersecurity</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Salesforce Administrator</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Cisco Networking</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Gaming with AR</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> UI/UX</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Robotic Process Automation</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Oracle APEX</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Redhat Linux System Administration</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Internet Of Things (IoT)</span><br />
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;