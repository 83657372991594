import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import '../../../components/Styles/pragram.css';
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_opportunities';
// import Higher_education from './images/Higher_education.jpg';
import Higher_educationSlider from './higher_education_slider';






const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                  {/* <Higher_education pageTitle="Higher Education" /> */}
                  <Higher_educationSlider />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}> Higher Education :</b> <br />
                                                     <p className='name'>
                                                     Higher education is a critical step for those looking to deepen their expertise, pursue specialized fields and enhance their career prospects. Our Career Development Centre is dedicated to guiding students through every aspect of this journey, from selecting the right programs to navigating the application process. We provide comprehensive resources and personalized counselling to help students identify the best-fit institutions and courses that align with their academic interests and professional aspirations. Additionally, our partnerships with top universities and colleges worldwide offer students access to exclusive information sessions, scholarship opportunities and application workshops. By supporting our students in their pursuit of higher education, we aim to empower them to achieve academic excellence and secure a competitive edge in their chosen careers.
                                                    </p>
                                                   
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;