import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_mba';



const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Master of Business Administration" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Overview </b> </center><br />
                                                    <div class="about__content">

                                                        <p className='name'>The Department of Management Studies at Aditya University is dedicated to fostering innovative thinking by challenging traditional business and management practices. Our curriculum emphasizes a holistic learning experience through a dynamic blend of methodologies, including a case study approach, the LPA (Learn, Practice, Apply) methodology, and activity-based learning (ABL), which encourage students to engage deeply with concepts and apply them in real-world contexts. Industrial visits, guest lectures, and seminars expose students to industry insights, while business fests and community initiatives build leadership, teamwork, and social responsibility. Interactive sessions with entrepreneurs and business professionals provide valuable perspectives on contemporary entrepreneurship, innovation, and practical business experience, equipping students for success in today’s evolving business landscape.
                                                        </p>
                                                        <p className='name'>The committed faculty members are highly qualified and experienced professionals dedicated to academic excellence and student success. With diverse expertise across various management domains, they bring a blend of industry knowledge and academic rigor to the classroom.
                                                        </p>
                                                        <p className='name'>Partnerships with industry leaders like SAP-ABAP and Pearson-Mepro provide students with access to cutting-edge resources and opportunities for professional development.
                                                        </p>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop />
        </div >
            <FooterTwo />

        </>
    );
}

export default CEview;