import React from "react";
import "./admission.css"; // Ensure this file contains styles for the testimonials
import SectionTitle from "../../components/SectionTitle";
import Testimonials_image from "./images/admissioimages/testimonials_new1.png";

const TestimonialCard = ({ image, name, designation, title, description }) => {
    return (
        <div className="card-admission">
            
               
             <div className="card-header-admission">
                <img src={image} alt={name} />
             <div>
                <h4>{name} <img src={Testimonials_image} className="Testimonials_image" /></h4>
                <hr className="linename"/> 
                <p>{designation}</p>
                
                </div>
            </div>
            <div className="card-body-admission">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </div>
    );
};

const Testimonials = () => {
    const testimonials = [
        {
            image:
                "https://adityauniversity.in/static/media/20A91A05A2MSARATHCHANDRA_CSE.42571fb6a1f406796a25.jpg",
            name: "M Sarath Chandra, CSE",
            designation: "Student",
            title: "My Gateway to a Successful Career",
            description: `The greatest success for an engineering student would be to get an opportunity to kick start their career in a reputed organization with a good package. This I was able to find in Aditya which has given me the best by driving out the best from me. Aditya made me to take life seriously and challengingly in the campus. It trained me to be always in win-win situation and helped me get a job in MNC, CONTROL’s with a huge package. The vast hands-on experience and enriching helped me bag this job. Thank you Aditya for shaping me and my skills in both academic and non-academic activities.`,
        },
        {
            image:
                "https://adityauniversity.in/static/media/20A91A0429MDURGAPRASANNA_ECE.3958de235e3d1a30bb5e.jpg",
            name: "M Durga Prasanna, ECE",
            designation: "Student",
            title: "A Supportive Community That Nurtured My Growth",
            description: `Life in Aditya is cozy and the warmth of the management, faculty and other staff makes me and my parents to be a part of this good college. The superiors take utmost care to hone the skills of students in all aspects. Administration is great because we have all the activities for the students. It was my immense luck and fortune to be the part of this abode of learning. It helped me to grow. The entire faculty and department leaves no stone unturned to shape one’s future.`,
        },
        {
            image:
                "https://adityauniversity.in/static/media/20A91A2712MSRIDURGAKALYAN_PT.581d9b097df233d5e3e6.jpg",
            name: "M Sri Durga Kalyan, PT",
            designation: "Student",
            title: "From Classroom to Real-World Impact",
            description: `A conducive environment marked my learning at Aditya college. A fine balance of academics and co-curricular activities made me to rethink about my future fostering my ideas to reality. A great support rendered by the teaching fraternity leveraged my skills to prove my talent researching fossil fuels to bio fuels.`,
        },
    ];

    return (
        <div className="ptt---100 pb---70" style={{backgroundColor:'rgb(210 212 217)'}} >
            <section className="container " >
               <div className="row" >
                {/* <SectionTitle Title="Students Testimonials" /> */}
                <span className="titlename"> Students <span style={{color:'#f05718'}}>Testimonials</span> </span>
                <div className="testimonials-admission ">
                    {testimonials.map((testimonial, index) => (
                        <TestimonialCard key={index} {...testimonial} />
                    ))}
                </div>
        </div>
            </section> 
        </div>
    );
};

export default Testimonials;
