import React from 'react';
import { Link } from 'react-router-dom';
const Sidebar = () => {
    return (
        <div class="col-md-3 col-sm-3 col-0">
            <div class="side-navbar">
                <nav class="navbar">
                    <ul class="navbar-nav">
                        <li class="navbar-title text-center">
                            <a class="navbar-brand text-light " href="#">Programs offered</a>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/diploma_programs' || window.location.pathname === '/programsoffered' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/diploma_programs">Diploma Programs</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/programsoffered_ug' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/programsoffered_ug"> UG Programs</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/programsoffered_pg' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/programsoffered_pg"> PG Programs</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/phd_programs' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/phd_programs"> Ph.D Programs</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/corporate_programs' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/corporate_programs"> Corporate PG Diploma Programs</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Sidebar;