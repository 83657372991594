import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_it';
import Banner from './banner';




const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        
                                                        <p className='name'>The Information Technology (IT) Department at Aditya University is dedicated to producing highly skilled IT professionals equipped with the latest technological knowledge, problem-solving skills, and a professional and ethical attitude. Established in 2001 with an initial intake of 60 students, the department now offers comprehensive undergraduate and postgraduate programs designed to meet the evolving needs of the IT industry. 
                                                        </p>
                                                        <p className='name'>The faculty in the IT Department comprises experienced professionals with strong academic background and industry expertise. They are actively involved in research across various domains such as artificial intelligence, machine learning, data science, cybersecurity, and cloud computing. Faculty members publish their research findings in prestigious journals and participate in international conferences, contributing to the advancement of IT knowledge and practices.
                                                        </p>
                                                        <p className='name'>To provide a solid foundation in core IT concepts while allowing students to specialize in areas of interest, the department is equipped with state-of-the-art labs featuring the latest hardware and software. Facilities include networking labs, data science and big data labs, cybersecurity labs, and software development and testing labs, facilitating hands-on learning and experimentation.
                                                        </p>
                                                        <p className='name'>The department places a strong emphasis on industry collaboration to bridge the gap between academic learning and practical application. Strategic partnerships and MOUs with leading IT companies enable students to gain real-world experience through internships and industrial training programs. These opportunities provide valuable exposure to industry practices and emerging technologies.
                                                        </p>
                                                        <br />
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  VISION : </b> <br />

                                                        <p className='name'>To emerge as a premier department with quality of education, technical competency and innovations</p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> MISSION:</b><br />
                                                        <p className='name'>
                                                            M1: Provide an academic environment with quality infrastructure for solving real world problems and work in multi-disciplinary teams.<br />
                                                            M2: Impart value-based education in innovative research and leadership aspects.<br />
                                                            M3: Collaborate with the industry and academia towards addressing the evolving changes in Information Technology and related areas.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;