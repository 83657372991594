import React from 'react';
import { Link } from 'react-router-dom';

import Banner from '../../../../../assets/images/department_images/AIML.jpg'

const StudyBreadcrumb = (props) => {
	const { pageTitle } = props;
	console.log(pageTitle)

	return (
		<div className="react-breadcrumbs">
			<div className="breadcrumbs-wrap">
				<img className="desktop" src={Banner} alt="Breadcrumbs" />
				<img className="mobile" src={Banner} alt="Breadcrumbs" />
			</div>                
		</div>
	);
}

export default StudyBreadcrumb;




