import React, { useEffect } from 'react'; // Added useEffect import
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';
import "../../../../../components/Styles/pragram.css";
import point from '../../../../../assets/images/icons/image.png';
import SideManu from './sidebar_mca';
import Banner from './banner';

const CEview = () => {
    useEffect(() => {
        // Function to disable right-click
        const handleContextMenu = (event) => {
            event.preventDefault(); // Prevent the default context menu from appearing
        };

        // Attach the event listener
        window.addEventListener('contextmenu', handleContextMenu);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []); // Empty dependency array ensures this runs only on mount and unmount

    return (
        <>
            <Header parentMenu='page' menuCategoryEnable='enable' />
            <div className="react-wrapper">
                <div className="react-wrapper-inner">
                    <Banner />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div className="research">
                                    <div className="container">
                                        <div className="row m-0">
                                            <SideManu />
                                            <div className="col-md-1 col-sm-1 col-2"></div>
                                            <div className="col-md-8 col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div className="container">
                                                    <center>
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}>
                                                            <img src={point} alt="Overview Icon" style={{ height: "25px" }} />  Overview
                                                        </b>
                                                    </center>
                                                    <br />
                                                    <div className="about__content">
                                                        <p className='name'>
                                                        The Master of Computer Applications (MCA) program at Aditya University is dedicated to producing highly skilled IT professionals equipped with advanced technological knowledge, problem-solving abilities, and a strong ethical foundation. The program has evolved to meet the dynamic demands of the IT industry through comprehensive coursework and practical training. The Department of Computer Applications boasts a team of experienced faculty members with strong academic credentials and industry expertise. They are actively engaged in research across various domains, including artificial intelligence, machine learning, data science, cyber security, and cloud computing. Faculty members regularly publish their findings in prestigious journals and participate in international conferences, significantly contributing to the advancement of IT knowledge and practices. To ensure a solid grounding in core IT concepts while allowing students to specialize in their areas of interest, the MCA program is equipped with state-of-the-art laboratories featuring the latest hardware and software. Facilities include networking labs, data science and big data labs, cyber security labs, and software development and testing labs, all designed to facilitate hands-on learning and experimentation. The program emphasizes collaboration with industry to bridge the gap between academic learning and practical application. Strategic partnerships and Memoranda of Understanding (MOUs) with leading IT companies provide students with valuable real-world experience through internships and industrial training programs, exposing them to industry practices and emerging technologies.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />
        </>
    );
}

export default CEview;
