import React from 'react';
import point from '../../assets/images/icons/image.png';


const VisionMissionPart = () => {

    return (
        <div class="  p-relative pb---60" style={{ marginTop: "40px" }}>
            <div class="container">
                <div class="about__content">
                    <div>
                        <h3 class="about2__area about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s"><b>Vision</b></h3>
                        <div class="degree-list-container">
                            <ul class="degree-list">
                                <li><span class="icon"><img className='pointimage' src={point} /></span><span>Aditya University aspires to be a globally recognised academic institution dedicated to quality education, cutting-edge research, and technological service to our country, and envisions itself as a beacon of holistic advancement and long-term impact, remaining dynamic in the ever-changing worlds of society, ecology, and economics..</span></li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <h3 class="about2__area about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s"><b>Mission</b></h3>
                        <div class="degree-list-container">
                            <ul class="degree-list">
                                <li><span class="icon"><img className='pointimage' src={point} /></span><span>Aditya University pushes boundaries to design high-quality curricula and to provide students with a vibrant and relevant education that prepares them for a changing world. Our industry insights and creative teaching methods attempt to equip our students to be lifelong learners.</span></li>
                                <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Aditya University's learning environment encourages intellectual curiosity, critical thinking, and cooperation, with the goal of providing students with an immersive education that fosters creativity and innovation. Our cutting-edge facilities, interactive classrooms, and supportive faculty aim to motivate students to realise their full potential and contribute to society. </span></li>
                                <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Aditya University promotes cross-disciplinary inquiry and discovery and leads cutting-edge research and innovation. Through strategic partnerships, research grants, and a dedicated faculty, we aim to advance science, technology, and social sciences and empower students and faculty to conduct transformative research that solves real-world problems and elevates our institution globally. </span></li>
                                <li><span class="icon"><img className='pointimage' src={point} /></span><span>  Aditya University is committed to producing world-changing business leaders and entrepreneurs through its emphasis on entrepreneurship, mentoring, and business incubation programmes.</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default VisionMissionPart;