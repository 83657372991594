import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import clutch5 from './images/clutch5.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                    <StudyBreadcrumb pageTitle="Automobile Engineering" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                            <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>A Clutch Restriction System</b> <br />
                                                    <b className='name'>Theme:</b>
                                                     <p className='name'>
                                                     An innovative work to restrict the movement of a two-wheeler when supporting stand is in downward direction. More particularly, present invention, provides a clutch restriction system, which does not allow the movement of clutch and thereby, avoiding movement of two-wheeler, when the supporting stand is in downward direction.
                                                    </p>
                                                    <b className='name'>Background:</b>
                                                     <p className='name'>
                                                     Motorized two wheelers are generally provided with a supporting stand on its side, attached pivotally to the wheeler. The term supporting stand refers to all the kind of arrangements such as a rod, bar on one side or arranged to be on both the sides of the two-wheeler vehicle. The side stand is swung upwardly so that it does not interfere during the running of the two-wheeler and is moved downwardly to rest the two-wheeler.
                                                    </p>
                                                    
                                                     <p className='name'>
                                                     The two-wheeler rests upon the supporting stand, while not in motion. If a two-wheeler is started, with its side stand remaining upright, the side stand tends to impinge on the road surface, particularly when the two-wheeler is turned to the side of the side stand, thus leading to the overturning of the motorcycle and leading to skidding of bike. This invention relates to a device for controlling the movement of the clutch, if the supporting stand is in a downward position, so as to avoid the motion of the two-wheeler.
                                                    </p>
                                                    
                                                    <b className='name'>Our Invention</b>
                                                    <p className='name'>
                                                    According to an embodiment of the invention, provided a clutch restriction system to avoid the pressing of clutch, when a two wheeler supporting stand is in downward direction, said system comprises a cord connected between a clutch lever and supporting stand; wherein the length of the cord is just enough to tighten the clutch lever when supporting stand is in downward direction and allowing the clutch lever to operate when the supporting stand is in upward direction.
                                                    </p>
                                                    

                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>
                                                   
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Automobile Industry </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Manual Transmission Assistance </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Aerospace and Aviation</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Marine Applications</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Robotics and Automation </span><br />
                                                    
                                                    </p>
                                                    <b className='name'>IP Status </b>
                                                    <p className='name'>
                                                    The invention is the subject of a Granted Patent No. 493844 IP India   
                                                    </p>
                                                    <p className='name'>
                                                    Application No. 201941000382
                                                    </p>
                                                    <b className='name'>Inventors </b>
                                                    <p className='name'>
                                                    Dr. P. S. Ranjit
                                                    </p>
                                                    <p className='name'>
                                                    Dr V V Kamesh
                                                    </p>
                                                    <p className='name'>
                                                    Dr M Sreenivasa Reddy
                                                    </p>
                                                    <p className='name'>
                                                    Mr. D.V.S.S.Bhimesh Reddy
                                                    </p>
                                                    <p className='name'>
                                                    Mr. V S N Ch Dattu
                                                    </p>
                                                    <p className='name'>
                                                    Mr K L Kishore
                                                    </p>
                                                    <p className='name'>
                                                    Dr. K.V.S.R.Murthy
                                                    </p>

                                                    <center>
                                                        <img src={clutch5} /><br></br>
                                                        <b className='name'>Clutch Restriction System  </b>
                                                    </center>
                                                    <br/>

                                                    <b className='name'>Contact us</b>
                                                     <p className='name'>
                                                     If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at  :<b>dean_rc@adityauniversity.in</b>                                                      
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;