import React from 'react';
import { Link } from 'react-router-dom';

import aboutImg from '../../assets/images/about/ab.jpg'
import shapeImg from '../../assets/images/about/badge.png'

const AboutPart = () => {

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img src={aboutImg} alt="About" />
                            {/* <img className="react__shape__ab" src={shapeImg} alt="Shape Image" /> */}
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">ADITYA UNIVERSITY <br /> <em>Profile</em></h2>
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">Aditya University is a State Private University formed under the Andhra Pradesh Private Universities Act, 2016. It has evolved from the well-established Aditya Engineering College in Surampalem, Kakinada District, Andhra Pradesh. Aditya University is committed to provide quality higher education with global standards. Programs are well crafted to blend academic rigor with practical relevance, equipping students to effectively address both societal and industrial challenges. Experienced and learned teachers encourage intellectual curiosity, critical thinking, and cooperation among the diverse student community in an inclusive manner to realise their full potential and contribute to society. The memorandum of understanding with foreign universities usher a new era of international academic excellence, fostering a vibrant, globally engaged educational community at Aditya University leading to joint degree certifications and joint research programmes. Industry collaborations build a synergistic relationship for internship opportunities, project-based learning, and innovative research. </p>
                            {/* <p className="about__paragraph2 wow animate__fadeInUp" data-wow-duration="0.7s"> Have questions?  <Link to="#"> Get Free Guide </Link></p> */}
                            {/* <p className="wow animate__fadeInUp" data-wow-duration="0.9s">Education also refers to the knowledge received through schooling instruction <br/>and to the institution of teaching as a whole. The main purpose of education <br/>is the integral development of a person.</p> */}
                            {/* <ul>
                                <li><Link to="/about" className="more-about wow animate__fadeInUp" data-wow-duration="1.2s"> Read More <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link></li>
                                <li className="last-li wow animate__fadeInUp" data-wow-duration="1.3s">
                                    <em>Get Support</em>
                                    <a href="mailto:support@react.com">support@react.com</a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>

                <div className="row">

                    <div className="col-lg-12">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s"><br /> <em>History</em></h2>
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">Aditya Engineering College (AEC) was established in the year 2001 by Aditya Academy. AEC renowned for its vibrant community, hosts over 5,000 students from across India and 14 foreign nations. The institute offers 11 UG programs, 5 PG programs in Engineering, Masters in Business Administration and Masters in Computer Applications. AEC has achieved significant milestones, including autonomous status, an impressive A++ Grade by National Assessment and Accreditation Council, and UG Engineering Programs are accredited by the National Board of Accreditation under Tier-I. The college is also recognized by the UGC under sections 2(f) and 12(B). AEC has research publications of around 338 indexed in Web of Science, 1015 in Scopus, 226 patents published, and 13 patents grant to its credit.</p>
                                <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">Rooted deeply in the vision of Dr. N. Sesha Reddy, the Aditya Academy, founded in 1984, with a vision and mission to create a platform for holistic growth and success to students. As a non-profit Educational Society, its commitment to quality education for rural students in Andhra Pradesh is a story of inspiration and perseverance. Having been an educational lighthouse for almost 40 years, it today boasts of a sprawling network of over 40 revered institutions. With an impeccable workforce of over 3,000 staff, it serves a diverse student community of over 40,000 sprawled over different   campuses situated in various cities and towns of Andhra Pradesh. The academy's reputation for exceptional teaching practices, state-of-the-art infrastructure, robust ICT integration, impressive placement drives, and higher education opportunities remains unmatched.
                            </p>
                            {/* <p className="about__paragraph2 wow animate__fadeInUp" data-wow-duration="0.7s"> Have questions?  <Link to="#"> Get Free Guide </Link></p> */}
                            {/* <p className="wow animate__fadeInUp" data-wow-duration="0.9s">Education also refers to the knowledge received through schooling instruction <br/>and to the institution of teaching as a whole. The main purpose of education <br/>is the integral development of a person.</p> */}
                            {/* <ul>
                                <li><Link to="/about" className="more-about wow animate__fadeInUp" data-wow-duration="1.2s"> Read More <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link></li>
                                <li className="last-li wow animate__fadeInUp" data-wow-duration="1.3s">
                                    <em>Get Support</em>
                                    <a href="mailto:support@react.com">support@react.com</a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;