import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/accreditations';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';

import SideManu from './sidebar_credentials';
import image1 from './accreditations_images/nba.png';
import image2 from './accreditations_images/naac.png';




const accreditations = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                <StudyBreadcrumb pageTitle="Accreditations , Recognitions & Rankings" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                {/* <center><b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>Recognitions</b></center>
                                                <br /> */}
                                                <div class="container row">
                                                    <div class="col-md-10" style={{backgroundColor:'white'}}>
                                                        <br/>
                                                        <span className='name' style={{textAlign:'justify'}}> Six programs (CE, EEE, ME, ECE,  CSE, IT) accredited by NBA under Tier I.
                                                        </span> 
                                                    </div>
                                                    <div class="col-md-2">
                                                        <center><img src={image1}/></center>
                                                    </div>
                                                 </div>
                                                 <br />
                                                <div class="container row">
                                                <br/>
                                                    <div class="col-md-10" style={{backgroundColor:'white'}}>
                                                        <br/>
                                                        <span className='name' style={{textAlign:'justify'}}>Accredited with CGPA of 3.60 on four point scale at A++ grade.
                                                        </span> 
                                                    </div>
                                                    <div class="col-md-2">
                                                      <center><img src={image2}/></center>
                                                    </div>
                                                 </div>
                                                 <br />
                                             </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default accreditations;