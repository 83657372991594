import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import Food_management_1 from './images/food_management_1.png';




const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                    <StudyBreadcrumb pageTitle="Food Engineering" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>Cooking System with Automatic Level Adjustment Mechanism of Containers and Method Thereof</b> <br />
                                                    <b className='name'>Theme:</b>
                                                     <p className='name'>
                                                     An innovative automatic cooking systems with simple level adjustment mechanism for ingredient containers to refill the ingredient containers.</p>
                                                    <b className='name'>Background:</b>
                                                     <p className='name'>
                                                     Cooking or cookery is the art or practice of preparing food for consumption with the use of heat. Cooking techniques and ingredients vary widely across, the world, reflecting unique environmental, economic, and cultural traditions.  There are many methods of cooking, most of which have been known since antiquity. These include baking, roasting, frying, grilling, barbecuing, smoking, boiling, steaming, braising, and microwaving. The existing methods are generally used by the majority of people for cooking food. The cooking devices available today are compatible with these methods of cooking. 
                                                    </p>
                                                    <b className='name'>Our Invention:</b>
                                                     <p className='name'>
                                                     The present disclosure proposes a cooking system with automatic level adjustment mechanism of containers and method thereof. The cooking system comprises a rotating chamber with several ingredient storage containers a level adjusting mechanism and a control unit. The cooking system achieves the automatic level adjustment for ingredient containers to refill the ingredient containers. Further, the cooking system is configured with different ingredient containers which controllably dispense ingredients in a cooking pot based on the recipe. The proposed automatic cooking system provides an alert to a user when the ingredients quantity is less than the threshold quantity in the ingredient containers. The proposed automatic cooking system automatically lowers the empty ingredient containers and aids the user to refill the ingredient containers.
                                                    </p>
                                                    
                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>
                                                   
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Home Cooking Appliances</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Commercial Kitchen Equipment</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Specialized Cooking Devices</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Improved Cooking Experience</span><br />
                                                    </p>

                                                    <b className='name'>IP Status</b>
                                                     <p className='name'>
                                                     The invention is the subject of a Granted Patent No. 511460 IP India<br></br>
                                                     Application No. 202241059287 IP India                                                       
                                                    </p>

                                                    <b className='name'>Inventors</b>
                                                     <p className='name'>K Arun Bhaskar</p>
                                                    <center>
                                                        <img src={Food_management_1} /><br></br>
                                                        <b className='name'>Cooking System with Automatic Level Adjustment</b>
                                                    </center>
                                                    <br/>

                                                    <b className='name'>Contact us</b>
                                                     <p className='name'>
                                                     If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at:<b>dean_rc@adityauniversity.in</b>                                                     
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;