import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import virtual_reality1 from './images/virtual_reality1.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">

                    <StudyBreadcrumb pageTitle="Patents Commercialisation" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center> <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> <img src={point} style={{ height: "25px" }} /> overview</b> <br /></center>
                                                    <p className='name'>Aditya University collaborates closely with organisations such as NIMSME, MSME, DST, and NRDC to commercialize research and innovation.  Aditya University is attempting to create firm relationships with investors and entrepreneurs to facilitate technology transfer as part of the creation of an innovation ecosystem.</p>
                                                    <b style={{ fontSize: "20px", textAlign: "center" }}>Our Research Focus Areas are:</b><br/>
                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  1.Advanced Engineering Applications</b><br />
                                                    <p style={{ padding: '15px' }}>
                                                        <span className='name'><img src={point1} style={{ height: "25px" }} />  <a href='/virtual_reality'  style={{color:'#000'}} > Virtual Reality Learning And Amusement System Based On Artificial Intelligence (AI) And IoT.</a></span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} />  Fractal Based MultiInput and MultiOutput (MIMO) Antenna for Wideband Applications.</span><br />
                                                    </p>

                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  2.Automobile Engineering</b><br />
                                                    <p style={{ padding: '15px' }}>
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> Performance and Emission Analysis of Direct Injection Diesel Engine Fuelled by Biodiesel with Al2O3 Nano Additives.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> Development of Clutch based Mechanical oriented Side stand Retrieval system mechanism for 2 wheelers and Method thereof.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> Brightness Enhancing and Super Water-Repellent Composition for Transparent Films.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> An Automatic Extendable Rear Seat for a Motorcycle.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> A Clutch Restriction System.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> Hydraulic System Based Clutch Lever Restriction for Two-Wheeler and Method There of.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> An Accelerator Restriction System.</span><br />
                                                    </p>

                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  3.Renewable Energy</b><br />
                                                    <p style={{ padding: '15px' }}>
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} />  A system for studying the performance of a photovoltaic panel under shady conditions.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} />  Estimating Method for Optimal Placement of Distributed Generation Units.</span><br />
                                                    </p>

                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  4.Medical & Health</b><br />
                                                    <p style={{ padding: '15px' }}>
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> Wearable Device and Method for Treatment of Plantar Fasia.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> A Container with a Treatment System for Ion Balancing and Mineralizing Water and Method Thereof.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> An Automatic Sanitizer Dispensing Accessory for Door Handles.</span><br />
                                                    </p>

                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  5.Waste Management </b><br />
                                                    <p style={{ padding: '15px' }}>
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> A Floor Drain Trap with an Automatic Waste Cleaning System and Method Thereof.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> Automatic Sorting Trash Bin and Method Thereof.</span><br />
                                                    </p>

                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  6.Food Processing </b><br />
                                                    <p style={{ padding: '15px' }}>
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> Cooking System with Automatic Level Adjustment Mechanism of Containers and Method Thereof.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> Sugarcane Harvesting Machine.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> Bottle Configuration System for Preparing Beverages and Method Thereof.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> A Multi-Chambered Bottle Configuration for Preparing Beverages.</span><br />
                                                    </p>
                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  7.Technology for Disabled and Elderly </b><br />
                                                    <p style={{ padding: '15px' }}>
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> A Height Adjustable Seat Apparatus for a Toilet Commode and Method Thereof.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> Multi-Chamber Mop Bucket for Cleaning and Squeezing Mop Head and Method Thereof.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> A Self Wringing Mop Stick and Method of Operating Same.</span><br />
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> A Control System and Method for a Universal Height-Adjustable Seat Apparatus of a Toilet Commode.</span><br />
                                                    </p>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;