
import { Link } from 'react-router-dom';
import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import AboutBreadcrumb from '../../components/Breadcrumb/AboutBreadcrumbs';
import ScrollToTop from '../../components/ScrollTop';

// Image
import aboutImg from '../../assets/images/about/sathish_reddy.jpg';
import aboutImg1 from '../../assets/images/about/deepak_reddy.jpg';

import shapeImg1 from '../../assets/images/about/line_new.png';

const Chancellor = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            // headerNormalLogo={Logo}
            // headerStickyLogo={Logo}
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <AboutBreadcrumb pageTitle="Pro - Chancellor's Message" />
                    <div class="about__area about2__area about3__area p-relative pb---60" style={{ marginTop: "40px" }}>
                        <div class="container">
                            <div class="row">
                                <div className="col-lg-4">
                                    <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                                        <img src={aboutImg} alt="About" />
                                    </div>
                                </div>

                                <div className="col-lg-8" style={{backgroundColor: 'rgb(248, 249, 250)',padding:'20px'}}>
                                    <div className="about__content about__content1">
                                        {/* <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s" style={{ color: "#D2093C" }}>Pro - Chancellor's Message </h2> */}
                                        <b style={{ fontSize: '20px',color: "#D2093C" }}>In the tapestry of education, each thread of experience weaves a unique story of Growth and excellence .
                                        </b>
                                        <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify' }}><br/>
                                            Welcome to Aditya University,<br/>
                                            Step into a world where education transcends boundaries and transforms lives.<br/>

                                            Our university isn't just a place of learning; it's an immersive experience, a voyage of discovery, and a thriving community all rolled into one. Here, academic brilliance seamlessly intertwines with a myriad of opportunities and experiences, crafting a unique essence that defines us.<br/>

                                            At Aditya University, we embrace a holistic approach to education that extends far beyond traditional classrooms. Our students are empowered to explore, innovate, and excel in every facet of their journey Yet, what truly distinguishes us is our unwavering commitment to nurturing individuals who are not just academically adept, but also socially conscious and emotionally resilient. Our vibrant campus culture celebrates diversity and fosters collaboration, ensuring that each student feels valued and supported every step of the way. As we embark on this relentless pursuit of academic excellence, we extend a warm invitation to you to experience the distinctive flavor of Aditya University. Join us and become part of a dynamic community dedicated to turning dreams into tangible achievements.</p>
                                        
                                        <b style={{ float: 'right' }}>
                                            Best regards,<br />
                                            Dr. N. Satish Reddy.<br />
                                            Pro-Chancellor,<br />
                                            Aditya university
                                        </b>
                                    </div>
                                </div>
                            </div>
                              <br/>
                               <center><img src={shapeImg1} alt="About" style={{width:'50%'}}/></center>
                               
                            <div class="row" style={{marginTop: '53px'}}>
                            <div className="col-lg-4">
                                    <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                                        <img src={aboutImg1} alt="About"/>
                                    </div>
                                </div>

                                <div className="col-lg-8" style={{backgroundColor: 'rgb(248, 249, 250)',padding:'20px'}}>
                                    <div className="about__content about__content1">
                                        {/* <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s" style={{ color: "#D2093C" }}>Pro - Chancellor's Message </h2> */}
                                        <b style={{ fontSize: '20px',color: "#D2093C" }}>Education is the key for unlocking a brighter tomorrow .
                                        </b>
                                        <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify' }}><br/>
                                            As Pro Chancellor, I'm thrilled to invite you to join our community dedicated to excellence and innovation in technical education. At Aditya, we prioritize interdisciplinary skills and leadership development, preparing our students to tackle global challenges. Our inclusive environment fosters holistic growth through diverse co-curricular activities, while our commitment to public service drives impactful research and societal change.<br/>

                                            Join us at Aditya University, where your potential meets limitless opportunities for success.</p>
                                        
                                        <b style={{ float: 'right' }}>
                                            Best regards,<br />
                                            Mr.N.Deepak Reddy.<br />
                                            Pro-Chancellor,<br />
                                            Aditya university
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default Chancellor;