import React from 'react';
import courses from '../../data/Scholarship.json';
const Scholarship = () => {
   return (
        <div className="high_quality-section pt---110 pb---120">
            <div className="container">
            <h2 class="wow animate__fadeInUp text-danger text-center" data-wow-duration="0.3s">Scholarships Offered</h2>
             <div className="react_featured_online">
                    <div className="container">
                       <div className="row pt---30">
                            {courses.map((data, index) => {
                                return (
                                    <div key={index} className="col-md-12 wow animate__fadeInUp" data-wow-duration="0.3s">
                                        {
                                            <div className="item__inner scholarshipstableheight table-responsive" style={{ width: "100%"}}>
                                            <div style={{ width: "100%" }} className="react-content scholarshipstable">
                                                <h5 style={{ textAlign:"center",width: "100%", }} className="price">{data.mode}</h5>
                                               
                                                <h3 style={{ width: "100%", color: "blue",textAlign:"center"  }}>{data.branches1}</h3>
                                               

                                                <ul className="react-lessones mt-2 scholarships">
                                                    <li style={{ width: "800px", color: "#D2093C" }} className="react-book"> <b>% of Concession</b> </li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }} className="react-book"> {data.concession100} </li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }}> {data.concession75}  </li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }} className="react-book"> {data.concession50} </li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }}> {data.concession25}  </li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }} className="react-book"> {data.concession10} </li>
                                                </ul>
                                                <ul className="react-lessones scholarships">
                                                    <li style={{ width: "800px", color: "#D2093C"}} className="react-book"> <b>{data.title}</b>  </li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }} className="react-book"> 100%</li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }}> 75%</li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }} className="react-book">50%</li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }}>25%</li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }} className="react-book">10%</li>
                                                </ul>
                                                <br></br>
                                                {/* <center><p>--------------------------------------------------------------------------------------------</p></center> */}
                                                <h3 style={{ color: "blue",textAlign:"center"  }}>{data.branches2}</h3>
                                                {/* <p  style={{ color: "black",fontSize:'25px',textAlign:"center" }}> % of Concession </p> */}
                                                <ul className="react-lessones mt-2 scholarships">
                                                    <li style={{ width: "800px", color: "#D2093C"}} className="react-book"><b>% of Concession</b> </li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center"}} className="react-book"> {data.concession_100} </li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center"}}> {data.concession_75}  </li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center"}} className="react-book"> {data.concession_50} </li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center"}}> {data.concession_25}  </li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center"}} className="react-book"> {data.concession_10} </li>
                                                </ul>
                                                <ul className="react-lessones mt-2 scholarships" >
                                                    <li style={{ width: "800px", color: "#D2093C" }} className="react-book"> <b>{data.title}</b>  </li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }} className="react-book"> 100%</li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }}> 75%</li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }} className="react-book">50%</li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }}>25%</li>
                                                    <li style={{ width: "100%", color: "#00306E",textAlign:"center" }} className="react-book">10%</li>
                                                </ul>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div >
        </div >
      );
}


export default Scholarship;