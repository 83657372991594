import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
// import StudyBreadcrumb from '../../components/Breadcrumb/StudyBreadcrumbs';
import ScrollToTop from '../../components/ScrollTop';
import Scholarships from './scholarships';


const  Scholarshipsnew = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
                // headerNormalLogo={Logo}
                // headerStickyLogo={Logo}
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    
                    {/* <StudyBreadcrumb pageTitle="Why ADITYA" /> */}

                    <Scholarships />

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default  Scholarshipsnew;