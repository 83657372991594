import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import Sustainability_2 from './images/sustainability_2.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                    <StudyBreadcrumb pageTitle="Sustainability" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                            <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>Automatic Sorting Trash Bin and Method Thereof</b> <br />
                                                    <b className='name'>Theme:</b>
                                                     <p className='name'>
                                                     An innovative trash processing and sorting devices, and in specific relates to a trash bin that automatically sorts pieces of waste and alerts the users for packed waste.
                                                    </p>
                                                    <b className='name'>Background:</b>
                                                     <p className='name'>
                                                     Commercial, industrial, and residential consumers generate large amounts of throw-away and waste products that need to be handled and disposed of in an environmentally satisfactory manner. Today, much waste or waste material from stores, convenience stores, fast food chains, restaurants, retail locations, offices, schools, and commercial, institutional, and government environments or locations is discarded by individuals into waste receptacles provided onsite. These waste receptacles typically are fitted with disposable plastic bags, which can be closed when filled, removed from the waste receptacles, and then disposed of in waste containers (e.g. trash cans, garbage cans, and dumpsters) usually located outside the buildings and/or adjacent to the parking lots of these locations to facilitate removal by a waste disposal service.
                                                    </p>
                                                    <b className='name'>Our Invention:</b>
                                                     <p className='name'>
                                                     The present disclosure proposes a trash bin that automatically sorts and stores pieces of waste and alerts the users for packed waste. The trash bin comprises three chambers namely a receiving chamber a sorting chamber and several storage chambers. The chambers are positioned one upon the other. The proposed trash bin enables simple, fast, complex and efficient trash segregation. The proposed trash bin automatically determines pieces of waste such as paper, plastic, and electronic waste and sorts them out accordingly to the determined type. The proposed trash bin is configured with a self-cleaning operation for cleaning the storage chambers.
                                                    </p>
                                                    
                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>
                                                   
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Household Waste Management</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Public Spaces and Offices</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Smart Cities and Urban Infrastructure</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Swimming Pools and Water Parks</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Enhanced Recycling in Offices</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Public places and Events</span><br />
                                                    </p>

                                                    <b className='name'>IP Status</b>
                                                     <p className='name'>
                                                     The invention is the subject of a Granted Patent 516813 IP India.<br></br>
                                                     Application No. 202241059289 IP India                                                       
                                                    </p>

                                                    <b className='name'>Inventors</b>
                                                     <p className='name'>
                                                     Dr. S. Aruna Kumari
                                                    </p>
                                                    <center>
                                                        <img src={Sustainability_2} /><br></br>
                                                        <b className='name'>Automatic Sorting Trash Bin</b>
                                                    </center>
                                                    <br/>

                                                    <b className='name'>Contact us</b>
                                                     <p className='name'>
                                                     If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at :<b>dean_rc@adityauniversity.in</b>                                                       
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;