import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import "./styles.css";
const backendUrl = "https://adityauniversity.in:4001/api/"


const Admission = () => {

  const location = useLocation();

  const { state } = location;
  const formDetails = location.state;
  const image = location.state?.Photo;
  console.log(formDetails)
  // const dataDetails = location.state?.dataDetails;

  console.log('Image URL:', image);
  const [imageSrc, setImageSrc] = useState(null);
  useEffect(() => {
    const storedImage = localStorage.getItem('uploadedImage');
    if (storedImage) {
      setImageSrc(storedImage);
    }
  }, []);
  // console.log('Form Details:', formDetails);




  const navigate = useNavigate();

  const [photo, setPhoto] = useState();
  const [Certificate, setCCertificate] = useState();
  const [Academic_Certificates, setAcademic_Certificates] = useState();
  const [NET_SET_GATE_Score, setNET_SET_GATE_Score] = useState();
  const [Professional_Experience, setProfessional_Experience] = useState();
  const [First_Page_Publication_file, setFirst_Page_Publication_file] = useState();
  const [Objection_certificate_file, setObjection_certificate_file] = useState();
  const [Research_plan_file, setResearch_plan_file] = useState();



  const [regdata, setRegdata] = useState({
    Name_of_Applicant: '',
    Name_of_Father: '',
    Gender: '',
    Category: '',
    Date_Of_Birth: '',
    Nationality: '',
    Email: '',
    ContactNo: '',
    Address: '',

    SSC_X_Standard_Place: '',
    SSC_X_Standard_Board: '',
    SSC_X_Standard_Year: '',
    SSC_X_Standard_Percentage: '',

    HSC_XII_Standard_Place: '',
    HSC_XII_Standard_Board: '',
    HSC_XII_Standard_Year: '',
    HSC_XII_Standard_Percentage: '',

    Bachelors_Degree_Place: '',
    Bachelors_Degree_Board: '',
    Bachelors_Degree_Year: '',
    Bachelors_Degree_Percentage: '',

    Masters_Degree_Place: '',
    Masters_Degree_Board: '',
    Masters_Degree_Year: '',
    Masters_Degree_Percentage: '',

    Other_Particulars: '',
    Research_Plan: '',
    Scopus_URL: '',
    Signature: '',
    Interested: '',
    department: '',
    published:'',
  });

  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(process.env.REACT_APP_DATABASEURL);

    const formData = new FormData();
    formData.append('Photo', photo);
    formData.append('CCertificate', Certificate);
    formData.append('Academic_Certificates', Academic_Certificates);
    formData.append('NET_SET_GATE_Score', NET_SET_GATE_Score);
    formData.append('Professional_Experience', Professional_Experience);
    formData.append('First_Page_Publication_file', First_Page_Publication_file);
    formData.append('Objection_certificate_file', Objection_certificate_file);
    formData.append('Research_plan_file', Research_plan_file);
    const dataDetails = {

      Employee_Experience: experienceRows,
      Publication: publications
    }
    formData.append('dataDetails', JSON.stringify(dataDetails));
    Object.keys(formDetails).forEach(key => {
      formData.append(key, formDetails[key]);
    });

    navigate('/PhD_admission_form', { state: formDetails })
    try {
      const res = await axios.post(backendUrl + "Admissionformdata", formDetails, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (res.status === 201) {
        console.log(res.data)
        // alert("Form submitted successfully!");
        localStorage.setItem('refId',res.data.application_id)
        window.location.href = '/phdpayment';
        setRegdata({
          Gender: '',
          Category: '',
          Date_Of_Birth: '',
          Nationality: '',
          Email: '',
          ContactNo: '',
          Address: '',

          SSC_X_Standard_Place: '',
          SSC_X_Standard_Board: '',
          SSC_X_Standard_Year: '',
          SSC_X_Standard_Percentage: '',

          HSC_XII_Standard_Place: '',
          HSC_XII_Standard_Board: '',
          HSC_XII_Standard_Year: '',
          HSC_XII_Standard_Percentage: '',

          Bachelors_Degree_Place: '',
          Bachelors_Degree_Board: '',
          Bachelors_Degree_Year: '',
          Bachelors_Degree_Percentage: '',

          Masters_Degree_Place: '',
          Masters_Degree_Board: '',
          Masters_Degree_Year: '',
          Masters_Degree_Percentage: '',

          Other_Particulars: '',
          Research_Plan: '',
          Scopus_URL: '',
          Signature: '',
          Interested: '',
          published:'',
          department: '',


        });
        window.history.replaceState({ state: formDetails }, '', '/phdpayment');
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an error submitting the form. Please try again.");
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(regdata)
    setRegdata(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  // --------------------------------------- image functionality start
  //image functionality
  const styles = {
    imageDisplay: {
      width: '150px',
      height: '150px',
      border: '1px solid #198754',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // marginTop: '-49px',
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  };
  const [experienceRows, setExperienceRows] = useState([
    { Name_Of_Organation: '', Desigination: '', Start_Date: '', End_Date: '', Duration: '' }
  ]);

  // Function to handle adding a new row
  const handleAddRow = () => {
    setExperienceRows([
      ...experienceRows,
      { Name_Of_Organation: '', Desigination: '', Start_Date: '', End_Date: '', Duration: '' }
    ]);
  };




  const [publications, setPublications] = useState([
    {
      sno: '',
      Title_of_Paper: '',
      Name_Of_Journal: '',
      Issue_No: '',
      Publication_year: ''
    }
  ]);

  // Function to handle adding a new row
  const handle_Add_Row = () => {
    setPublications([
      ...publications,
      {
        sno: '',
        Title_of_Paper: '',
        Name_Of_Journal: '',
        Issue_No: '',
        Publication_year: ''
      }
    ]);
  };
  // Function to handle changes in input fields
  const handleInputChange = (index, event) => {
    const values = [...publications];
    values[index][event.target.name] = event.target.value;
    setPublications(values);
    console.log(publications);
  };

  const handleBack = async (e) => {
    navigate('/PhD_admission_form', { state: formDetails });
  };




  return (
    <>
      <Header parentMenu='page' menuCategoryEnable='enable' />

      <form onSubmit={handleSubmit}>

        <div style={{ background: '#f2f4f9', backdropFilter: 'blur(10px)', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }} className="container mt-5 mb-5 ">
          <h2 style={{ textAlign: 'center' }} className="mt-5 mb-4"><b>Application for Admission into Ph.D.(<span style={{ color: 'rgb(211, 20, 63)' }}>Review</span>)</b></h2>
          <div className=''><center><span onClick={handleBack} className='btn btn-danger'> Back</span></center></div>

          {/*personal details*/}
          <div className="personal_details mt-5">
            <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>1.Personal Details:</p></strong>
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="name" className='form_name'>Name of the Applicant : </label> <span className='form_name_details' >{formDetails.Name_of_Applicant}</span>
                <div className="form-group">
                  <label htmlFor="guardian" className='form_name'>Name of the Father/Guardian :</label> <span className='form_name_details' >{formDetails.Name_of_Father}</span>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="gender" className='form_name'>Gender :</label ><span className='form_name_details' >{formDetails.Gender}</span>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="gender" className='form_name'>Category :</label ><span className='form_name_details' > {formDetails.Category}</span>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="gender" className='form_name'>File Upload Category Certificate :</label > <span className='form_name_details' >{formDetails.CCertificate != undefined ? (<b>Yes</b>) : " No"}</span>
                </div>
              </div>
              <div className="form-group col-md-6">
                <div className='row d-flex flex-row align-items-between'>
                  <div className='col-md-3 '>
                  </div>
                  <div className='col-md-6' id="image-display" style={styles.imageDisplay} >
                    {imageSrc ? <img src={imageSrc} alt="Uploaded" style={styles.image} /> : 'No file chosen'}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="dob" className='form_name'>Date of Birth :</label > <span className='form_name_details' >{formDetails.Date_Of_Birth}</span>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="dob" className='form_name'>Nationality : </label ><span className='form_name_details' >{formDetails.Nationality}</span>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="dob" className='form_name'>Email : </label ><span className='form_name_details' >{formDetails.Email}</span>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="dob" className='form_name'>Contact No :</label > <span className='form_name_details' >{formDetails.ContactNo}</span>
              </div>
            </div>
            <div className='row'>
              <div className="form-group">
                <label htmlFor="dob" className='form_name'>Address: </label > <span className='form_name_details' >{formDetails.Address}</span>
              </div>
            </div>
          </div>


          {/*academic details*/}
          <div className="academic_details mt-5">
            <div className='row'>
              <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>2. Academic Details  </p></strong>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <label> <b style={{ color: '#00306E' }}>SSC/ X Standard : </b> </label>
                <br />
                <label htmlFor="dob" className='form_name'>School/College and Place: </label ><span className='form_name_details' >{formDetails.SSC_X_Standard_Place}</span><br />
                <label htmlFor="dob" className='form_name'>Board/University: </label ><span className='form_name_details' >{formDetails.SSC_X_Standard_Board}</span><br />
                <label htmlFor="dob" className='form_name'>Year of completion: </label ><span className='form_name_details' >{formDetails.SSC_X_Standard_Year}</span><br />
                <label htmlFor="dob" className='form_name'>Percentage/CGPA: </label ><span className='form_name_details' >{formDetails.SSC_X_Standard_Percentage}</span>
              </div>
              <div className="form-group col-md-6">
                <label> <b style={{ color: '#00306E' }}> HSC / XII Standard : </b> </label>
                <br />
                <label htmlFor="dob" className='form_name'>School/College and Place: </label ><span className='form_name_details' >{formDetails.HSC_XII_Standard_Place}</span><br />
                <label htmlFor="dob" className='form_name'>Board/University: </label ><span className='form_name_details' >{formDetails.HSC_XII_Standard_Board}</span><br />
                <label htmlFor="dob" className='form_name'>Year of completion: </label ><span className='form_name_details' >{formDetails.HSC_XII_Standard_Year}</span><br />
                <label htmlFor="dob" className='form_name'>Percentage/CGPA: </label ><span className='form_name_details' >{formDetails.HSC_XII_Standard_Percentage}</span>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="form-group col-md-6">
                <label> <b style={{ color: '#00306E' }}>Bachelor's Degree : </b> </label>
                <br />
                <label htmlFor="dob" className='form_name'>School/College and Place: </label ><span className='form_name_details' >{formDetails.Bachelors_Degree_Place}</span><br />
                <label htmlFor="dob" className='form_name'>Board/University: </label ><span className='form_name_details' >{formDetails.Bachelors_Degree_Board}</span><br />
                <label htmlFor="dob" className='form_name'>Year of completion: </label ><span className='form_name_details' >{formDetails.Bachelors_Degree_Year}</span><br />
                <label htmlFor="dob" className='form_name'>Percentage/CGPA: </label ><span className='form_name_details' >{formDetails.Bachelors_Degree_Percentage}</span>
              </div>
              <div className="form-group col-md-6">
                <label> <b style={{ color: '#00306E' }}>Masters' Degree : </b> </label>
                <br />
                <label htmlFor="dob" className='form_name'>School/College and Place: </label ><span className='form_name_details' >{formDetails.Masters_Degree_Place}</span><br />
                <label htmlFor="dob" className='form_name'>Board/University: </label ><span className='form_name_details' >{formDetails.Masters_Degree_Board}</span><br />
                <label htmlFor="dob" className='form_name'>Year of completion: </label ><span className='form_name_details' >{formDetails.Masters_Degree_Year}</span><br />
                <label htmlFor="dob" className='form_name'>Percentage/CGPA: </label ><span className='form_name_details' >{formDetails.Masters_Degree_Percentage}</span>
              </div>
            </div>

            <div class="container mt-4">
              <div className='row mt-3'>
                <div className='col-md-12 d-flex flex-direction-row'>
                  <label htmlFor="dob" className='form_name'>File Upload Academic Certificates : </label ><span className='form_name_details' >{formDetails.Academic_Certificates != undefined ? (<b> Yes</b>) : "  No"}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
              <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>3.NET/SET/GATE Score  </p></strong>
            <div className="form-group col-md-6">
              <label htmlFor="dob" className='form_name'>Net Score: </label > <span className='form_name_details' >{formDetails.net_score}</span>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="dob" className='form_name'>File Upload NET/SET/GATE Score Card: </label ><span className='form_name_details' >{formDetails.NET_SET_GATE_Score != undefined ? (<b> Yes</b>) : " No"}    </span><br />
            </div>

          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>4.Professional Experience  </p></strong>
              <label htmlFor="dob" className='form_name'>File Upload Professional Experience Certificates: </label ><span className='form_name_details' >{formDetails.Professional_Experience != undefined ? (<b> Yes</b>) : " No"}</span><br />
            </div>

          </div>
          <br />
          <div>
            {formDetails && formDetails.Employee_Experience ? (
              formDetails.Employee_Experience.map((experience, index) => (
                <div className="row" key={index}>
                  <div className="form-group col-md-6">
                    <label htmlFor="institution" className='form_name'>Name of the Institution: </label>
                    <span className='form_name_details'>{experience.Name_Of_Organation}</span><br />
                    <label htmlFor="start_date" className='form_name'>Start Date: </label>
                    <span className='form_name_details'>{experience.Start_Date}</span><br />
                    <label htmlFor="duration" className='form_name'>Duration: </label>
                    <span className='form_name_details'>{experience.Duration}</span><br />
                    <br />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="Desigination" className='form_name'>Desigination: </label>
                    <span className='form_name_details'>{experience.Desigination}</span><br />
                    <label htmlFor="end_date" className='form_name'>End Date: </label>
                    <span className='form_name_details'>{experience.End_Date}</span><br />
                  </div>

                </div>

              ))

            ) : (
              <div>No employee experience data available.</div>
            )}

          </div>




          {/*Publications*/}
          <div className='Publications mt-5'>
            <div className='row'>
              <div className='col-md-6'>
                <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>5. Publications  </p></strong>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="dob" className='form_name'>File Upload First Page of Publication: </label ><span className='form_name_details' >{formDetails.First_Page_Publication_file != undefined ? (<b> Yes</b>) : " No"}</span><br />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="dob" className='form_name'>Whether the applicant has published any Journal papers / Books / Book Chapters: <span className='form_name_details' >{formDetails.published}</span></label ><br /><br />
                </div>
              </div>
            </div>


            <div>
              {formDetails && formDetails.Publication ? (
                formDetails.Publication.map((publication, index) => (
                  <div className="row" key={index}>
                    <div className="form-group col-md-6">
                      <label htmlFor="Sno" className='form_name'>Sno: </label>
                      <span className='form_name_details'>{publication.sno}</span><br />
                      <label htmlFor="Title of paper" className='form_name'>Title of paper: </label>
                      <span className='form_name_details'>{publication.Title_of_Paper}</span><br />
                      <label htmlFor="Publication_year" className='form_name'>Year: </label>
                      <span className='form_name_details'>{publication.Publication_year}</span><br />
                      <br />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="Name of journal" className='form_name'>Name of journal: </label>
                      <span className='form_name_details'>{publication.Name_Of_Journal}</span><br />
                      <label htmlFor="Issue no" className='form_name'>Issue no: </label>
                      <span className='form_name_details'>{publication.Issue_No}</span><br />
                    </div>
                  </div>
                ))
              ) : (
                <div>No employee experience data available.</div>
              )}

            </div>
            <div className='row'>
              <div className="form-group">
                <label htmlFor="dob" className='form_name'>Any other particulars the applicant would like to present: </label > <span className='form_name_details' >{formDetails.Other_Particulars}</span>
              </div>
            </div>
          </div>

          <div className='Publications mt-5'>
            <div className='row'>
              <div className='col-md-6'>
                <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>6.Upload Research Plan  </p></strong>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="dob" className='form_name'>File Upload Research Plan : </label ><span className='form_name_details' >{formDetails.Research_plan_file != undefined ? (<b> Yes</b>) : " No"} </span><br />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="dob" className='form_name'>Interested in Full Time / Part Time : </label ><span className='form_name_details' >{formDetails.Interested} </span><br />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="dob" className='form_name'>Discipline Applying for : </label ><span className='form_name_details' >{formDetails.department} </span><br />
                </div>
              </div>
            </div>
            <div className='Publications mt-5'>
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="dob" className='form_name'>Broad theme or Title of the proposed research topic :
                    <span className='form_name_details' >  {formDetails.Research_Plan}</span><br />
                    Abstract, objectives, Literature Survey, Scope and Timelines, expected Conclusions <br />  </label >
                </div>
              </div>
            </div>
          </div>
          <br />

          <div className="row">
            <div className="form-group col-md-6">
              <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>7.Scopus URL  </p></strong>
              <span className='form_name_details' >{formDetails.Scopus_URL} </span><br />
            </div>
            <div className="form-group col-md-6">
              <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>8.Declaration  </p></strong>
              <label htmlFor="dob" className='form_name'>
              <p>
                   <b>1.</b> I hereby declare that the information provided by me is true and correct to the best of my knowledge and belief.<br/>
                   <b>2.</b>. I hereby declare that I abide by the rules and regulations of the Aditya University regarding Ph.D. admissions
              </p> </label ><span className='form_name_details' >{formDetails.Signature}</span><br />
            </div>
          </div>
          <br /><br /><br />

          {/*submit button*/}
          <div className='submit_button'><center><button type='submit' className='btn btn-danger'> Payment</button></center></div>
          {/* <PaymentMain /> */}
        </div>

        <FooterTwo />
      </form>
    </>
  );
}

export default Admission;
