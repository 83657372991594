import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import Food_management_2 from './images/food_management_2.png';




const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                    <StudyBreadcrumb pageTitle="Food Engineering" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>Sugarcane Harvesting Machine</b> <br />
                                                    <b className='name'>Theme:</b>
                                                     <p className='name'>
                                                     An innovative work to develop a sugarcane harvesting machine</p>
                                                    <b className='name'>Background:</b>
                                                     <p className='name'>
                                                     The sugarcane harvesting machine works on shear cutting mechanism. A rotary cutting blade is arranged at the bottom of the harvester to cut the bottom portion of the sugarcane. Due to the rotary motion engine power wheel the shaft will rotate. These shafts are connected to the bevel gears. The bevel gears transfer the rotary power to the top and bottom cutters. The  bottom cutter has sharp cutting edges and about 140 sharp teeth. This will take 10 seconds to cut the sugarcane having a maximum diameter of 4 cm. The sugarcane harvesting machine helps to cut the height of sugarcane crop from ground level properly and minimize the crop damage.   The sugarcane harvesting machine includes a 4 hp, diesel engine and gear box a frame for mounting reduction unit, cutting unit and transportation wheel.<br></br><br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Effective cutting width of the sugarcane harvesting machine is 100 cm. Approximate weight of the cutting unit with the engine is 200 kg. Average field capacity of the sugarcane harvesting machine is 0.0132ha/h.  Cutting is done with shear cutting blades. Power transmission is done through belt and pulley mechanism. Top cutting is directly connected to base cutting through bevel gear to avoid another power supply to the top cutting. Conveying of material is done by gravity conveying. Improved utility through mounting type system is achieved. Frame and machine are appropriately balanced during operation of the sugarcane harvesting machine
                                                    </p>
                                                    <b className='name'>Our Invention:</b>
                                                     <p className='name'>
                                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The present invention relates to a sugarcane harvesting machine. In one embodiment of the invention, the sugarcane harvesting machine works on shear cutting mechanism. A rotary cutting blade is arranged at the bottom of the harvester to cut the bottom portion of the sugarcane. Due to the rotary motion engine power wheel the shaft will rotate. These shafts are connected to the bevel gears. The bevel gears transfer the rotary power to the top and bottom cutters. The bottom cutter has sharp cutting edges and about 140 sharp teeth. This will take 10 seconds to cut the sugarcane having a maximum diameter of 4 cm. The sugarcane harvesting machine helps cut the height of sugarcane crop from ground level properly and minimize the crop damage.
                                                    </p>
                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>
                                                   
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Harvesting of Sugarcane </span><br />
                                                    </p>

                                                    <b className='name'>IP Status</b>
                                                     <p className='name'>
                                                     The invention is the subject of a Granted Patent No. 516165 IP India<br></br>
                                                     Application No.  201841028064                                                       
                                                    </p>

                                                    <b className='name'>Inventors</b>
                                                     <p className='name'>
                                                        <ol>
                                                            <li>Rahul Kumar Gupta</li>
                                                            <li>Palagani Vamsi Krishna </li>
                                                            <li>Sikharam Siva Sai</li>     
                                                            <li>Konatham Kiran Kumar</li>                                          
                                                            <li>Konatham Sivaji</li>
                                                            <li>Aadi Maheswara Rao</li>
                                                            <li>Manam Naveen Kumar Narayana</li>
                                                            
                                                        </ol>
                                                     </p>
                                                    <center>
                                                        <img src={Food_management_2} /><br></br>
                                                        <b className='name'>Sugarcane Harvesting Machine</b>
                                                    </center>
                                                    <br/>

                                                    <b className='name'>Contact us</b>
                                                     <p className='name'>
                                                     If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at :<b>dean_rc@adityauniversity.in</b>                                                      
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;