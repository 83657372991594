import React from 'react';
import Header from '../../../../components/Header';
import FooterTwo from '../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../components/Styles/pragram.css"
import point from '../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_eng';



const Engoverview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                   <StudyBreadcrumb pageTitle="School of Engineering" />
                   <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                <div class="container">
                                                    <center><b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}><img src={point} style={{height:"25px"}}/>  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>Welcome to the School of Engineering at Aditya, a premier institution committed to excellence in engineering education and research. Our school offers a comprehensive range of undergraduate, postgraduate, and doctoral programs designed to cultivate the next generation of engineers and innovators.</p>
                                                        <p className='name'>Our Bachelor of Technology (B.Tech) programs provide a solid foundation in engineering principles, coupled with hands-on experience and opportunities for specialization in cutting-edge fields. Through our curriculum curated by industry experts, we aim to develop proficient engineers equipped with the knowledge and skills to address complex technical challenges and contribute effectively to the industry.</p>
                                                        <p className='name'>For those seeking advanced knowledge and research opportunities, our Master of Technology (M.Tech) programs offer in-depth study and specialized training in various engineering disciplines. These programs are designed to enhance professional expertise and foster innovation, preparing graduates for leadership roles in academia, industry, and research institutions.</p>
                                                        <p className='name'>Our Doctoral (Ph.D) programs are at the forefront of engineering research, encouraging scholars to pursue fundamental research that advances the boundaries of engineering knowledge. With access to state-of-the-art facilities and mentorship from distinguished faculty, our doctoral candidates are well-positioned to make significant contributions to their fields.</p>
                                                        <p className='name'>At the School of Engineering, we are dedicated to fostering a dynamic and inclusive learning environment that emphasizes collaboration, innovation, and practical application. Our partnerships with leading international universities and industry leaders provide our students with a global perspective and valuable professional networks.</p>
                                                        <p className='name'>We invite you to explore the diverse programs and opportunities available at the School of Engineering, where we are committed to empowering our students to achieve their academic and professional aspirations. Join us in shaping the future of engineering and making a meaningful impact on society.</p>
                                                        <br/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            <FooterTwo />

        </>
    );
}

export default Engoverview;