import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_ce';
import Banner from './banner';





const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>Inspired by architectural marvels, the Department of Civil Engineering at Aditya University champions innovation in engineering practices tailored to industry demands. With expert faculty and cutting-edge academic facilities, the department offers an immersive learning environment. Exclusive internship opportunities with leading companies like L&T Construction, Reliance Industries Limited, OIL, Irrigation Department (Govt. of A.P.), Soma Constructions, APCO Infratech, and Vizag Steel Plant enrich students' practical exposure.</p>

                                                        <p className='name'>The department honors the legacy of ‘Apara Bhageeratha’ Sir Arthur Cotton, revered in coastal Andhra Pradesh for his transformative projects that made the delta region the 'Rice Bowl' of the state. Drawing inspiration from his genius, the Civil Engineering department at Aditya emphasizes innovative applications to enhance civil engineering practices, meeting the needs of the civil construction industry.</p>

                                                        <p className='name'>Accredited laboratories, endorsed by the National Accreditation Board for Testing and Calibration Laboratories (NABL), ensure top-notch quality standards. The department engages in various consultancy activities such as quality control, design, detailing, soil testing, and concrete testing for nearby construction sites. Collaborations with prestigious entities like the Indian Concrete Institute (ICT) empower students and faculty with the latest industry insights and techniques.</p>

                                                        <p className='name'>To keep students abreast of the latest advancements, the department invites eminent industry and academic experts to deliver technical talks on emerging areas. The department is equipped with state-of-the-art facilities and the latest software, including STAAD.Pro, AUTOCAD, Revit Structures, and Robot Structure analysis. Laboratories are well-equipped with costly equipment such as Total Station, Universal Testing Machine, Tri-axle Shear Testing Machine, Kaplan turbine, Francis turbine, Pelton wheel testing rig, Compression testing machine, and Ultrasonic pulse velocity equipment.</p>

                                                        <p className='name'>The Department of Civil Engineering at Aditya University is dedicated to transferring innovative applications to improve civil engineering practices and addressing the challenges of the civil construction industry through education, research, and practical exposure.</p>

                                                        <br />
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  VISION : </b> <br />
                                                        
                                                        <p className='name'>To be a recognized center in Civil Engineering with values and innovation.</p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> MISSION:</b><br/>
                                                        <p className='name'>M1:
                                                        Practice learner-centric quality teaching-learning process abreast with changing industry needs and societal challenges.<br/>
                                                        M2:
                                                        Provide Quality infrastructure towards academics, research and innovation.<br/>
                                                        M3:
                                                        Establish effective industry and institutional collaboration.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;