import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/aditya_learning_academy';
import ScrollToTop from '../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_learning_academy';
import image1 from './Images/Vijaya Kumar.jpg';
import image2 from './Images/2.jpg';
import image3 from './Images/3.jpg';
import image4 from './Images/4.jpg';
import image5 from './Images/Neelima.jpg';
import image6 from './Images/6.jpg';





const overview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Aditya Learning Academy" pageName="Team"/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />

                                                {/* <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Aditya Learning Academy (Centre for Teaching & Learning), Aditya University </b> </center><br /> */}
                                                <div class="about__content">
                                                    {/* <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> <img src={point} style={{ height: "25px" }} />   Team </b></center>
                                                        <br />
                                                        <br /> */}
                                                    <div class="col-md-12 container row ">
                                                       <div class="col-md-6">
                                                            <center> <img src={image2} style={{ height: "350px" }} /><br />
                                                                <span className='name' style={{ textAlign: 'center' }}>
                                                                    <b style={{ color: "#D2093C"}}>Dr. I Veeranjaneyulu</b><br />
                                                                    Assistant Professor,Dept. of ME,<br/> School of Engineering <br /><b>Coordinator</b><br/>
                                                                </span> 
                                                            </center>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <center> <img src={image1} style={{ height: "350px" }} /><br />
                                                                <span className='name' style={{ textAlign: 'center' }}>
                                                                    <b style={{ color: "#D2093C"}}>Dr. P Vijaya Kumar</b><br />
                                                                     Assistant Professor,Dept. of ECE <br/> School of Engineering <br/><b>Member</b> <br />
                                                                </span> 
                                                            </center>
                                                        </div>
                                                        
                                                       
                                                        <div class="col-md-6">
                                                        <br/>
                                                            <center> <img src={image3} style={{ height: "350px" }} /><br />
                                                                <span className='name' style={{ textAlign: 'center' }}>
                                                                    <b style={{ color: "#D2093C"}}>Mr. N. Rajesh</b><br />
                                                                    Assistant Professor,Dept. of EEE,<br/> School of Engineering <br /><b>Member</b><br/>

                                                                </span> 
                                                            </center>
                                                        </div>
                                                        <div class="col-md-6">
                                                        <br/>
                                                            <center> <img src={image4} style={{ height: "350px" }} /><br />
                                                                <span className='name' style={{ textAlign: 'center' }}>
                                                                    <b style={{ color: "#D2093C"}}>Mrs. N. Akhila</b><br />
                                                                    Assistant Professor,Dept. of CSE,<br/> School of Engineering <br /><b>Member</b><br/>

                                                                </span> 
                                                            </center>
                                                        </div>
                                                        <div class="col-md-6">
                                                        <br/>
                                                            <center> <img src={image5} style={{ height: "350px" }} /><br />
                                                                <span className='name' style={{ textAlign: 'center' }}>
                                                                    <b style={{ color: "#D2093C"}}>Mrs. S. Neelima</b><br />
                                                                    Assistant Professor,Dept. of IT,<br/> School of Engineering <br /><b>Member</b><br/>

                                                                </span> 
                                                            </center>
                                                        </div>
                                                        <div class="col-md-6">
                                                        <br/>
                                                            <center> <img src={image6} style={{ height: "350px" }} /><br />
                                                                <span className='name' style={{ textAlign: 'center' }}>
                                                                    <b style={{ color: "#D2093C"}}>Mr. G. Sivasankar</b><br />
                                                                    Assistant Professor,Dept. of AIML,<br/> School of Engineering <br /><b>Member</b><br/>

                                                                </span> 
                                                            </center>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default overview;