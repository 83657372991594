import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import '../../../components/Styles/pragram.css';
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_opportunities';
import PlacementSlider from './placement_slider';




const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    {/* <StudyBreadcrumb pageTitle="Placement Policy" /> */}
                    <PlacementSlider />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container" style={{ textAlign: 'justify' }}>
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> Placement Rules </b> <br /></center>
                                                    {/* <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> General Rules :</b> <br /> */}
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> It is mandatory for all the students to register for all the campus drives using their college email id only.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Update your details of Academic percentages, Backlogs etc.. @ AJIVIKA time to time.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Before applying for the drive, carefully go through the Job Description and required skillset for the respective role.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> To maintain good relationships with the recruiters, all the registered students ought to attend the drives without fail.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> If, we find any student not participating in Two drives after Registration, they will be removed from all the upcoming campus drives.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Whenever a Survey link is shared to the students, they have to fill required details immediately for follow up. </span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> For all Virtual Campus drives make sure of System requirements, Internet connectivity, Webcam and Mic to avoid technical issues.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> As Virtual Campus drives are web proctored, students need to be away from malpractice to avoid disqualification.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Give equal importance to English (Verbal Ability) as it also plays a pivotal role in the evaluation process of recruitment.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> To grab Higher packages in Product-Based Companies, practice more in Coding. Also participate in all Contest-Based hiring, Hackathons and other competitions.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Apply for more companies to explore all possibilities to enhance your capability for Industry requirements.</span><br />

                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Conditions for allowing students for multiple offers.</span>
                                                    <p className='name' style={{ padding: "14px"}}>   
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> If the CTC of new offer is more than 50% or above the existing offer</span> <br/>
                                                        <span className='name'> <img src={point1} style={{ height: "25px" }} /> If core background students got the offer in IT sector.</span> 
                                                    </p>

                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> For Quick communications, Join the respective campus drive WhatsApp groups.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Students must carry their Xerox copies of the necessary certificates along with two passport size photographs and a copy of their resume for the interview.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Maintain Punctuality for all Placement-related activities. </span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Students must be in Formal attire and be Professional.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> For International Placement drives, learn concern foreign languages as per the schedule given by the Placement department.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> 90% attendance is mandatory in concern foreign language trainings to attend any International Placements.</span><br />





                                                    {/* <span className='name'><img src={point} style={{ height: "25px" }} /> It is mandatory for all the students to register for all the campus drives using their college email id only.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Update your details with our placement team about your academic percentages, backlog and other details from time to time.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Before applying for the drive, please go through the Job Description and required skillset.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> To maintain good relationship with our clients, all the registered students for any drive must attend the drive without fail.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> If we found any student after registration not participating in 2 drives, they will be removed for upcoming campus drives.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Quick response needed from you whenever a survey link is shared to fill by you, so that we can do the required follow up.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> For all virtual campus drives make sure of system requirements, internet connectivity, webcam and mic to avoid last minutes issues.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> As virtual campus drives are proctor-based test so avoid plagiarism to avoid disqualification.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Give equal importance to English (Verbal Ability) as it is also part of evaluation in the recruitment process.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> To grab higher packages in product-based companies practice more on Coding and coding. Also participate in all contexts-based hiring, Hackathon and other competitions.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> We are also giving chance to the students to participate for multiple companies but some students getting relaxed with one selection.</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Conditions for allowing students for Multiple offers.</span><br />
                                                    <span className='name'><img src={point1} style={{ height: "25px" }} /> If the CTC of new offer is more than 50% and above of the existing offer</span><br />
                                                    <span className='name'><img src={point1} style={{ height: "25px" }} /> If IT offered student selected in their core area</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> For Quick communications join the concern campus drive WhatsApp groups and be attentive in it </span>
                                                    <br /><br />

                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> Job Search Strategies :</b> <br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Resume Writing </span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Cover Letters </span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Interview Preparation</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Networking </span>

                                                    <br /><br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> Resume Writing :</b> <br />
                                                    <p className='name'> Resume Writing Crafting an effective resume is crucial in making a strong first impression on potential employers. </p><br />


                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> Tips:</b>
                                                    <p class="name">
                                                        <b>Tailor Your Resume:</b> Customize your resume for each job application to highlight the most relevant skills and experiences. <br />
                                                        <b>Use Keywords:</b> Incorporate industry-specific keywords from the job description <br />
                                                        <b>Keep It Concise:</b> Aim for a one-page resume if you have less than 10 years of experience, focusing on the most pertinent information. <br />
                                                        <b>Highlight Achievements:</b> Use bullet points to showcase your accomplishments and quantify them when possible. <br />
                                                        <b>Cover Letters:</b> A well-written cover letter can set you apart from other candidates. <br />
                                                    </p>

                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> Guidelines:</b>
                                                    <p class="name">
                                                        <b>Personalize It:</b> Address the letter to a specific person, usually the hiring manager, to show you've done your homework.<br />
                                                        <b>Be Concise:</b> Keep your cover letter to one page, focusing on why you're a great fit for the role.<br />
                                                        <b>Highlight Your Value:</b> Explain how your skills and experiences align with the job requirements and what you can bring to the company.<br />
                                                        <b>Show Enthusiasm:</b> Convey your genuine interest in the role and the company.<br />
                                                    </p>
                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> Interview Preparation:</b>
                                                    <p class="name">Preparing for an interview involves understanding the types of questions you might be asked and practicing your responses.</p>

                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> Common Interview Questions:</b> <br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Tell me about yourself ? </span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Why do you want to work here ?</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> What are your strengths and weaknesses ?</span><br />
                                                    <span className='name'><img src={point} style={{ height: "25px" }} /> Describe a challenging situation and how you handled it ?</span><br /><br />

                                                    <p class="name">
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> Tips for Success:</b><br/>
                                                        <b>Research the Company:</b> Understand the company's mission, values, and recent developments.<br />
                                                        <b>Practice Answers:</b> Rehearse responses to common questions and prepare examples that showcase your skills.<br />
                                                        <b>Dress Appropriately:</b> Choose professional attire that aligns with the company's dress code.<br />
                                                        <b>Follow Up:</b> Send a thank-you note after the interview to express your appreciation and reiterate your interest.<br />
                                                    </p> */}

                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;