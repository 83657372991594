import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';

import Food_management_3_1 from './images/food_management_3_1.png';
import Food_management_3_2 from './images/food_management_3_2.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                    <StudyBreadcrumb pageTitle="Food Engineering" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>Bottle Configuration System for Preparing Beverages and Method Thereof</b> <br />
                                                    <b className='name'>Theme:</b>
                                                     <p className='name'>
                                                     An innovative work on beverage containers and in specific relates to a bottle configuration system that facilitates a user to interact and select desired ingredients in the bottle and controls multiple components in the bottle to prepare various beverages. </p>
                                                    <b className='name'>Background:</b>
                                                     <p className='name'>
                                                     Water consumption is the primary way humans hydrate themselves. Often, people carry around water bottles so that they can be hydrated when they may not be near a water source. In specific, water bottles are used to store different beverages and to serve as a vessel for bringing beverages to their mouths. Sometimes, water bottles are designed to be reusable and are more eco-friendly than disposable plastic water bottles. Such reusable water bottles typically have a bottle portion for holding a liquid and a cap for covering the opening of the bottle portion. Some water bottles have a cap that is separate from the water bottle and some have a cap that is connected to the water bottle by a strap. Some water bottles may have a spout on the cap that can be opened for drinking or for pouring liquid out of the bottle. Some bottles have filters to remove contaminants and to improve the taste of the drinking water.<br></br><br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The filters may use ion resin exchange to reduce lead, copper, mercury and other impurities from drinking water. Later, portable refillable bottles are widely used for water and other beverages for health and hydration. In addition, vitamins, health and dietary supplements are available in liquids, powders, gels and solid tablets to consume with water, and storage for the supplements is also available in such bottle configurations. However, one limitation of such bottles is that the consumable contents remain constant and unchanged except for changes in quantity as the contents are consumed and replenished.
                                                    </p>
                                                    <b className='name'>Our Invention:</b>
                                                     <p className='name'>
                                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The present disclosure proposes a bottle configuration system that facilitates a user to interact and select desired ingredients in the bottle and controls multiple components in the bottle to prepare various beverages. The bottle configuration system comprises a computing module and a controller. The proposed system assists the user in preparing desired beverages. The system analyses various benefits of ingredients present in the bottle for preparing different beverages. The system assists the user by displaying analysed benefits of multiple ingredients or combinations of ingredients present in the bottle. Further, the proposed system aids the user to select healthy beverages based on the benefits of multiple ingredients.
                                                    </p>
                                                    
                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>
                                                   
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} />Customizable Beverage Dispensing</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} />Automated Cocktail Making</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} />Home Beverage Stations</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} />Commercial Beverage Production</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} />Event Catering</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} />Health and Nutrition Industry</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} />Hotels and Hospitality</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} />Educational Institutions</span><br />
                                                    </p>

                                                    <b className='name'>IP Status</b>
                                                     <p className='name'>
                                                     The invention is the subject of a Granted Patent No. 434135 IP India<br></br>
                                                     Application No.  202241050493                                                       
                                                    </p>

                                                    <b className='name'>Inventors</b>
                                                     <p className='name'>
                                                     Dr. A. Vanathi
                                                     </p>
                                                    <center>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                            <img  style={{marginTop:"60px"}} src={Food_management_3_1} />
                                                            </div>
                                                            <div className='col-md-6'>
                                                            <img src={Food_management_3_2} />
                                                            </div>
                                                        </div>
                                                        <b className='name'>Bottle Configuration System for Preparing Beverages</b>
                                                    </center>
                                                    <br/>

                                                    <b className='name'>Contact us</b>
                                                     <p className='name'>
                                                     If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at :<b>dean_rc@adityauniversity.in</b>                                                      
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;