import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_pt';
import Banner from './banner';




const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        
                                                        <p className='name'>The Department of Petroleum Technology established in 2011, offers both a B.Tech in Petroleum Technology and an M.Tech in Petroleum Engineering. The department's mission is to provide innovative teaching and research facilities, focusing on areas such as Drilling Engineering, Production Engineering, Reservoir Engineering, and Geology, with special emphasis on Engineering Geology and Petroleum Geology. The goal is to equip students with the knowledge and skills necessary to address challenges in the oil and gas industry through the application of innovative technologies.
                                                        </p>
                                                        <p className='name'>The department is known for its strong industry-institution relationship, organizing workshops, seminars, and consultancy services. It boasts highly qualified faculty and technical staff who play a crucial role in nurturing the students' development.
                                                        </p>
                                                        <p className='name'>Students undergo summer training at prestigious oil and gas organizations such as ONGC, Reliance, HPCL, BPCL, among others. Leading companies like OFI, L&T, and Tech Mahindra are prominent recruiters through campus interviews.
                                                        </p>
                                                        <p className='name'>The department's computational laboratories are equipped with advanced petroleum engineering software like CMG, MATLAB, and ASPEN HYSIS. Additionally, it houses excellent laboratories including Drilling Fluids, Reservoir Engineering, Petroleum Analysis, and Instrumentation Process Control, providing students with hands-on experience and practical skills.
                                                        </p>
                                                        <p className='name'>Situated in the heart of India's oil basin, the Department of Petroleum Engineering  is among the first to offer an undergraduate program in Petroleum Technology in Andhra Pradesh. Collaborating with organizations like the Indian Institute of Chemical Engineers (IICHE), the department offers unparalleled opportunities for students to explore the oil and gas exploration sector. With experienced faculty and state-of-the-art facilities, the program prepares graduates to excel in the dynamic and challenging field of petroleum engineering.</p>



                                                        <br />
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  VISION : </b> <br />

                                                        <p className='name'>To attain recognition in research and equip students for meeting the challenging needs of petroleum, allied industries and society.</p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> MISSION:</b><br />
                                                        <p className='name'>
                                                            M1: To provide excellent instruction and design experience essential for Petroleum Engineers to enter the practice of petroleum engineering and pursue life-long professional development.<br />
                                                            M2: To conduct research that develops, communicates, and applies new knowledge for the betterment of society.<br />
                                                            M3: To foster a spirit of service and leadership among students and faculty and assist the public in addressing issues concerning the use of resources, protection of the environment, and development of infrastructure.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;