import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Header from '../../../../components/Header';
import FooterTwo from '../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../components/ScrollTop';
import "../../../../components/Styles/pragram.css";
import point from '../../../../assets/images/icons/image.png';
import SideManu from './sidebar_management';
import Linkimage from './images/link.gif';
import MBAimage from './images/mba.png';
import IMBAimage from './images/imba.png';

// const backendUrl = process.env.REACT_APP_DATABASEURL;
// console.log(backendUrl);

const Curriculum = () => {
    const [activeIndex, setActiveIndex] = useState(0); 
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [programData, setProgramData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const openModal = (pdf) => {
        setSelectedPdf(pdf);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedPdf(null);
    };

    const getImageForProgram = (title) => {
        switch (title) {
            case 'MBA':
                return MBAimage;
            case 'MBA(Integrated)':
                return IMBAimage;
            default:
                return point;
        }
    };

    useEffect(() => {
        const fetchSyllabusData = async () => {
            try {
                // const response = await fetch(`${backendUrl}api/getAggregation_MBA_IMBA`);
                const response = await fetch("https://adityauniversity.in:4001/api/getAggregation_MBA_IMBA");

               
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setProgramData(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
            console.log(response)
        };
        fetchSyllabusData();
    }, []);

    return (
        <>
            <Header parentMenu='page' menuCategoryEnable='enable' />
            <div className="react-wrapper">
                <div className="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="School of Business" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div className="research" id="">
                                    <div className="container">
                                        <div className="row m-0">
                                            <SideManu />
                                            <div className="col-md-1 col-sm-1 col-2"></div>
                                            <div className="col-md-8 col-sm-8" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div className="container">
                                                    <div className="about__content">
                                                        {loading ? (
                                                            <p>Loading...</p>
                                                        ) : error ? (
                                                            <p style={{ color: 'red' }}>{error}</p>
                                                        ) : programData.length > 0 ? (
                                                            <div>
                                                                {programData.map(({ Department_order, title, Department_title, Curriculam, Curriculum_name, filecount }) => (
                                                                    <React.Fragment key={Department_order}>
                                                                        <button 
                                                                            className={`accordion ${activeIndex === Department_order ? 'active' : ''}`} 
                                                                            onClick={() => toggleAccordion(Department_order)}
                                                                        >
                                                                            <img src={getImageForProgram(title)} style={{ height: '40px' }} alt={title} />
                                                                            &nbsp;&nbsp;&nbsp;&nbsp; {title}
                                                                            <span className="symbol">{activeIndex === Department_order ? '▲' : '▼'}</span>
                                                                        </button>
                                                                        <div 
                                                                            className="panel" 
                                                                            style={{ display: activeIndex === Department_order ? 'block' : 'none', marginBottom: '14px' }}
                                                                        >
                                                                            <br />
                                                                            <table style={{ width: "100%" }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ textAlign: 'center' }}>Program</th>
                                                                                        <th style={{ textAlign: 'center' }}>2024-25</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td rowSpan={filecount + 1}>{Department_title}</td>
                                                                                    </tr>
                                                                                    {Curriculam && Curriculum_name && Curriculam.map((curr, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>
                                                                                                <span onClick={() => openModal(curr)} style={{ color: "#000", cursor: 'pointer' }}>
                                                                                                    {Curriculum_name[index]}
                                                                                                </span>
                                                                                                <img src={Linkimage} style={{ height: '25px' }} alt="link icon" />
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                            <br />
                                                                        </div>
                                                                    </React.Fragment>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <p>No programs available.</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            </div>
            <FooterTwo />
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Curriculum PDF" className="Modal">
                <button onClick={closeModal} className="close-button" style={{
                    float: 'right',
                    background: '#1f3860',
                    color: 'white',
                    height: '39px',
                    width: '79px',
                    padding: '3px'
                }}><b>Close</b></button>
                {selectedPdf && (
                    <center>
                        <iframe
                             src={`https://adityauniversity.in:4001/department_syllabus/${selectedPdf}#toolbar=0`}
                            title="Curriculum PDF"
                            width="60%"
                            height="800px"
                            style={{ marginTop: '25px' }}
                        />
                    </center>
                )}
            </Modal>
        </>
    );
}

export default Curriculum;
