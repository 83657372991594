import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_mba';
import coming_soon from '../../../../../assets/images/icons/coming_soon.png';




const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Master of Business Administration" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    {/* <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> Programs Offered </b> </center><br /> */}
                                                    <div class="about__content">

                                                    <p className='name'>
                                                           
                                                            <p className='name'>The Department of Management Studies offers a comprehensive 4-Year MBA Integrated course designed to prepare students for diverse career opportunities in the business world. Our approach emphasizes holistic development through a curriculum that integrates theoretical learning with practical application. Students engage in hands-on projects, internships, case studies, and interactive workshops that enhance their analytical and problem-solving skills. Additionally, the program includes opportunities for industrial visits, guest lectures, and participation in business fests, providing real-world exposure and networking opportunities.</p>
                                                            <p className='name'>By fostering a culture of innovation and critical thinking, the Department equips students with the skills and knowledge necessary to thrive in today’s competitive business environment, ensuring they are well-prepared for successful careers across various industries.</p>
                                                      
                                                        <span>Department is offering following Specializations:</span>
                                                        <div class="degree-list-container">
                                                            <ul class="degree-list">
                                                                <li><span class="icon name"><img src={point1} style={{ height: "25px" }} /> </span>
                                                                    <span>Finance</span></li>
                                                                <li><span class="icon name"><img src={point1} style={{ height: "25px" }} /> </span><span>Marketing</span></li>
                                                                <li><span class="icon name"><img src={point1} style={{ height: "25px" }} /> </span><span>Human Resource Management</span></li>
                                                                <li><span class="icon name"><img src={point1} style={{ height: "25px" }} /> </span><span>Business Analytics</span></li>
                                                                <li><span class="icon name"><img src={point1} style={{ height: "25px" }} /> </span><span>Logistics & Supply Chain </span></li>
                                                                <li><span class="icon name"><img src={point1} style={{ height: "25px" }} /> </span><span>Information Systems Management</span></li>
                                                                <li><span class="icon name"><img src={point1} style={{ height: "25px" }} /> </span><span>Digital Marketing</span></li>
                                                            </ul>
                                                        </div>
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;