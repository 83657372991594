import React from 'react';
import { Link } from 'react-router-dom';
const Sidebar = () => {
    return (
        <div class="col-md-3 col-sm-3 col-0">
            <div class="side-navbar">
                <nav class="navbar">
                    <ul class="navbar-nav">
                        <li class="navbar-title text-center">
                            <a class="navbar-brand text-light1 " href="#" style={{padding: '12px 43px 12px 67px'}}>Management Studies</a>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/school_of_business' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/school_of_business">Overview</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/pg_regulations' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/pg_regulations">Regulations</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/MBA_academic_calendar' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/MBA_academic_calendar">Academic calendar</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/mba_curriculum' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/mba_curriculum">Curriculum</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/MBA' ? 'active' : ''}`} style={{fontsize:"17px !important"}}>
                            <Link class="nav-link " to="/MBA" style={{fontsize:"17px !important"}}> Department of Management Studies</Link>
                        </li>
                    <br/>
                        
                    </ul>
                </nav>
                    <div className="campus_sec1">
                             <div className="text-center">
                                <Link to="/academics" className="more-about"  style={{ width: "100%" }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg> Home </Link>
                            </div>
                    </div>
            </div>
        </div>
    );
}

export default Sidebar;