import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/programsoffered';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
import Enquire from '../../home/Enquire';
import SideManu from './sidebar_programs';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                <Enquire />

                    <StudyBreadcrumb pageTitle="Diploma Programs" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>Diploma Programs Offered :</b> <br />
                                                    <span className='name'><img src={point} style={{height:"25px"}}/> Diploma in Civil Engineering</span> <br />
                                                    <span className='name'><img src={point} style={{height:"25px"}}/> Diploma in Computer Science and Engineering</span> <br />
                                                    <span className='name'><img src={point} style={{height:"25px"}}/> Diploma in Communication & Computer Networking</span> <br />
                                                    <span className='name'><img src={point} style={{height:"25px"}}/> Diploma in Electronics and Communication Engineering</span> <br />
                                                    <span className='name'><img src={point} style={{height:"25px"}}/> Diploma in Electrical and Electronics Engineering</span> <br />
                                                    <span className='name'><img src={point} style={{height:"25px"}}/> Diploma in Mechanical Engineering</span> <br />
                                                    <b style={{ color: "#D2093C",fontSize:"20px" }}><br/>Eligibility Criteria for Diploma Admission</b>
                                                    
                                                    <div class="about__content">
                                                    
                                                        <p className='name'>Candidates should have passed S.S.C. Examination conducted by State Board of Secondary Education, Andhra Pradesh/ Telangana or any other examination recognized as equivalent thereto by the Board of Secondary Education, A.P/TS, such as Central Board of Secondary Education (CBSE), Indian Council for Secondary Education (ICSE), National Institute of Open School (NIOS), A.P. Open School Society (APOSS), X class examination conducted by various State Boards of Secondary Education in India with Mathematics as one of the subjects.</p>
                                                        <br/>
                                                        <div className="campus_sec1 row" style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                            {/* <div className="col-lg-4">
                                                                <div className="text-center">
                                                                    <Link to="/fees&scholarship" className="more-about" target="blank" style={{width:"100%"}}> Fees <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="text-center">
                                                                    <Link to="/fees&scholarship" className="more-about" target="blank" style={{width:"100%"}}> Scholarships   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                                                </div>
                                                            </div> */}
                                                            {/* <div className="col-lg-4" >
                                                                <div className="text-center">
                                                                    <Link to="https://apply.adityauniversity.in/" className="more-about" target="blank" style={{width:"100%"}}> Apply Now !  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                                                </div>
                                                            </div> */}

                                                        </div>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;