import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_patents';
import sanitizer from './images/sanitizer.png';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                <StudyBreadcrumb pageTitle="Healthcare" />
                <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                            <div class="container">
                                                    <b style={{color: "#D2093C",fontSize:"20px",textAlign:"center" }}>An Automatic Sanitizer Dispensing Accessory for Door Handles</b> <br />
                                                    <b className='name'>Theme:</b>
                                                     <p className='name'>
                                                     An innovative work on self-energized sanitizing door handle and user’s hand by spraying disinfectant upon locking and unlocking the door, thereby maintaining hygiene environment and possibly eliminating the spread of infection diseases.
                                                    </p>
                                                    <b className='name'>Background:</b>
                                                     <p className='name'>
                                                     Many surfaces that humans come into contact with are contaminated with bacteria and other germs that cause diseases. For example, such germs are commonly found on door handles. The nature of many types of handles, such as door handles and the like, requires that the handles are repeatedly contacted by different users. It is important for keeping hands sanitized, particularly after contact with a contaminated surface to prevent the spread of diseases. Individuals often seek to limit their exposure to germs and bacteria on public surfaces, such as door handles, for example, at hospitals in order to minimize the risk of contracting an illness.
                                                    </p>
                                                    <b className='name'>Our Invention</b>
                                                    <p className='name'>
                                                    The present disclosure proposes an automatic sanitizer dispensing accessory with door handles. In order to overcome the above deficiencies of the prior art, the present disclosure is to solve the technical problem to provide an accessory for automatically self-sanitizing the door handle by spraying disinfectant on door handle surfaces and user’s hand by using push button function, thereby maintaining hygiene environment and possibly eliminating the spread of infection diseases. The housing having one or more nozzles, which is in fluid communication with the conduit are configured to spray the disinfectant onto the door handle and the user’s hand upon rotating the door handle. The housing is detachably secured to the door using one or more fasteners include vacuum grips, adhesives, screws, nut and bolts. Further, objects and advantages of the present invention will be apparent from a study of the following portion of the specification, the claims, and the attached drawings
                                                    </p>
                                                    <b className='name'>Potential Applications</b>
                                                    <p className='name'>
                                                   
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Schools and Educational Institutions </span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Restrooms</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Hospitals and Clinics</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Office Spaces</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Public Transportation</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Airports and railway Stations</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Stores and Shopping Centres</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Conferences and Exhibitions</span><br />
                                                    <span className='name'> <img src={point} style={{ height: "25px" }} /> Sports Venues</span><br />
                                                    </p>
                                                    <b className='name'>IP Status </b>
                                                    <p className='name'>
                                                    The invention is the subject of a Granted Patent No. 501778 IP India 
                                                    </p>
                                                    <p className='name'>
                                                    Application No. 202241075066
                                                    </p>
                                                    <b className='name'>Inventors </b>
                                                    <p className='name'>
                                                    P. Shiva Kumar
                                                    </p>
                                                    <center>
                                                        <img src={sanitizer} /><br></br>
                                                        <b className='name'>Automatic Sanitizer Dispensing Accessory</b>
                                                    </center>
                                                    <br/>

                                                    <b className='name'>Contact us</b>
                                                     <p className='name'>
                                                     If you are interested in working with our researchers to develop any of our technologies, please contact the Aditya Commercialisation Team at :<b>dean_rc@adityauniversity.in</b>                                                      
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default diploma_programs;