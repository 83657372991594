import React from 'react';
import { Link } from 'react-router-dom';
const Sidebar = () => {
    return (
        <div class="col-md-3 col-sm-3 col-0">
            <div class="side-navbar">
                <nav class="navbar">
                    <ul class="navbar-nav">
                         <li className="navbar-title text-center">
                            <a className="navbar-brand text-light1" href="#" style={{ padding: '12px 121px 12px 131px' }}>Explore</a>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/iqac' || window.location.pathname === '/iqac' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/iqac">Vision</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/iqac_objectives' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/iqac_objectives"> Objectives</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/iqac_strategies' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/iqac_strategies"> Strategies</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/iqac_functions' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/iqac_functions"> Functions</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/iqac_benefits' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/iqac_benefits"> Benefits</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/iqac_members' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/iqac_members"> Members</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/iqac_events' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/iqac_events"> Events</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/QS_I_Gauge' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/QS_I_Gauge"> QS I Gauge</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Sidebar;