import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import Enquire from './buttonapply_now';

const Sidebar = () => {
    const [visibleSubmenu, setVisibleSubmenu] = useState(null);
    const location = useLocation();

    const toggleSubmenu = (submenu) => {
        setVisibleSubmenu(visibleSubmenu === submenu ? null : submenu);
    };

    const handleSubmenuClick = (path) => {
        setVisibleSubmenu(null);
    };

    return (

        <div className="col-md-3 col-sm-3 col-0">
            {/* <Enquire /> */}
            <div className="side-navbar">
                <nav className="navbar">
                    <ul className="navbar-nav">
                        <li className="navbar-title text-center">
                            <a className="navbar-brand text-light1" href="#" style={{ padding: '12px 121px 12px 131px' }}>Explore</a>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/Patents_Commercialisation' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/Patents_Commercialisation"> Overview</Link>
                        </li>
                        <li
                            className={`nav-item ${['/virtual_reality', '/fractal_based',].includes(location.pathname) || visibleSubmenu === 'Inter-disciplinary' ? 'active' : ''}`}
                            onMouseEnter={() => toggleSubmenu('Inter-disciplinary')}
                            onMouseLeave={() => toggleSubmenu(null)}
                            onClick={() => toggleSubmenu('Inter-disciplinary')}
                        >
                            <Link className="nav-link" to="#">Advanced Engineering Applications

                            </Link>
                            {visibleSubmenu === 'Inter-disciplinary' && (
                                <ul className="submenu">
                                    <li
                                        className={`nav-item ${location.pathname === '/virtual_reality' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/virtual_reality')}
                                    >
                                        <Link className="nav-link" to="/virtual_reality">Virtual Reality Learning And Amusement
                                            System Based On Artificial Intelligence (AI) And IoT</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/fractal_based' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/fractal_based')}
                                    >
                                        <Link className="nav-link" to="/fractal_based"> Fractal Based MultiInput and MultiOutput (MIMO) Antenna for Wideband Applications</Link>
                                    </li>

                                </ul>
                            )}
                        </li>
                        
                        <li
                            className={`nav-item ${['/diesel_engine', '/retrieval_system', '/water_repellent', '/motorcycle', '/clutch_system', '/hydraulic System', '/restriction System',].includes(location.pathname) || visibleSubmenu === 'Automobile Engineering' ? 'active' : ''}`}
                            onMouseEnter={() => toggleSubmenu('Automobile Engineering')}
                            onMouseLeave={() => toggleSubmenu(null)}
                            onClick={() => toggleSubmenu('Automobile Engineering')}
                        >
                            <Link className="nav-link" to="#">Automobile Engineering
                            </Link>
                            {visibleSubmenu === 'Automobile Engineering' && (
                                <ul className="submenu">

                                    {/* <li
                                        className={`nav-item ${location.pathname === '/diesel_engine' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/diesel_engine')}
                                    >
                                        <Link className="nav-link" to="/diesel_engine">Performance and Emission Analysis of Direct Injection Diesel Engine Fuelled by Biodiesel with Al2O3 Nano Additives
                                        </Link>
                                    </li> */}
                                    <li
                                        className={`nav-item ${location.pathname === '/retrieval_system' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/retrieval_system')}
                                    >
                                        <Link className="nav-link" to="/retrieval_system">Development of Clutch based Mechanical oriented Side stand Retrieval system mechanism for 2 wheelers and Method thereof
                                        </Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/water_repellent' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/water_repellent')}
                                    >
                                        <Link className="nav-link" to="/water_repellent">Brightness Enhancing and Super Water-Repellent Composition for Transparent Films
                                        </Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/motorcycle' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/motorcycle')}
                                    >
                                        <Link className="nav-link" to="/motorcycle">An Automatic Extendable Rear Seat for a Motorcycle
                                        </Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/clutch_system' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/clutch_system')}
                                    >
                                        <Link className="nav-link" to="/clutch_system">A Clutch Restriction System
                                        </Link>
                                    </li>
                                    {/* <li
                                        className={`nav-item ${location.pathname === '/hydraulic System' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/hydraulic System')}
                                    >
                                        <Link className="nav-link" to="/hydraulic System">Hydraulic System Based Clutch Lever Restriction for Two Wheeler and Method There of
                                        </Link>
                                    </li> */}
                                    {/* <li
                                        className={`nav-item ${location.pathname === '/restriction System' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/restriction System')}
                                    >
                                        <Link className="nav-link" to="/restriction System">An Accelerator Restriction System
                                        </Link>
                                    </li> */}
                                </ul>
                            )}
                        </li>
                        <li
                            className={`nav-item ${['/photovoltaic', '/estimating',].includes(location.pathname) || visibleSubmenu === 'renewable_energy' ? 'active' : ''}`}
                            onMouseEnter={() => toggleSubmenu('renewable_energy')}
                            onMouseLeave={() => toggleSubmenu(null)}
                            onClick={() => toggleSubmenu('renewable_energy')}
                        >
                            <Link className="nav-link" to="#">Renewable Energy

                            </Link>
                            {visibleSubmenu === 'renewable_energy' && (
                                <ul className="submenu">

                                    {/* <li
                                        className={`nav-item ${location.pathname === '/photovoltaic' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/photovoltaic')}
                                    >
                                        <Link className="nav-link" to="/photovoltaic">A system for studying the performance of a photovoltaic panel under shady conditions
                                        </Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/estimating' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/estimating')}
                                    >
                                        <Link className="nav-link" to="/estimating">Estimating Method for Optimal Placement of Distributed Generation Units
                                        </Link>
                                    </li> */}


                                </ul>
                            )}
                        </li>
                        <li
                            className={`nav-item ${['/wearable_Device', '/mineralizing_water', '/Sanitizer',].includes(location.pathname) || visibleSubmenu === 'Healthcare' ? 'active' : ''}`}
                            onMouseEnter={() => toggleSubmenu('Healthcare')}
                            onMouseLeave={() => toggleSubmenu(null)}
                            onClick={() => toggleSubmenu('Healthcare')}
                        >
                            <Link className="nav-link" to="#">Healthcare
                            </Link>
                            {visibleSubmenu === 'Healthcare' && (
                                <ul className="submenu">

                                    <li
                                        className={`nav-item ${location.pathname === '/wearable_Device' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/wearable_Device')}
                                    >
                                        <Link className="nav-link" to="/wearable_Device">Wearable Device and Method for Treatment of Plantar Fasia</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/mineralizing_water' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/mineralizing_water')}
                                    >
                                        <Link className="nav-link" to="/mineralizing_water">A Container with a Treatment System for
                                            Ion Balancing and Mineralizing Water and Method Thereof</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/Sanitizer' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/Sanitizer')}
                                    >
                                        <Link className="nav-link" to="/Sanitizer">An Automatic Sanitizer Dispensing Accessory for Door Handles</Link>
                                    </li>

                                </ul>
                            )}
                        </li>
                        <li
                            className={`nav-item ${['/Automatic_waste', '/Automatic_sorting',].includes(location.pathname) || visibleSubmenu === 'Sustainability' ? 'active' : ''}`}
                            onMouseEnter={() => toggleSubmenu('Sustainability')}
                            onMouseLeave={() => toggleSubmenu(null)}
                            onClick={() => toggleSubmenu('Sustainability')}
                        >
                            <Link className="nav-link" to="#">Sustainability

                            </Link>
                            {visibleSubmenu === 'Sustainability' && (
                                <ul className="submenu">
                                    <li
                                        className={`nav-item ${location.pathname === '/Automatic_waste' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/Automatic_waste')}
                                    >
                                        <Link className="nav-link" to="/Automatic_waste">A Floor Drain Trap with an Automatic Waste Cleaning System and Method Thereof</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/Automatic_sorting' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/Automatic_sorting')}
                                    >
                                        <Link className="nav-link" to="/Automatic_sorting"> Automatic Sorting Trash Bin and Method Thereof </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li
                            className={`nav-item ${['/cooking_system', '/Sugarcane_harvesting', '/Bottle_configuration', '/chambered_bottle',].includes(location.pathname) || visibleSubmenu === 'Food Engineering' ? 'active' : ''}`}
                            onMouseEnter={() => toggleSubmenu('Food Engineering')}
                            onMouseLeave={() => toggleSubmenu(null)}
                            onClick={() => toggleSubmenu('Food Engineering')}
                        >
                            <Link className="nav-link" to="#">Food Engineering
                            </Link>
                            {visibleSubmenu === 'Food Engineering' && (
                                <ul className="submenu">
                                    <li
                                        className={`nav-item ${location.pathname === '/cooking_system' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/cooking_system')}
                                    >
                                        <Link className="nav-link" to="/cooking_system">Cooking System with Automatic Level Adjustment
                                            Mechanism of Containers and Method Thereof </Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === 'Sugarcane_harvesting' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/Sugarcane_harvesting')}
                                    >
                                        <Link className="nav-link" to="/Sugarcane_harvesting">Sugarcane Harvesting Machine</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/Bottle_configuration' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/Bottle_configuration')}
                                    >
                                        <Link className="nav-link" to="/Bottle_configuration">Bottle Configuration System for Preparing
                                            Beverages and Method Thereof </Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/chambered_bottle' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/chambered_bottle')}
                                    >
                                        <Link className="nav-link" to="/chambered_bottle">A Multi-Chambered Bottle Configuration
                                            for Preparing Beverages</Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li
                            className={`nav-item ${['/Toilet_commode', '/Multi-Chamber', '/operating', '/universal',].includes(location.pathname) || visibleSubmenu === 'Elderly' ? 'active' : ''}`}
                            onMouseEnter={() => toggleSubmenu('Elderly')}
                            onMouseLeave={() => toggleSubmenu(null)}
                            onClick={() => toggleSubmenu('Elderly')}
                        >
                            <Link className="nav-link" to="#">Technology for Disabled and Elderly
                            </Link>
                            {visibleSubmenu === 'Elderly' && (
                                <ul className="submenu">
                                    <li
                                        className={`nav-item ${location.pathname === '/Toilet_commode' ? 'active' : 'inactive'}`}
                                        onClick={() => handleSubmenuClick('/Toilet_commode')}
                                    >
                                        <Link className="nav-link" to="/Toilet_commode">A Height Adjustable Seat Apparatus for a Toilet Commode and Method Thereof</Link>
                                    </li>
                                    {/* <li
                                        className={`nav-item ${location.pathname === '/Multi-Chamber' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/Multi-Chamber')}
                                    >
                                        <Link className="nav-link" to="/Multi-Chamber">Multi-Chamber Mop Bucket for Cleaning and Squeezing Mop
                                            Head and Method Thereof</Link>
                                    </li>

                                    <li
                                        className={`nav-item ${location.pathname === '/operating' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/operating')}
                                    >
                                        <Link className="nav-link" to="/operating">A Self Wringing Mop Stick and Method of operating Same</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/universal' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/universal')}
                                    >
                                        <Link className="nav-link" to="/universal">A Control System and Method for a Universal
                                            Height-Adjustable Seat Apparatus of a Toilet Commode</Link>
                                    </li> */}

                                </ul>
                            )}
                        </li>
                        <br />
                    </ul>
                </nav>
            </div>
        </div>

    );
}

export default Sidebar;
