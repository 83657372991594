import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_agri';
import coming_soon from '../../../../../assets/images/icons/coming_soon.png';

import Banner from './banner';





const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    {/* <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> Ph.D Program </b> </center><br /> */}
                                                    <div class="about__content">
                                                        <div id="overview" class="container">
                                                            {/* <center><img src={coming_soon} style={{ height: "250px", width: "250px" }} /></center> */}
                                                            <p className='name'>The Ph.D. programme in Agricultural Engineering at Aditya University is designed to cultivate world-class researchers equipped with the knowledge, skills, and attitude to advance the field of agricultural engineering. This rigorous programme encourages Ph.D. students to publish their findings in leading international journals and conferences, showcasing innovative solutions to real-world agricultural challenges. Candidates from across the country join this programme, contributing to a diverse and vibrant research environment within the Department of Agricultural Engineering.
                                                            </p>
                                                            <p className='name'>Our department boasts state-of-the-art laboratories, equipped with advanced instrumentation and systems that support research in various specialized domains such as Precision Agriculture, Remote Sensing, IoT-based Smart Farming, Renewable Energy, and Post-Harvest Technology.</p>
                                                            <p className='name'>Faculty members in the Agricultural Engineering department are actively engaged in pioneering research in fields that include:</p>

                                                            <div class="degree-list-container">
                                                                <ul class="degree-list">
                                                                    <li><span class="icon"><img className='pointimage' src={point1} /></span><span> Precision Agriculture</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point1} /></span><span> Soil and Water Conservation Engineering</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point1} /></span><span> Renewable Energy Sources in Agriculture</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point1} /></span><span> Remote Sensing and GIS Applications</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point1} /></span><span> IoT and Automation in Agriculture</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point1} /></span><span> Agricultural Waste Management</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point1} /></span><span> Farm Machinery and Power Engineering</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point1} /></span><span> Post-Harvest and Food Processing Technology</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point1} /></span><span> Climate Change Impact and Adaptation in Agriculture</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point1} /></span><span> Smart Irrigation Systems</span></li>
                                                                    <li><span class="icon"><img className='pointimage' src={point1} /></span><span> Sustainable Agricultural Practices</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;