import React from 'react';
import { Link } from 'react-router-dom';

// Image
import aboutImg from '../../assets/images/about/hostel.jpg';
import shapeImg1 from '../../assets/images/about/shape_02.png';
import point from '../../assets/images/icons/image.png';


const HostelPart = () => {

    return (
        <div class=" about3__area p-relative pb---60" style={{ marginTop: "40px" }}>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp img-change" data-wow-duration="0.3s">
                            <img class="react__shape__1" src={shapeImg1} alt="Shape Image" />
                            <img src={aboutImg} alt="About" width="80%" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="degree-list-container">
                            <ul class="degree-list">
                               
                                <li><span class="icon"><img className='pointimage' src={point} /></span><span> Hostel Fee: 95000/- per year for Non-AC Accommodation & 105000/- per year for AC Accommodation (Includes Laundry expenses & Medical Insurance coverage of 200000/-).</span></li>
                                <li><span class="icon"><img className='pointimage' src={point} /></span><span> For all type of rooms, Electric power charges to be paid as per individual consumption. </span></li>
                                <li><span class="icon"><img className='pointimage' src={point} /></span><span> Rooms are allotted on First-Come-First serve basis and subject to availability</span></li>
                                <li><span class="icon"><img className='pointimage' src={point} /></span><span> Round the Clock (24*7) Ambulance Facility.</span></li>
                                <li><span class="icon"><img className='pointimage' src={point} /></span><span> AC & non-AC 3-Sharing Accommodation with attached Bath & Balcony.</span></li>
                                <li><span class="icon"><img className='pointimage' src={point} /></span><span> 24*7 CCTV Surveillance.</span></li>
                                <li><span class="icon"><img className='pointimage' src={point} /></span><span> Uninterrupted Power Supply.</span></li>
                                <li><span class="icon"><img className='pointimage' src={point} /></span><span> Women Security in Women’s Hostels.</span></li>

                            </ul>
                        </div>
                    </div>
                           
                </div>
            </div>
        </div>
    );
}

export default HostelPart;