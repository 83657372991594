import React from 'react';
import Header from '../../../../components/Header';
import FooterTwo from '../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../components/ScrollTop';
import "../../../../components/Styles/pragram.css"
import point from '../../../../assets/images/icons/image.png';
import SideManu from './sidebar_examination';
import { Link } from 'react-router-dom';

import B_Tech from './files/B_tech.pdf';
import M_Tech from './files/M_tech.pdf';
import MBA from './files/mba.pdf';
import MBAI from './files/mbai.pdf';
import MCA from './files/mca.pdf';






const Managementoverview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Examination Centre" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    {/* <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Examination Notifications </b> </center><br /> */}
                                                       <div className="about__area about__area_one p-relative pt---10 pb---120">
                                                            <div className="container">
                                                                <div className="about__content" style={{ marginTop: '-56px' }}>
                                                                    <div className="campus_sec1 row">
                                                                            <div className="col-lg-6">
                                                                                <div className="text-center">
                                                                                    <Link to= {B_Tech} className="more-about" target="blank" style={{ width: "100%" }}> B.Tech <br/> I Semester Internal Examinations-I</Link>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="text-center">
                                                                                    <Link to= {M_Tech} className="more-about" target="blank" style={{ width: "100%" }}> M.Tech <br/> I Semester Internal Examinations-I</Link>
                                                                                </div>
                                                                            </div>
                                                                           
                                                                        </div>
                                                                    </div>
                                                                    <br/>
                                                                    <div className="about__content" style={{ marginTop: '-56px' }}>
                                                                       <div className="campus_sec1 row">
                                                                           <div className="col-lg-6">
                                                                                <div className="text-center">
                                                                                    <Link to= {MBA} className="more-about" target="blank" style={{ width: "100%" }}> MBA <br/>I Semester Internal Examinations-I</Link>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="text-center">
                                                                                    <Link to= {MBAI} className="more-about" target="blank" style={{ width: "100%" }}> MBA(Integrated) <br/>I Semester Internal Examinations-I</Link>
                                                                                </div>
                                                                            </div>
                                                                       </div>
                                                                    </div>
                                                                    <br/>
                                                                    
                                                                    <div className="about__content" style={{ marginTop: '-56px' }}>
                                                                       <div className="campus_sec1 row">
                                                                            <div className="col-lg-6">
                                                                                <div className="text-center">
                                                                                    <Link to= {MCA} className="more-about" target="blank" style={{ width: "100%" }}> MCA <br/> I Semester Internal Examinations-I</Link>
                                                                                </div>
                                                                            </div>
                                                                         </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                               </div>
                            </div>
                        </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default Managementoverview;