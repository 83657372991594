import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_it';
import Banner from './banner';
// import ITPopUp from "../../../../../components/Popup/IT/ITPopUp";






const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    {/* <ITPopUp /> */}

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  B.Tech </b> </center><br />
                                                    <div class="about__content">

                                                        <p className='name'>The B.Tech Information Technology program, spanning four years, provides a comprehensive curriculum encompassing hardware, software, system management, and data communication. It incorporates core subjects, electives, practicals, and industrial training, ensuring students acquire proficiency in efficient coding, software development, and database design. Equipped with these skills, graduates are prepared for diverse roles across IT, consulting, research, and government sectors, poised to make meaningful contributions in the ever-evolving landscape of technology.<br></br><br></br>
                                                            Through major core courses such as linear algebra, calculus, data structures, software engineering, and artificial intelligence, students gain essential technical expertise. Additionally, specialized courses in areas like database management, machine learning, and computer networks allow students to delve deeper into specific domains. These courses not only enhance students' theoretical understanding but also provide hands-on experience and practical skills relevant to real-world applications in the field of information technology. With a balanced mix of foundational and specialized knowledge, students are equipped to tackle complex challenges and innovate within their chosen areas of expertise upon graduation.<br></br>
                                                        </p>

                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />   B.Tech in (Information Technology)<br></br>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech in (Information Technology) with Minor  specialization in
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Network Security<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Machine Intelligence<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Data Engineering <br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Mobile Application Development<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Cyber Security  <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b><br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Agile Methodology with DevOps Programming  <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b><br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Cloud Infrastructure <b style={{ color: "#328afc", fontSize: "17px" }}> (Certification Program - CISCO, Red hat, Oracle, AWS, Google & Microsoft)</b><br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Rapid Application Development <b style={{ color: "#328afc", fontSize: "17px" }}> (Certification Program - PEGA, Oracle, Microsoft & Salesforce)  </b> <br />
                                                        </p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech in (Information Technology) with
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Civil  Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Electrical and Electronics Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Mechanical Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Electronics and Communication Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Petroleum Technology<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Mining Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Agricultural Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Entrepreneurship Development and Incubation<br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;