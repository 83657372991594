import React from 'react';
import { Link } from 'react-router-dom';

// Image
import aboutImg from '../../assets/images/about/ab3.png';
import shapeImg1 from '../../assets/images/about/shape_02.png';

const EligibilityCriteriapart = () => {

    return (
        <div class="about__area about2__area about3__area p-relative pb---60" style={{ marginTop: "40px" }}>
            <div class="container">
                <div class="row">
                    {/* <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img class="react__shape__1" src={shapeImg1} alt="Shape Image" />
                            <img src={aboutImg} alt="About" />
                        </div>
                    </div> */}
                    <div class="col-lg-12">
                        <div class="about__content">
                            <div>
                                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Eligibility Criteria for B.Tech Admission</h2>
                                <ul>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><i class="icon_check"></i>The applicant should be a Resident Indian National</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><i class="icon_check"></i>Applicants seeking admission into Engineering & Pharmacy Programs should have completed 16 years of age as on 31st December of the year of admission. There is no upper age limit.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><i class="icon_check"></i>The date of birth as recorded in the High School / SSC / X Certificate will be considered authentic.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><i class="icon_check"></i>Applicants should produce this certificate in the original form as proof of their age at the time of counseling/admission, failing which their candidacy for admission will be disqualified.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><i class="icon_check"></i>Candidates have to be successful in ASAT (Aditya’s Scholastic Aptitude Test)/ JEE-Main/ State-Level Engineering Entrance Exams across India including EAPCET and Merit in SportsActivities</li>
                                </ul>
                            </div>
                            {/* <div class="about__btn wow animate__fadeInUp" data-wow-duration="0.7s">
                                <Link to="/about"> Read More </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="about__content">
                            <div>
                                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Necessary requirement for Eligibility:</h2>
                                <ul>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><i class="icon_check"></i>Candidates applying for the UG Engineering admission should have either completed or shall be appearing in 2024, in any one of the following qualifying examinations</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"> A. The final examination of the 10+2 system of Higher Secondary Examination conducted by the State Board; Central Board of Secondary Education (CBSE, New Delhi), The Council for Indian School Certificate Examination (ISCE), New Delhi with Mathematics, Physics & Chemistry as the core subjects.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"> B. Intermediate or Two-year Pre-University Examination conducted by a recognized Board/ University.</li>
                                </ul>
                            </div>
                            {/* <div class="about__btn wow animate__fadeInUp" data-wow-duration="0.7s">
                                <Link to="/about"> Read More </Link>
                            </div> */}
                        </div>
                    </div>
                    {/* <div class="col-lg-6"> */}
                        {/* <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s"> */}
                            {/* <img class="react__shape__1" src={shapeImg1} alt="Shape Image" /> */}
                            {/* <img src={aboutImg} alt="About" /> */}
                        {/* </div> */}
                    {/* </div> */}
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="about__content" style={{  marginTop:"-100px"}}>
                            <div>
                                <ul>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><i class="icon_check"></i>A minimum of 60% or equivalent CGPA is mandatory for the provisional candidature.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><i class="icon_check"></i>Applicants who have completed the Class 12 (or equivalent) examination outside India or from a Board not specified above should produce a certificate from the Association of Indian Universities (AIU) to the effect that the examination they have passed is equivalent to the Class 12 Examination with grade/CGPA converted to percentage.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><i class="icon_check"></i>Students with Physics, Chemistry and Mathematics (PCM) are eligible for B.Tech Programs in Civil Engineering, Mechanical Engineering, Petroleum Technology & Mining Engineering.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><i class="icon_check"></i>Students with Physics, Chemistry & Mathematics/ Biology/ Biotechnology/ Agriculture/ Agriculture Stream are eligible for B.Tech program in Agriculture Engineering.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><i class="icon_check"></i>Students with Physics, Mathematics & Chemistry/ Computer Science/ Electronics/ Information Technology/ Biology/ Informatics Practices/ Biotechnology/Technical Vocational Subject/ Agriculture/ Engineering Graphics/ Business Studies/ Entrepreneurship are eligible for B.Tech Program in Electrical & Electronics Engineering, Electronics & Communications Engineering, Computer Science & Engineering, Information Technology, Artificial Intelligence & Machine Learning, Data Science. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EligibilityCriteriapart;