import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../components/Breadcrumb/international';
import ScrollToTop from '../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../components/Styles/pragram.css"
import point from '../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import PHD from './images/phd.jpg';



const diploma_programs = () => {

    return (
            <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container-fluid">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                 <div class="container">
                                 <center>  <img src={PHD} /> </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterTwo />

        </>
    );
}

export default diploma_programs;