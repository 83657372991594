import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/rankings';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';

import SideManu from './sidebar_credentials';
import image1 from './ranking_images/1.png';
import image2 from './ranking_images/2.png';
import image3 from './ranking_images/3.png';
import image4 from './ranking_images/4.png';
import image5 from './ranking_images/5.png';
import image6 from './ranking_images/6.png';





const accreditations = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Accreditations , Recognitions & Rankings" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                {/* <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}>Rankings</b></center> */}

                                                <div class="container row" style={{border: '1px solid #dfe2e7',boxShadow: '0 1.5px 3px 0 #00000026',padding: '11px',marginLeft: '0px',background: 'white'}}>
                                                    <div class="col-md-6 ranking_line" >
                                                    <center> 
                                                        <span>
                                                            <b style={{color:"#D2093C"}}>RANKED</b><br/> 
                                                            <b style={{color:"#00c853",fontSize:'35px'}}>29<sup>th</sup></b>
                                                        </span>
                                                        <br />
                                                        <img src={image1} />
                                                        <br />
                                                        <span className='name'><b>INDIA'S TOP 50 ENGINEERING COLLEGES SURVEY</b></span>
                                                    </center>
                                                    </div>
                                                    <div class="col-md-6">
                                                    <center> 
                                                        <span>
                                                            <b style={{color:"#D2093C"}}>RANKED</b><br/> 
                                                            <b style={{color:"#00c853",fontSize:'35px'}}>AAA+</b>
                                                        </span>
                                                        <br />
                                                        <img src={image2} />
                                                        <br />
                                                        <span className='name'><b>INDIA'S BEST ENGINEERING COLLEGES IN SOUTH ZONE</b></span>
                                                    </center>
                                                    </div>
                                                </div>
                                                <br/><br/>


                                                <div class="container row" style={{border: '1px solid #dfe2e7',boxShadow: '0 1.5px 3px 0 #00000026',padding: '11px',marginLeft: '0px',background: 'white'}}>
                                                <div class="col-md-6 ranking_line" >
                                                    <center> 
                                                        <span>
                                                            <b style={{color:"#D2093C"}}>RANKED</b><br/> 
                                                            <b style={{color:"#00c853",fontSize:'35px'}}> 4<sup>th</sup></b>
                                                        </span>
                                                        <br />
                                                        <img src={image3} />
                                                        <br />
                                                        <span className='name'><b>AMONG TOP 10 ENGINEERING COLLEGES IN SOUTH INDIA</b></span>
                                                    </center>
                                                    </div>
                                                    <div class="col-md-6">
                                                    <center> 
                                                        <span>
                                                            <b style={{color:"#D2093C"}}>RANKED</b><br/> 
                                                            <b style={{color:"#00c853",fontSize:'35px'}}>15<sup>th</sup></b>
                                                        </span>
                                                        <br />
                                                        <img src={image3} />
                                                        <br />
                                                        <span className='name'><b>AMONG TOP 100 ENGINEERING COLLEGES IN INDIA</b></span>
                                                    </center>
                                                    </div>
                                                </div>
                                                <br/><br/>

                                                <div class="container row" style={{border: '1px solid #dfe2e7',boxShadow: '0 1.5px 3px 0 #00000026',padding: '11px',marginLeft: '0px',background: 'white'}}>
                                                <div class="col-md-6 ranking_line" >
                                                    <center> 
                                                        <span>
                                                            <b style={{color:"#D2093C"}}>RANKED</b><br/>  GOLD BAND WITH
                                                            <b style={{color:"#00c853",fontSize:'35px'}}> A</b>
                                                        </span>
                                                        <br />
                                                        <img src={image4} />
                                                        <br />
                                                        <span className='name'><b>Grade IN THE GREEN RANKING 2023</b></span>
                                                    </center>
                                                    </div>
                                                    <div class="col-md-6">
                                                    <center> 
                                                        <span>
                                                            <b style={{color:"#D2093C"}}>RANKED</b><br/> 
                                                            <b style={{color:"#00c853",fontSize:'35px'}}>44<sup>th</sup></b>
                                                        </span>
                                                        <br />
                                                        <img src={image5} />
                                                        <br />
                                                        <span className='name'><b>TOP T-SCHOOLS IN INDIA 2023</b></span>
                                                    </center>
                                                    </div>
                                                </div>

                                                <br/><br/>

                                                <center>  <div class="container row" style={{border: '1px solid #dfe2e7',boxShadow: '0 1.5px 3px 0 #00000026',padding: '11px',marginLeft: '0px',background: 'white'}}>
                                                    <div class="col-md-12">
                                                    <center> 
                                                        <span>
                                                            <b style={{color:"#D2093C"}}>RANKED</b><br/> 
                                                            <b style={{color:"#00c853",fontSize:'35px'}}> 14<sup>th</sup> </b>
                                                        </span>
                                                        <br />
                                                        <img src={image6} />
                                                        <br />
                                                        {/* <span className='name'><b>As per TIMES ENGINEERING RANKINGS IN AP 2023</b></span> */}
                                                    </center>
                                                    </div>
                                                </div>
                                                </center>
                                                <br/><br/>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default accreditations;