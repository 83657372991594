import React, { useEffect, useState, useRef } from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
const backendUrl = process.env.REACT_APP_DATABASEURL;

const Admission = () => {
  const [formData, setFormData] = useState({
    how_did: '',
    name: '',
    nationality: '',
    state_city: '',
    passport_no: '',
    email: '',
    whatsapp_no: '',
    contact_no: '',
    highest: '',
    study: '',
    graduation_year: '',
    gpa_equivalent: '',
    intended_program: '',
    intended_program_other: '',
    research: '',
    resume_file: null,
    transcripts_file: null,
    liked: '',
  });

  const [errors, setErrors] = useState({});
  const how_didRef = useRef(null);
  const nameRef = useRef(null);
  const nationalityRef = useRef(null);
  const state_cityRef = useRef(null);
  const passport_noRef = useRef(null);
  const emailRef = useRef(null);
  const whatsapp_noRef = useRef(null);
  const contact_noRef = useRef(null);
  const highestRef = useRef(null);
  const studyRef = useRef(null);
  const graduation_yearRef = useRef(null);
  const gpa_equivalentRef = useRef(null);
  const intended_programRef = useRef(null);
  const researchRef = useRef(null);
  const resume_fileRef = useRef(null);
  const transcripts_fileRef = useRef(null);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Use the event parameter

    if (file) {
      // Check file extensions
      const filePath = file.name;
      const fileExtension = filePath.split('.').pop().toLowerCase(); // Convert to lowercase
      if (fileExtension !== 'pdf') {
        setErrors((prev) => ({ ...prev, [e.target.name]: 'Only PDF files are allowed.' }));
        e.target.value = ''; // Clear the file input field
        return;
      }

      const maxSize = 300 * 1024; // 300 KB in bytes
      if (file.size > maxSize) {
        setErrors((prev) => ({ ...prev, [e.target.name]: 'File size should not exceed 300 KB.' }));
        e.target.value = ''; // Clear the file input field
        return;
      }

      // Clear any existing error for the file input
      setErrors((prev) => ({ ...prev, [e.target.name]: undefined }));

      // Update formData with the valid file
      setFormData((prev) => ({ ...prev, [e.target.name]: file }));
    }
  };


  const validateForm = () => {
    const validationErrors = {};
    if (!formData.how_did.trim()) {
      validationErrors.how_did = 'This field is required';
    }
    if (!formData.name.trim()) {
      validationErrors.name = 'This field is required';
    }
    else if (!formData.nationality.trim()) {
      validationErrors.nationality = 'This field is required';
    }
    else if (!formData.state_city.trim()) {
      validationErrors.state_city = 'This field is required';
    }
    else if (!formData.passport_no.trim()) {
      validationErrors.passport_no = 'This field is required';
    }
    else if (!formData.email.trim()) {
      validationErrors.email = 'This field is required';
    }
    else if (!formData.whatsapp_no.trim()) {
      validationErrors.whatsapp_no = 'This field is required';
    }
    else if (!formData.contact_no.trim()) {
      validationErrors.contact_no = 'This field is required';
    }
    else if (!formData.highest.trim()) {
      validationErrors.highest = 'This field is required';
    }
    else if (!formData.study.trim()) {
      validationErrors.study = 'This field is required';
    }
    else if (!formData.graduation_year.trim()) {
      validationErrors.graduation_year = 'This field is required';
    }
    else if (!formData.gpa_equivalent.trim()) {
      validationErrors.gpa_equivalent = 'This field is required';
    }
    else if (!formData.intended_program.trim()) {
      validationErrors.intended_program = 'This field is required';
    }
    else if (!formData.research.trim()) {
      validationErrors.research = 'This field is required';
    }
    // Add additional validations as needed
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      
      if (validationErrors.how_did) {
        how_didRef.current.focus();
      }
      else if (validationErrors.name) {
        nameRef.current.focus();
      }
      else if (validationErrors.nationality) {
        nationalityRef.current.focus();
      }
      else if (validationErrors.state_city) {
        state_cityRef.current.focus();
      }
      else if (validationErrors.passport_no) {
        passport_noRef.current.focus();
      }
      else if (validationErrors.email) {
        emailRef.current.focus();
      }
      else if (validationErrors.whatsapp_no) {
        whatsapp_noRef.current.focus();
      }
      else if (validationErrors.contact_no) {
        contact_noRef.current.focus();
      }
      else if (validationErrors.highest) {
        highestRef.current.focus();
      }
      else if (validationErrors.study) {
        studyRef.current.focus();
      }
      else if (validationErrors.graduation_year) {
        graduation_yearRef.current.focus();
      }
      else if (validationErrors.gpa_equivalent) {
        gpa_equivalentRef.current.focus();
      }
      else if (validationErrors.intended_program) {
        intended_programRef.current.focus();
      }
      else if (validationErrors.research) {
        researchRef.current.focus();
      }
      else if (validationErrors.resume_file) {
        resume_fileRef.current.focus();
      }
      else if (validationErrors.transcripts_file) {
        transcripts_fileRef.current.focus();
      }
      return;
    }

    const formDataToSubmit = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSubmit.append(key, value);
    });
    // calling api
    try {
      const response = await fetch(`${backendUrl}international_phd_form`, {
        method: 'POST',
        body: formDataToSubmit,
      });

      if (!response.ok) {
        const errorData = await response.json();

        // duplicate email error message
        if (errorData.message === 'Email already exists.') {
          setErrors((prev) => ({ ...prev, email: 'This email is already in use.' }));
        } else {
          setErrors((prev) => ({ ...prev, submit: errorData.message || 'Submission failed' }));
        }
        return;
      }


      alert("Form submitted successfully!");
      window.location.href = '/international/PHD/apply';


      setFormData({
        how_did: '',
        name: '',
        nationality: '',
        state_city: '',
        passport_no: '',
        email: '',
        whatsapp_no: '',
        contact_no: '',
        highest: '',
        study: '',
        graduation_year: '',
        gpa_equivalent: '',
        intended_program: '',
        intended_program_other: '',
        research: '',
        resume_file: null,
        transcripts_file: null,
        liked: '',
      });
      setErrors({}); // Clear errors
    } catch (error) {
      console.error("Error during submission:", error);
      setErrors((prev) => ({ ...prev, submit: 'Server error. Please try again later.' }));
    }


  };



  return (
    <>
      <Header parentMenu='page' menuCategoryEnable='enable' />
      <form onSubmit={handleSubmit}>
        <div style={{ background: '#f2f4f9', backdropFilter: 'blur(10px)', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }} className="container mt-5 mb-5">
          <h2 style={{ textAlign: 'center' }} className="mt-5 mb-4"><b>PHD ADMISSION FORM 2025-26</b></h2>

          <div style={{ fontSize: "18px" }}>
            <span>You require a soft copy of Passport or citizenship to fill the form. </span><br />
            <span>This form is exclusively to Study in India @<b>ADITYA UNIVERSITY</b></span><br />
            <span>The scholarship is upto 60% of tuition fees only. </span><br />
            <span>You have to pay for Accommodation, Food, Visa, Travel .Accomodation is optional.</span><br />
            <span>Also like the Facebook page facebook.com/adityaedygrp before filling the form. </span><br />
            <span>For any clarity write to : <b>global@aditya.ac.in</b> </span><br />

            <br></br>
            <span>Scholarship upto 60%  :  <b>https://aditya.ac.in/aditya-admissions/ </b>  </span><br />
            <span>Whats :app +919676115608 , global@aditya.ac.in</span><br />
            <span>Brochure Link :  <b>https://shorturl.at/fjrWZ </b>   </span><br />
            <span>Video Link : Watch Here <b>//rb.gy/nz94ly</b> </span>
          </div>






          <div className="personal_details mt-5">
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="how_did ">How did you come to know about Aditya University <b style={{ color: 'red' }}>*</b></label><br />
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input" type="radio" id="college" name="how_did"  ref={how_didRef} value="college" onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="college">College</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="facebook" name="how_did" value="facebook" onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="facebook">Facebook</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="friends" name="how_did" value="friends" onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="friends">Friends</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="social_media" name="how_did" value="social_media" onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="social_media">Social Media</label>
                </div>

                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="how_did_others" name="how_did" value="others" onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="how_did_others">Others</label>
                </div>
                {errors.how_did && <span style={{ color: 'red' }}>{errors.how_did}</span>}
                {formData.how_did === 'others' && (
                  <div className="form-group col-md-6">
                    <label htmlFor="how_did_other">Please specify How did you come to know about <b style={{ color: 'red' }}>*</b></label>
                    <input type="text" className="form-control" id="how_did_other" name="how_did_other" value={formData.how_did_other || ''} onChange={handleChange}
                    />
                    {errors.how_did_other && <span style={{ color: 'red' }}>{errors.how_did_other}</span>}
                  </div>
                )}
              </div>
            </div>
            <br />
            <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>1. Personal Details:</p></strong>
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="name">Name of the Applicant <b style={{ color: 'red' }}>*</b></label>
                <input type="text" className="form-control" id="name" ref={nameRef} name="name" value={formData.name} onChange={handleChange} />
                {errors.name && <span style={{ color: 'red' }}>{errors.name}</span>}
              </div>
              <div className="row mt-3">
                <div className="form-group col-md-6">
                  <label htmlFor="nationality">Nationality <b style={{ color: 'red' }}>*</b></label>
                  <input type="text" className="form-control" id="nationality" ref={nationalityRef} name="nationality" value={formData.nationality} onChange={handleChange} />
                  {errors.nationality && <span style={{ color: 'red' }}>{errors.nationality}</span>}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="state_city">State / City <b style={{ color: 'red' }}>*</b></label>
                  <input type="test" className="form-control" id="state_city" name="state_city" ref={state_cityRef} value={formData.state_city} onChange={handleChange} />
                  {errors.state_city && <span style={{ color: 'red' }}>{errors.state_city}</span>}
                </div>
              </div>

              <div className="row mt-3">
                <div className="form-group col-md-6">
                  <label htmlFor="passport_no">Passport No<b style={{ color: 'red' }}>*</b></label>
                  <input type="text" className="form-control" id="passport_no" name="passport_no" ref={passport_noRef} value={formData.passport_no} onChange={handleChange} />
                  {errors.passport_no && <span style={{ color: 'red' }}>{errors.passport_no}</span>}

                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="email">Email <b style={{ color: 'red' }}>*</b></label>
                  <input type="email" className="form-control" id="email" name="email" ref={emailRef} value={formData.email} onChange={handleChange} />
                  {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}

                </div>
              </div>

              <div className='row mt-3'>
                <div className="form-group col-md-6">
                  <label htmlFor="whatsapp_no"> Whatsapp No (with ISD Code) <b style={{ color: 'red' }}>*</b></label>
                  <input type="tel" className="form-control" id="whatsapp_no" name="whatsapp_no" ref={whatsapp_noRef} value={formData.whatsapp_no} onChange={handleChange} />
                  {errors.whatsapp_no && <span style={{ color: 'red' }}>{errors.whatsapp_no}</span>}

                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="contact_no">Contact No (with ISD Code) <b style={{ color: 'red' }}>*</b></label>
                  <input type="tel" className="form-control" id="contact_no" name="contact_no" ref={contact_noRef} value={formData.contact_no} onChange={handleChange} />
                  {errors.contact_no && <span style={{ color: 'red' }}>{errors.contact_no}</span>}

                </div>
              </div>
            </div>
          </div>
          {/*academic details*/}
          <div className="academic_details mt-5">
            <div className='row'>
              <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>2. Academic Details  </p></strong>
              <div className="form-group col-md-6">
                <label htmlFor="highest">Highest Degree earned <b style={{ color: 'red' }}>*</b></label><br />
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="bachelors" name="highest" value="bachelors" onChange={handleChange} />
                  <label className="form-check-label" htmlFor="bachelors">Bachelors</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="masters" name="highest" value="masters" onChange={handleChange} />
                  <label className="form-check-label" htmlFor="masters">Masters</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="highest_others" name="highest" value="others" onChange={handleChange} />
                  <label className="form-check-label" htmlFor="highest_others">Others</label>
                </div>
                {errors.highest && <span style={{ color: 'red' }}>{errors.highest}</span>}

              </div>
              <div className="form-group col-md-6">
                <label htmlFor="study">Find of study <b style={{ color: 'red' }}>*</b></label>
                <input type="text" className="form-control" id="study" name="study" ref={studyRef} value={formData.study} onChange={handleChange} />
                {errors.study && <span style={{ color: 'red' }}>{errors.study}</span>}
              </div>

            </div>

            <div className='row mt-3'>
              <div className="form-group col-md-6">
                <label htmlFor="graduation_year">Graduation Year <b style={{ color: 'red' }}>*</b></label>
                <input type="graduation_year" className="form-control" id="graduation_year" name="graduation_year" ref={graduation_yearRef} value={formData.graduation_year} onChange={handleChange} />
                {errors.graduation_year && <span style={{ color: 'red' }}>{errors.graduation_year}</span>}
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="gpa_equivalent">GPA or Equivalent <b style={{ color: 'red' }}>*</b></label>
                <input type="tel" className="form-control" id="gpa_equivalent" name="gpa_equivalent" ref={gpa_equivalentRef} value={formData.gpa_equivalent} onChange={handleChange} />
                {errors.gpa_equivalent && <span style={{ color: 'red' }}>{errors.gpa_equivalent}</span>}
              </div>

            </div>

            <div className='row mt-3'>
              <div className="form-group col-md-6">
                <label htmlFor="intended_program">Intended program of studying Ph.D in <b style={{ color: 'red' }}>*</b></label><br />
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input" type="radio" id="engineering" name="intended_program" value="engineering" onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="engineering">Engineering</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="management" name="intended_program" value="management" onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="management">Management</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="intended_program_others" name="intended_program" value="others" onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="intended_program_others">Others</label>
                </div>
                {errors.intended_program && <span style={{ color: 'red' }}>{errors.intended_program}</span>}
                {formData.intended_program === 'others' && (
                  <div className="form-group col-md-6">
                    <label htmlFor="intended_program_other">Please specify Intended program <b style={{ color: 'red' }}>*</b></label>
                    <input type="text" className="form-control" id="intended_program_other" name="intended_program_other" value={formData.intended_program_other || ''} onChange={handleChange}
                    />
                    {errors.intended_program_other && <span style={{ color: 'red' }}>{errors.intended_program_other}</span>}
                  </div>
                )}
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="research">Research interests <b style={{ color: 'red' }}>*</b></label>
                <input type="text" className="form-control" id="research" name="research" ref={researchRef} value={formData.research} onChange={handleChange}
                />
                {errors.research && <span style={{ color: 'red' }}>{errors.research}</span>}
              </div>
            </div>
          </div>


          {/* Supporting Documents */}
          <div className='Publications mt-5'>
            <div className='row'>
              <strong><p style={{ fontSize: '20px', color: '#d3143f' }}>3. Supporting Documents</p></strong>
              <div className='col-md-6'>
                <label htmlFor="resume_file">CV/ Resume Upload <b style={{ color: 'red' }}>*</b></label>
                <input
                  type="file"
                  className="form-control-file"
                  id="resume_file"
                  name="resume_file"
                  ref={resume_fileRef}
                  onChange={handleFileChange} required
                />
                <br />
                {errors.resume_file && <span style={{ color: 'red' }}>{errors.resume_file}</span>}
                <br />
                <b style={{ color: 'red' }}>NOTE :</b> <br />
                1. Please Upload <b>(PDF File)</b> Only.<br />
                2. File Size Should not Exceed <b>300</b> KB


              </div>

              <div className="form-group col-md-6">
                <label htmlFor="transcripts_file">Transcripts Upload <b style={{ color: 'red' }}>*</b></label>
                <input
                  type="file"
                  className="form-control-file"
                  id="transcripts_file"
                  name="transcripts_file"
                  ref={transcripts_fileRef}
                  onChange={handleFileChange} required
                />
                <br />
                {errors.transcripts_file && <span style={{ color: 'red' }}>{errors.transcripts_file}</span>}
                <br />
                <b style={{ color: 'red' }}>NOTE :</b> <br />
                1. Please Upload <b>(PDF File)</b> Only.<br />
                2. File Size Should not Exceed <b>300</b> KB

              </div>
            </div>
            <br />
            <div className='row'>
              <div className="form-group col-md-6">
                <label htmlFor="liked">Liked the page facebook.com/adityaedugrp <b style={{ color: 'red' }}>*</b></label><br />
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input" type="radio" id="yes" name="liked" value="yes" onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="yes">Yes</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="no" name="liked" value="no" onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="no">No</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="maybe" name="liked" value="maybe" onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="maybe">Maybe</label>
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className='mt-5'><center><button type='submit' className='btn btn-danger'> Submit </button></center></div>
        </div>
        <FooterTwo />
      </form>
    </>
  );
}

export default Admission;
