import React from 'react';
import { useState } from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_mining';
import Banner from './banner';
// import MiningPopUp from "../../../../../components/Popup/Mining/MiningPopUp";






const CEview = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    {/* <MiningPopUp /> */}
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  B.Tech </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>Mining Engineering is a multidisciplinary field that integrates principles from geology, geotechnical, mechanical, environmental, safety, mineral, and metallurgy engineering, as well as applications from electrical, electronics, and software engineering. This comprehensive program equips students with an in-depth understanding of the mining industry, focusing on core disciplines such as geological mapping, rock mechanics, mining equipment design, sustainable practices, risk management, and mineral extraction. Additionally, it involves interdisciplinary applications like electrical systems and software knowledge for analytics, data science, mine planning and optimization.</p>

                                                        <p className='name'>Graduates of this program have diverse career opportunities in government organizations like the Indian Bureau of Mines (IBM), Mineral Exploration Corporation Limited (MECL), and the Directorate General of Mines Safety (DGMS), as well as in public sector enterprises such as Coal India Limited (CIL), National Thermal Power Corporation (NTPC), and Steel Authority of India Limited (SAIL). Private sector opportunities include companies like Tata Steel and Vedanta Resources. For those interested in higher education, options include Master of Technology (M.Tech.), Master of Science (MS) from universities abroad, Master of Business Administration (MBA), Doctor of Philosophy (Ph.D.) in Mining Engineering, and various graduate certificate programs.</p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Mining Engineering <br />
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Mining Engineering with Minor Specialization  in
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Environment and Safety in Mining</span><br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Innovative Mining</span><br />
                                                        </p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Mining Engineering with
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Civil  Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Electrical and Electronics Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Mechanical Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Electronics and Communication Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Computer Science and Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Data Science<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Artificial Intelligence and Machine Learning<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Petroleum Technology<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Agricultural Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Entrepreneurship Development and Incubation<br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;